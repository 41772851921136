var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        [_c("l10n", { attrs: { value: "Optimization run name" } })],
        1
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { placeholder: _vm.configName },
        domProps: { value: _vm.runName },
        on: { input: _vm.onRunNameInput }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "gp-check",
          {
            attrs: { checked: _vm.temporaryRun },
            on: { change: _vm.onTemporaryRunChange }
          },
          [_c("l10n", { attrs: { value: "Don't save optimization results" } })],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.availableOptimizationTypes.length
      ? _c("div", { staticClass: "form-group optimization-types" }, [
          _c(
            "label",
            [_c("l10n", { attrs: { value: "Optimization type" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.availableOptimizationTypes, function(optimizationType) {
              return _c(
                "gp-check",
                {
                  key: optimizationType.code,
                  ref: "optimizationTypeCheckbox",
                  refInFor: true,
                  attrs: {
                    checked: _vm.checkedOptimizationTypes.includes(
                      optimizationType.code
                    ),
                    type: _vm.multipleTypes ? "checkbox" : "radio",
                    name: "optimization-type"
                  },
                  on: {
                    click: function($event) {
                      return _vm.onOptimizationTypeClick(optimizationType.code)
                    }
                  }
                },
                [_c("l10n", { attrs: { value: optimizationType.name } })],
                1
              )
            }),
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }