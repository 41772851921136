var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "class-attribute-select" },
    [
      _c(
        "h6",
        { staticClass: "class-attribute-select-header" },
        [_c("l10n", { attrs: { value: "Привязка атрибута" } })],
        1
      ),
      _vm._v(" "),
      _c("gp-treeview", {
        key: _vm.key,
        attrs: { data: _vm.treeData },
        on: { "item-click": _vm.onItemClick }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }