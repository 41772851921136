var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "gp-date",
      class: { focused: _vm.focused },
      attrs: { title: _vm.warningText }
    },
    [
      _c("a", { attrs: { href: "javascript:void(0)" } }, [
        _c("span", [_vm._v(_vm._s(_vm.formattedDate))]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.modelValue,
              expression: "modelValue"
            }
          ],
          ref: "input",
          staticClass: "form-control form-control-sm",
          attrs: { type: "date", required: "", min: _vm.min, max: _vm.max },
          domProps: { value: _vm.modelValue },
          on: {
            blur: _vm.handleBlur,
            focus: _vm.handleFocus,
            keydown: _vm.handleKeyDown,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.modelValue = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.warningText
        ? _c("feather-icon", { attrs: { name: "alert-circle" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }