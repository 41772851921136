var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "discard-buttons-container" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-danger",
        attrs: { type: "button" },
        on: { click: _vm.onClickDiscardEdits }
      },
      [_c("l10n", { attrs: { value: "Discard all edits" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-danger",
        attrs: { type: "button", disabled: !_vm.isUserAdmin },
        on: { click: _vm.onClickClearStream }
      },
      [_c("l10n", { attrs: { value: "Clear stream" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }