var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plain-table-tile-size" },
    [
      _c("feather-icon", { attrs: { name: "maximize-2" } }),
      _vm._v(" "),
      _c("label", [_vm._v("Tile size")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-sm",
        attrs: { type: "number", min: "100", max: "1000", step: "10" },
        domProps: { value: _vm.value },
        on: {
          change: function($event) {
            return _vm.$emit("change", $event.target.value)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }