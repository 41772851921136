var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.model,
          expression: "model"
        }
      ],
      ref: "input",
      staticClass: "form-control form-control-sm",
      attrs: { multiple: _vm.multiple },
      on: {
        keyup: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.$emit("submit")
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
            ) {
              return null
            }
            return _vm.$emit("cancel")
          }
        ],
        change: function($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function(o) {
              return o.selected
            })
            .map(function(o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.model = $event.target.multiple ? $$selectedVal : $$selectedVal[0]
        }
      }
    },
    _vm._l(_vm.selectOptions, function(key, name) {
      return _c("option", { key: key, domProps: { value: name } }, [
        _vm._v("\n    " + _vm._s(name) + "\n  ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }