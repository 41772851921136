<template>
  <div class="gp-endings">
    <table>
      <tr>
        <th
          class="gp-endings-all"
          :class="{
            active: isAllActive(),
          }"
          @click="toogleAll()"
        >
          *
        </th>
        <th
          v-for="i in digitsAsStr"
          :key="i"
          :class="{
            active: isColActive(i),
          }"
          @click="toogleCol(i)"
        >
          {{ `${prefix}${i}0${suffix}` }}
        </th>
      </tr>
      <tr
        v-for="j in digitsAsStr"
        :key="j"
      >
        <th
          :class="{
            active: isRowActive(j),
          }"
          @click="toogleRow(j)"
        >
          {{ `${prefix}0${j}${suffix}` }}
        </th>
        <td
          v-for="i in digitsAsStr"
          :key="i"
          :class="{
            active: endings[i + j],
          }"
          @click="toogleCell(i, j)"
        >
          {{ `${prefix}${i}${j}${suffix}` }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
module.exports = {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      digitsAsStr: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      endings: _(this.value).map((ending) => [ending, true]).fromPairs().value(),
    };
  },
  methods: {
    isAllActive() {
      return _.every(this.digitsAsStr, (i) => _.every(this.digitsAsStr, (j) => this.endings[i + j]));
    },
    isRowActive(j) {
      return _.every(this.digitsAsStr, (i) => this.endings[i + j]);
    },
    isColActive(i) {
      return _.every(this.digitsAsStr, (j) => this.endings[i + j]);
    },
    toogleAll() {
      if (this.isAllActive()) {
        this.endings = {};
      } else {
        this.digitsAsStr.forEach((i) => {
          this.digitsAsStr.forEach((j) => {
            this.$set(this.endings, i + j, true);
          });
        });
      }
      this.$emit('change', this.currentValue);
    },
    toogleRow(j) {
      const flag = !this.isRowActive(j);

      this.digitsAsStr.forEach((i) => this.$set(this.endings, i + j, flag));
      this.$emit('change', this.currentValue);
    },
    toogleCol(i) {
      const flag = !this.isColActive(i);

      this.digitsAsStr.forEach((j) => this.$set(this.endings, i + j, flag));
      this.$emit('change', this.currentValue);
    },
    toogleCell(i, j) {
      this.$set(this.endings, i + j, !this.endings[i + j]);
      this.$emit('change', this.currentValue);
    },
  },
  computed: {
    currentValue() {
      return _(this.endings)
        .toPairs()
        .filter(([, flag]) => flag)
        .map(([ending]) => ending)
        .sortBy()
        .value();
    },
  },
  watch: {
    value() {
      this.endings = _(this.value)
        .map((ending) => [ending, true])
        .fromPairs()
        .value();
    },
  },
};
</script>
<style>
.gp-rule-popup .gp-endings {
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--gray);
    border-radius: 5px;
}
.gp-endings {
    font-size: 0.9em;
}
.gp-endings tr > * {
    color: var(--cyan);
    padding: 2px 3px;
    cursor: pointer;
    text-align: center;
}
.gp-endings tr > *:hover {
    text-decoration: underline;
}
.gp-endings tr:first-child > * {
    border-bottom: 1px solid var(--gray);
}
.gp-endings tr > *:first-child {
    border-right: 1px solid var(--gray);
}
.gp-endings .active {
    color: white;
    background-color: var(--cyan);
}
</style>
