var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-design-panel" },
    [
      _c("ul", { staticClass: "nav nav-tabs" }, [
        _c(
          "li",
          { staticClass: "nav-item", staticStyle: { position: "relative" } },
          [
            _c("small", { staticClass: "text-muted" }, [_vm._v("^1")]),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { "nav-link": true, active: _vm.editTab === "template" },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.editTab = "template"
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Template" } })],
              1
            ),
            _vm._v(" "),
            _vm.textWarning
              ? _c(
                  "span",
                  { attrs: { id: "my-text-warning", title: _vm.textWarning } },
                  [_c("feather-icon", { attrs: { name: "alert-triangle" } })],
                  1
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "nav-item", staticStyle: { position: "relative" } },
          [
            _c("small", { staticClass: "text-muted" }, [_vm._v("^2")]),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { "nav-link": true, active: _vm.editTab === "elements" },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.editTab = "elements"
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Elements" } })],
              1
            ),
            _vm._v(" "),
            _vm.dataWarning
              ? _c(
                  "span",
                  { attrs: { id: "my-data-warning", title: _vm.dataWarning } },
                  [_c("feather-icon", { attrs: { name: "alert-triangle" } })],
                  1
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c("small", { staticClass: "text-muted" }, [_vm._v("^3")]),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { "nav-link": true, active: _vm.editTab === "sources" },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.editTab = "sources"
                }
              }
            },
            [_c("l10n", { attrs: { value: "Schema" } })],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c("small", { staticClass: "text-muted" }, [_vm._v("^4")]),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { "nav-link": true, active: _vm.editTab === "assets" },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.editTab = "assets"
                }
              }
            },
            [_c("l10n", { attrs: { value: "Assets" } })],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c("small", { staticClass: "text-muted" }, [_vm._v("^5")]),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { "nav-link": true, active: _vm.editTab === "versions" },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.editTab = "versions"
                }
              }
            },
            [_c("l10n", { attrs: { value: "Versions" } })],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c("small", { staticClass: "text-muted" }, [_vm._v("^6")]),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { "nav-link": true, active: _vm.editTab === "sitemap" },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.editTab = "sitemap"
                }
              }
            },
            [_c("l10n", { attrs: { value: "Sitemap" } })],
            1
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c("small", { staticClass: "text-muted" }, [_vm._v("^6")]),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { "nav-link": true, active: _vm.editTab === "variables" },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.editTab = "variables"
                }
              }
            },
            [_c("l10n", { attrs: { value: "Variables" } })],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.visitedTabs.template
        ? _c("ace-editor", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editTab === "template",
                expression: "editTab === 'template'"
              }
            ],
            ref: "templateEditor",
            attrs: {
              value: _vm.text,
              mode: "markdown",
              persistKey: _vm.path + "text/state",
              darkTheme: _vm.darkTheme,
              readOnly: !_vm.original
            },
            on: {
              change: function($event) {
                return _vm.$emit("change:text", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.visitedTabs.elements
        ? _c("ace-editor", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editTab === "elements",
                expression: "editTab === 'elements'"
              }
            ],
            ref: "elementsEditor",
            attrs: {
              value: _vm.data,
              mode: "yaml",
              "persist-key": _vm.path + "data/state",
              darkTheme: _vm.darkTheme,
              readOnly: !_vm.original,
              getCompletions: _vm.getCompletions
            },
            on: {
              change: function($event) {
                return _vm.$emit("change:data", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.visitedTabs.variables
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editTab === "variables",
                  expression: "editTab === 'variables'"
                }
              ],
              staticClass: "my-variables-view"
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.variablesRoot,
                        expression: "variablesRoot"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "Elements path" },
                    domProps: { value: _vm.variablesRoot },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.variablesRoot = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("my-search", {
                    staticClass: "form-control",
                    attrs: { placeholder: "Search..." },
                    model: {
                      value: _vm.variablesSearch,
                      callback: function($$v) {
                        _vm.variablesSearch = $$v
                      },
                      expression: "variablesSearch"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("ace-editor", {
                attrs: {
                  readOnly: true,
                  mode: "yaml",
                  darkTheme: _vm.darkTheme
                },
                model: {
                  value: _vm.variables,
                  callback: function($$v) {
                    _vm.variables = $$v
                  },
                  expression: "variables"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visitedTabs.sources
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editTab === "sources",
                  expression: "editTab === 'sources'"
                }
              ],
              staticClass: "my-sources-view"
            },
            [
              _vm.selectedStream
                ? _c(
                    "div",
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-secondary btn-sm",
                          staticStyle: {
                            float: "left",
                            "margin-right": "10px",
                            "margin-top": "-3px"
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedStream = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { name: "chevron-left" }
                          }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Streams" } })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h5", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.selectedStream.name) +
                            "\n                "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.stream.reload()
                              }
                            }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { name: "refresh-cw" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/admin#/dataset/streams/" +
                                _vm.selectedStream.name,
                              target: "_blank"
                            }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { name: "external-link" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("my-description", {
                        attrs: { text: _vm.selectedStream.description }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "td",
                                { attrs: { colspan: "6" } },
                                [
                                  _c("gp-check", {
                                    attrs: {
                                      checked:
                                        _vm.selectedStreamHideColumns.length !=
                                        _vm.selectedStream.columns.length,
                                      indeterminate:
                                        _vm.selectedStreamHideColumns.length >
                                          0 &&
                                        _vm.selectedStreamHideColumns.length <
                                          _vm.selectedStream.columns.length
                                    },
                                    on: {
                                      change: function($event) {
                                        $event
                                          ? _vm.selectedStreamHideColumns.splice(
                                              0,
                                              _vm.selectedStreamHideColumns
                                                .length
                                            )
                                          : (_vm.selectedStreamHideColumns = _vm.selectedStream.columns.map(
                                              function(column) {
                                                return column.gqlName
                                              }
                                            ))
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.selectedStream.columns, function(
                              column
                            ) {
                              return _c("tr", { key: column.name }, [
                                _c(
                                  "td",
                                  [
                                    _c("gp-check", {
                                      attrs: {
                                        inline: true,
                                        checked: !_vm.selectedStreamHideColumns.includes(
                                          column.gqlName
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          $event
                                            ? _vm.selectedStreamHideColumns.splice(
                                                _vm.selectedStreamHideColumns.indexOf(
                                                  column.gqlName
                                                ),
                                                1
                                              )
                                            : _vm.selectedStreamHideColumns.push(
                                                column.gqlName
                                              )
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          column.synonym || column.gqlName
                                        ) +
                                        "\n                            "
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(column.type))
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  column.indexed
                                    ? _c("span", [_vm._v("indexed")])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  column.name != column.gqlName
                                    ? _c("span", [_vm._v(_vm._s(column.name))])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  column.synonym &&
                                  column.synonym != column.gqlName
                                    ? _c("span", [
                                        _vm._v(_vm._s(column.gqlName))
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("my-description", {
                                      attrs: { text: column.description }
                                    })
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("gp-stream", {
                            ref: "stream",
                            attrs: {
                              stream: _vm.selectedStream.name,
                              "hide-columns": _vm.selectedStreamHideColumns
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.selectedReport
                ? _c(
                    "div",
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-secondary btn-sm",
                          staticStyle: {
                            float: "left",
                            "margin-right": "10px",
                            "margin-top": "-3px"
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.selectedReport = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { name: "chevron-left" }
                          }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Reports" } })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h5", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.selectedReport.name) +
                            "\n                "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.report.reload()
                              }
                            }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { name: "refresh-cw" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/admin#/dataset/reports/" +
                                _vm.selectedReport.name,
                              target: "_blank"
                            }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { name: "external-link" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("my-description", {
                        attrs: { text: _vm.selectedReport.description }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("table", [
                          _c(
                            "tbody",
                            _vm._l(_vm.selectedReportColumns, function(column) {
                              return _c("tr", { key: column.name }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(column.synonym || column.gqlName)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(column.type))
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  column.indexed
                                    ? _c("span", [_vm._v("indexed")])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  column.name != column.gqlName
                                    ? _c("span", [_vm._v(_vm._s(column.name))])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-muted" }, [
                                  column.synonym &&
                                  column.synonym != column.gqlName
                                    ? _c("span", [
                                        _vm._v(_vm._s(column.gqlName))
                                      ])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("my-description", {
                                      attrs: { text: column.description }
                                    })
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("gp-report", {
                            ref: "report",
                            attrs: { report: _vm.selectedReport.name },
                            on: {
                              columns: function($event) {
                                _vm.selectedReportColumns = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c("div", [
                    _c("h5", [
                      _c(
                        "a",
                        {
                          class: { active: _vm.schemaTab == "streams" },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.schemaTab = "streams"
                            }
                          }
                        },
                        [_c("l10n", { attrs: { value: "Streams" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          class: { active: _vm.schemaTab == "reports" },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.schemaTab = "reports"
                            }
                          }
                        },
                        [_c("l10n", { attrs: { value: "Reports" } })],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("my-search", {
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.schemaTab == "streams"
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("table", [
                            _c(
                              "tbody",
                              _vm._l(_vm.streams, function(stream) {
                                return !_vm.search ||
                                  stream.name.indexOf(_vm.search) !== -1
                                  ? _c("tr", { key: stream.name }, [
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.selectedStream = stream
                                                _vm.selectedStreamHideColumns = []
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(stream.name) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-muted" }, [
                                        stream.paused
                                          ? _c("span", [_vm._v("paused")])
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-muted",
                                          staticStyle: { "text-align": "right" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              Number(
                                                stream.size
                                              ).toLocaleString()
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-muted" },
                                        [
                                          _c("my-description", {
                                            attrs: { text: stream.description }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e()
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.schemaTab == "reports"
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("table", [
                            _c(
                              "tbody",
                              _vm._l(_vm.reports, function(report) {
                                return !_vm.search ||
                                  report.name.indexOf(_vm.search) !== -1
                                  ? _c("tr", { key: report.name }, [
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.selectedReport = report
                                                _vm.selectedReportColumns = null
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(report.name) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editTab === "assets",
              expression: "editTab === 'assets'"
            }
          ],
          staticClass: "my-assets-view"
        },
        [
          _vm.selectedAsset
            ? [
                _c(
                  "div",
                  { staticClass: "my-selected-asset-head" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-xs btn-secondary",
                        class:
                          _vm.selectedAsset.data !== _vm.selectedAsset._data
                            ? "disabled"
                            : null,
                        on: { click: _vm.tryDismissAsset }
                      },
                      [_c("feather-icon", { attrs: { name: "chevron-left" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.selectedAsset.link,
                          target: "_blank",
                          nofollow: ""
                        }
                      },
                      [_c("h3", [_vm._v(_vm._s(_vm.selectedAsset.name))])]
                    ),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "flex-grow": "1" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.formatSize(_vm.selectedAsset.size)))
                    ]),
                    _vm._v(" "),
                    _vm.selectedAsset.name.endsWith(".yml") ||
                    _vm.selectedAsset.name.endsWith(".md") ||
                    _vm.selectedAsset.name.endsWith(".js") ||
                    _vm.selectedAsset.name.endsWith(".css")
                      ? [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-xs btn-primary",
                              attrs: {
                                disabled:
                                  _vm.selectedAsset.data ===
                                  _vm.selectedAsset._data,
                                id: "submit-asset"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitAssetChanges(
                                    _vm.selectedAsset
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Submit changes\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-xs btn-secondary",
                              attrs: {
                                disabled:
                                  _vm.selectedAsset.data ===
                                  _vm.selectedAsset._data,
                                id: "discard-asset"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.discardAssetChanges(
                                    _vm.selectedAsset
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Discard changes\n                    "
                              )
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.selectedAsset.data !== undefined &&
                (_vm.selectedAsset.name.endsWith(".yml") ||
                  _vm.selectedAsset.name.endsWith(".md") ||
                  _vm.selectedAsset.name.endsWith(".js") ||
                  _vm.selectedAsset.name.endsWith(".css"))
                  ? _c("ace-editor", {
                      attrs: {
                        darkTheme: _vm.darkTheme,
                        mode: _vm.selectedAsset.name.endsWith(".yml")
                          ? "yaml"
                          : _vm.selectedAsset.name.endsWith(".md")
                          ? "markdown"
                          : _vm.selectedAsset.name.endsWith(".js")
                          ? "javascript"
                          : _vm.selectedAsset.name.endsWith(".css")
                          ? "css"
                          : "plain_text"
                      },
                      model: {
                        value: _vm.selectedAsset.data,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedAsset, "data", $$v)
                        },
                        expression: "selectedAsset.data"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedAsset.name.endsWith(".png") ||
                _vm.selectedAsset.name.endsWith(".svg") ||
                _vm.selectedAsset.name.endsWith(".jpg") ||
                _vm.selectedAsset.name.endsWith(".jpeg")
                  ? _c("img", { attrs: { src: _vm.selectedAsset.link } })
                  : _vm._e()
              ]
            : [
                _c(
                  "table",
                  { staticClass: "table table-sm table-hover table-striped" },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.assets, function(asset) {
                          return _c("tr", { key: asset.name }, [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.selectedAsset = asset
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { name: "edit" }
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(asset.name) +
                                      "\n                            "
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.formatSize(asset.size)))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              asset.name !== "elements.yml" &&
                              asset.name !== "template.md" &&
                              asset.name !== "translation.json"
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteAsset(asset)
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "trash" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ])
                        }),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: { click: _vm.createAsset }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { name: "plus-square" }
                                }),
                                _vm._v(
                                  "\n                                create new asset\n                            "
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ],
                      2
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "dropZone",
                    staticClass: "drop-zone",
                    on: {
                      dragover: function($event) {
                        $event.preventDefault()
                        _vm.$refs.dropZone.classList.add("highlight")
                      },
                      dragenter: function($event) {
                        $event.preventDefault()
                        _vm.$refs.dropZone.classList.add("highlight")
                      },
                      dragleave: function($event) {
                        $event.preventDefault()
                        _vm.$refs.dropZone.classList.remove("highlight")
                      },
                      drop: function($event) {
                        $event.preventDefault()
                        _vm.$refs.dropZone.classList.remove("highlight")
                        _vm.uploadAssets($event)
                      }
                    }
                  },
                  [
                    _c("l10n", {
                      attrs: { value: "Drag & drop new files here" }
                    })
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.editTab === "versions"
        ? _c("div", { staticClass: "my-versions-view" }, [_c("backups")], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.editTab === "sitemap"
        ? _c(
            "div",
            { staticClass: "my-sitemap-view" },
            [_c("sitemap", { attrs: { path: _vm.path } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }