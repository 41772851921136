module.exports = _.merge({
    props: {
        xTickFormat:        { type: [Function, String], /*default: _.identity*/ },
        yTickFormat:        { type: [Function, String], /*default: _.identity*/ },
        rightYTickFormat:   { type: [Function, String], /*default: _.identity*/ },
        xTickAngle:         { type: Number, default: 0 },
        labelAngle:         { type: Number, default: 0 },
        xTicks:             { type: Number },
        yTicks:             { type: Number },
        rightYTicks:        { type: Number },
    },
    watch: {
        xTickAngle:         function(x) { this.instantRenderChart() },
        labelAngle:         function(x) { this.instantRenderChart() },
        xTickFormat:        function(x) { this.createChart() },
        yTickFormat:        function(x) { this.createChart() },
        rightYTickFormat:   function(x) { this.createChart() },
        xTicks:             function(x) { this.createChart() },
        yTicks:             function(x) { this.createChart() },
    },
    methods: {
        tuneChart(chart) {
            chart.selectAll(".axis text").finishTextTransitons()

            this.setupTooltips(chart)

            if (this.xTickAngle != 0) {
                let textAnchor = ""
                let transform = ""
                if (this.xTickAngle > 0) {
                    textAnchor = "start"
                }
                if (this.xTickAngle < 0) {
                    textAnchor = "end"
                }
                if (this.xTickAngle != 0) {
                    transform = `translatey(10px) rotate(${this.xTickAngle}deg) translatey(-12px)`
                }
                chart
                    .select(".axis.x")
                    .selectAll("text")
                    .attr("text-anchor", textAnchor)
                    .style("transform", transform)
                    .style("transform-origin", function (d) {
                        let x = this.getAttribute("x")
                        let y = this.getAttribute("y")
                        let dy = this.getAttribute("dy")
                        return `${x}px ${y+dy}px`
                    })
            }
            if (this.labelAngle != 0) {
                let transformOrigin = ""
                let transform = ""
                let textAnchor = ""
                if (this.labelAngle > 0) {
                    textAnchor = "end"
                    // transformOrigin = "100% -9px"
                }
                if (this.labelAngle < 0) {
                    textAnchor = "start"
                    // transformOrigin = "0 9px"
                }
                if (this.labelAngle != 0) {
                    transform = `translatey(0) rotate(${this.labelAngle}deg) translatey(4px)`
                }
                chart
                    .selectAll("text.barLabel")
                    .attr("text-anchor", textAnchor)
                    .style("transform-origin", function (d) {
                        let x = this.getAttribute("x")
                        let y = this.getAttribute("y")
                        return `${x}px ${y}px`
                    })
                    .style("transform", transform)
            }
            chart
                .selectAll(".axis.x .tick text")
                .style("pointer-events", "visible")
                .on("click", (d) => chart.filter(d))

            chart
                .select(".chart-body")
                .selectAll(".node text")
                .each(function(d) {
                    let self = d3.select(this)
                    let parts = self.text().split(/\n/g)
                    if (parts.length > 1) {
                        self.text("")
                        self.style("transform", `translatey(-${parts.length/2*1.2+0.2}em)`)
                        for (part of parts) {
                            self.append("tspan")
                                .attr("x", 0)
                                .attr("dy","1.2em")
                                .text(part)
                        }
                    }
                })

            chart
                .select(".axis.x")
                .selectAll("text")
                .each(function(d) {
                    let self = d3.select(this)
                    let parts = self.text().split(/\n/g)
                    if (parts.length > 1) {
                        self.text("")
                        for (part of parts) {
                            self.append("tspan")
                                .attr("x", 0)
                                .attr("dy","1.2em")
                                .text(part)
                        }
                    }
                })
        }
    }
}, require("./props").chartProps({
    brush:                      { type: [Function, String], default: "d3.brushX()" },
    brushOn:                    { type: Boolean, default: true },
    clipPadding:                { type: Number, default: 5 },
    elasticX:                   { type: Boolean, default: false },
    elasticY:                   { type: Boolean, default: false },
    mouseZoomable:              { type: Boolean, default: false },
    renderHorizontalGridLines:  { type: Boolean, default: false },
    renderVerticalGridLines:    { type: Boolean, default: false },
    round:                      { type: [Function, String], default: undefined },
    useRightYAxis:              { type: Boolean, default: false },
    x:                          { type: [Function, String], default: "d3.scaleLinear().domain([])" },
    xAxisLabel:                 { type: String, default: undefined },
    xAxisPadding:               { type: [String, Number], default: 0 },
    xUnits:                     { type: [Function, String], default: (start, end) => Math.abs(end-start) },
    y:                          { type: [Function, String], default: "d3.scaleLinear().domain([])" },
    yAxisLabel:                 { type: String, default: undefined },
    yAxisPadding:               { type: [String, Number], default: 0 },
    zoomOutRestrict:            { type: Boolean, default: true },
    zoomScale:                  { type: Array, default: () => [1, Infinity] },
}))
