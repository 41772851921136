var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "base", staticClass: "gp-rules" },
    [
      _vm.showRulesMigrateTool
        ? _c("MigrationTool", { attrs: { "rules-library": _vm.rulesLibrary } })
        : _vm._e(),
      _vm._v(" "),
      _c("gp-stored", {
        attrs: {
          family: _vm.storedFamilyName,
          username: _vm.username,
          "share-groups": _vm.shareGroups
        },
        on: { saved: _vm.configChanged, change: _vm.configChanged },
        model: {
          value: _vm.config,
          callback: function($$v) {
            _vm.config = $$v
          },
          expression: "config"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_c("l10n", { attrs: { value: "Rules scope" } })], 1),
          _vm._v(" "),
          _c("gp-filter", {
            attrs: {
              stream: _vm.stream,
              source: _vm.source,
              groups: _vm.groups,
              filter0: _vm.extraFilter0,
              filter1: _vm.extraFilter1,
              filter2: _vm.extraFilter2,
              threshold: _vm.threshold,
              attributes: _vm.attributes,
              formulas: _vm.formulas,
              vars: _vm.vars
            },
            model: {
              value: _vm.editingFilter,
              callback: function($$v) {
                _vm.editingFilter = $$v
              },
              expression: "editingFilter"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gp-rules-hint" },
        [
          _c("l10n", {
            attrs: { value: "Use drag & drop to reorder sections and rules" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gp-rules-weight" },
        [
          _c("l10n", { attrs: { value: "Rule weight" } }),
          _vm._v(" "),
          _c("inline-help", {
            attrs: {
              text:
                "Optimization minimizes cummulative rules violation measured in price delta beyond boundaries defined by rules. Rule weight allows to control importance of every rule or an entire section of rules."
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "sections", staticClass: "gp-rules-sections" },
        [
          _vm._l(_vm.editingSections, function(section) {
            return _c(
              "div",
              {
                key: section.id,
                staticClass: "gp-rules-section",
                class: { expanded: _vm.expandedSections[section.id] },
                attrs: { "data-section": section.id }
              },
              [
                _c(
                  "label",
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.selectedSection = section
                          }
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { name: "more-vertical" } })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "gp-rules-weight",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.promptSectionWeight(section)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPercent(
                              section.weight !== undefined
                                ? section.weight * 100
                                : 100
                            )
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.types.length > 1
                      ? _c(
                          "div",
                          { staticClass: "gp-section-opt-types-icons" },
                          _vm._l(_vm.types, function(sectionType, idx) {
                            return _c(
                              "span",
                              {
                                key: idx,
                                class: {
                                  active: section.types.includes(
                                    sectionType.code
                                  )
                                }
                              },
                              [
                                _c("l10n", {
                                  attrs: { value: sectionType.icon }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.$set(
                              _vm.expandedSections,
                              section.id,
                              !_vm.expandedSections[section.id]
                            )
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            name: _vm.expandedSections[section.id]
                              ? "chevrons-down"
                              : "chevrons-right"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.sectionName(section)))])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("–")]),
                    _vm._v(" "),
                    _c("l10n", {
                      attrs: {
                        value: "{rules} price rules",
                        rules: new Number(section.rules.length).toLocaleString()
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                section.rules.length
                  ? _c(
                      "a",
                      {
                        staticClass: "gp-section-expand-collapse",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.isAllRulesExpanded(section)
                              ? _vm.collapseAllRules(section)
                              : _vm.expandAllRules(section)
                          }
                        }
                      },
                      [
                        _c("l10n", {
                          attrs: {
                            value: _vm.isAllRulesExpanded(section)
                              ? "collapse all"
                              : "expand all"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.types.length > 1 && _vm.expandedSections[section.id]
                  ? _c(
                      "div",
                      { staticClass: "gp-section-opt-types-checks" },
                      _vm._l(_vm.types, function(sectionType, idx) {
                        return _c(
                          "gp-check",
                          {
                            key: idx,
                            attrs: {
                              checked: section.types.includes(sectionType.code)
                            },
                            on: {
                              click: function($event) {
                                return _vm.toogleSectionType(
                                  section,
                                  sectionType.code
                                )
                              }
                            }
                          },
                          [_c("l10n", { attrs: { value: sectionType.name } })],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.expandedSections[section.id]
                  ? _c(
                      "ul",
                      {
                        ref: "rules",
                        refInFor: true,
                        staticClass: "gp-rules-list"
                      },
                      _vm._l(section.rules, function(rule, i) {
                        return _c(
                          "li",
                          {
                            key: rule.id,
                            class: {
                              "gp-rule-item": true,
                              expanded: _vm.expandedRules[rule.id]
                            },
                            attrs: {
                              "data-section": section.id,
                              "data-rule": rule.id
                            }
                          },
                          [
                            _c("label", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.selectedRule = rule
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { name: "more-vertical" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              rule.type !== "rounding" &&
                              rule.type !== "allowable_percent" &&
                              rule.type !== "price_change_cnt_limits" &&
                              rule.type !== "weak_optimization" &&
                              rule.type !== "optimization_effect_limits"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "gp-rules-weight",
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.promptRuleWeight(rule)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.formatPercent(
                                              rule.weight !== undefined
                                                ? rule.weight * 100
                                                : 100
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$set(
                                        _vm.expandedRules,
                                        rule.id,
                                        !_vm.expandedRules[rule.id]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      name: _vm.expandedRules[rule.id]
                                        ? "chevron-down"
                                        : "chevron-right"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("#" + _vm._s(i + 1))]),
                                  _vm._v(" "),
                                  _c("l10n", { attrs: { value: rule.name } })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _vm.expandedRules[rule.id] &&
                            _vm.strictRules.includes(rule.type)
                              ? _c(
                                  "gp-check",
                                  {
                                    staticClass: "float-right",
                                    model: {
                                      value: rule.strict,
                                      callback: function($$v) {
                                        _vm.$set(rule, "strict", $$v)
                                      },
                                      expression: "rule.strict"
                                    }
                                  },
                                  [
                                    _c("l10n", {
                                      attrs: { value: "strict rule" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.expandedRules[rule.id] &&
                            rule.type !== "price_change_cnt_limits" &&
                            rule.type !== "optimization_effect_limits"
                              ? _c(
                                  "p",
                                  { staticClass: "gp-rule-filter" },
                                  [
                                    _c("gp-filter", {
                                      attrs: {
                                        stream: _vm.stream,
                                        source: _vm.source,
                                        groups: _vm.groups,
                                        filter0: _vm.extraFilter0,
                                        filter1: _vm.extraFilter1,
                                        filter2: _vm.extraFilter2,
                                        threshold: _vm.threshold,
                                        attributes: _vm.attributes,
                                        formulas: _vm.formulas,
                                        vars: _vm.vars
                                      },
                                      model: {
                                        value: rule.filter,
                                        callback: function($$v) {
                                          _vm.$set(rule, "filter", $$v)
                                        },
                                        expression: "rule.filter"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("gp-filter", {
                                      attrs: {
                                        stream: _vm.stream,
                                        source: _vm.source,
                                        groups: _vm.groups,
                                        filter0: _vm.extraFilter0,
                                        filter1: _vm.extraFilter1,
                                        filter2: _vm.extraFilter2,
                                        threshold: _vm.threshold,
                                        attributes: _vm.attributes,
                                        formulas: _vm.formulas,
                                        vars: _vm.vars
                                      },
                                      model: {
                                        value: rule.filter_not,
                                        callback: function($$v) {
                                          _vm.$set(rule, "filter_not", $$v)
                                        },
                                        expression: "rule.filter_not"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.expandedRules[rule.id]
                              ? _c("RuleText", {
                                  attrs: {
                                    rule: rule,
                                    locale: _vm.locale,
                                    currency: _vm.currency
                                  },
                                  on: {
                                    "update-value": function($event) {
                                      return _vm.updateValue(rule, $event)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.expandedSections[section.id]
                  ? _c(
                      "div",
                      { staticClass: "gp-rules-add-rule" },
                      [
                        _vm.showRulesLibrary === section.id
                          ? _c(
                              "my-popup",
                              {
                                attrs: {
                                  portal: "popup",
                                  placement: "bottom-start"
                                },
                                on: {
                                  escape: function($event) {
                                    _vm.showRulesLibrary = null
                                  },
                                  clickoutside: function($event) {
                                    _vm.showRulesLibrary = null
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "anchor",
                                      fn: function() {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              staticStyle: { display: "block" },
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.showRulesLibrary = null
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: { name: "plus" }
                                              }),
                                              _vm._v(" "),
                                              _c("l10n", {
                                                attrs: {
                                                  value: "Add price rule"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "gp-rules-library" },
                                  _vm._l(
                                    _vm.rulesLibrary.filter(function(rule) {
                                      return rule.show !== false
                                    }),
                                    function(rule, idx) {
                                      return _c("li", { key: idx }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addRule(
                                                  section,
                                                  rule
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(rule.name))]
                                        )
                                      ])
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          : _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    _vm.showRulesLibrary = section.id
                                  }
                                }
                              },
                              [
                                _c("feather-icon", { attrs: { name: "plus" } }),
                                _vm._v(" "),
                                _c("l10n", {
                                  attrs: { value: "Add price rule" }
                                })
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _c("RulesActions", {
            attrs: {
              "default-types": _vm.defaultTypes,
              "editing-sections": _vm.editingSections,
              config: _vm.config
            },
            on: { "add-section": _vm.addSection }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("OptimizationSettings", {
        attrs: {
          "config-name": _vm.config.name,
          "multiple-types": _vm.multipleTypes,
          "available-optimization-types": _vm.types,
          "checked-optimization-types": _vm.optimizationTypes
        },
        on: {
          "set-run-name": function($event) {
            _vm.runName = $event
          },
          "set-checked-optimization-types": function($event) {
            _vm.optimizationTypes = $event
          },
          "set-is-temporary-run": function($event) {
            _vm.temporaryRun = $event
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btm-sm btn-secondary",
            attrs: { type: "button", disabled: _vm.optimizing },
            on: { click: _vm.optimize }
          },
          [_c("l10n", { attrs: { value: "Optimize" } })],
          1
        )
      ]),
      _vm._v(" "),
      _vm.optimizing
        ? _c(
            "div",
            [
              _c("l10n", { attrs: { value: _vm.optimizationStatus } }),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass:
                    "progress-bar progress-bar-striped progress-bar-animated bg-info",
                  style: { width: _vm.optimizationProgress * 100 + "%" }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("OptimizationResults", {
        attrs: { stats: _vm.stats, locale: _vm.locale }
      }),
      _vm._v(" "),
      _c("OptimizationSchedule", {
        attrs: {
          "config-schedule": _vm.config.schedule,
          "default-scheduled-run": _vm.defaultScheduledRun,
          stream: _vm.stream,
          source: _vm.source,
          filter0: _vm.filter0,
          filter1: _vm.filter1,
          filter2: _vm.filter2,
          vars: _vm.vars
        },
        on: {
          "set-schedule": function($event) {
            return _vm.$set(_vm.config, "schedule", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("OptimizationConfiguration", {
        attrs: {
          filter: _vm.editingFilter,
          sections: _vm.editingSections,
          schedule: _vm.config.schedule
        },
        on: { "apply-config": _vm.applyConfig }
      }),
      _vm._v(" "),
      _c("SelectionPopup", {
        attrs: { "selected-section": _vm.selectedSection },
        on: {
          "close-popup": function($event) {
            _vm.selectedSection = null
          },
          "rename-section": _vm.renameSection,
          "clone-section": _vm.cloneSection,
          "delete-section": _vm.deleteSection
        }
      }),
      _vm._v(" "),
      _vm.selectedRule
        ? _c(
            "my-popup",
            {
              attrs: {
                portal: "popup",
                placement: "bottom-end",
                anchor:
                  "*[data-rule='" +
                  _vm.selectedRule.id +
                  "'] .feather-icon-more-vertical"
              },
              on: {
                escape: function($event) {
                  _vm.selectedRule = null
                },
                clickoutside: function($event) {
                  _vm.selectedRule = null
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "popover show plain-table-manage-table" },
                [
                  _c("div", { staticClass: "popover-body" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.promptRuleName(_vm.selectedRule)
                                _vm.selectedRule = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "edit-3" } }),
                            _vm._v(" "),
                            _c("l10n", {
                              attrs: { value: "Rename price rule" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.cloneRule(_vm.selectedRule)
                                _vm.selectedRule = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "copy" } }),
                            _vm._v(" "),
                            _c("l10n", {
                              attrs: { value: "Duplicate price rule" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.deleteRule(_vm.selectedRule)
                                _vm.selectedRule = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "trash" } }),
                            _vm._v(" "),
                            _c("l10n", {
                              attrs: { value: "Delete price rule" }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }