var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", {
    class: _vm.$options.methods.rowClass(_vm.$props),
    style: _vm.$options.methods.rowStyle(_vm.$props),
    attrs: { tabindex: "0", "data-row": _vm.i },
    domProps: {
      innerHTML: _vm._s(_vm.$options.methods.staticCells(_vm.$props))
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }