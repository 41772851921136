<template>
    <div class="plain-table" :style="{
            display,
            '--head-height': `${tableHeadHeight}px`,
            '--head-first-row-height': `${tableHeadFirstRowHeight}px`,
            '--head-last-row-height': `${tableHeadLastRowHeight}px`,
        }">
        <div class="plain-table-head">
            <my-errors :errors="errors" @retry="requestData"/>

            <my-caption :caption="caption"/>

            <div class="my-filters">
                <a
                    v-for="(filter, i) in filters"
                    v-if="filter.show !== false"
                    :class="filterClass(filter)"
                    href="javascript:void(0)"
                    @click="filters.splice(i,1)"
                    >
                    {{filter.name}}
                </a>
            </div>

            <div ref="style"/>

            <div
                tabindex="0"
                v-if="pagination"
                @keydown="handlePaginatorKeyDown"
                class="plain-table-paginator">

                <a
                    href="javascript:void(0)"
                    :class="{disabled: page === 0}"
                    @click="setDesiredPage(page - 10)">
                    <feather-icon name="chevrons-left"/></a><a
                    href="javascript:void(0)"
                    :class="{disabled: page === 0}"
                    @click="setDesiredPage(page - 1)">
                    <feather-icon name="chevron-left"/></a>

                <a href="javascript:void(0)" @click="promptPage">
                    <l10n value="page {page}" :page="new Number(page+1).toLocaleString()"/></a>

                <a
                    href="javascript:void(0)"
                    :class="{disabled: page === pageCount-1}"
                    @click="setDesiredPage(page + 1)">
                    <feather-icon name="chevron-right"/></a><a
                    href="javascript:void(0)"
                    :class="{disabled: page === pageCount-1}"
                    @click="setDesiredPage(page + 10)">
                    <feather-icon name="chevrons-right"/></a>

                <l10n class="nowrap" :rows="new Number(size).toLocaleString()" value="{rows} rows,"/>
                <l10n class="nowrap" :pages="new Number(pageCount).toLocaleString()" value="{pages} pages,"/>

                <a href="javascript:void(0)" @click="promptPageSize">
                    <l10n class="nowrap" :page-size="new Number(pageSize).toLocaleString()" value="{page-size} rows per page"/>
                </a>

                <inline-help
                    text="Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward."
                    :html="true"/>

            </div>
            <div v-else-if="overscroll" class="plain-table-paginator">
                <l10n v-if="!report" value="Loading..."/>
                <l10n v-else :rows="new Number(size).toLocaleString()" value="{rows} rows"/>
            </div>
      <div
        v-else-if="showExpandTableButton && mode === 'tree'"
        class="plain-table-paginator">
        <a
          class="expand-table-button"
          :class="{ opened: openAllNodes }"
          href="#"
          @click.prevent="handleExpandButtonClick"
          :title="getTitleForExpandTableButton">
          {{ getTitleForExpandTableButton }}
          <feather-icon name="table" />
        </a>
      </div>
            <div class="plain-table-table-actions">
                <my-popup
                    v-if="showTableActions"
                    @escape="showTableActions = false"
                    @clickoutside="showTableActions = false"
                    placement="bottom-end"
                    :anchor="$refs.showTableActions">
                    <div class="popover show plain-table-manage-table">
                        <div class="popover-body">
                            <ul>
                            <li v-for="action of tableActionsEx()" v-if="action.menu !== false">
                                <RenderProxy
                                    v-if="action.tagname"
                                    :tagname="action.tagname"
                                    :config="action.config"
                                    />
                                <a
                                    v-else
                                    href="javascript:void(0)"
                                    @click="callTableAction(action, $event)">
                                    <feather-icon :name="action.icon"/>
                                    <l10n :value="action.text"/>
                                </a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </my-popup>
        <template v-if="downloadFromUrlButton">
          <my-button
            class="download-url-button"
            :isLoading="downloadFileFromUrlButtonLoading"
            @mybutton-click="handleDownloadButtonClick"
          >
            <l10n :value="downloadFromUrlButton.name ? downloadFromUrlButton.name : 'Download template'" />
            <feather-icon name='download' />
          </my-button>
        </template>
                <template v-if="uploadToUrlButton">
                    <my-button
                        class="upload-url-button"
                        :isLoading="uploadFileToUrlButtonLoading"
                        @mybutton-click="handleUploadButtonClick"
                    >
                        <l10n :value="uploadToUrlButton.name ? uploadToUrlButton.name : 'Upload file'" />
                        <feather-icon name='upload' />
                    </my-button>
                </template>
                <template v-if="evaluateButtons">
                    <my-button
                        v-for="(evaluateButton, i) in evaluateButtons"
                        :key="i"
                        :isLoading="isEvaluateBtnsLoading[i]"
                        :evaluateButton="evaluateButton"
                        @mybutton-click="handleEvaluateButtonClick"
                    >
                        <l10n :value="evaluateButton.value ? evaluateButton.value : 'Evaluate'" />
                    </my-button>
                </template>
                <template v-for="action of tableActionsEx()" v-if="action.menu === false">
                    <RenderProxy
                        v-if="action.tagname"
                        :tagname="action.tagname"
                        :config="action.config"
                        />
                    <a
                        href="#"
                        @click.prevent="callTableAction(action, $event)"
                        :title="l10n(action.text)">
                        <feather-icon :name="action.icon"/>
                    </a>
                </template>
        <a
          v-if="restartStreamButton"
          class="restart-stream-button"
          href="#"
          @click.prevent="restartStreams"
          :title="getTitleForRestartStreamButton">
          {{ getTitleForRestartStreamButton }}
          <feather-icon name="revert" />
        </a>
                <a
                    v-if="tableActionsEx().length == 0"
                    href="javascript:void(0)"
                    @click="exportToFile()">
                    <feather-icon name="download"/>
                </a>
                <a
                    href="javascript:void(0)"
                    @click="toogleFullscreen">
                    <feather-icon :name="inFullscreenMode ? 'minimize' : 'maximize'"/>
                </a>
                <a
                    v-if="tableActionsEx().length === 0 || tableActionsButtonEnabled"
                    ref="showTableActions"
                    href="javascript:void(0)"
                    @click="showTableActions = true">
                    <feather-icon name="more-vertical"/>
                </a>
            </div>

            <my-progress
                :key="1"
                :working="working"
                :sorting="sorting"
                :progress="progress"
                :downloading="downloading"
                :processRows="processRows"
                :processTime="processTime"
                :meta="meta"
                :refreshData="refreshData"
                :assembly="assembly"/>

            <my-download :file="file"/>

            <my-tooltip
                ref="tooltip"
                :meta="null"
                :keepOnly="keepOnly"
                :exclude="exclude"
                />


            <div style="clear: both"></div>

            <div
                class="plain-table-slider"
                v-if="pagination && size > 0"
                ref="slider"
                @click="handlePageSliderClick"
                >
                <div class="plain-table-slider-background"></div>
                <div
                    class="plain-table-slider-prefetch-window"
                    :style="{
                        left: skip / size * 100 + '%',
                        right: (1-Math.min(1, (skip + take) / size)) * 100 + '%',
                    }"
                    ></div>
                <div
                    class="plain-table-slider-visible-window"
                    :style="{
                        left: page * pageSize / size * 100 + '%',
                        right: (1-Math.min(1, (page + 1) * pageSize / size)) * 100 + '%',
                    }"
                    ></div>
            </div>

            <my-popup
                :key="`section-popup-${selectedSection}`"
                :draggable="true"
                placement="bottom-start"
                v-if="selectedSection !== null"
                @escape="selectedSection = null"
                @clickoutside="selectedSection = null"
                :anchor="resolveSelectedSection">
                <div class="popover show plain-table-manage-section">
                    <div class="popover-body">
                        <ul>
                            <template v-for="section in [sections[selectedSection]]">
                                <li v-for="action of sectionActionsEx(section)">
                                    <RenderProxy
                                        v-if="action.tagname"
                                        :tagname="action.tagname"
                                        :section="section"
                                        :meta="meta"
                                        :config="action.config"
                                        />
                                    <a v-else href="javascript:void(0)" @click="callSectionAction(section, action)"
                                        ><feather-icon :name="action.icon"/> <l10n :value="action.text"/></a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </my-popup>

            <my-popup
                :key="`column-popup-${selectedColumn}`"
        :draggable="true"
        :resizable="true"
                v-if="selectedColumn !== null"
                @escape="selectedColumn = null"
                @clickoutside="selectedColumn = null"
                :anchor="resolveSelectedColumn"
                >
                <div class="popover show plain-table-manage-column">
                    <div class="popover-body">
                        <ul>
                            <li
                                key="sort-help"
                                v-if="
                                    desiredSort.length > 1 ||
                                    desiredSort.length === 1 && sortingLevel(selectedColumn) !== 0">
                                <l10n value="Hold shift for secondary sorting"/>
                            </li>
                            <li key="sort-asc" v-if="!sortedAscending(selectedColumn)">
                                <a href="javascript:void(0)" @click="sortAscending($event, selectedColumn)">
                                <feather-icon name="arrow-down"/> <l10n value="Sort ascending"/></a>
                            </li>
                            <li key="sort-desc" v-if="!sortedDescending(selectedColumn)">
                                <a href="javascript:void(0)" @click="sortDescending($event, selectedColumn)">
                                <feather-icon name="arrow-up"/> <l10n value="Sort descending"/></a>
                            </li>
                            <li key="sort-none" v-if="sortedAscending(selectedColumn) || sortedDescending(selectedColumn)">
                                <a href="javascript:void(0)" @click="removeSorting($event, selectedColumn)">
                                <feather-icon name="x"/> <l10n value="Remove sorting"/></a>
                            </li>
                            <template v-for="section in sections">
                                <template v-for="column in section.columns" v-if="column.i == selectedColumn">
                                    <li :key="action.key || `action-${i}`" v-for="action, i of columnActionsEx(column)">
                                        <RenderProxy
                                            v-if="action.tagname"
                                            :tagname="action.tagname"
                                            :column="column"
                                            :meta="meta"
                                            :config="action.config"
                                            />
                                        <a
                                            v-else
                                            href="javascript:void(0)"
                                            @click="callColumnAction(column, action)">
                                        <feather-icon :name="action.icon"/> <l10n :value="action.text"/></a>
                                    </li>
                                </template>
                            </template>
                            <li key="clear-filters" v-if="hasFilters(selectedColumn)">
                                <a href="javascript:void(0)" @click="removeFilter($event, selectedColumn)">
                                <feather-icon name="filter"/> <l10n value="Clear column filters"/></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </my-popup>
        </div>

        <div
            v-if="mode == 'tiles'"
            ref="tiles"
            class="plain-table-tiles"
            :style="{opacity: pending ? 0.5 : 1 }"
            @click="handleTableClick"
            @change="handleTableChange"
            @keydown="handleTableKeyDown"
            @mousedown="handleTableMouseDown"
            >

            <plain-table-tile
                v-for="row, i in rows"
                :i="i"
                :key="row.key || i"
                :row="rowOverrides[row.key] || row"
                :rows="editableRow === i ? report.rows : null"
                :meta="meta"
                :primary="selectedRow === i"
                :selected="selectedRows[i]"
                :editable="editableRow === i"
                :sections="sections"
                :editableColumn="presentedRow == null ? editableColumn : null"
                @maximize="presentedRow = i"
                />
        </div>

        <div
            class="plain-table-body"
            ref="tableBody"
            @scroll.passive="deferUpdateOverscrollAnchor"
            v-if="mode == 'table' || mode == 'tree'">

            <table
                :id="tableId"
                ref="table"
                v-show="data !== null"
                :class="{
                    table: true,
                    'table-sm': true,
                    'table-striped': striped,
                    'table-bordered': bordered,
                    'table-hover': hover,
                    'table-responsive': responsive,
                    'table-frozen-dims': freezeDims,
                    'table-expandable': expandable,
                    'table-column-resizing': resizingColumn,
                }"
                :style="{ opacity: pending ? 0.5 : 1 }"
                @click="handleTableClick"
                @change="handleTableChange"
                @keydown="handleTableKeyDown"
                @mousedown="handleTableMouseDown"
                @mouseleave="handleTableMouseLeave"
                @mousemove="handleTableMouseMove"
                cellspacing="0"
                cellpadding="0"
                >
                <thead>
                    <tr v-if="sections.length > 1">
                        <th
                            :class="{
                                'my-column-dim': isDimsSection(section),
                                'section-start': true,
                                'section-end': true,
                            }"
                            v-for="section, i in sections"
                            v-if="section.visibleColumns.length > 0"
                            :data-section="i"
                            :data-column="section.columns[0].i"
                            :colspan="section.visibleColumns.length">
                            <div v-if="sectionActionsEx(section).length" class="float-left" style="margin-right: 8px">
                                <a
                                    class="float-right"
                                    href="javascript:void(0)"
                                    @click="selectedSection = i">
                                    <feather-icon name="table-head-filter"/>
                                </a>
                            </div>
                            <l10n :value="section.name"/>
                        </th>
                    </tr>
                    <tr ref="sortable">
                        <template v-for="section in sections">
                            <th
                                v-for="column, i in section.visibleColumns"
                                :id="column.id"
                                :data-column="column.i"
                                :data-type="column.type"
                                :data-name="column.name"
                                :rowspan="sections !== null ? 2 : undefined"
                                :class="{
                                    'my-column-dim': column.i < meta.dims.length,
                                    'section-start': i === 0,
                                    'section-end': i === section.visibleColumns.length - 1,
                                }"
                                >
                                <!-- <div> -->
                                <div>
                                    <a
                                        href="javascript:void(0)"
                                        @click="selectedColumn = column.i">
                                        <l10n :value="column.name"/>
                                        <div class="plain-table-sort-icons">
                                            <feather-icon name="arrow-down" v-if="sortedAscending(column.i)"/>
                                            <feather-icon name="arrow-up" v-if="sortedDescending(column.i)"/>
                                            <sup v-if="desiredSort.length > 1 && sortingLevel(column.i) !== -1">
                                                {{sortingLevel(column.i)+1}}
                                            </sup>
                                        </div>
                                        <feather-icon name="filter" v-if="hasFilters(column.i)"/>
                                        <feather-icon :name="name" :key="name" v-for="name in columnIconsEx(column)"/>
                                    </a>
                                </div>
                                <span
                                    class="column-move-target"/>
                                <span
                                    class="column-resize-left"
                                    @mousedown="startColumnResize($event, previousColumn(column))"
                                    @dblclick="resetColumnWidth($event, previousColumn(column))"/>
                                <span
                                    class="column-resize-right"
                                    @mousedown="startColumnResize($event, column)"
                                    @dblclick="resetColumnWidth($event, column)"/>
                            </th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="report && report.totals">
                        <tr
                            :data-row="-1"
                            v-for="row in report.totals.rows"
                            :class="{
                                'plain-table-totals': true,
                                primary: selectedRow === -1,
                                selected: selectedRows[-1],
                                editable: editableRow === -1}"
                            >
                            <template v-for="section, i in sections">
                                <plain-table-cell-static
                                    v-for="column, j in section.visibleColumns"
                                    :key="`${column.id}-${i}-${j}`"
                                    :totals="true"
                                    :meta="meta"
                                    :row="row"
                                    :column="column"
                                    :headerWithoutSum="headerWithoutSum"
                                    :section="section"
                                    :no-link="true"
                                    :no-style="true"
                                    :no-action="true"
                                    />
                            </template>
                        </tr>
                        <tr v-if="expandable && selectedRow === -1"
                            v-for="row in report.totals.rows"
                            class="expanded"
                            key="expanded"
                            :data-row="selectedRow"
                            >
                            <td v-for="section in sections"
                                :colspan="section.visibleColumns.length"
                                class="section-start section-end"
                                >
                                <RenderProxy
                                    :section="section"
                                    :row="row"
                                    :meta="meta"
                                    :tagname="expandTagName"
                                    :totals="true"
                                    :extraProps="expandProps"
                                    />
                            </td>
                        </tr>
                    </template>
                    <template v-else-if="overscroll">
                        <tr
                            :data-row="-1"
                            :class="{
                                'plain-table-totals': true,
                                primary: selectedRow === -1,
                                selected: selectedRows[-1],
                                editable: editableRow === -1}"
                            >
                            <template v-for="section, i in sections">
                                <plain-table-cell-static
                                    v-for="column, j in section.visibleColumns"
                                    :key="`${column.id}-${i}-${j}`"
                                    :meta="meta"
                                    :row="overallSummary"
                                    :column="column"
                                    :headerWithoutSum="headerWithoutSum"
                                    :section="section"
                                    :no-link="true"
                                    :no-style="true"
                                    :no-action="true"
                                    />
                            </template>
                        </tr>
                    </template>
                    <template v-if="overscroll">
                        <tr ref="overscrollRef" v-show="false">
                            <td>&nbsp;</td>
                        </tr>
                        <tr
                            class="overscroll"
                            ref="overscrollTop"
                            :set="height=overscrollTopHeight">
                            <template v-for="section, i in sections">
                                <td
                                    v-for="column, j in section.visibleColumns"
                                    :data-column="column.i"
                                    :style="{height: height+'px'}"
                                    :class="{
                                        'my-column-dim': i == 0,
                                        'section-start': j == 0,
                                        'section-end': j == section.visibleColumns.length - 1
                                    }"
                                    />
                            </template>
                        </tr>
                    </template>
                    <template v-if="subTotals">
                        <template v-for="rowGroup in rowGroups">
                            <tr class="sub-total">
                                <template v-for="section, i in sections">
                                    <plain-table-cell-static
                                        v-for="column, j in section.visibleColumns"
                                        :key="`${column.id}-${i}-${j}`"
                                        :meta="meta"
                                        :row="rowGroup.summary"
                                        :column="column"
                                        :section="section"
                                        :no-link="true"
                                        :no-style="true"
                                        :no-action="true"
                                        />
                                </template>
                            </tr>
                            <template v-for="row, i in rowGroup.rows">
                                <plain-table-row-static
                                    :i="rows.indexOf(row)"
                                    :key="`${rowGroup.key};${row.key || i}`"
                                    :row="rowOverrides[row.key] || row"
                                    :meta="meta"
                                    :primary="selectedRow == rows.indexOf(row)"
                                    :selected="selectedRows[rows.indexOf(row)]"
                                    :sections="sections"
                                    />
                            </template>
                        </template>
                    </template>
                    <template v-else v-for="row, i in rows">
                        <plain-table-row-editable
                            v-if="editableRow === i"
                            :i="i"
                            :key="row.key || i"
                            :row="rowOverrides[row.key] || row"
                            :rows="editableRow === i ? report.rows : null"
                            :meta="meta"
                            :primary="selectedRow === i"
                            :selected="selectedRows[i]"
                            :editable="true"
                            :sections="sections"
                            :getOptionsListFromStream="getOptionsListFromStream"
                            :editableColumn="presentedRow == null ? editableColumn : null"
                            />
                        <plain-table-row-static
                            v-else
                            :class="groupSize !== 0 ? getRowClassByIndex(i + 1, groupSize): null"
                            :i="i"
                            :key="row.key || i"
                            :row="rowOverrides[row.key] || row"
                            :meta="meta"
                            :primary="selectedRow === i"
                            :selected="selectedRows[i]"
                            :sections="sections"
                            />
                        <tr v-if="expandable && i === selectedRow"
                            class="expanded"
                            key="expanded"
                            :data-row="selectedRow"
                            >
                            <td v-for="section in sections"
                                :colspan="section.visibleColumns.length"
                                :class="{
                                    'section-start': true,
                                    'section-end': true,
                                    selected:
                                        selectedColumn !== null &&
                                        selectedColumn >= section.columns[0].i &&
                                        selectedColumn <= section.columns[section.columns.length-1].i
                                    }"
                                >
                                <RenderProxy
                                    :section="section"
                                    :row="row"
                                    :meta="meta"
                                    :tagname="expandTagName"
                                    :totals="false"
                                    :extraProps="expandProps"
                                    />
                            </td>
                        </tr>
                    </template>
                    <template v-if="overscroll">
                        <tr
                            class="overscroll"
                            ref="overscrollBottom"
                            :set="height=overscrollBottomHeight">
                            <template v-for="section, i in sections">
                                <td
                                    v-for="column, j in section.visibleColumns"
                                    :data-column="column.i"
                                    :style="{height: height+'px'}"
                                    :class="{
                                        'my-column-dim': i == 0,
                                        'section-start': j == 0,
                                        'section-end': j == section.visibleColumns.length - 1
                                    }"
                                    />
                            </template>
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr v-show="numSelectedRows > 1">
                        <template v-for="section, i in sections">
                            <plain-table-cell-static
                                v-for="column, j in section.visibleColumns"
                                :key="`${column.id}-${i}-${j}`"
                                :meta="meta"
                                :row="selectedSummary"
                                :column="column"
                                :section="section"
                                :no-link="true"
                                :no-style="true"
                                :no-action="true"
                                />
                        </template>
                    </tr>
                </tfoot>
            </table>
        </div>
        <my-dialog
            v-if="presentedRow != null && rows[presentedRow]"
            :large="true"
            :title="rows[presentedRow].slice(dims.length > 0 && dims[0].type == 'check' ? 1 : 0, dims.length).join(', ')"
            @close="presentedRow = null"
            >
            <div class="plain-table-presented-row">
                <table
                    ref="presentedRow"
                    class="table table-sm table-hover"
                    @click="handleTableClick"
                    @change="handleTableChange"
                    @keydown="handleTableKeyDown"
                    @mousedown="handleTableMouseDown"
                    >
                    <tbody>
                        <template v-for="section in sections">
                            <template v-for="row in [rows[presentedRow]]">
                                <tr v-if="section.name"><th class="plain-table-presented-row__section-name" colspan="2"><l10n :value="section.name"/></th></tr>
                                <tr v-for="column in section.visibleColumns" :data-row="presentedRow">
                                    <td :data-type="column.type"><l10n :value="column.name"/></td>
                                    <plain-table-cell-editable
                                        v-if="column.i == editableColumn && presentedRow == editableRow"
                                        :editing="true"
                                        :meta="meta"
                                        :row="rowOverrides[row.key] || row"
                                        :column="column"
                                        :section="{visibleColumns:[]}"
                                        :no-class="false"
                                        :no-style="false"
                                        :no-action="false"/>
                                    <plain-table-cell-static
                                        v-else
                                        :meta="meta"
                                        :row="rowOverrides[row.key] || row"
                                        :column="column"
                                        :section="{visibleColumns:[]}"
                                        :no-class="false"
                                        :no-style="false"
                                        :no-action="false"/>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
            <template slot="footer">
                <button class="btn plain-table-presented-row__btn" :disabled="page == 0" @click="desiredPage = page - 1; presentedRow = pageSize - 1">
                    <feather-icon name="chevrons-left-sm"/>
                </button>
                <button
                    class="btn plain-table-presented-row__btn"
                    :disabled="presentedRow <= 0 && page == 0"
                    @click="prevPresentedRow">
                    <feather-icon name="chevron-left-sm"/>
                </button>
                <div>
                    <a 
                        class="plain-table-presented-row__page-count" 
                        href="#" 
                        @click.prevent="promptPresentedRow"
                    >
                        <l10n value="row {row} of {rows}"
                            :row="new Number(presentedRow + 1).toLocaleString()"
                            :rows="new Number(rows.length).toLocaleString()"/>
                    </a>
                    <template v-if="pageCount > 1">
                        ,
                        <a href="#" @click.prevent="promptPage">
                            <l10n value="page {page} of {pages}"
                                :page="new Number(page + 1).toLocaleString()"
                                :pages="new Number(pageCount).toLocaleString()"/>
                        </a>
                    </template>
                    <inline-help text="You can use <Left> and <Right> arrow keys to switch between rows."/>
                </div>
                <button
                    class="btn plain-table-presented-row__btn"
                    :disabled="presentedRow >= rows.length - 1 && page == pageCount - 1"
                    @click="nextPresentedRow">
                    <feather-icon name="chevron-right-sm"/>
                </button>
               <button class="btn plain-table-presented-row__btn" :disabled="page >= pageCount - 1" @click="desiredPage = page + 1; presentedRow = 0">
                    <feather-icon name="chevrons-right-sm"/>
                </button>
            </template>
        </my-dialog>
        <slot/>
    </div>
</template>

<script>
let utils = require('./my-utils')
const MyButton = require('./my-button.vue').default;

let {PlainTableRowStatic} = require('./plain-table-row-static.js')
let {PlainTableCellStatic} = require('./plain-table-cell-static.js')

window.PlainTableRowStatic = PlainTableRowStatic
window.PlainTableCellStatic = PlainTableCellStatic

module.exports = {
    mixins: [require("./data")],
    components: {
        RenderProxy: {
            props: {
                tagname:  { type: String },
                section:  { type: Object },
                meta:     { type: Object },
                row:      { type: Array  },
                column:   { type: Object },
                totals:   { type: Boolean },
                config:   { type: Object },
            },
            render(h) {
                let config = _.clone(this.config) || {}
                let props = _.clone(config.props) || {}
                _.assign(props, _.pick(this, ["section", "meta", "row", "column", "totals"]))
                config.props = props
                return h(this.tagname, config, config.children)
            },
        },
        RenderFunc: {
            props: {
                func: { type: Function },
            },
            render(h) {
                return this.func(h)
            }
        },
        'my-button': MyButton,
    },
    props: {
        mode: { type: String, default: "table" },
        treeAggregation: { type: String, default: "summary" },
        display: { type: String, default: "block" },
        caption: { type: String },
        responsive: { type: Boolean, default: true },
        expandable: { type: Boolean, default: false },
        striped: { type: Boolean, default: false },
        bordered: { type: Boolean, default: false },
        hover: { type: Boolean, default: false },
        pagination: { type: Boolean, default: true },
        overscroll: { type: Boolean, default: false },
        prefetchSize: { type: Number, default: 10 },
        freezeDims: { type: Boolean, default: false },
        freezeMargin: { type: Number, default: -20 },
        gradients: { type: Array, default: () => [
            "interpolateGreens",
            "interpolateReds",
            "interpolateBlues",
            "interpolateRdYlGn",
        ] },
        tableActions: { type: [Array, String, Function], default: () => [] },
        columnIcons: { type: [Function, String], default: () => [] },
        columnActions: { type: [Function, String], default: () => [] },
        sectionActions: { type: [Function, String], default: () => [] },
        expandTagName: { type: String },
        expandProps: { type: Object },
        rowIdentity: { type: [Function, String] },
        subTotals: { type: Boolean, default: false },
        evaluateButtons: {
            type: Array,
            required: false,
            default: () => [],
        },
        downloadFromUrlButton: {
            type: Object,
            required: false,
            default: () => {},
        },
        uploadToUrlButton: {
            type: Object,
            required: false,
            default: () => {},
        },
        downloadFileFromUrlButtonLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        uploadFileToUrlButtonLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        headerWithoutSum: {
            type: Boolean,
            required: false,
            default: false,
        },
        isEvaluateBtnsLoading: {
            type: Array,
            required: false,
            default: () => [],
        },
        editCellOnChange: { type: Boolean, default: false },
        groupSize: {
            type: Number,
            required: false,
            default: 0,
        },
        tableActionsButtonEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        isOptimizationsTable: {
          type: Boolean,
          required: false,
          default: false
        },
        parentExtraFilters: { type: Object, default: () => null },
        originVars: { type: Object, default: () => null },
        getOptionsListFromStream: {
            type: Array,
            required: false,
            default: () => [],
        },
        restartStreamButton: {
            type: Object,
            required: false,
            default: () => {},
        },
        showExpandTableButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        needColumnsIndexes: {
            type: Boolean,
            required: false,
            default: false,
        },
        uniqueKey: { type: String, default: 'Initial' },
    },
    watch: {
        rows() {
            if (this.rowIdentityEx) {
                let selectedRow = this.rows.findIndex(row => this.rowIdentityEx(row) == this.selectedRowId)
                if (selectedRow == -1 && this.rows.length > 0) {
                    selectedRow = 0
                    this.selectedRowId = this.rowIdentityEx(this.rows[selectedRow])
                    this.selectedRowsIds = [this.selectedRowId]
                }

                if (selectedRow != -1)
                    this.selectedRow = selectedRow
                else if (selectedRow == -1)
                    this.selectedRow = null

                let selectedRows = {}
                for (let selectedRowId of this.selectedRowsIds) {
                    let selectedRow = this.rows.findIndex(row => this.rowIdentityEx(row) == selectedRowId)
                    if (selectedRow != -1)
                        selectedRows[selectedRow] = true
                }
                this.selectedRows = selectedRows
            }
        },
        offset(curr, prev) {
            if (this.overscroll) {
                let shift = prev - curr
                if (this.selectedRow !== null) {
                    this.selectedRow += shift
                }
                if (this.editableRow !== null) {
                    this.editableRow += shift
                }
                if (!_.isEmpty(this.selectedRows)) {
                    let selectedRows = {}
                    for (let i of _.keys(this.selectedRows))
                        selectedRows[+i + shift] = true
                    this.selectedRows = selectedRows
                }
            }
        },
        selectedRow() {
            if (this.rowIdentityEx) {
                let row = this.rows[this.selectedRow]
                this.selectedRowId = row ? this.rowIdentityEx(row) : null
            }
            else
                this.$emit("row-selected", this.selectedRow, this)
        },
        selectedRowId() {
            if (this.rowIdentityEx)
                this.$emit("row-selected", this.selectedRow, this)
        },
        selectedColumn() {
            this.$emit("column-selected", this.selectedColumn, this)
        },
        selectedSection() {
            this.$emit("section-selected", this.selectedSection, this)
        },
        selectedRows() {
            if (this.rowIdentityEx) {
                this.selectedRowsIds = _(this.selectedRows)
                    .keys()
                    .map(selectedRow => this.rows[selectedRow])
                    .filter()
                    .map(row => this.rowIdentityEx(row))
                    .filter()
                    .value()
            }
            this.$emit("rows-selected", this.selectedRows, this)
            if (this.freezeDims)
                this.updateStickyOffsets(true)
        },
        overscrollAnchor(x) {
            if (this.freezeDims)
                this.updateStickyOffsets(true)
        },
        pageSize(pageSize) {
            this.$emit("page-size-changed", pageSize, this)
        },
        desiredPage(desiredPage) {
            this.$emit("desired-page-changed", desiredPage, this)
        },
        desiredSort: {
            deep: true,
            handler(sort) {
                this.$emit("desired-sort-changed", sort, this)
            }
        },
        visibility: {
            deep: true,
            handler(visibility) {
                this.$emit("visibility-changed", visibility)
            }
        },
        filters(newVal, oldVal) {
            utils.bridge.trigger("filtersChanged", -this._uid, {
                section: this.section,
                stream: this.stream,
                groups: this.groups,
                filter1: "",
                filter2: this.filters
                    .filter(({column}) => !column)
                    .map(({code}) => code)
                    .join(" && "),
                filter3: this.filters
                    .filter(({column}) => column)
                    .map(({code}) => code)
                    .join(" && "),
            })
            this.$emit("filters-changed", this.filters)
        },
        reportId() {
            if (this.reportId === null)
                this.previousPage = null
        },
        columns(columns, previousColumns) {
            if (this.needColumnsIndexes) {
                this.setColumnsIndexes();
            }
            this.setupColumnsWidths()
            _.defer(() => this.setupColumnsSortable())

            for (let row of this.report?.rows || [])
                if (row.__cache?.staticCells)
                    delete row.__cache.staticCells
        },
        report(newReport, oldReport) {
            this.$emit("report-updated", newReport)

            if (this.overscroll) {
                if (oldReport && newReport && this.selectedRow != null) {
                    let oldSelectedRow = Math.max(0, this.overscrollAnchor - this.overscrollWindow) + this.selectedRow
                    let selectedRowIds = oldReport.rows[oldSelectedRow]?.slice(0, oldReport.meta.dims.length) || []
                    let newSelectedRow = newReport.rows.findIndex(row => _.isEqual(row.slice(0, newReport.meta.dims.length), selectedRowIds))

                    if (newSelectedRow != -1) {
                        if (newSelectedRow != oldSelectedRow) {
                            this.selectedRow = null
                            this.$nextTick(() => {
                                let selectedRow = newSelectedRow - this.offset
                                let selectedRows = {}
                                selectedRows[selectedRow] = true
                                this.selectedRow = selectedRow
                                this.selectedRows = selectedRows

                                let tableHeight = this.$refs.tableBody.offsetHeight
                                let tableHeadHeight = $(this.$refs.table).find("thead").outerHeight()
                                let tableBodyHeight = tableHeight - tableHeadHeight

                                this.$refs.tableBody.scrollTop = newSelectedRow * this.rowHeight + this.rowHeight - tableBodyHeight / 2

                                this.updateOverscrollAnchor()
                            })
                        }
                    }
                }

                _.defer(() => {
                    let rowHeight = $(this.$refs.overscrollRef).outerHeight()
                    if (rowHeight && this.rowHeight !== rowHeight) {
                        this.rowHeight = rowHeight
                    }
                })
            }
            if (this.freezeDims)
                this.updateStickyOffsets(true)

            this.$nextTick(this.updateOverscrollAnchor)
        },
        stickyOffsets(prev, curr) {
            if (!_.isEqual(prev, curr))
                this.setupStickyOffsets()
        },
        sections() {
            let summarySections = this.sections.map(({columns}) =>
                ({columns: columns.map(({i, type, span, aggregation}) =>
                    ({i, type, span, aggregation}))}))
            if (!_.isEqual(this.summarySections, summarySections))
                this.summarySections = Object.freeze(summarySections)
        },
    },
    computed: {
        getTitleForRestartStreamButton() {
            return this.restartStreamButton.hint_text ? utils.l10n(this.restartStreamButton.hint_text) : utils.l10n('Revert changes');
        },
        getTitleForExpandTableButton() {
            return this.openAllNodes ? utils.l10n('Сollapse table') : utils.l10n('Expand table');
        },
        sectionActionsEx() {
            return this.compileFunction(this.sectionActions, () => [])
        },
        columnActionsEx() {
            return this.compileFunction(this.columnActions, () => [])
        },
        columnIconsEx() {
            return this.compileFunction(this.columnIcons, () => [])
        },
        tableActions_() {
            if (_.isArray(this.tableActions)) {
                let actions = []
                this.tableActions.forEach(x => {
                    let call = x.call
                    if (_.isString(call) && call) {
                        try {
                            call = eval(call)
                        }
                        catch (ex) {
                            console.warn(ex, call)
                            call = () => false
                        }
                    }
                    if (!_.isFunction(call))
                        call = () => false
                    actions.push({...x, call})
                })
                return actions
            }
            else return this.tableActions
        },
        tableActionsEx() {
            return this.compileFunction(this.tableActions_, () => [])
        },
        rowIdentityEx() {
            return this.compileFunction(this.rowIdentity, null)
        },
        numSelectedRows() {
            return _.size(this.selectedRows)
        },
        overallSummary() {
            return this.computeSummary("overall")
        },
        selectedSummary() {
            return this.computeSummary("selected")
        },
        overscrollTopHeight() {
            return this.rowHeight * Math.max(0, this.overscrollAnchor - this.overscrollWindow)
        },
        overscrollBottomHeight() {
            return this.rowHeight * Math.max(0, this.size - (this.overscrollAnchor + this.overscrollWindow))
        },
        visibleColumns() {
            return _(this.sections)
                .map(({visibleColumns}) => visibleColumns)
                .flatten()
                .value()
        },
        sort() {
            if (!_.isEmpty(this.desiredSort)) {
                let ids = {}
                let i = 0

                for (let dim of this.dims)
                    if (dim.show !== false)
                        ids[dim] = ids[dim.id] = ids[dim.calc] = ids[i] = i += 1

                for (let val of this.vals)
                    if (val.show !== false)
                        ids[val] = ids[val.id] = ids[val.calc] = ids[i] = i += 1

                for (let col of this.cols)
                    if (col.show !== false)
                        ids[col] = ids[col.id] = ids[col.calc] = ids[i] = i += 1
                    else
                        i += 1

                let sort = []
                for (let {id, asc, num} of this.desiredSort) {
                    if (id)
                        num = ids[id]
                    if (num) {
                        if (asc)
                            sort.push(num)
                        else
                            sort.push(-num)
                    }
                }
                return sort
            }
            else
                return this.initialSort
        },
        tree() {
            let {rows, size, meta} = this.report
            let dims = meta.dims.length
            let tree = { rows: [], children: {}, key: rows.length ? rows[0][0] : "tree" }
            for (let row of rows) {
                let node = tree
                let slicedRow =  _.slice(row, 1, dims);
                for (let dim of slicedRow) {
                    if (!dim) {
                        node.self = row
                        break
                    }
                    let next = node.children[dim]
                    if (next === undefined) {
                        next = node.children[dim] = { rows: [], children: {}, key: `${node.key}|${dim}`, parent:node }
                    }
                    node = next
                    node.rows.push(row)
                }
            }
            if (this.treeAggregation == "summary") {
                const reportRows = this.report.rows;
                let loop = (node) => {
                    if (_.isEmpty(node.children)) {
                        let summary = this.computeSummary("custom", node.rows, true, reportRows)
                        return node.summary = summary
                    }
                    else {
                        let rows = Object.values(node.children).map(child => loop(child))
                        if (node.self)
                            rows.push(node.self)
                        let summary = this.computeSummary("custom", rows, true, reportRows)
                        return node.summary = summary
                    }
                }
                loop(tree)
            }

            return tree
        },
        rows() {
            if (this.report == null)
                return []
            let {rows, size, meta} = this.report
            let dims = meta.dims.length
            if (this.mode == "tree") {
                let tree = this.tree
                rows = []
                let key = 0
                let loop = (node, level=0) => {
                    if (_.isEmpty(node.children)) {
                        for (let row of node.rows) {
                            let key = row.key
                            row = _.clone(row)
                            row.key = key
                            row.level = level
                            row.opened = null
                            row.node = node
                            row.__cache = {}
                            rows.push(row)
                        }
                        if (this.treeAggregation == "summary")
                            return node.summary
                        else
                            return node.self
                    }
                    else {
                        let row = undefined
                        if (this.treeAggregation == "summary")
                            row = _.clone(node.summary)
                        else {
                            if (node.self)
                                row = _.clone(node.self)
                            else
                                row = node.key.split("|")
                        }
                        row.key = node.key
                        row.level = level
                        row.node = node
                        row.__cache = {}

                        if (this.openAllNodes) {
                            row.opened = true;
                        } else {
                            row.opened = this.openedRows[row.key] || false;
                        }
                        rows.push(row)
                        if (row.opened)
                            for (let child of Object.values(node.children))
                                loop(child, level + 1)
                        return row
                    }
                }
                loop(tree)
                
                const index = this.meta.columns.findIndex((column) => column.calc === 'item');
                
                rows.forEach(row => {
                    row.firstElement = row[0];
                    row.gpTargetGroup = row[index];
                    row[0] = row[row.level];
                    Object.freeze(row);
                });
            }
            if (this.pagination) {
                let page = this.presentedPage
                let pageSize = this.pageSize
                let {take,skip} = this.meta.args
                let start = page * pageSize - skip
                let end = start + pageSize
                rows = start >= 0 ? rows.slice(start, end) : []
            }
            if (this.overscroll) {
                let start = Math.max(0, this.overscrollAnchor - this.overscrollWindow)
                let end = Math.min(size, this.overscrollAnchor + this.overscrollWindow)
                rows = rows.slice(start, end)
            }
            rows.forceUpdate = this.forceUpdate
            return rows
        },
        rowGroups() {
            return _(this.rows)
                .groupBy(row => row.slice(0, this.meta.dims.length-1).join(";"))
                .toPairs()
                .map(([key, rows]) => {
                    let summary = this.computeSummary("custom", rows)
                    summary = _.clone(summary)
                    summary.key = key
                    summary.__cache = {}
                    return {key, rows, summary}
                })
                .value()
        },
        offset() {
            if (this.report == null)
                return 0
            if (this.pagination) {
                let page = this.presentedPage
                let pageSize = this.pageSize
                let {take,skip} = this.meta.args
                let start = page * pageSize - skip
                return start >= 0 ? start : 0
            }
            if (this.overscroll) {
                let start = Math.max(0, this.overscrollAnchor - this.overscrollWindow)
                return start
            }
            return 0
        },
        pending() {
            if (this.silent) {
                return false;
            }

            if (!this.pagination) {
                return this.reportId != null;
            }

            let expectedRows = Math.min(this.size, (this.page + 1) * this.pageSize) - Math.max(0, this.page * this.pageSize);
            return expectedRows < this.rows.length || this.reportId != null;
        },
        size() {
            return this.report != null ? (this.overscroll ? this.report.rows.length : this.report.size) : 0
        },
        pageCount() {
            if (!this.pagination)
                return 1
            return Math.floor((this.size + this.pageSize - 1) / this.pageSize)
        },
        page() {
            let desiredPage = this.desiredPage
            return Math.max(0, Math.min(this.pageCount - 1, desiredPage))
        },
        presentedPage() {
            let desiredPage = this.desiredPage
            if (this.reportId != null && this.previousPage !== null)
                 desiredPage = this.previousPage
            return Math.max(0, Math.min(this.pageCount - 1, desiredPage))
        },
        take() {
            return this.computeFetchWindow().take
        },
        skip() {
            return this.computeFetchWindow().skip
        },
        sections() {
            return _(this.columns)
                .groupBy(({section}) => section)
                .values()
                .map((columns) => _.sortBy(columns, "order"))
                .map((columns) => ({
                    name: columns[0].section,
                    columns: columns,
                    visibleColumns: columns.filter(
                        ({visible, type, show}) => visible !== false && show !== false)
                }))
                .sortBy(({columns}) => columns[0].order)
                .value()
        },
        columns() {
            return Object.freeze(this.report != null ?
                _(this.report.columns)
                    .map(({type}, i) => {
                        let column = this.meta.columns[i]
                        let {id, name, calc, section, format, onclick, editable, editValue, actionable, actionicon, actionlink, options, aggregation, override, visible} = column;
                        if (column.type)
                            type = column.type
                        if (column.metric?.formula?.endsWith("_html"))
                            type = "html"
                        if (column.attribute?.calc?.endsWith("_html"))
                            type = "html"
                        let className = [`my-column-${type}`]
                        if (column.className)
                            className.push(column.className)
                        if (i < this.dims.length) {
                            className.push("my-column-dim")
                        }
                        if (this.meta.columns[i]["class"] !== undefined)
                            className.push(`${this.meta.columns[i]["class"]}`)
                        className = className.join(" ")

                        if (!aggregation) {
                            aggregation = "sum"

                            switch (type) {
                                case "string":
                                case "tagged":
                                    aggregation = "mix"
                                    break
                            }

                            switch (column.metric?.format) {
                                case "percent":
                                    aggregation = "avg"
                                    break
                                case "datetime":
                                case "timestamp":
                                    aggregation = "max"
                                    break
                            }

                            if (column.metric?.formula?.startsWith("avg"))
                                aggregation = "avg"

                            if (column.metric?.formula?.startsWith("conj")) {
                                aggregation = "all"
                            }
                            
                            if (_.startsWith(calc, "avg"))
                                aggregation = "avg"
                            if (_.startsWith(calc, "min"))
                                aggregation = "min"
                            if (_.startsWith(calc, "max"))
                                aggregation = "max"
                            if (_.startsWith(calc, "mix"))
                                aggregation = "mix"
                            if (_.startsWith(calc, "prefix"))
                                aggregation = "mix"
                            if (_.startsWith(calc, "conj")) {
                                aggregation = "all"
                            }

                        }

                        let style = this.meta.columns[i].style
                        if (_.isPlainObject(style)) {
                            let original_style = style
                            style = () => original_style
                        }
                        if (_.isString(style) && style) {
                            try {
                                style = eval(style)
                            }
                            catch (ex) {
                                console.warn(ex, style)
                                style = () => null
                            }
                        }
                        if (!_.isFunction(style))
                            style = () => null

                        let rowStyle = this.meta.columns[i].rowStyle
                        if (_.isPlainObject(rowStyle)) {
                            let original_rowStyle = rowStyle
                            rowStyle = () => original_rowStyle
                        }
                        if (_.isString(rowStyle) && rowStyle) {
                            try {
                                rowStyle = eval(rowStyle)
                            }
                            catch (ex) {
                                console.warn(ex, rowStyle)
                            }
                        }
                        if (!_.isFunction(rowStyle))
                            rowStyle = undefined

                        let rowClass = this.meta.columns[i].rowClass
                        if (_.isString(rowClass) && rowClass !== "")
                            try {
                                rowClass = eval(rowClass)
                            }
                            catch (ex) {
                                console.warn(ex, rowClass)
                                rowClass = undefined
                            }
                        else
                            rowClass = undefined

                        let columnClass = this.meta.columns[i].columnClass
                        if (_.isString(columnClass) && columnClass !== "")
                            try {
                                columnClass = eval(columnClass)
                            }
                            catch (ex) {
                                console.warn(ex, columnClass)
                                columnClass = undefined
                            }
                        else
                            columnClass = undefined

                        if (_.isString(actionicon)) {
                            if (actionicon.match(/^[a-z-]+$/)) {
                                let icon = actionicon
                                actionicon = () => icon
                            }
                            else {
                                try {
                                    actionicon = eval(actionicon)
                                }
                                catch (ex) {
                                    console.warn(ex, actionicon)
                                    actionicon = undefined
                                }
                            }
                        }

                        if (_.isString(actionlink)) {
                            if (actionlink.match(/^[a-z-]+$/)) {
                                let link = actionlink
                                actionlink = () => link
                            }
                            else {
                                try {
                                    actionlink = eval(actionlink)
                                }
                                catch (ex) {
                                    console.warn(ex, actionlink)
                                    actionlink = undefined
                                }
                            }
                        }

                        let title = this.meta.columns[i].title
                        if (_.isString(title) && title !== "") {
                            try {
                                title = eval(title)
                            }
                            catch (ex) {
                                console.warn(ex)
                                title = () => null
                            }
                        }
                        else
                            title = () => null

                        let target = this.meta.columns[i].target
                        if (target === undefined)
                            target = "column"

                        if (_.isString(format)) {
                            try {
                                format = eval(format)
                            }
                            catch (ex) {
                                console.warn(format, ex)
                                format = undefined
                            }
                        }

                        if (_.isString(editable)) {
                            try {
                                editable = eval(editable)
                            }
                            catch (ex) {
                                console.warn(ex)
                                editable = () => false
                            }
                        }
                        if (_.isBoolean(editable)) {
                            let original_editable = editable
                            editable = () => original_editable
                        }

                        if (editValue && _.isString(editValue)) {
                            try {
                                editValue = eval(editValue)
                            }
                            catch (ex) {
                                console.warn(ex)
                                editValue = () => []
                            }
                        } else {
                            editValue = () => []
                        }

                        if (!_.isFunction(format)) {
                            format = _.identity
                            let yes = utils.l10n("yes")
                            let no = utils.l10n("no")
                            switch (type) {
                                case "docid":
                                case "int8":
                                case "int16":
                                case "int32":
                                case "int64":
                                case "float":
                                case "double":
                                    format = (x) => x != null
                                        ? new Number(x).toLocaleString()
                                        : ""
                                    break
                                case "date":
                                    format = (x) => x != null
                                        ? new Date(x).toLocaleDateString()
                                        : ""
                                    break
                                case "time":
                                    format = (x) => x != null
                                        ? new Date(x).toLocaleTimeString()
                                        : ""
                                    break
                                case "datetime":
                                    format = (x) => x != null
                                        ? new Date(x).toLocaleString()
                                        : ""
                                    break
                                case "bool":
                                    format = (x) => x != null
                                        ? (x ? yes : no)
                                        : ""
                                    break
                            }
                        }

                        if (_.isString(options))
                            options = options.split(/\s+/g)

                        if (_.isString(override)) {
                            try {
                                override = eval(override)
                            }
                            catch (ex) {
                                console.warn(ex, override)
                                override = undefined
                            }
                        }

                        if (!_.isFunction(override))
                            override = undefined

                        if (visible === undefined)
                            visible = this.visibility[id || i] !== false

                        if (!_.isString(name))
                            name = _.snakeCase(calc?.replace(/^.* as (.*)$/, (x,y) => y))
                                .replace(/_/g, ' ')
                                .split(/\s+/g)
                                .map(_.capitalize)
                                .join(" ")

                        return _.assign(
                            {},
                            column,
                            {   i,
                                name,
                                calc,
                                type,
                                section,
                                className,
                                style,
                                rowStyle,
                                rowClass,
                                columnClass,
                                title,
                                format,
                                target,
                                onclick,
                                editable,
                                editValue,
                                actionable,
                                actionicon,
                                actionlink,
                                visible,
                                aggregation,
                                options,
                                override,
                            })
                    })
                    .reduce((columns, column) => {
                        if (column == null && columns.length > 0) {
                            let i = columns.length - 1
                            if (columns[i].span === undefined)
                                columns[i].span = 2
                            else
                                columns[i].span += 1
                        }
                        else
                            columns.push(column)
                        return columns
                    }, [])
                    : [])
        },
        hasHiddenColumns() {
            return _.some(this.columns, ({visible}) => !visible)
        },
    },
    data() {
        let style1 = document.createElement('style')
        style1.type = 'text/css'
        let style2 = document.createElement('style')
        style2.type = 'text/css'
        return {
            l10n: utils.l10n,
            style1,
            style2,
            tableId: `table-${utils.randomId()}`,
            pageSize: this.initialTake || 20,
            previousPage: null,
            desiredPage: 0,
            desiredSort: [],
            visibility: {},
            filters: [],
            showTableActions: false,
            presentedRow: null,
            selectedRow: null,
            selectedRows: {},
            selectedColumn: null,
            selectedSection: null,
            editableRow: null,
            editableColumn: null,
            resizingColumn: null,
            resizingColumnStartX: null,
            resizingColumnStartW: null,
            overscrollAnchor: 25,
            overscrollWindow: 50,
            rowHeight: 24,
            tableHeadHeight: 24,
            tableHeadFirstRowHeight: 24,
            tableHeadLastRowHeight: 24,
            rowOverrides: {},
            stickyOffsets: [],
            summarySections: [],
            inFullscreenMode: false,
            imageOverAnchor: null,
            imageOverOpacity: 1,
            imageOverLink: null,
            openedRows: {},
            checkedRows: {},
            settings: {},
            selectedRowId: null,
            selectedRowsIds: [],
            forceUpdate: null,
            columnFilter: [],
            openAllNodes: false,
            columnsIndexes: {},
        }
    },
    mounted() {
        this.onMountVisibilityObserver(this.$el, () => {
            this.updateStickyOffsets(true);
        });

        document.addEventListener("click", this.interceptClickEvent)
        document.addEventListener("keydown", this.interceptKeyDownEvent)
        document.addEventListener("fullscreenchange", this.handleFullscreenChange);
        this.setupColumnsWidths()
        _.defer(() => this.setupColumnsSortable())
        this.$refs.style.appendChild(this.style1)
        this.$refs.style.appendChild(this.style2)

        if (this.overscroll) {
            this.overscrollObserver = new IntersectionObserver(
                (entries, observer) => {
                    for (let entry of entries) {
                        if (entry.isIntersecting) {
                            this.updateOverscrollAnchor()
                        }
                    }

                }, {
                    root: this.$refs.tableBody,
                    rootMargin: "100px 0px",
                    thresholds: [0],
                });
            this.overscrollObserver.observe(this.$refs.overscrollTop)
            this.overscrollObserver.observe(this.$refs.overscrollBottom)
            this.updateOverscrollWindow()
            this.overscrollWindowTimer = setInterval(this.updateOverscrollWindow, 1000)
        }
        
        if (this.mode == "table")
            this.resizeSensor = new ResizeSensor(
                this.$refs.tableBody,
                this.updateOverscrollAnchor)
    },
    beforeDestroy() {
        if (this.overscrollWindowTimer)
            clearInterval(this.overscrollWindowTimer)
        if (this.overscrollObserver)
            this.overscrollObserver.disconnect()
        if (this.resizingColumn)
            this.stopColumnResize()
            
        if (this.visibilityObserver) {
            this.visibilityObserver.disconnect();
        }
        
        document.removeEventListener("click", this.interceptClickEvent)
        document.removeEventListener("keydown", this.interceptKeyDownEvent)
        document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
        this.resizeSensor?.detach()
    },
    methods: {
        setColumnsIndexes() {
            const { columns } = this.meta;

            for (let i = 0; i < columns.length; i++) {
                Vue.set(this.columnsIndexes, columns[i].calc, i);
            }
        },

        handleExpandButtonClick() {
            this.openAllNodes = !this.openAllNodes;
        },
        restartStreams() {
            const url = this.restartStreamButton.value;
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const data = {};
            data.stream_names = this.restartStreamButton.stream_names;
            options.body = JSON.stringify(data);

            fetch(url, options)
            .then((response) => {
                if (response.status === 200) {
                    this.createNotification('Data was reseted successfully', 'success');
                    this.refreshData();
                } else {
                    this.createNotification('Произошла ошибка во время запроса, пожалуйста, повторите попытку', 'error');
                    return;
                }
            }).catch((error) => {
                this.createNotification('Произошла ошибка во время запроса, пожалуйста, повторите попытку', 'error');
                console.error(error.text);
            })
        },
        getRowClassByIndex(i, groupSize) {
            if (i % groupSize === 1) {
                return 'group-primary-row'
            } else {
                return 'group-secondary-row'
            }
        },
        onMountVisibilityObserver(element, callback) {
            this.visibilityObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if(entry.intersectionRatio > 0) {
                        callback(element);
                    }
                });
            });
            this.visibilityObserver.observe(element);
        },
        selectRow(row) {
            let selectedRows = {}
            selectedRows[row] = true
            this.selectedRow = row
            this.selectedRows = selectedRows
        },
        deselectRow() {
            this.selectedRow = null
            this.selectedRows = {}
            this.selectedRowsIds = []
        },
        modifyRow(row, func) {
            if (row.key) {
                let key = row.key
                row = _.clone(row)
                Vue.set(this.rowOverrides, key, row)
            }
            row.__cache = {}
            func(row)
            return row
        },
        compileFunction(func, defval) {
            if (_.isString(func)) {
                try {
                    func = eval(func)
                }
                catch (ex) {
                    console.warn(func)
                    console.warn(ex.message)
                }
            }
            if (_.isFunction(func)) {
                return (a,b,c,d) => {
                    return func(a,b,c,d)
                }
            }
            if (func != null)
                return () => func
            return defval
        },
        handleFullscreenChange() {
            this.inFullscreenMode = document.fullscreenElement == this.$el
        },

        handleEvaluateButtonClick(evaluateButton) {
            this.$emit('evaluate-button-click', evaluateButton);
        },

        handleDownloadButtonClick() {
            this.$emit('download-url-button-click');
        },

        handleUploadButtonClick() {
            this.$emit('upload-url-button-click');
        },

        toogleFullscreen() {
            if (document.fullscreenElement == this.$el) {
                document.exitFullscreen()
            }
            else {
                this.$el.requestFullscreen()
            }
        },
        promptPresentedRow() {
            let row = parseInt(window.prompt(utils.l10n("Please enter row number"), this.presentedRow + 1))
            if (!Number.isNaN(row))
                this.presentedRow = Math.max(0, Math.min(this.rows.length - 1, row - 1))
        },
        renderStaticRow(i) {
            return new PlainTableRowStatic({
                i,
                row: this.getRow(i),
                meta: this.meta,
                primary: this.selectedRow === i,
                selected: this.selectedRows[i],
                sections: this.sections
            }).render()
        },
        updateOverscrollWindow() {
            let tableHeight = Math.max($(this.$refs.tableBody).outerHeight(), 100)
            if (this.tableHeight !== tableHeight) {
                this.tableHeight = tableHeight
                this.overscrollWindow = Math.round(tableHeight / this.rowHeight * 2)
                this.updateOverscrollAnchor()
            }
        },
        getRow(i) {
            let row = this.rows[i]
            return row && this.rowOverrides[row.key] || row
        },
        rowClass(row, i) {
            let classes = {
                primary: this.selectedRow === i,
                selected: this.selectedRows[i],
                editable: i === this.editableRow,
            }
            for (let section of this.sections) {
                for (let column of section.columns) {
                    if (column.rowClass) {
                        let x = this.getValue(row, column)
                        let class_ = column.rowClass(x, row, column, this.meta)
                        if (class_)
                            classes[class_] = true
                    }
                }
            }
            return classes
        },
        rowStyle(row, i) {
            let style = {}
            for (let section of this.sections)
                for (let column of section.columns)
                    if (column.rowStyle)
                        _.assign(style,
                            column.rowStyle(
                                this.getValue(row, column),
                                row,
                                column,
                                this.meta))
            return style
        },
        computeSummary(kind, customRows, freeze = true, reportRows = null) {
            if (this.report == null)
                return []
            let summary = []
            let rows = reportRows || this.report.rows
            if (kind === "selected") {
                selectedRows = []
                for (let j of _.keys(this.selectedRows)) {
                    let row = rows[+j + this.offset]
                    if (row !== undefined)
                        selectedRows.push(row)
                }
                rows = selectedRows
            }
            if (kind == "custom") {
                rows = customRows
            }

            let rowOverrides = this.rowOverrides
            let summarySections = this.summarySections

            for (let section of summarySections) {
                for (let column of section.columns) {
                    for (let i = column.i; i < column.i + (column.span || 1); ++i) {

                        let aggregation = i === column.i ?
                            column.aggregation : "sum"

                        let isNumber = false
                        let isBool = false;

                        switch (column.type) {
                            case "docid":
                            case "int8":
                            case "int16":
                            case "int32":
                            case "int64":
                            case "float":
                            case "double":
                                isNumber = true
                                break
                            case "mix":
                                aggregation = "mix"
                                break
                            case "bool":
                                aggregation = "all";
                                isBool = true;
                                break;
                        }

                        let min = undefined
                        let max = undefined
                        let cnt = 0
                        let sum = 0
                        let one = undefined
                        let all = true;

                        if (isNumber) {
                            for (let row of rows) {
                                row = rowOverrides[row.key] || row
                                let val = row[i]
                                if (min === undefined || val < min)
                                    min = val
                                if (max === undefined || val > max)
                                    max = val
                                if (val !== 0) {
                                    cnt += 1
                                    sum += val
                                }
                                if (one === undefined)
                                    one = val
                            }
                        } else if (isBool) {
                            for (let row of rows) {
                                row = rowOverrides[row.key] || row;
                                let val = row[i];

                                if (String(val) === 'false') {
                                    all = false;
                                }
                            }
                        } else {
                            for (let row of rows) {
                                row = rowOverrides[row.key] || row
                                let val = row[i]
                                if (min === undefined || val < min)
                                    min = val
                                if (max === undefined || val > max)
                                    max = val
                                if (one === undefined)
                                    one = val
                            }
                        }

                        let accum = {min, max, cnt, sum, all}

                        if (this.headerWithoutSum) {
                            aggregation = "none";
                        }

                        switch (aggregation) {
                            case "min":
                                summary[i] = accum.min !== undefined ? accum.min : 0
                                break
                            case "max":
                                summary[i] = accum.max !== undefined ? accum.max : 0
                                break
                            case "sum":
                                summary[i] = accum.sum !== undefined ? accum.sum : 0
                                break
                            case "avg":
                                summary[i] = accum.sum !== undefined && accum.cnt > 0 ? accum.sum / accum.cnt : 0
                                break
                            case "mix":
                                if (accum.min !== undefined && accum.min === accum.max)
                                    summary[i] = accum.min
                                else if (_.isString(accum.min) && _.isString(accum.max)) {
                                    let j = 0
                                    for (;j < accum.min.length; ++j)
                                        if (accum.min[j] !== accum.max[j])
                                            break
                                    summary[i] = `${accum.min.slice(0,j)}***`
                                }
                                else
                                    summary[i] = "***"
                                break
                            case "all":
                                summary[i] = String(accum.all);
                                break;
                            case "one":
                                summary[i] = one
                                break
                            case "none":
                                summary[i] = null
                                break
                        }
                    }
                }
            }
            summary.summary = true
            return freeze ? Object.freeze(summary) : summary
        },
        deferUpdateOverscrollAnchor() {
            if (!this.updateOverscrollAnchorTimeout) {
                this.updateOverscrollAnchorTimeout = setTimeout(() => {
                    this.updateOverscrollAnchor()
                    this.updateOverscrollAnchorTimeout = null
                }, 100)
            }
        },
        updateOverscrollAnchor() {
            if (!this.overscroll)
                return
            let { scrollTop, offsetHeight } = this.$refs.tableBody;
            let tableHeadHeight = $(this.$refs.table).find("thead").outerHeight()
            this.tableHeadHeight = tableHeadHeight
            this.tableHeadFirstRowHeight = $(this.$refs.table).find("thead tr:first-child:not(:last-child)").outerHeight() || 0
            this.tableHeadLastRowHeight = $(this.$refs.table).find("thead tr:last-child").outerHeight()
            let overscrollAnchor = Math.round((scrollTop - tableHeadHeight + offsetHeight / 2) / this.rowHeight)
            overscrollAnchor = Math.min(overscrollAnchor, this.size)
            if (Math.abs(overscrollAnchor - this.overscrollAnchor) > offsetHeight / this.rowHeight) {
                this.overscrollAnchor = overscrollAnchor
            }
        },
        resolveSelectedSection() {
            return $(this.$refs.table).find(`th[data-section="${this.selectedSection}"] .feather-icon-table-head-filter`)[0]
        },
        resolveSelectedColumn() {
            return $(this.$refs.table).find(`tr:last-child th[data-column="${this.selectedColumn}"] a`)[0]
        },
        updateStickyOffsets(delayed) {
            if (delayed) {
                let updateStickyOffsetsGen = this.updateStickyOffsetsGen || 0
                updateStickyOffsetsGen += 1
                this.updateStickyOffsetsGen = updateStickyOffsetsGen
                _.defer(() => {
                    if (this.updateStickyOffsetsGen == updateStickyOffsetsGen)
                        this.updateStickyOffsets()
                })
                return
            }
            if (!this.$refs.table)
                return

            this.$refs.table.classList.add("updating-sticky-offsets")

            let columnWidths = {}
            _.forEach(this.$refs.table.querySelectorAll(":scope > thead > tr:last-child, :scope > tbody > tr"), row => {
                _.forEach(row.querySelectorAll(":scope > .my-column-dim"), (col, i) => {
                    let column = col.dataset.column
                    let width = columnWidths[column]
                    if (width === undefined) {
                        if (this.mode === 'tree' && column === '0') {
                            let maxRowLevel = 0;
                            this.rows.forEach(row => {
                                if (row.level > maxRowLevel) {
                                    maxRowLevel = row.level;
                                }
                            });

                            width = col.offsetWidth + (maxRowLevel * 8);
                        } else {
                            width = col.offsetWidth;
                        }
                    } else {
                        width = Math.max(width, col.offsetWidth);
                    }
                    columnWidths[column] = width
                })
            })

            let stickyOffsets = []
            _.forEach(this.$refs.table.querySelectorAll(":scope > thead > tr:last-child"), row => {
                let offset = this.freezeMargin
                _.forEach(row.querySelectorAll(":scope > .my-column-dim"), (col, i) => {
                    let column = col.dataset.column
                    let width = columnWidths[column]
                    stickyOffsets[col.dataset.column] = {offset, width}
                    offset += width
                })
            })

            let tableHeadFirstRow = $(this.$refs.table).find("thead tr:first-child:not(:last-child)")
            let tableHeadLastRow = $(this.$refs.table).find("thead tr:last-child")

            let tableHeadFirstRowHeight = tableHeadFirstRow.outerHeight() || 0
            let tableHeadLastRowHeight = tableHeadLastRow.outerHeight()
            let tableHeadHeight = tableHeadFirstRowHeight + tableHeadLastRowHeight

            this.stickyOffsets = stickyOffsets
            this.tableHeadHeight = tableHeadHeight
            this.tableHeadFirstRowHeight = tableHeadFirstRowHeight
            this.tableHeadLastRowHeight = tableHeadLastRowHeight

            this.$refs.table.classList.remove("updating-sticky-offsets")
        },
        sortingLevel(i) {
            let id = this.getColumnId(i)
            return _.findIndex(this.desiredSort, (column) => column.id === id)
        },
        sortedAscending(i) {
            let id = this.getColumnId(i)
            return _.find(this.desiredSort, (column) => column.id === id)?.asc === true
        },
        sortedDescending(i) {
            let id = this.getColumnId(i)
            return _.find(this.desiredSort, (column) => column.id === id)?.asc === false
        },
        hasFilters(i) {
            let id = this.getColumnId(i)
            return this.filters.find(filter => filter.column == id)
       },
        isNumericColumn(i) {
            switch (this.getColumn(i)?.type) {
                case "int8":
                case "int16":
                case "int32":
                case "int64":
                case "float":
                case "double":
                    return true
            }
        },
        isStringColumn(i) {
            switch (this.getColumn(i)?.type) {
                case "string":
                case "html":
                    return true
            }
        },
        getColumn(i) {
            return this.columns.find(column => column.i == i)
        },
        getColumnId(i) {
            let column = this.getColumn(i)
            return column?.id || column?.calc || `${i}`
        },
        sortAscending(e, column) {
            let id = this.getColumnId(column)
            let num = column + 1
            let asc = true
            if (e.shiftKey) {
                let j = this.sortingLevel(column)
                if (j !== -1)
                    this.desiredSort.splice(j,1,{id,num,asc})
                else
                    this.desiredSort.push({id,num,asc})
            }
            else
                this.desiredSort = [{id,num,asc}]
        },
        sortDescending(e, column) {
            let id = this.getColumnId(column)
            let num = column + 1
            let asc = false
            if (e.shiftKey) {
                let j = this.sortingLevel(column)
                if (j !== -1)
                    this.desiredSort.splice(j,1,{id,num,asc})
                else
                    this.desiredSort.push({id,num,asc})
            }
            else
                this.desiredSort = [{id,num,asc}]
        },
        removeSorting(e, column) {
            if (e.shiftKey) {
                let l = this.sortingLevel(column)
                if (l !== -1)
                    this.desiredSort.splice(l,1)
                else
                    this.desiredSort = []
            }
            else
                this.desiredSort = []
        },
        removeFilter(e, i) {
            let id = this.getColumnId(i)
            this.filters = this.filters.filter(filter => filter.column != id)
            if (this.columnFilter[i]) {
                this.columnFilter.splice(i, 1)
            }
        },
        hideZeroes(e, i) {
            let id = this.getColumnId(i)
            let column = this.getColumn(i)
            this.filters.push({
                type: "hideZeroes",
                name: `Hide zeroes for ${utils.l10n(column.name)}`,
                code: `col${i+1} != 0`,
                column: id
            })
        },
        hideBlanks(e, i) {
            let id = this.getColumnId(i)
            let column = this.getColumn(i)
            this.filters.push({
                type: "hideBlanks",
                name: `Hide blanks for ${utils.l10n(column.name)}`,
                code: `col${i+1} != ''`,
                column: id
            })
        },
        refreshData(args) {
            this.requestData(args || { forced: true }).then(() => {
                this.rowOverrides = {};
            })
        },
        previousColumn(column) {
            let columns = this.visibleColumns
            return columns[columns.indexOf(column)-1]
        },
        setupColumnsSortable() {
            $(this.$refs.sortable)
                .sortable({
                    items: "th",
                    axis: "x",
                    cursor: "move",
                    helper: "clone",
                    distance: 4,
                    cancel: ".my-column-dim, .popover",
                    start: (e, ui) => {
                        ui.item.data("prev", null)
                        ui.item.data("next", null)
                        this.selectedColumn = null
                        this.selectedSection = null
                    },
                    change: (e, ui) => {
                        let x = ui.placeholder[0]
                        $(this.$refs.sortable).find("th").removeClass("column-move-target-prev")
                        $(this.$refs.sortable).find("th").removeClass("column-move-target-next")
                        $(x.previousSibling).addClass("column-move-target-prev")
                        $(x.nextSibling).addClass("column-move-target-next")
                        ui.item.data("prev", $(x.previousSibling).attr("id"))
                        ui.item.data("next", $(x.nextSibling).attr("id"))
                    },
                    beforeStop: (e, ui) => {
                        $(this.$refs.sortable).find("th").removeClass("column-move-target-prev")
                        $(this.$refs.sortable).find("th").removeClass("column-move-target-next")
                        ui.item.parent().sortable("cancel")

                        let self = ui.item.attr("id")
                        let prev = ui.item.data("prev")
                        let next = ui.item.data("next")

                        if (prev || next)
                            this.$emit("column-moved", {self, prev, next})
                    }
                })
        },
        setupColumnsWidths(column, width) {
            let makeColumnStyle = (column, width) => `
                #${this.tableId} > thead > tr > th[data-column="${column.i}"],
                #${this.tableId} > tbody > tr > td[data-column="${column.i}"],
                #${this.tableId} > tfoot > tr > td[data-column="${column.i}"]
                {
                    max-width: ${width}px;
                    min-width: ${width}px;
                }`

            let columnStyles =
                _(this.columns)
                    .filter("width")
                    .filter(({i}) => !column || column.i !== i)
                    .map((column) => makeColumnStyle(column, column.width))
                    .value()

            if (column)
                columnStyles.push(makeColumnStyle(column, width))

            this.style1.textContent = columnStyles.join("\n")
            if (this.freezeDims)
                this.updateStickyOffsets(true)
        },
        setupStickyOffsets() {
            let headStyle = `
                #${this.tableId}:not(.updating-sticky-offsets) > thead > tr:first-child:not(:last-child) th {
                    top: 0;
                    height: ${this.tableHeadFirstRowHeight}px;
                }
                #${this.tableId}:not(.updating-sticky-offsets) > thead > tr:last-child th {
                    top: ${this.isOptimizationsTable ? '0' : this.tableHeadFirstRowHeight}px;
                    height: ${this.tableHeadLastRowHeight}px;
                    ${this.isOptimizationsTable ? 'vertical-align: middle;' : ''}
                }
                #${this.tableId} > tbody > tr {
                    scroll-margin-top: ${this.tableHeadHeight + 1}px;
                }`

            let bodyStyle = _(this.stickyOffsets)
                .map((info, column) => info ? `
                    #${this.tableId}:not(.updating-sticky-offsets) > thead > tr > th[data-column="${column}"],
                    #${this.tableId}:not(.updating-sticky-offsets) > tbody > tr > td[data-column="${column}"],
                    #${this.tableId}:not(.updating-sticky-offsets) > tfoot > tr > td[data-column="${column}"]
                    {
                        left: ${info.offset}px;
                        min-width: ${info.width}px;
                        max-width: ${info.width}px;
                    }` : "")
                .filter()
                .join("\n")

            this.style2.textContent = headStyle + bodyStyle
        },
        resetColumnWidth(e, column) {
            if (column) {
                delete column.width
                this.$emit("column-updated", column, {width:undefined})
                this.setupColumnsWidths()
                this.$forceUpdate()
            }
        },
        getActualColumnWidth(column) {
            return $(`#${this.tableId} > thead > tr:last-child > th[data-column="${column.i}"]`).outerWidth()
        },
        startColumnResize(e, column) {
            if (!this.resizingColumn) {
                document.addEventListener("mousemove", this.trackColumnDragging)
                document.addEventListener("mouseup", this.stopColumnResize)
            }
            if (column) {
                this.resizingColumn = column
                this.resizingColumnStartX = e.pageX
                this.resizingColumnStartW = this.getActualColumnWidth(column)
                e.stopPropagation()
                e.preventDefault()
            }
        },
        stopColumnResize(e) {
            if (this.resizingColumn) {
                let width = Math.round(Math.max(20, this.resizingColumnStartW + e.pageX - this.resizingColumnStartX))
                this.resizingColumn.width = width
                this.$emit("column-updated", this.resizingColumn, {width})
                e.stopPropagation()
                e.preventDefault()
                document.removeEventListener("mousemove", this.trackColumnDragging)
                document.removeEventListener("mouseup", this.stopColumnResize)
                this.skipTableClick = true
                _.delay(() => this.skipTableClick = false, 100)
            }
            this.resizingColumn = null
        },
        trackColumnDragging(e) {
            let width = this.resizingColumnStartW + e.pageX - this.resizingColumnStartX
            this.setupColumnsWidths(this.resizingColumn, Math.max(20, width))
            e.stopPropagation()
            e.preventDefault()
        },
        interceptClickEvent(e) {
            if (this.editableColumn !== null) {
                if ($(e.target).closest(this.$refs.table).length == 0 &&
                    $(e.target).closest(this.$refs.tiles).length == 0 &&
                    $(e.target).closest(this.$refs.presentedRow).length == 0)
                {
                    this.editableRow = null
                    this.editableColumn = null
                }
            }
        },
        prevPresentedRow() {
            if (this.presentedRow > 0)
                this.presentedRow -= 1
            else if (this.page > 0) {
                this.presentedRow = this.pageSize - 1
                this.desiredPage = this.page - 1
            }
        },
        nextPresentedRow() {
            if (this.presentedRow < this.rows.length - 1)
                this.presentedRow += 1
            else if (this.page < this.pageCount - 1) {
                this.presentedRow = 0
                this.desiredPage = this.page + 1
            }
        },
        interceptKeyDownEvent(e) {
            if (this.presentedRow !== null) {
                if (e.key == "ArrowLeft") {
                    e.preventDefault()
                    this.prevPresentedRow()
                }
                if (e.key == "ArrowRight") {
                    e.preventDefault()
                    this.nextPresentedRow()
                }
            }
        },
        isDimsSection(section) {
            return _.every(section.columns, (column) => column.i < this.meta.dims.length)
        },
        getValue(row, column, totals = false) {
            let value = undefined
            if (totals)
                value = row[column.i-this.meta.dims.length]
            else
                value = row[column.i]
            if (column.override)
                value = column.override({row, column, totals, value})
            return value
        },
        filterClass: function(filter) {
            return {
                "badge": true,
                "badge-pill": true,
                "badge-secondary": filter.type !== "keepOnly" && filter.type !== "exclude",
                "badge-primary": filter.type === "keepOnly",
                "badge-danger": filter.type === "exclude",
            }
        },
        computeFetchWindow() {
            if (this.pagination) {
                let bucket = Math.floor(this.page / this.prefetchSize) * this.prefetchSize
                let skip = bucket * this.pageSize
                let take = this.prefetchSize * this.pageSize
                return {skip, take}
            }
            else {
                return {skip: null, take: null}
            }
        },
        promptPage() {
            let page = prompt("Go to page:", this.page+1)
            if (page !== null) {
                page = parseInt(page)
                if (!_.isNaN(page)) {
                    this.setDesiredPage(page-1)
                    if (this.presentedRow !== null)
                        this.presentedRow = 0
                }
            }
        },
        setDesiredPage(page) {
            if (this.reportId === null)
                this.previousPage = this.desiredPage
            this.desiredPage = page
        },
        promptPageSize() {
            let pageSize = prompt("Rows per page:", this.pageSize)
            if (pageSize !== null) {
                pageSize = parseInt(pageSize)
                if (!_.isNaN(pageSize))
                    this.pageSize = pageSize
            }
        },
        callColumnAction(column, action) {
            if (action.call(column))
                this.selectedColumn = null
        },
        callTableAction(action, event) {
            if (action.call(event))
                this.showTableActions = false
        },
        callSectionAction(section, action) {
            if (action.call(section))
                this.selectedSection = null
        },
        handleTableKeyDown(e) {
            if (e.target.tagName === "INPUT" ||
                e.target.tagName === "TEXTAREA" ||
                e.target.tagName === "SELECT")
            {
                if (e.key === "ArrowUp" && e.target.tagName != "TEXTAREA") {
                    this.editNextRow(-1);
                    e.stopPropagation()
                    e.preventDefault()
                }
                if (e.key === "ArrowDown" && e.target.tagName != "TEXTAREA") {
                    this.editNextRow(1);
                    e.stopPropagation()
                    e.preventDefault()
                }
                if (e.key === "Escape") {
                    this.editableRow = null
                    this.editableColumn = null
                    Vue.nextTick(() => $(this.$refs.table).focus())
                }
                if (e.key === "Enter" && (e.target.tagName != "TEXTAREA" || e.shiftKey)) {
                    let row = this.editableRow !== -1 ? this.getRow(this.editableRow) : null;
                    let column = this.columns.find(({i}) => i == this.editableColumn);
                    let value = e.target.value;
                    const rows = this.rows;
                    this.$emit("cell-edited", {rows, row, column, value, meta: this.meta}, this);

                    if (e.shiftKey || !this.editNextRow(1)) {
                        this.editableRow = null
                        this.editableColumn = null
                    }
                }
                return
            }
            let selectedRow = undefined
            if (this.selectedRow === null) {
                if (e.key === "ArrowUp")
                    selectedRow = -1
                if (e.key === "ArrowDown")
                    selectedRow = 0
            }
            else {
                if (e.key === "ArrowUp")
                    selectedRow = this.selectedRow - 1
                if (e.key === "ArrowDown")
                    selectedRow = this.selectedRow + 1
            }
            if (e.key === "ArrowLeft" && e.shiftKey) {
                this.setDesiredPage(this.page - 1)
            }
            if (e.key === "ArrowRight" && e.shiftKey) {
                this.setDesiredPage(this.page + 1)
            }
            if (selectedRow !== undefined) {
                e.preventDefault()
                e.stopPropagation()
                if (selectedRow < 0)
                    selectedRow = 0
                if (selectedRow >= this.rows.length)
                    selectedRow = this.rows.length - 1
                if (this.selectedRow != selectedRow) {
                    this.selectedRow = selectedRow
                    this.selectedRows = {}
                    this.$set(this.selectedRows, this.selectedRow, true)
                    Vue.nextTick(() => {
                        let selectedRow = $(this.$refs.table).find(".selected")[0]
                        let expandedRow = $(this.$refs.table).find(".expanded")[0]
                        if (selectedRow)
                            selectedRow.scrollIntoView({
                                behavior: "auto",
                                block: "nearest",
                                inline: "nearest"
                            })
                        if (expandedRow)
                            expandedRow.scrollIntoView({
                                behavior: "auto",
                                block: "nearest",
                                inline: "nearest"
                            })
                    })
                }
            }
            if (e.key === "Enter" &&
                this.selectedRow !== null &&
                this.editableColumn === null)
            {
                let column = this.columns.find(column => column.editable && column.editable(this.selectedRow, column, this.meta) && (this.selectedRow >= 0 || this.editableTotals))
                if (column) {
                    this.editableRow = this.selectedRow
                    this.editableColumn = column.i
                }
                else {
                    this.presentedRow = this.selectedRow
                }
            }
        },
        handleTableChange(e) {
            this.$emit("change", e, this.makeInfo(e))

            if (this.editCellOnChange) {
                let row = this.editableRow !== -1 ? this.getRow(this.editableRow) : null;
                let column = this.columns.find(({i}) => i == this.editableColumn);
                let value = e.target.value;
                this.$emit("cell-edited", {row, column, value, meta: this.meta}, this);
            }
        },
        handleTableClick(e) {
            this.$emit("click", e, this.makeInfo(e))
        },
        handleTableMouseMove(e) {
            if (e.target.tagName == "IMG") {
                if (this.imageOverTimer) {
                    clearTimeout(this.imageOverTimer)
                    this.imageOverTimer = null
                }
                if (this.imageOverLink != e.target.src) {
                    this.imageOverLink = e.target.src
                    this.imageOverAnchor = e.target
                    this.imageOverOpacity = 1
                }
                this.imageOverOpacity = 1
            }
            else {
                if (this.imageOverAnchor && !this.imageOverTimer) {
                    this.imageOverOpacity = 0
                    this.imageOverTimer = setTimeout(() => {
                        this.imageOverLink = null
                        this.imageOverTimer = null
                        this.imageOverAnchor = null
                    }, 300)
                }
            }
        },
        handleTableMouseLeave(e) {
            clearTimeout(this.imageOverTimer)
            this.imageOverAnchor = null
            this.imageOverOpacity = 1
            this.imageOverLink = null
            this.imageOverTimer = null
        },
        handleTableMouseDown(e) {
            if (this.skipTableClick)
                return
            _.defer(() => {
                if (!getSelection().isCollapsed && (e.shiftKey || e.ctrlKey))
                    getSelection().removeAllRanges()
            })
            this.$emit("mousedown", e, this.makeInfo(e))
            if (!getSelection().isCollapsed) {
                if (e.shiftKey || e.ctrlKey)
                    getSelection().removeAllRanges()
                else
                    return
            }

            if (e.target.tagName == "TR") {
                let row = parseInt($(e.target).attr("data-row"))
                if (!Number.isNaN(row))
                    this.presentedRow = row
            }

            let cell = $(e.target).closest("th,td")
            if (cell.length == 0)
                return

            let row = parseInt(cell.closest("[data-row]").attr("data-row"))
            let column = parseInt(cell.attr("data-column"))
            column = _.findIndex(this.columns, ({i}) => i == column)

            let a = $(e.target).closest("a")
            if (a.length !== 0) {
                e.preventDefault()
                e.stopPropagation()
                if (a.hasClass("my-colum-action")) {
                    let info = this.makeInfo(e)
                    if (_.isString(info.column.action)) {
                        eval(info.column.action)(info.row, info.column, info.meta)
                    }
                    else if (_.isFunction(info.column.action)) {
                        info.column.action(info.row, info.column, info.meta)
                    }
                    this.$emit("action", e, this.makeInfo(e))
                }
                else
                    this.$emit("link-clicked", e, this.makeInfo(e))
                return
            }

            if (row !== undefined && column !== -1) {
                if (this.selectedRow === row)
                    this.$emit("cell-clicked", e, this.makeInfo(e))
                if (this.columns[column].editable &&
                        (row >= 0 && this.columns[column].editable(this.getRow(row), this.columns[column], this.meta) ||
                        this.editableTotals))
                {
                    this.editableRow = row
                    this.editableColumn = this.columns[column].i
                }
                else {
                    this.editableRow = null
                    this.editableColumn = null
                    if (this.selectedRow !== row) {
                        if ((e.ctrlKey || e.altKey || e.metaKey) && this.selectedRow !== null) {
                            if (this.selectedRows[row])
                                this.$delete(this.selectedRows, row)
                            else
                                this.$set(this.selectedRows, row, true)
                        }
                        else if (e.shiftKey && this.selectedRow !== null) {
                            let selectedRows = {}
                            if (row < this.selectedRow)
                                for (let i = row; i <= this.selectedRow; ++i)
                                    selectedRows[i] = true
                            else
                                for (let i = this.selectedRow; i <= row; ++i)
                                    selectedRows[i] = true
                            this.selectedRows = selectedRows
                        }
                        else {
                            this.selectedRow = row
                            this.selectedRows = {}
                            this.$set(this.selectedRows, this.selectedRow, true)
                        }
                        cell.parent().focus()
                        e.preventDefault()
                    }
                }
            }
            else if (column !== -1) {
                this.editableRow = null
                this.editableColumn = null
                this.selectedColumn = this.columns[column].i
            }
            else {
                this.editableRow = null
                this.editableColumn = null
            }
        },
        handlePaginatorKeyDown: function(e) {
            switch (e.key) {
                case "ArrowLeft":
                    e.preventDefault()
                    e.stopPropagation()
                    this.setDesiredPage(this.page - (e.shiftKey ? 10 : 1))
                    break
                case "ArrowRight":
                    e.preventDefault()
                    e.stopPropagation();
                    this.setDesiredPage(this.page + (e.shiftKey ? 10 : 1))
                    break
            }
        },
        handlePageSliderClick: function(e) {
            let offset = $(this.$refs.slider).offset()
            let width = $(this.$refs.slider).width()
            let position = (e.pageX - offset.left) / width
            this.setDesiredPage(Math.floor(position * this.size / this.pageSize))
        },
        keepOnly({row, column}) {
            let value = row[column.i]
            this.filters.push({
                type: "keepOnly",
                name: `${utils.l10n(column.name)}: ${column.format(value)}`,
                code: `(${column.calc}) == ${utils.quote(value, column)}`,
            })
        },
        exclude({row, column}) {
            let value = row[column.i]
            this.filters.push({
                type: "exclude",
                name: `${utils.l10n(column.name)}: ${column.format(value)}`,
                code: `(${column.calc}) != ${utils.quote(value, column)}`,
            })
        },
        editNextRow(direction = 1) {
            let getEditableColumns = (row) =>
                _(this.sections)
                    .map("visibleColumns")
                    .flatten()
                    .filter(column => column.editable && column.editable(row, column, this.meta))
                    .value()
            if (this.presentedRow != null || this.mode == "tiles") {
                let row = this.getRow(this.editableRow)
                let editableColumns = getEditableColumns(row)
                let column = editableColumns[_.findIndex(editableColumns, ({i}) => i === this.editableColumn) + direction]
                if (column) {
                    this.editableColumn = column.i
                    return true
                }
                else if (
                    direction === 1 && this.editableRow < this.rows.length - 1 ||
                    direction === -1 && this.editableRow > 0)
                {
                    let row = this.getRow(this.editableRow + direction)
                    let editableColumns = getEditableColumns(row)
                    if (editableColumns.length) {
                        if (direction == 1) {
                            this.editableRow += direction
                            this.editableColumn = editableColumns[0].i
                        }
                        else {
                            this.editableRow += direction
                            this.editableColumn = editableColumns[editableColumns.length - 1].i
                        }
                        return true
                    }
                    else return false
                }
                else return false
            }
            else {
                if (direction === 1 && this.editableRow < this.rows.length - 1 ||
                    direction === -1 && this.editableRow > 0)
                {
                    let row = this.getRow(this.editableRow + direction)
                    let column = _.find(this.columns, ({i}) => i === this.editableColumn)
                    if (column.editable(row, column, this.meta)) {
                        this.editableRow += direction
                        return true
                    }
                    else return false
                }
                else return false
            }
        },
        resolveColumn(i) {
            for (let section of this.sections)
                for (let column of section.columns)
                    if (column.i === i)
                        return column
            return null
        },
        makeInfo(e) {
            let td = $(e.target).closest("[data-column]")
            let tr = $(e.target).closest("[data-row]")
            let row = parseInt(tr.attr("data-row"))
            let column = parseInt(td.attr("data-column"))
            return {
                row: this.getRow(row),
                column: this.resolveColumn(column),
                meta: this.meta,
            }
        },
        columnFilterChanged(changes) {
            const column = this.getColumn(this.selectedColumn)
            const columnId = this.getColumnId(this.selectedColumn)
            const i = this.filters.findIndex(x => x?.type === "columnFilter")
            if (i >= 0) {
                this.filters.splice(i, 1)
            }
            if (changes.filter) {
                this.filters.push({
                    type: "columnFilter",
                    name: `Column filter for ${utils.l10n(column.name)}`,
                    code: changes.filter,
                    column: columnId,
                    show: true
                })
            }
        },
    },
}
</script>
<style>
.my-column-docid,
.my-column-int8,
.my-column-int16,
.my-column-int32,
.my-column-int64,
.my-column-float,
.my-column-double,
.my-column-date,
.my-column-time,
.my-column-datetime {
    text-align: right;
}
.my-column-docid > input,
.my-column-int8 > input,
.my-column-int16 > input,
.my-column-int32 > input,
.my-column-int64 > input,
.my-column-float > input,
.my-column-double  > input{
    text-align: right;
}
.plain-table {
    position: relative;
}
.plain-table .pagination {
    margin-bottom: 4px;
    font-size: 13px;
    clear: left;
}
.plain-table .table {
    font-size: 10px;
    line-height: 12px;
    margin-top: 4px;
}
.plain-table .table tbody > tr > th,
.plain-table .table thead > tr:last-child > th,
.plain-table .table tr.expanded td.my-column-dim {
    font-weight: normal;
}
.plain-table .table th > div  {
    display: flex;
    flex-direction: row;
}
.plain-table .table th .feather-icon {
    margin: -2px;
    vertical-align: top;
    display: inline-block;
}
.plain-table .table th > div > * + * {
    margin-left: 2px!important;
}
.plain-table .table th .feather-icon svg {
    width: 18px;
    height: 18px;
}
.plain-table-slider {
    position: relative;
    margin-top: 6px;
    margin-bottom: -6px;
    z-index: 1;
    clear: both;
    cursor: pointer;
}
.plain-table-slider {
    height: 4px;
    transition: height 1s, margin-top 1s;
}
.plain-table-slider-background,
.plain-table-slider-prefetch-window,
.plain-table-slider-visible-window {
    position: absolute;
    background-color: var(--info);
    top: 0;
    bottom: 0;
}
.plain-table-slider-background {
    left: 0;
    right: 0;
}
.my-dark-theme .plain-table-slider-background {
    background-color: #33373a;
}
.my-light-theme .plain-table-slider-background {
    background-color: #e5e8ea;
}
.plain-table-slider-prefetch-window {
    opacity: 0.5;
}
.plain-table-paginator {
    clear: left;
    float: left;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}
.plain-table-paginator .feather-icon {
    vertical-align: top;
    width: 22px;
    height: 22px;
}
.plain-table-paginator a.disabled {
    cursor: arrow;
    opacity: 0.8;
    filter: grayscale(100%);
}
.plain-table-paginator .nowrap {
    white-space: nowrap;
}
tr.plain-table-totals > td {
    font-weight: bold;
    position: relative;
}
th.section-start,
td.section-start {
    padding-left: 8px;
}
th.section-end,
td.section-end {
    padding-right: 10px;
}
tr.plain-table-totals:not(:hover):not(.selected) > td:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 0px !important;
    background-color: #E9E9E9;
}
tr.plain-table-totals:not(:hover):not(.selected) > td.section-end:after {
    right: 10px;
}
.plain-table-paginator .feather-icon > svg {
    display: inline-block;
    vertical-align: top;
    height: 20px;
}
.plain-table-paginator:focus {
    outline: none;
    position: relative;
}
.plain-table-paginator:focus:after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 1px solid var(--cyan);
}
.table > tbody > tr > td,
.table > tfoot > tr > td {
    position: relative;
}
.table > tbody > tr:hover > td {
    background-color: rgb(233,242,250);
}
.my-dark-theme .table > tbody > tr:hover > td {
    background-color: #283139;
}
.table.table-expandable tr.selected.primary > td:after {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background-color: var(--cyan);
    z-index: 1;
}
.table:not(.table-expandable) tr.selected.primary > td:after {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    top: -1px;
    bottom: -1px;
    z-index: 1;
}
tr.expanded > td:after {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: var(--cyan);
    z-index: 1;
}
tr.expanded > td > table {
    margin: 4px auto;
    font-size: 0.95em;
}
tr.expanded > td > table > * > tr > * {
    border: none;
    padding: 2px 4px;
}
.plain-table-colors {
    height: 16px;
    vertical-align: top;
    margin-top: 4px;
    margin-bottom: -2px;
    width: 120px;
    border: 2px solid white;
    display: inline-block;
    opacity: 0.7;
}
.plain-table-manage-table {
    max-width: 200px;
}
.plain-table-manage-table ul,
.plain-table-manage-column ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.plain-table-manage-table ul>li,
.plain-table-manage-column ul>li {
    padding: 4px;
}
.plain-table-manage-column .form-check {
    display: flex;
    align-items: baseline;
    gap: 3px;
}
.plain-table-manage-table .feather-icon,
.plain-table-manage-column .feather-icon {
    display: inline-block;
    vertical-align: top;
    margin-top: -2px;
}
.plain-table-manage-table .feather-icon svg,
.plain-table-manage-column .feather-icon svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}
.plain-table-manage-column ul li a {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #000000;
}
.my-dark-theme .plain-table-manage-column ul li a {
    color: #ffffff;
}
.gp-column-precision label span {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #000000;
}
.table {
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
}
.table > thead > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border: none;
}
.table > tfoot > tr > td {
    font-weight: bold;
}
.table > thead > tr > th {
    position: relative;
}
.table > thead > tr:first-child:not(:last-child) > th:not(:first-child) {
    border-left: 1px solid var(--dark);
}
.table > thead > tr:first-child:not(:last-child) > th:after {
    content: "";
    position: absolute;
    left: 0;
    right: 10px;
    bottom: 0;
    height: 0px;
    background-color: var(--dark);
}
.table > thead > tr:first-child:not(:last-child) > th:last-child:after {
    right: 0;
}
.table > thead > tr:not(:first-child) > th {
    font-weight: normal;
}
.table > thead > tr > th.section-start:not(:first-child),
.table > tbody > tr > td.section-start:not(:first-child),
.table > tfoot > tr > td.section-start:not(:first-child) {
    border-left: none !important;
}
[data-type="int8"],
[data-type="int16"],
[data-type="int32"],
[data-type="int64"],
[data-type="float"],
[data-type="double"],
[data-type="date"],
[data-type="time"],
[data-type="datetime"] {
    text-align: right;
}
.plain-table .my-filters {
    float: left;
}
td.my-column-editable {
    max-width: 250px;
    cursor: pointer;
    color: var(--cyan);
}
td.my-column-editable:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    pointer-events: none;
    border:
        1px solid #3498db40;
    border-radius: 2px;
    z-index: 1;
}
td.my-column-editable.editable > span:first-child {
    font-weight: normal;
    display: block;
    overflow: hidden;
}
td.my-column-editable.editable > span:last-child {
    display: block;
    overflow: hidden;
}
td.my-column-editable.editable > span:not(:empty) + span {
    margin-top: -15px;
}
td.my-column-editable.editable > .chosen-container,
td.my-column-editable.editable > input,
td.my-column-editable.editable > textarea,
td.my-column-editable.editable > select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border: none;
    padding: 0.3rem;
}
.plain-table .table:focus {
    outline: none
}
.plain-table-table-actions {
    float: right;
    margin-left: 8px;
}
.plain-table-manage-columns {
    float: right;
    margin-left: 8px;
}
.plain-table-manage-columns .popover {
    width: 200px;
    max-height: 80vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.plain-table-manage-columns .form-group > label {
    font-weight: bold;
    margin: 0;
}
.plain-table-manage-columns .form-group {
    margin-bottom: 8px;
}
.plain-table-manage-columns b {
    text-decoration: underline;
}
.my-column-date.my-column-editable {
    min-width: 110px;
}
.plain-table .table > tbody > tr > td[data-type="int8"] > span,
.plain-table .table > tbody > tr > td[data-type="int16"] > span,
.plain-table .table > tbody > tr > td[data-type="int32"] > span,
.plain-table .table > tbody > tr > td[data-type="int64"] > span,
.plain-table .table > tbody > tr > td[data-type="float"] > span,
.plain-table .table > tbody > tr > td[data-type="double"] > span,
.plain-table .table > tfoot > tr > td[data-type="int8"] > span,
.plain-table .table > tfoot > tr > td[data-type="int16"] > span,
.plain-table .table > tfoot > tr > td[data-type="int32"] > span,
.plain-table .table > tfoot > tr > td[data-type="int64"] > span,
.plain-table .table > tfoot > tr > td[data-type="float"] > span,
.plain-table .table > tfoot > tr > td[data-type="double"] > span {
    float: right;
    max-width: 100%;
}
.plain-table .table > thead > tr > th > span,
.plain-table .table > tbody > tr > td[data-type="string"] > span,
.plain-table .table > tbody > tr > td[data-type="tagged"] > span,
.plain-table .table > tfoot > tr > td[data-type="string"] > span,
.plain-table .table > tfoot > tr > td[data-type="tagged"] > span {
    text-overflow: ellipsis;
}
.table-column-resizing * {
    cursor: col-resize!important;
}
.column-resize-left,
.column-resize-right {
    position: absolute;
    top: 0;
    bottom: 1px;
    width: 5px;
    z-index: 1;
    cursor: col-resize;
    user-select: none;
    display: none;
}
.column-resize-left {
    left: 0;
    border-left: 2px solid var(--light);
}
.column-resize-right {
    border-right: 2px solid var(--light);
    right: 0;
}
th:first-child .column-resize-left {
    display: none!important;
}
th:last-child .column-resize-right {
    width: 8px;
    border-right: 4px solid var(--light);
}
.table-column-resizing > thead > tr> th .column-resize-left,
.table-column-resizing > thead > tr> th .column-resize-right,
.plain-table .table > thead > tr:hover > th .column-resize-left,
.plain-table .table > thead > tr:hover > th .column-resize-right {
    display: block;
}
.plain-table-sort-icons {
    display: inline-block;
}
.plain-table-sort-icons:empty {
    display: none;
}
.my-column-editable {
    white-space: nowrap;
}
.my-colum-action {
    position: absolute;
    top: 6px;
    right: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    background-color: #E9E9E9;
    border-radius: 4px;
    color: inherit;
    display: var(--display)!important;
    pointer-events: var(--pointer-events)!important;
}
.my-colum-action:has(svg[class*="feather-"]) {
  background: none;
}
.my-colum-action svg[class*="feather-"] {
  width: 18px;
  height: 18px;
}
td.my-column-actionable .my-colum-action:has(svg[class*="feather-"])::before {
  display: none;
}
.section-start .my-colum-action::before {
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    left: -12px;
    top: 50%;
    background-color: #E9E9E9;
}
.my-colum-action::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    left: -12px;
    top: 5px;
    background-color: #E9E9E9;
}
td.my-column-actionable .my-colum-action {
    right: 6px;
}
/* td.my-column-actionable .my-colum-action::before { // Leave for a while, will be used in lama code
    width: 0;
    height: 0;
} */
td.my-column-actionable .my-colum-action::after {
    width: 0;
    height: 0;
}
td.my-column-actionable[style^='color:var(--green)'] .my-colum-action::before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border: 1px solid grey;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
tr:last-child .my-colum-action::before {
    content: url('./icons/last-element.svg');
    position: absolute;
    width: 11px;
    height: 14px;
    left: -12.5px;
    top: -7px;
    background-color: transparent;
}
tr:last-child .my-colum-action::after {
    width: 0px;
}
tr[style='--level: 0;'] .my-colum-action::before,
tr:last-child[style='--level: 0;'] .my-colum-action::before,
tr[style='--level: 0;'] .my-colum-action::after,
tr:last-child[style='--level: 0;'] .my-colum-action::after {
    content: '';
    width: 0px;
    height: 0px;
}
/* Закомментировано в рамках https://jira.napoleonit.ru/browse/GP-133 */

/* tr[style='--level: 2;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9;
}
tr[style='--level: 3;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9;
}
tr[style='--level: 4;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9;
}
tr[style='--level: 5;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9;
}
tr[style='--level: 6;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9;
}
tr[style='--level: 7;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9;
}
tr[style='--level: 8;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9;
}
tr[style='--level: 9;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9,
                -128px 0px #E9E9E9;
}
tr[style='--level: 9;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9,
                -128px 0px #E9E9E9;
}
tr[style='--level: 10;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9,
                -128px 0px #E9E9E9,
                -144px 0px #E9E9E9;
}
tr[style='--level: 11;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9,
                -128px 0px #E9E9E9,
                -144px 0px #E9E9E9,
                -160px 0px #E9E9E9;
}
tr[style='--level: 12;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9,
                -128px 0px #E9E9E9,
                -144px 0px #E9E9E9,
                -160px 0px #E9E9E9,
                -176px 0px #E9E9E9;
}
tr[style='--level: 13;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9,
                -128px 0px #E9E9E9,
                -144px 0px #E9E9E9,
                -160px 0px #E9E9E9,
                -176px 0px #E9E9E9,
                -192px 0px #E9E9E9;
}
tr[style='--level: 14;'] .my-colum-action::before {
    box-shadow: -16px 0px #E9E9E9,
                -32px 0px #E9E9E9,
                -48px 0px #E9E9E9,
                -64px 0px #E9E9E9,
                -80px 0px #E9E9E9,
                -96px 0px #E9E9E9,
                -112px 0px #E9E9E9,
                -128px 0px #E9E9E9,
                -144px 0px #E9E9E9,
                -160px 0px #E9E9E9,
                -176px 0px #E9E9E9,
                -192px 0px #E9E9E9,
                -208px 0px #E9E9E9;
} */

.my-colum-action svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.my-colum-action:hover {
    color: inherit;
}
.my-column-actionable {
    padding-right: 24px!important;
}
.plain-table-head .feather-icon-refresh-cw svg {
    transform: scale(0.85);
}
.plain-table-manage-table {
    font-size: 14px;
}
.plain-table-manage-table .feather-icon svg {
    width: 18px;
    height: 18px;
}
.plain-table .table > tfoot > tr > td {
    position: sticky;
    bottom: -1px;
    z-index: 1;
    padding-top: calc(.3rem + 1px);
    padding-bottom: calc(.3rem + 1px);
}
.plain-table .table > tfoot > tr > td:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-top: 1px solid var(--dark);
}
.plain-table-manage-section .popover-body {
    padding: 0;
}
.my-dark-theme .plain-table .table > tfoot > tr > td:after {
    border-top: 1px solid var(--light);
}
.table-frozen-dims .my-column-dim {
    position: sticky;
    z-index: 2!important;
}
.table td {
    background-color: white;
}
.table .capacity-grade-column {
    background-color: #B8860B;
}
.my-dark-theme .table td {
    background-color: rgb(34,34,34);
}
.table tr.group-primary-row td {
    background-color: #CCE5FF;
}
.table tr.group-secondary-row td {
    background-color: #F4FAFF;
}
.table tr.selected td,
.table tr.expanded td {
    background-color: rgb(233,242,250);
}
.my-dark-theme .table tr.selected td,
.my-dark-theme .table tr.expanded td {
    background-color: #283139;
}
.my-column-dim.section-end:before {
    content: "";
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--dark);
}
.my-dark-theme .table .my-column-dim:before {
    background-color: var(--light);
}
.overscroll td {
    padding: 0!important;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='23'><rect fill='rgba(128,128,128,0.1)' x='4' y='4' width='80%' height='15'/></svg>");
    background-repeat: repeat;
    background-size: 100% 23px;
}
@keyframes downloading {
    from { transform: translatey(-2px) }
    70% { transform: translatey(2px) }
    to { transform: translatey(-2px) }
}
.feather-download-cloud line,
.feather-download-cloud polyline {
    animation: downloading 1s infinite;
}
td.my-column-editable.editable > input,
td.my-column-editable.editable > textarea,
td.my-column-editable.editable > select {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--cyan);
    border-radius: 3px;
    padding: calc(0.3rem - 1px);
}
td.my-column-editable > span {
    color: var(--color);
    font-style: var(--font-style);
    font-weight: var(--font-weight);
    text-decoration: var(--text-decoration);
}
td.my-column-tagged,
td.my-column-string {
    max-width: 250px;
}
td.my-column-tagged > span,
td.my-column-string > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
tr.expanded > td > table {
    margin: 10px;
}
.plain-table-totals {
    background-color: #3498DB20;
}
.plain-table > table:focus {
    outline: none
}
td.my-column-editable.editable > span:not(:empty) + span {
    clear: both;
}
td.my-column-editable.editable > select {
    padding: 0;
}
.plain-table-manage-column {
    max-height: 100vh;
    width: 100%;
    overflow-y: auto;
}
.my-colum-action + span {
    overflow: hidden;
}
td.my-column-editable.editable > input {
    line-height: 14px;
    height: 24px;
    padding: 4px 3px;
}
td.my-column-editable.editable > textarea {
    line-height: 14px;
    padding: 4px 3px;
}
td.my-column-editable.editable > select {
    line-height: 14px;
    height: 24px;
    padding: 0px 0px;
}
.my-column-date > span {
    white-space: nowrap;
    overflow: hidden;
}
.table > thead > tr > th > span,
.table > tbody > tr > td > span {
    color: var(--color);
    font-style: var(--font-style);
    font-weight: var(--font-weight);
    text-decoration: var(--text-decoration);
}
.overscroll td {
    padding: 0!important;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='23'><rect fill='rgba(128,128,128,0.1)' x='4' y='4' width='80%' height='15'/></svg>");
    background-repeat: repeat;
    background-size: 100% 23px;
}
.feather-download-cloud line,
.feather-download-cloud polyline {
    animation: downloading 1s infinite;
}
@keyframes downloading {
    from { transform: translatey(-2px) }
    70% { transform: translatey(2px) }
    to { transform: translatey(-2px) }
}
td.my-column-editable.editable > input,
td.my-column-editable.editable > textarea {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--cyan);
    border-radius: 3px;
    padding: calc(0.3rem - 1px);
}
.plain-table-totals {
    background-color: #3498DB20;
}
.plain-table > table:focus {
    outline: none
}
.plain-table .table th > div  {
    display: inline;
}
.table-expired,
.table-expired > th,
.table-expired > td,
.my-dark-theme .table-expired,
.my-dark-theme .table-expired > th,
.my-dark-theme .table-expired > td {
    color: var(--gray);
}
.table-success,
.table-success > th,
.table-success > td,
.my-dark-theme .table-success,
.my-dark-theme .table-success > th,
.my-dark-theme .table-success > td {
    color: inherit;
    background-color: #18bc9c3b;
}
.table-warning,
.table-warning > th,
.table-warning > td,
.my-dark-theme .table-warning,
.my-dark-theme .table-warning > th,
.my-dark-theme .table-warning > td {
    color: inherit;
    background-color: #F39C123b;
}
.table-danger,
.table-danger > th,
.table-danger > td,
.my-dark-theme .table-danger,
.my-dark-theme .table-danger > th,
.my-dark-theme .table-danger > td {
    color: inherit;
    background-color: #E74C3C3b;
}
.table-info,
.table-info > th,
.table-info > td,
.my-dark-theme .table-info,
.my-dark-theme .table-info > th,
.my-dark-theme .table-info > td {
    color: inherit;
    background-color: #3498db26;
}
td.my-column-editable.editable > span:not(:empty) + span {
    clear: both;
}
.plain-table-presented-row {
    font-size: 14px;
}
.plain-table-presented-row .form-group {
    position: sticky;
    background-color: white;
    z-index: 1;
    top: 0;
}
.plain-table-presented-row .table th {
    padding: 2px 5px;
    border: none;
    position: relative;
}
.plain-table-presented-row .table th:after {
    content: "";
    background-color: var(--gray);
    bottom: 4px;
    left: 0;
    right: 0;
    height: 1px;
    position: absolute;
}
.plain-table-presented-row .table td {
    padding: 2px 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: left;
}
.plain-table-presented-row__section-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
}
.plain-table-presented-row .table td:first-child {
    color: var(--dark);
    width: 50%;
    padding-right: 0;
}
.my-dark-theme .plain-table-presented-row .table td:first-child {
    color: var(--light);
}
.plain-table-presented-row .table td:last-child {
    width: 50%;
}
.plain-table-presented-row__page-count {
    font-size: 10px;
    font-weight: 500;
    line-height: 120%;
}
.plain-table-presented-row__page-count .btn {
    padding: 0;
}
.plain-table-presented-row__btn {
    padding: 0;
    color: #AFAFAF;
    background-color: #F7F7F7;
    border-color: #F7F7F7;
} 
.plain-table-body > table > * > tr:before {
    opacity: 0;
    width: 22px;
    height: 22px;
    display: table-cell;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 3px 3px;
    content: "";
    position: absolute;
    left: -20px;
    cursor: pointer;
    pointer-events: all;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' %3E%3Cpath d='M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3'%3E%3C/path%3E%3C/svg%3E");
}
.my-dark-theme .plain-table-body > table > * > tr:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(230,230,230)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' %3E%3Cpath d='M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3'%3E%3C/path%3E%3C/svg%3E");
}
.plain-table-body > table > tbody > tr:not(.plain-table-totals):hover:before {
    opacity: 1;
}
.gp-table .plain-table-body > table > * > tr:before {
    left: 0;
}
.plain-table-table-actions {
    margin-left: 10px;
}
.plain-table-table-actions a svg {
    width: 20px!important;
}
.plain-table-table-actions > a + a {
    margin-left: 8px;
}
.plain-table-presented-row table {
    width: 100%;
}
.my-column-check .form-check {
    margin-top: -4px;
    vertical-align: top;
}
.plain-table-body > .table > tbody > tr:focus {
    outline: none;
    box-shadow: none;
}
.plain-table-image-over {
    pointer-events: none;
}
.plain-table-image-over .popover-body {
    padding: 10px;
}
.plain-table-image-over .popover {
    transition: opacity 0.3s;
}
.plain-table-image-over img {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
}
.leaf > td.my-column-dim{
    background-color: #F4FAFF;
}

.plain-table .download-url-button .feather-icon svg,
.plain-table .upload-url-button .feather-icon svg {
    width: 17px;
    height: 17px;
}

.restart-stream-button,
.expand-table-button {
    vertical-align: middle;
    font-size: 0px;
}
.expand-table-button:not(.opened) {
    color: var(--gray);
}
</style>
