var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "base", staticClass: "gp-select" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputValue,
          expression: "inputValue"
        }
      ],
      ref: "input",
      staticClass: "form-control",
      attrs: { placeholder: _vm.placeholder },
      domProps: { value: _vm.inputValue },
      on: {
        focus: function($event) {
          _vm.optionsOpened = true
          _vm.showAllOptions = true
        },
        keydown: _vm.handleInputKeyDown,
        input: [
          function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.inputValue = $event.target.value
          },
          function($event) {
            return _vm.$emit("select-input", $event)
          }
        ]
      }
    }),
    _vm._v(" "),
    _vm.inputValue === ""
      ? _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                _vm.optionsOpened = true
                _vm.showAllOptions = true
              }
            }
          },
          [
            _c("svg", { attrs: { viewBox: "-4 -4 18 18" } }, [
              _c("path", { attrs: { d: "M0 1 L10 1 L 5 9 Z" } })
            ])
          ]
        )
      : _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.submitValue(null)
              }
            }
          },
          [_c("feather-icon", { attrs: { name: "x" } })],
          1
        ),
    _vm._v(" "),
    _vm.optionsOpened && _vm.visibleOptions.length > 0
      ? _c(
          "ul",
          { attrs: { refs: "options" } },
          [
            _vm._l(_vm.recentOptions, function(option, i) {
              return _c(
                "li",
                {
                  key: i,
                  class:
                    (option.item.class || "") +
                    " " +
                    (_vm.selectedOption === option ? "selected" : "")
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "gp-select-remove-from-recent",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.removeFromRecent(option.item)
                        }
                      }
                    },
                    [_c("feather-icon", { attrs: { name: "x" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.submitValue(option.item)
                        }
                      }
                    },
                    [
                      option.item.username
                        ? _c(
                            "span",
                            { staticClass: "gp-select-username" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(option.item.username) +
                                  "\n          "
                              ),
                              option.item.shared
                                ? _c("feather-icon", {
                                    attrs: { name: "users" }
                                  })
                                : _c("feather-icon", {
                                    attrs: { name: "user" }
                                  })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      option.item.badge
                        ? _c("span", { staticClass: "gp-select-badge" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(option.item.badge) +
                                "\n        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.formatOption(option), function(part, j) {
                        return _c(
                          "span",
                          { key: j, class: { matched: part.matched } },
                          [_vm._v(_vm._s(part.text))]
                        )
                      })
                    ],
                    2
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm.recentOptions.length ? _c("li", [_c("hr")]) : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.visibleOptions, function(option, n) {
              return _vm.recentOptions.indexOf(option.item) === -1 &&
                (n < _vm.threshold || _vm.showMore)
                ? _c(
                    "li",
                    {
                      key: _vm.recentOptions.length + n,
                      class:
                        (option.item.class || "") +
                        " " +
                        (_vm.selectedOption === option ? "selected" : "")
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.submitValue(option.item)
                            }
                          }
                        },
                        [
                          option.item.username
                            ? _c(
                                "span",
                                { staticClass: "gp-select-username" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(option.item.username) +
                                      "\n          "
                                  ),
                                  option.item.shared
                                    ? _c("feather-icon", {
                                        attrs: { name: "users" }
                                      })
                                    : _c("feather-icon", {
                                        attrs: { name: "user" }
                                      })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          option.item.badge
                            ? _c("span", { staticClass: "gp-select-badge" }, [
                                _vm._v(_vm._s(option.item.badge))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.formatOption(option), function(part, i) {
                            return _c(
                              "span",
                              { key: i, class: { matched: part.matched } },
                              [_vm._v(_vm._s(part.text))]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.visibleOptions.length > _vm.threshold && !_vm.showMore
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          _vm.showMore = true
                        }
                      }
                    },
                    [_c("l10n", { attrs: { value: "Show more..." } })],
                    1
                  )
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }