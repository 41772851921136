var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPopupVisible
    ? _c(
        "my-popup",
        {
          attrs: { portal: "popup" },
          on: {
            clickoutside: function($event) {
              return _vm.$emit("cancel")
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "anchor",
                fn: function() {
                  return [
                    _c("span", {
                      staticClass: "gp-rule-knob-value",
                      domProps: { innerHTML: _vm._s(_vm.display) }
                    })
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            86877210
          )
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gp-rule-popup" },
            [
              _vm._t("input"),
              _vm._v(" "),
              _c("div", { staticClass: "gp-rule-popup-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-xs",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("submit")
                      }
                    }
                  },
                  [_c("l10n", { attrs: { value: "OK" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-xs",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel")
                      }
                    }
                  },
                  [_c("l10n", { attrs: { value: "Cancel" } })],
                  1
                )
              ])
            ],
            2
          )
        ]
      )
    : _c("span", {
        staticClass: "gp-rule-knob-value",
        domProps: { innerHTML: _vm._s(_vm.display) },
        on: {
          click: function($event) {
            _vm.isPopupVisible = true
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }