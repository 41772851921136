var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-popup" },
    [
      _c(
        "span",
        { ref: "anchor", staticClass: "my-popup-anchor" },
        [_vm._t("anchor")],
        2
      ),
      _vm._v(" "),
      _vm.portal
        ? _c("portal", { attrs: { to: _vm.portal } }, [
            _c(
              "div",
              {
                key: _vm.popupKey,
                ref: "popup",
                staticClass: "my-popup-popup",
                class: _vm.className
              },
              [_vm._t("default")],
              2
            )
          ])
        : _c(
            "div",
            {
              key: _vm.popupKey,
              ref: "popup",
              staticClass: "my-popup-popup",
              class: _vm.className
            },
            [_vm._t("default")],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }