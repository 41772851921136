var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-config" },
    [
      !_vm.config
        ? [_vm._v("\n        Loading...\n    ")]
        : [
            _c(
              "div",
              { staticClass: "gp-config-actions" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    attrs: { disabled: !_vm.somethingEdited },
                    on: { click: _vm.submitChanges }
                  },
                  [_c("l10n", { attrs: { value: "Save changes" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { disabled: !_vm.somethingEdited },
                    on: { click: _vm.discardChanges }
                  },
                  [_c("l10n", { attrs: { value: "Discard changes" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: {
                      disabled:
                        !_vm.config.attributes.find(function(x) {
                          return x.deleted
                        }) &&
                        !_vm.config.metrics.find(function(x) {
                          return x.deleted
                        }) &&
                        !_vm.config.formulas.find(function(x) {
                          return x.deleted
                        }) &&
                        !_vm.config.formats.find(function(x) {
                          return x.deleted
                        }) &&
                        !_vm.config.timeframes.find(function(x) {
                          return x.deleted
                        })
                    },
                    on: { click: _vm.purgeDeleted }
                  },
                  [_c("l10n", { attrs: { value: "Purge deleted" } })],
                  1
                ),
                _vm._v(" "),
                _c("my-search", {
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Settings" } })], 1),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_c("l10n", { attrs: { value: "Locale" } })], 1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.config.locale,
                    expression: "config.locale"
                  }
                ],
                staticClass: "form-control",
                domProps: { value: _vm.config.locale },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.config, "locale", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_c("l10n", { attrs: { value: "Currency" } })], 1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.config.currency,
                    expression: "config.currency"
                  }
                ],
                staticClass: "form-control",
                domProps: { value: _vm.config.currency },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.config, "currency", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Attributes" } })], 1),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table-sm table-hover table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_c("l10n", { attrs: { value: "id" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "name" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "calc" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "aggregation" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "title" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "format" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "style" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "columnClass" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "rowClass" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "editable" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "keys" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "stream" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "position" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionable" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionicon" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionlink" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "action" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "options" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "description" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.config.attributes, function(attribute, i) {
                    return _vm.isVisible(attribute)
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.id !== attribute._.id
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.id))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.id,
                                    expression: "attribute.id"
                                  }
                                ],
                                domProps: { value: attribute.id },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.name !== attribute._.name
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.name))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.name,
                                    expression: "attribute.name"
                                  }
                                ],
                                domProps: { value: attribute.name },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.calc !== attribute._.calc,
                                unknown: !_vm.isFormulaKnown(attribute.calc)
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.calc))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.calc,
                                    expression: "attribute.calc"
                                  }
                                ],
                                domProps: { value: attribute.calc },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "calc",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.aggregation !==
                                  attribute._.aggregation
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(attribute.aggregation))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.aggregation,
                                    expression: "attribute.aggregation"
                                  }
                                ],
                                domProps: { value: attribute.aggregation },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "aggregation",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.title !== attribute._.title
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.title))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.title,
                                    expression: "attribute.title"
                                  }
                                ],
                                domProps: { value: attribute.title },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "title",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.format !== attribute._.format
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.format))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.format,
                                    expression: "attribute.format"
                                  }
                                ],
                                domProps: { value: attribute.format },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "format",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.style !== attribute._.style
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.style))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.style,
                                    expression: "attribute.style"
                                  }
                                ],
                                domProps: { value: attribute.style },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "style",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.columnClass !==
                                  attribute._.columnClass
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(attribute.columnClass))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.columnClass,
                                    expression: "attribute.columnClass"
                                  }
                                ],
                                domProps: { value: attribute.columnClass },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "columnClass",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.rowClass !== attribute._.rowClass
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.rowClass))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.rowClass,
                                    expression: "attribute.rowClass"
                                  }
                                ],
                                domProps: { value: attribute.rowClass },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "rowClass",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.editable !== attribute._.editable
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.editable))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.editable,
                                    expression: "attribute.editable"
                                  }
                                ],
                                domProps: { value: attribute.editable },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "editable",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.keys !== attribute._.keys
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.keys))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.keys,
                                    expression: "attribute.keys"
                                  }
                                ],
                                domProps: { value: attribute.keys },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "keys",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.stream !== attribute._.stream
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.stream))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.stream,
                                    expression: "attribute.stream"
                                  }
                                ],
                                domProps: { value: attribute.stream },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "stream",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.position !== attribute._.position
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.position))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.position,
                                    expression: "attribute.position"
                                  }
                                ],
                                domProps: { value: attribute.position },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "position",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.actionable !==
                                  attribute._.actionable
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(attribute.actionable))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.actionable,
                                    expression: "attribute.actionable"
                                  }
                                ],
                                domProps: { value: attribute.actionable },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "actionable",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.actionicon !==
                                  attribute._.actionicon
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(attribute.actionicon))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.actionicon,
                                    expression: "attribute.actionicon"
                                  }
                                ],
                                domProps: { value: attribute.actionicon },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "actionicon",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.actionlink !==
                                  attribute._.actionlink
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(attribute.actionlink))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.actionlink,
                                    expression: "attribute.actionlink"
                                  }
                                ],
                                domProps: { value: attribute.actionlink },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "actionlink",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: attribute.action !== attribute._.action
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.action))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.action,
                                    expression: "attribute.action"
                                  }
                                ],
                                domProps: { value: attribute.action },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "action",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.options !== attribute._.options
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(attribute.options))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.options,
                                    expression: "attribute.options"
                                  }
                                ],
                                domProps: { value: attribute.options },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "options",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  attribute.description !==
                                  attribute._.description
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(attribute.description))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: attribute.description,
                                    expression: "attribute.description"
                                  }
                                ],
                                domProps: { value: attribute.description },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      attribute,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(attribute, "deleted", true)
                                  }
                                }
                              },
                              [_c("l10n", { attrs: { value: "delete" } })],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                on: {
                  click: function($event) {
                    return _vm.addRow("attributes")
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Add row" } })],
              1
            ),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Metrics" } })], 1),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table-sm table-hover table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_c("l10n", { attrs: { value: "id" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "name" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "formula" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "timeframe" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "title" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "format" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "style" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "columnClass" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "rowClass" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "editable" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "keys" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "stream" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "editValue" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "position" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionable" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionicon" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionlink" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "action" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "options" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "override" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "description" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.config.metrics, function(metric, i) {
                    return _vm.isVisible(metric)
                      ? _c("tr", [
                          _c(
                            "td",
                            { class: { changed: metric.id !== metric._.id } },
                            [
                              _c("span", [_vm._v(_vm._s(metric.id))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.id,
                                    expression: "metric.id"
                                  }
                                ],
                                domProps: { value: metric.id },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(metric, "id", $event.target.value)
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: { changed: metric.name !== metric._.name }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.name))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.name,
                                    expression: "metric.name"
                                  }
                                ],
                                domProps: { value: metric.name },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.formula !== metric._.formula,
                                unknown: !_vm.isFormulaKnown(metric.formula)
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.formula))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.formula,
                                    expression: "metric.formula"
                                  }
                                ],
                                domProps: { value: metric.formula },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "formula",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.timeframe !== metric._.timeframe
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.timeframe))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.timeframe,
                                    expression: "metric.timeframe"
                                  }
                                ],
                                domProps: { value: metric.timeframe },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "timeframe",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.title !== metric._.title
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.title))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.title,
                                    expression: "metric.title"
                                  }
                                ],
                                domProps: { value: metric.title },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "title",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.format !== metric._.format
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.format))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.format,
                                    expression: "metric.format"
                                  }
                                ],
                                domProps: { value: metric.format },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "format",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.style !== metric._.style
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.style))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.style,
                                    expression: "metric.style"
                                  }
                                ],
                                domProps: { value: metric.style },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "style",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  metric.columnClass !== metric._.columnClass
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.columnClass))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.columnClass,
                                    expression: "metric.columnClass"
                                  }
                                ],
                                domProps: { value: metric.columnClass },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "columnClass",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.rowClass !== metric._.rowClass
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.rowClass))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.rowClass,
                                    expression: "metric.rowClass"
                                  }
                                ],
                                domProps: { value: metric.rowClass },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "rowClass",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.editable !== metric._.editable
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.editable))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.editable,
                                    expression: "metric.editable"
                                  }
                                ],
                                domProps: { value: metric.editable },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "editable",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: { changed: metric.keys !== metric._.keys }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.keys))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.keys,
                                    expression: "metric.keys"
                                  }
                                ],
                                domProps: { value: metric.keys },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "keys",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.stream !== metric._.stream
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.stream))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.stream,
                                    expression: "metric.stream"
                                  }
                                ],
                                domProps: { value: metric.stream },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "stream",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.editValue !== metric._.editValue
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.editValue))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.editValue,
                                    expression: "metric.editValue"
                                  }
                                ],
                                domProps: { value: metric.editValue },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "editValue",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.position !== metric._.position
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.position))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.position,
                                    expression: "metric.position"
                                  }
                                ],
                                domProps: { value: metric.position },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "position",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  metric.actionable !== metric._.actionable
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.actionable))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.actionable,
                                    expression: "metric.actionable"
                                  }
                                ],
                                domProps: { value: metric.actionable },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "actionable",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  metric.actionicon !== metric._.actionicon
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.actionicon))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.actionicon,
                                    expression: "metric.actionicon"
                                  }
                                ],
                                domProps: { value: metric.actionicon },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "actionicon",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  metric.actionlink !== metric._.actionlink
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.actionlink))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.actionlink,
                                    expression: "metric.actionlink"
                                  }
                                ],
                                domProps: { value: metric.actionlink },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "actionlink",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.action !== metric._.action
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.action))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.action,
                                    expression: "metric.action"
                                  }
                                ],
                                domProps: { value: metric.action },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "action",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.options !== metric._.options
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.options))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.options,
                                    expression: "metric.options"
                                  }
                                ],
                                domProps: { value: metric.options },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "options",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: metric.override !== metric._.override
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.override))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.override,
                                    expression: "metric.override"
                                  }
                                ],
                                domProps: { value: metric.override },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "override",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  metric.description !== metric._.description
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(metric.description))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: metric.description,
                                    expression: "metric.description"
                                  }
                                ],
                                domProps: { value: metric.description },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      metric,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(metric, "deleted", true)
                                  }
                                }
                              },
                              [_c("l10n", { attrs: { value: "delete" } })],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                on: {
                  click: function($event) {
                    return _vm.addRow("metrics")
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Add row" } })],
              1
            ),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Calc columns" } })], 1),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table-sm table-hover table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_c("l10n", { attrs: { value: "id" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "name" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "calc" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "aggregation" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "title" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "format" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "style" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "columnClass" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "rowClass" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionable" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionicon" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "actionlink" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "action" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "options" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "override" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "description" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.config.calc_columns, function(calc_column, i) {
                    return _vm.isVisible(calc_column)
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              class: {
                                changed: calc_column.id !== calc_column._.id
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.id))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.id,
                                    expression: "calc_column.id"
                                  }
                                ],
                                domProps: { value: calc_column.id },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: calc_column.name !== calc_column._.name
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.name))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.name,
                                    expression: "calc_column.name"
                                  }
                                ],
                                domProps: { value: calc_column.name },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: calc_column.calc !== calc_column._.calc
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.calc))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.calc,
                                    expression: "calc_column.calc"
                                  }
                                ],
                                domProps: { value: calc_column.calc },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "calc",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.aggregation !==
                                  calc_column._.aggregation
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.aggregation))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.aggregation,
                                    expression: "calc_column.aggregation"
                                  }
                                ],
                                domProps: { value: calc_column.aggregation },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "aggregation",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.title !== calc_column._.title
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.title))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.title,
                                    expression: "calc_column.title"
                                  }
                                ],
                                domProps: { value: calc_column.title },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "title",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.format !== calc_column._.format
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.format))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.format,
                                    expression: "calc_column.format"
                                  }
                                ],
                                domProps: { value: calc_column.format },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "format",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.style !== calc_column._.style
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.style))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.style,
                                    expression: "calc_column.style"
                                  }
                                ],
                                domProps: { value: calc_column.style },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "style",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.columnClass !==
                                  calc_column._.columnClass
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.columnClass))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.columnClass,
                                    expression: "calc_column.columnClass"
                                  }
                                ],
                                domProps: { value: calc_column.columnClass },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "columnClass",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.rowClass !==
                                  calc_column._.rowClass
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.rowClass))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.rowClass,
                                    expression: "calc_column.rowClass"
                                  }
                                ],
                                domProps: { value: calc_column.rowClass },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "rowClass",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.actionable !==
                                  calc_column._.actionable
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.actionable))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.actionable,
                                    expression: "calc_column.actionable"
                                  }
                                ],
                                domProps: { value: calc_column.actionable },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "actionable",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.actionicon !==
                                  calc_column._.actionicon
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.actionicon))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.actionicon,
                                    expression: "calc_column.actionicon"
                                  }
                                ],
                                domProps: { value: calc_column.actionicon },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "actionicon",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.actionlink !==
                                  calc_column._.actionlink
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.actionlink))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.actionlink,
                                    expression: "calc_column.actionlink"
                                  }
                                ],
                                domProps: { value: calc_column.actionlink },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "actionlink",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.action !== calc_column._.action
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.action))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.action,
                                    expression: "calc_column.action"
                                  }
                                ],
                                domProps: { value: calc_column.action },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "action",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.options !== calc_column._.options
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(calc_column.options))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.options,
                                    expression: "calc_column.options"
                                  }
                                ],
                                domProps: { value: calc_column.options },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "options",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.override !==
                                  calc_column._.override
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.override))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.override,
                                    expression: "calc_column.override"
                                  }
                                ],
                                domProps: { value: calc_column.override },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "override",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed:
                                  calc_column.description !==
                                  calc_column._.description
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(calc_column.description))
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: calc_column.description,
                                    expression: "calc_column.description"
                                  }
                                ],
                                domProps: { value: calc_column.description },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      calc_column,
                                      "description",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(
                                      calc_column,
                                      "deleted",
                                      true
                                    )
                                  }
                                }
                              },
                              [_c("l10n", { attrs: { value: "delete" } })],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                on: {
                  click: function($event) {
                    return _vm.addRow("calc_columns")
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Add row" } })],
              1
            ),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Formulas" } })], 1),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table-sm table-hover table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_c("l10n", { attrs: { value: "id" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "calc" } })], 1),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.config.formulas, function(formula, i) {
                    return _vm.isVisible(formula)
                      ? _c("tr", [
                          _c(
                            "td",
                            { class: { changed: formula.id !== formula._.id } },
                            [
                              _c("span", [_vm._v(_vm._s(formula.id))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: formula.id,
                                    expression: "formula.id"
                                  }
                                ],
                                domProps: { value: formula.id },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(formula, "id", $event.target.value)
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: formula.calc !== formula._.calc,
                                unknown: !_vm.isFormulaKnown(formula.calc)
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(formula.calc))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: formula.calc,
                                    expression: "formula.calc"
                                  }
                                ],
                                domProps: { value: formula.calc },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      formula,
                                      "calc",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(formula, "deleted", true)
                                  }
                                }
                              },
                              [_c("l10n", { attrs: { value: "delete" } })],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                on: {
                  click: function($event) {
                    return _vm.addRow("formulas")
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Add row" } })],
              1
            ),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Timeframes" } })], 1),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table-sm table-hover table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_c("l10n", { attrs: { value: "id" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "name" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "group" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "calc" } })], 1),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.config.timeframes, function(timeframe, i) {
                    return _vm.isVisible(timeframe)
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              class: {
                                changed: timeframe.id !== timeframe._.id
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(timeframe.id))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: timeframe.id,
                                    expression: "timeframe.id"
                                  }
                                ],
                                domProps: { value: timeframe.id },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      timeframe,
                                      "id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: timeframe.name !== timeframe._.name
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(timeframe.name))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: timeframe.name,
                                    expression: "timeframe.name"
                                  }
                                ],
                                domProps: { value: timeframe.name },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      timeframe,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: timeframe.group !== timeframe._.group
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(timeframe.group))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: timeframe.group,
                                    expression: "timeframe.group"
                                  }
                                ],
                                domProps: { value: timeframe.group },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      timeframe,
                                      "group",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: {
                                changed: timeframe.calc !== timeframe._.calc
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(timeframe.calc))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: timeframe.calc,
                                    expression: "timeframe.calc"
                                  }
                                ],
                                domProps: { value: timeframe.calc },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      timeframe,
                                      "calc",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(timeframe, "deleted", true)
                                  }
                                }
                              },
                              [_c("l10n", { attrs: { value: "delete" } })],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                on: {
                  click: function($event) {
                    return _vm.addRow("timeframes")
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Add row" } })],
              1
            ),
            _vm._v(" "),
            _c("h2", [_c("l10n", { attrs: { value: "Formats" } })], 1),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table table-sm table-hover table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_c("l10n", { attrs: { value: "id" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "calc" } })], 1),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.config.formats, function(format, i) {
                    return _vm.isVisible(format)
                      ? _c("tr", [
                          _c(
                            "td",
                            { class: { changed: format.id !== format._.id } },
                            [
                              _c("span", [_vm._v(_vm._s(format.id))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: format.id,
                                    expression: "format.id"
                                  }
                                ],
                                domProps: { value: format.id },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(format, "id", $event.target.value)
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              class: { changed: format.calc !== format._.calc }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(format.calc))]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: format.calc,
                                    expression: "format.calc"
                                  }
                                ],
                                domProps: { value: format.calc },
                                on: {
                                  keydown: _vm.handleKeyDown,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      format,
                                      "calc",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$set(format, "deleted", true)
                                  }
                                }
                              },
                              [_c("l10n", { attrs: { value: "delete" } })],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                on: {
                  click: function($event) {
                    return _vm.addRow("formats")
                  }
                }
              },
              [_c("l10n", { attrs: { value: "Add row" } })],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }