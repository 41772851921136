<template>
  <div class="discard-buttons-container">
    <button
      class="btn btn-sm btn-danger"
      type="button"
      @click="onClickDiscardEdits"
    >
      <l10n value="Discard all edits" />
    </button>
    <button
      class="btn btn-sm btn-danger"
      type="button"
      :disabled="!isUserAdmin"
      @click="onClickClearStream"
    >
      <l10n value="Clear stream" />
    </button>
  </div>
</template>

<script>
const ls = require('../api/localStorage');

module.exports = {
  props: {
    stream: {
      type: String,
      required: true,
    },
    user: {
      type: String,
      required: true,
    },
  },

  data() {
    const users = ls.loadDataFromLocalStorage('users') || [];

    return {
      users,
    };
  },

  computed: {
    isUserAdmin() {
      const currentUser = this.users?.find((user) => user.id === this.user);
      return currentUser?.groups?.includes('admin');
    },
  },

  methods: {
    onClickDiscardEdits() {
      this.makeRequest('discardEdits');
    },

    onClickClearStream() {
      this.makeRequest('clearStream');
    },

    makeRequest(type) {
      const data = {
        stream: this.stream,
        user: this.user,
      };
      let url = '/api/calculation/restart-stream';
      const options = {
        method: type === 'discardEdits' ? 'POST' : 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (type === 'discardEdits') {
        options.body = JSON.stringify(data);
      } else if (type === 'clearStream') {
        // TODO add getQueryString function
        url += `?stream=${this.stream}`;
      }

      fetch(url, options)
        // TODO: make error handling for this case
        .catch((error) => console.error(error.text));
    },
  },
};
</script>

<style>
</style>
