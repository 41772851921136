<template>
    <div
        tabindex="0"
        :class="rowClass"
        :style="rowStyle"
        :data-row="i"
        class="plain-table-tile"
        >
        <a href="javascript:void(0)" @click="$emit('maximize')" style="float: right" tabindex="-1">
            <feather-icon name="maximize"/>
        </a>
        <template v-for="section, i in sections" v-if="section.visibleColumns.length">
            <template v-for="column, j in section.visibleColumns">
                <plain-table-cell-editable
                    v-if="editable && editableColumn == column.i"
                    :key="column.id || i+'-'+j"
                    :i="i"
                    :meta="meta"
                    :row="row"
                    :rows="rows"
                    :column="column"
                    :section="{visibleColumns:[]}"
                    :editing="true"
                    />
                <plain-table-cell-static
                    v-else
                    :key="column.id || i+'-'+j"
                    :i="i"
                    :meta="meta"
                    :row="row"
                    :label="column.name"
                    :column="column"
                    :section="{visibleColumns:[]}"
                    />
            </template>    
        </template>
    </div>
</template>
<script>
let utils = require('./my-utils')

module.exports = {
    props: {
        i:          { type: Number,  default: -1 },
        row:        { type: Array },
        rows:       { type: Array },
        meta:       { type: Object },
        primary:    { type: Boolean },
        selected:   { type: Boolean },
        editable:   { type: Boolean },
        subTotal:   { type: Boolean },
        sections:   { type: Array },
        editableColumn: { type: Number },
    },
    methods: {
        makeInfo(column) {
            return {
                row: this.row,
                column: this.resolveColumn(column),
                meta: this.meta,
            }
        },
        resolveColumn(i) {
            for (let section of this.sections)
                for (let column of section.visibleColumns)
                    if (column.i === i)
                        return column
            return null
        },
    },
    computed: {
        rowClass() {
            let classes = {
                primary: this.primary,
                selected: this.selected,
                editable: this.editable,
                "sub-total": this.subTotal,
            }
            for (let section of this.sections) {
                for (let column of section.columns) {
                    if (column.rowClass) {
                        let class_ = column.rowClass(
                            this.row[column.i],
                            this.row,
                            column,
                            this.meta)
                        if (class_)
                            classes[class_] = true
                    }
                }
            }
            return classes
        },
        rowStyle() {
            let style = {}
            for (let section of this.sections)
                for (let column of section.columns)
                    if (column.rowStyle)
                        _.assign(style,
                            column.rowStyle(
                                this.row[column.i],
                                this.row,
                                column,
                                this.meta))
            return style
        },
    },
}
</script>
<style>
</style>