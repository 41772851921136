var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-stored", class: { compact: _vm.compact } },
    [
      _c(
        "div",
        { staticClass: "gp-stored-select" },
        [
          _c("gp-select", {
            ref: "gpSelectConfig",
            attrs: {
              placeholder: _vm.l10n("Config name"),
              options: _vm.visibleConfigs
            },
            on: { change: _vm.onChangeSelectedConfig },
            model: {
              value: _vm.savedConfig,
              callback: function($$v) {
                _vm.savedConfig = $$v
              },
              expression: "savedConfig"
            }
          }),
          _vm._v(" "),
          !_vm.compact
            ? _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.onClickRefreshConfigsList }
                },
                [
                  _c("feather-icon", {
                    attrs: { name: _vm.loading ? "clock" : "refresh-cw" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.compact
            ? _c(
                "a",
                {
                  class: {
                    disabled:
                      !_vm.username || (!_vm.somethingEdited && _vm.savedConfig)
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.onClickSaveSettings }
                },
                [_c("feather-icon", { attrs: { name: "save" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.compact
            ? _c(
                "a",
                {
                  class: {
                    disabled:
                      !_vm.savedConfig ||
                      _vm.savedConfig.username !== _vm.username
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.onClickDeleteSettings }
                },
                [_c("feather-icon", { attrs: { name: "trash" } })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.compact
        ? [
            _vm.config
              ? _c(
                  "gp-check",
                  {
                    attrs: {
                      checked: _vm.config.shared,
                      disabled:
                        _vm.config.username &&
                        _vm.config.username != _vm.username
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.config, "shared", $event)
                      }
                    }
                  },
                  [_c("l10n", { attrs: { value: "Share this config" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.config && _vm.config.shared && _vm.shareGroups.length != 0
              ? _c("gp-pills", {
                  attrs: {
                    options: _vm.shareGroups,
                    placeholder: "Shared with"
                  },
                  model: {
                    value: _vm.config.sharedWith,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "sharedWith", $$v)
                    },
                    expression: "config.sharedWith"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                on: { change: _vm.onShowShareConfigStateChange },
                model: {
                  value: _vm.showSharedConfigs,
                  callback: function($$v) {
                    _vm.showSharedConfigs = $$v
                  },
                  expression: "showSharedConfigs"
                }
              },
              [_c("l10n", { attrs: { value: "Show shared configs" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                attrs: {
                  type: "button",
                  disabled:
                    !_vm.username || (!_vm.somethingEdited && _vm.savedConfig)
                },
                on: { click: _vm.onClickSaveSettings }
              },
              [_c("l10n", { attrs: { value: "Save settings" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                attrs: { type: "button", disabled: !_vm.somethingEdited },
                on: { click: _vm.onClickRevertSettings }
              },
              [_c("l10n", { attrs: { value: "Revert settings" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-danger",
                attrs: {
                  type: "button",
                  disabled:
                    !_vm.savedConfig ||
                    _vm.savedConfig.username !== _vm.username
                },
                on: { click: _vm.onClickDeleteSettings }
              },
              [_c("l10n", { attrs: { value: "Delete settings" } })],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }