var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-portal" },
    [
      _vm.fullscreen || _vm.indialog
        ? [_c("div", { ref: "content" }, [_vm._t("default")], 2)]
        : _c("portal", { attrs: { to: _vm.to } }, [
            _c("div", { ref: "content" }, [_vm._t("default")], 2)
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }