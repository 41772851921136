<template>
    <gp-data
        :stream="stream"
        :dims="dims"
        :vals="vals"
        :cols="cols"
        :sort="sort"
        :filter1="filter1"
        :filter2="filter2"
        v-model="report"
        />
</template>
<script>
module.exports = {
    props: {
        stream: { type: String, default: "default" },
        dims: { type: Array, default: () => ['id'] },
        vals: { type: Array, default: () => ['name'] },
        cols: { type: Array },
        sort: { type: Array, default: () => [2] },
        filter1: { type: String },
        filter2: { type: String },
    },
    data() {
        return {
            report: null,
        }
    },
    computed: {
        value() {
            return this.report ?
                Object.freeze(this.report.rows.map(row =>
                    _(this.report.columns)
                        .map("synonym")
                        .zip(row)
                        .map(([key, val]) => [key, Object.freeze(val)])
                        .fromPairs()
                        .value())) : []
        },
    },
    watch: {
        value() {
            this.$emit("change", this.value)
        }
    }
}
</script>