<template>
    <div class="gp-table-dates">
        <div>
            <feather-icon name="target"/>
            <label><l10n value="Optimization run:"/></label>
            <div class="gp-table-timeframe">
                <span>{{getOptimizationName(optimizationId) || '...'}}</span>
                <select
                    :value="optimizationId"
                    @change="$emit('change', $event.target.value)"
                    class="form-control form-control-sm"
                    :key="optimizationsControlKey">
                    <option
                        :value="optimizationId"
                        v-if="!getOptimizationName(optimizationId)"
                        >...</option>
                    <option v-else value="">...</option>
                    <option v-for="{id, name} in optimizations" :value="id">{{name}}</option>
                </select>
            </div>
            <a href="javascript:void(0)" @click="showOptimizationPicker = true">
                <feather-icon
                    :name="showOptimizationPicker ?
                        'book-open' : 'book'"/>
            </a>
        </div>
        <gp-data
            id="optimizations"
            ref="optimizations"
            :stream="stream"
            :groups="['optimizations', 'reference-date', 'search']"
            :source="{
                dims: ['item', 'target_price_zone as price_zone'],
                links: [{
                    linkName: 'optimization',
                    sourceName: 'optimization',
                    columnPairs: [{
                        srcColumn: 'item',
                        dstColumn: 'item'
                    }, {
                        srcColumn: 'price_zone',
                        dstColumn: 'price_zone'
                    }]
                }],
            }"
            :dims="['optimization.optimization_run_id']"
            :vals="[
                'optimization.strategy_name',
                'optimization.create_user',
                'optimization.create_time'
            ]"
            :vars="vars"
            :sort="[-4]"
            expand="optimization"
            v-model="optimizationsReport"
            />
        <my-dialog
            :xlarge="true"
            v-if="showOptimizationPicker && bounds"
            title="Select optimization run"
            @close="showOptimizationPicker = false">
            <gp-runs
                @click="
                    $emit('change', $event)
                    showOptimizationPicker = false"
                :locale="locale"
                :bounds="bounds"
                :username="username"
                :referenceDate="referenceDate"
                :pastTimeframe="pastTimeframe"
                :futureTimeframe="futureTimeframe"
                :stream="stream"
                :groups="['optimizations', 'search']"
                :source="{
                    filter0: `date == \`${referenceDate}\``,
                    dims: ['item', 'target_price_zone as price_zone'],
                    links: [{
                        linkName: 'optimization',
                        sourceName: 'optimization',
                        columnPairs: [{
                            srcColumn: 'item',
                            dstColumn: 'item'
                        }, {
                            srcColumn: 'price_zone',
                            dstColumn: 'price_zone'
                        }]
                    }],
                }"
                :filter2="`true || optimization.create_user == '${username}' || !(optimization.create_user in ['ag', 'oleg'])`"
                expand="optimization"
                :dims="optimizationDims"
                :vals="optimizationVals"
                :vars="vars"
                :sort="optimizationSort"
                :take="optimizationTake"
                :sections="optimizationSections"
                :metrics="metrics"
                :formats="formats"
                :formulas="formulas"
                :timeframes="timeframes"
                />
        </my-dialog>
    </div>
</template>
<script>
let utils = require("../my-utils")
module.exports = {
    mixins: [
        utils.extraFilters,
    ],
    model: {
        prop: "optimizationId",
        event: "change",
    },
    props: {
        optimizationId: { type: String },
        stream: { type: String },
        locale: { type: String },
        username: { type: String },
        metrics: { type: Array },
        attributes: { type: Array },
        formats: { type: Object },
        formulas: { type: Object },
        timeframes: { type: Object },
        referenceDate: { type: String },
        vars: { type: Object },
        optimizationDims: { type: Array },
        optimizationVals: { type: Array },
        optimizationSort: { type: Number },
        optimizationTake: { type: Number },
        optimizationSections: { type: Array, default: () => [] },
        pastTimeframe: { type: String },
        futureTimeframe: { type: String },
    },
    data() {
        return {
            optimizationsControlKey: 0,
            optimizationsReport: null,
            showOptimizationPicker: false,
        }
    },
    mounted() {
        utils.bridge.bind("optimizationComplete", this.handleOptimizationComplete)
    },
    beforeDestroy() {
        utils.bridge.unbind("optimizationComplete", this.handleOptimizationComplete)
    },
    watch: {
        optimizations() {
            // if (!this.optimizationId && this.optimizations.length)
                // this.$emit("change", this.optimizations[0].id)
            this.optimizationsControlKey += 1
        },
    },
    computed: {
        bounds() {
            let getBounds = node =>
                node.bounds || node.$parent && getBounds(node.$parent) || ""
            return getBounds(this.$parent)
        },
        optimizations() {
            return this.optimizationsReport ?
                _(this.optimizationsReport.rows)
                    .orderBy(([id, strategy, user, time]) => -time)
                    // .filter(([id, strategy, user, time]) =>
                    //     user === this.username ||
                    //     (user !== "ag" && user !== "oleg") ||
                    //     this.username == "ag" ||
                    //     this.username == "oleg" ||
                    //     this.username == "maxim")
                    .filter(([id, strategy]) => !strategy.startsWith("[tmp]") || this.optimizationId == id)
                    .take(20)
                    .map(([id, strategy, user, time]) => 
                        ({
                            id,
                            name: utils.l10n("{strategy} by {user} at {time}")
                                .replace("{strategy}", strategy)
                                .replace("{user}", user)
                                .replace("{time}", new Date(time).toLocaleString())
                        }))
                    .value() : []
        },
    },
    methods: {
        getOptimizationName(optimizationId) {
            return this.optimizations.find(optimization =>
                optimization.id === optimizationId)?.name
        },
        handleOptimizationComplete(optimizationId) {
            this.$emit("change", optimizationId)
            this.$refs.optimizations.requestData({forced: true})
        },
    }
}
</script>