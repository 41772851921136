<template>
  <gp-portal :to="portal">
    <div class="modal fade show" style="display:block">
      <div
        ref="dialog"
        :class="dialogClass">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <l10n :value="title" />
              <slot name="title" />
            </h5>
            <a
              href="javascript:void(0)"
              @click="onClose"
              aria-label="Close"
            >
              <feather-icon name="close" />
            </a>
            <slot name="header" />
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div class="modal-footer" v-if="hasFooter">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </gp-portal>
</template>

<script>
module.exports = {
  props: {
    portal: { type: String, default: 'modal' },
    title: { type: String },
    className: { type: String },
    large: { type: Boolean, default: false },
    xlarge: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
  },

  methods: {
    onClose() {
      this.$emit('close');

      if (window[this.$attrs.id]) {
        window[this.$attrs.id].show = false;
      }
    },

    interceptKeyDownEvent(e) {
      if (e.key === 'Escape') {
        this.onClose();
      }
    },
  },

  computed: {
    hasFooter() {
      return this.$slots.footer != null;
    },

    dialogClass() {
      const dialogClass = {
        'modal-dialog': true,
        'modal-lg': this.large,
        'modal-xl': this.xlarge,
        'modal-sm': this.small,
        'modal-dialog-scrollable': this.scrollable,
      };

      if (this.className) {
        dialogClass[this.className] = true;
      }

      return dialogClass;
    },
  },

  mounted() {
    document.addEventListener('keydown', this.interceptKeyDownEvent);

    Vue.nextTick(() => {
      $(this.$refs.dialog).draggable({ handle: '.modal-header' });
    });
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.interceptKeyDownEvent);
    try {
      $(this.$refs.dialog).draggable('destroy');
    } catch (ex) {
      console.warn('destroy on draggable failed in my-dialod', ex);
    }
  },
};
</script>

<style>
.modal-header,
.modal-footer {
    position: sticky;
    background-color: white;
    z-index: 1;
}
.modal-header {
    top: 0;
}
.modal-footer {
    bottom: 0;
}
.my-dark-theme .modal-header,
.my-dark-theme .modal-footer {
    background-color: #303030;
}
.modal-header {
    padding: 8px 16px!important;
}
.modal.fade.show:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
}
.modal-header a .feather-icon {
    display: block;
    margin: 0;
}
.modal-dialog.modal-xl {
    width: auto!important;
    max-width: calc(100vw - 40px)!important;
}
</style>
