var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "gp-support": true,
        "gp-support-ready": _vm.ready,
        "gp-support-expanded": _vm.expanded,
        "gp-support-online": _vm.online,
        "gp-support-shown": _vm.shown
      }
    },
    [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: { click: _vm.handleClick }
        },
        [
          _c("feather-icon", { attrs: { name: "message-square" } }),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Chat with support" } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }