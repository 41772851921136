var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "inline-select" }, [
    _c("span", [_c("l10n", { attrs: { value: _vm.text } })], 1),
    _vm._v(" "),
    _c(
      "select",
      { on: { input: _vm.handleInput } },
      _vm._l(_vm.options, function(text, name) {
        return _c(
          "option",
          { domProps: { value: name, selected: _vm.value === name } },
          [_c("l10n", { attrs: { value: text } })],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }