var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "my-inline-help",
      attrs: { title: _vm.translatedText },
      on: { click: _vm.promptTranslation }
    },
    [_c("feather-icon", { attrs: { name: "help-circle" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }