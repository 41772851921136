var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c("v-calendar", {
        ref: "calendar",
        staticClass: "calendar",
        attrs: {
          "show-weeknumbers": "",
          attributes: _vm.attributes,
          value: _vm.selectedDates
        },
        on: {
          weeknumberclick: _vm.selectDates,
          dayclick: _vm.onDayClick,
          "update:from-page": _vm.updateWeekdayClick
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-danger btn-xs w-100 mt-2",
          attrs: { type: "button" },
          on: { click: _vm.onResetClick }
        },
        [_c("l10n", { attrs: { value: "Reset dates" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }