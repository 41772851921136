var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "pim-tabs pim-tabs-" + _vm.mode },
    [
      _c(
        "ul",
        _vm._l(_vm.tabs, function(name, id) {
          return _c(
            "li",
            {
              class: { active: _vm.tab == id },
              on: {
                click: function($event) {
                  _vm.tab != id
                    ? _vm.$emit("change", id)
                    : _vm.allowDeselect
                    ? _vm.$emit("change", null)
                    : null
                  $event.stopPropagation()
                }
              }
            },
            [
              _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _c("span", [_vm._v(_vm._s(name))])
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }