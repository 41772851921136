<template>
  <div class="gp-section-columns">
    <my-search
      v-model="searchString"
      :autofocus="autofocus"
      ref="search"
    />
    <ul class="gp-section-columns__list">
      <li v-for="(entry, idx) in currentColumns" :key="idx">
        <gp-check
          :checked="true"
          :key="entry.item.id"
          @change="removeColumn(entry.item)"
        >
          <span
            v-for="(part, idx) in formatSearchItem(entry.item.name, entry.matches)"
            :key="idx"
            :class="{ matched: part.matched }"
          >
            {{part.text}}
          </span>
        </gp-check>
      </li>
      <li><hr /></li>
      <li v-for="(entry, idx) in visibleColumnCandidates" :key="idx">
        <gp-check
          :checked="false"
          :key="entry.item.name"
          @change="addColumn(entry.item)"
        >
          <span
            v-for="part in formatSearchItem(entry.item.name, entry.matches)"
            :class="{matched: part.matched}"
          >{{part.text}}</span>
        </gp-check>
      </li>
      <li v-if="visibleColumnCandidates.length < columnCandidates.length">
        <a href="javascript:void(0)" @click="showMore = true">
          <l10n value="Show more..."/>
        </a>
      </li>
    </ul>
    <div class="gp-section-columns-actions">
      <button
        class="button button_primary"
        :disabled="!somethingChanged"
        @click="$emit('submit', changes)">
        <l10n v-if="persitent" value="Submit changes"/>
        <l10n v-else value="OK"/>
      </button>
      <button
        class="button button_secondary"
        @click="$emit('cancel')">
        <l10n v-if="persitent" value="Discard changes"/>
        <l10n v-else value="Cancel"/>
      </button>
    </div>
  </div>
</template>
<script>
let utils = require('../my-utils')

module.exports = {
  props: {
    columns: { type: Array, default: () => [] },
    attributes: { type: Array, default: () => [] },
    metrics: { type: Array, default: () => [] },
    autofocus: { type: Boolean, default: true },
    persitent: { type: Boolean, default: false },
    showMore: { type: Boolean, default: false },
    threshold: { type: Number, default: 100 },
    grouped: { type: Boolean, default: false },
  },
  data() {
    return {
      l10n: utils.l10n,
      formatSearchItem: utils.formatSearchItem,
      searchString: '',
      changes: {},
    };
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.search.$el.focus();
    }
  },
  watch: {
    searchString() {
      this.showMore = false;
    },
  },
  methods: {
    editableColumns() {
      if (!this.changes.columns) {
        this.$set(this.changes, 'columns', _.cloneDeep(this.columns));
      }

      return this.changes.columns;
    },

    addColumn(column) {
      column = _.clone(column);
      if (column.attribute) {
        _.assign(column, column.attribute);
        column.type = 'attribute';
      }
      if (column.metric) {
        _.assign(column, column.metric);
        column.type = 'metric';
      }
      column.id = utils.randomId();
      this.editableColumns().push(column);
    },

    removeColumn(column) {
      let columns = this.editableColumns();
      let i = _.findIndex(columns, ({ id }) => id === column.id);

      if (i !== -1) {
        columns.splice(i, 1);
      }
    },

    applySearch(columns) {
      return this.searchString
        ? new Fuse(columns, {
          isCaseSensitive: false,
          shouldSort: true,
          includeMatches: true,
          keys: ['name'],
        }).search(this.searchString) : columns.map((column) => ({ item: column }));
    },
  },
  computed: {
    visibleGroups() {
      return _.groupBy(this.visibleColumnCandidates, 'group');
    },

    somethingChanged() {
      return !_.isEmpty(this.changes);
    },

    currentColumns() {
      return this.applySearch(this.changes.columns || this.columns);
    },

    columnCandidates() {
      const currentNames = new Set(_.map(this.currentColumns, 'item.name'));
      const columns = [];

      for (let attribute of this.attributes) {
        if (!attribute.deleted && !currentNames.has(attribute.name)) {
          columns.push({ name: attribute.name, attribute });
        }
      }

      for (let metric of this.metrics) {
        if (!metric.deleted && !currentNames.has(metric.name)) {
          columns.push({name: metric.name, metric});
        }
      }

      return this.applySearch(_.sortBy(columns, 'name'))
    },

    visibleColumnCandidates() {
      let columns = this.columnCandidates;
      return this.showMore ? columns : columns.slice(0, this.threshold);
    },
  },
};
</script>

<style>
.plain-table-manage-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.gp-section-columns .form-control {
    height: 46px;
    padding: 0 8px 0 42px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    border: none;
    border-bottom: 1px solid #E9E9E9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #f7f7f7 url('../icons/search-icon.svg' ) no-repeat 12px;
}
.gp-section-columns .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.gp-section-columns ul.gp-section-columns__list {
    overflow-y: auto;
    max-height: 300px;
    padding: 8px 12px;
}
ul.gp-section-columns__list::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff;
}
ul.gp-section-columns__list::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 50%;
    background-color: #E9E9E9;
}
.gp-section-columns__list hr {
    margin: 0;
}
.gp-section-columns__list li {
    padding: 8px;
}
.gp-section-columns-actions {
    display: flex;
    justify-content: space-around;
    padding: 8px 16px 16px;
}
.gp-section-columns-actions .button {
    width: 130px;
    height: 40px;
}
.gp-section-columns .matched {
    color: var(--red);
}
.gp-section-columns ul {
  list-style: none;
}
</style>
