var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-workflow" },
    [
      _vm._l(_vm.errors, function(error) {
        return [
          _c("p", { staticClass: "alert alert-warning" }, [
            _vm._v(_vm._s(error))
          ])
        ]
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.editable,
              expression: "!editable"
            }
          ],
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function($event) {
              _vm.editable = true
            }
          }
        },
        [_vm._v("edit workflow")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pim-workflow-body" }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editable,
              expression: "editable"
            }
          ],
          ref: "palette",
          staticClass: "pim-workflow-palette"
        }),
        _vm._v(" "),
        _c("div", {
          ref: "diagram",
          staticClass: "pim-workflow-diagram",
          style: _vm.style
        })
      ]),
      _vm._v(" "),
      _vm.editable
        ? _c("div", { staticClass: "pim-workflow-actions" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                on: { click: _vm.submit }
              },
              [_vm._v("Submit changes")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                on: { click: _vm.discard }
              },
              [_vm._v("Discard changes")]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }