var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-date-filter" },
    [
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-inline", staticStyle: { clear: "left" } },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("gp-date", {
                attrs: { max: _vm.date2 },
                model: {
                  value: _vm.date1,
                  callback: function($$v) {
                    _vm.date1 = $$v
                  },
                  expression: "date1"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("feather-icon", { attrs: { name: "minus" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("gp-date", {
                attrs: { min: _vm.date1 },
                model: {
                  value: _vm.date2,
                  callback: function($$v) {
                    _vm.date2 = $$v
                  },
                  expression: "date2"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }