var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-rules__actions" }, [
    _c(
      "button",
      {
        staticClass: "gp-rules__actions-button",
        attrs: { type: "button" },
        on: { click: _vm.addSection }
      },
      [
        _c("feather-icon", { attrs: { name: "plus" } }),
        _vm._v(" "),
        _c("l10n", { attrs: { value: "Add price rules section" } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "gp-rules__actions-button",
        attrs: { type: "button" },
        on: { click: _vm.exportToExcel }
      },
      [
        _c("feather-icon", { attrs: { name: "download" } }),
        _vm._v(" "),
        _c("l10n", { attrs: { value: "Export rules to Excel" } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "gp-rules__actions-button",
        attrs: { type: "button" },
        on: { click: _vm.importFromExcel }
      },
      [
        _c("feather-icon", { attrs: { name: "upload" } }),
        _vm._v(" "),
        _c("l10n", { attrs: { value: "Import rules from Excel" } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }