module.exports = require("./props").chartProps({
    brush:                  { type: [Function, String], default: 'd3.brush()' },

    maxBubbleRelativeSize:  { type: Number, default: 0.3 },
    radius:                 { type: Number, default: 10 },
    minRadiusWithLabel:     { type: Number, default: 10 },
    // r:                      { type: Function, default: d3.scaleLinear().domain([0, 100]) },
    radiusValueAccessor:    { type: [Function, String], default: (d) => d.value[1] },
})

