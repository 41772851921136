var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "my-composite-chart",
      style: { opacity: _vm.pending ? 0.5 : 1 }
    },
    [
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("my-tooltip", {
        ref: "tooltip",
        attrs: {
          meta: _vm.meta,
          chart: _vm.chart,
          keepOnly: _vm.keepOnly,
          exclude: _vm.exclude,
          actions: _vm.actions
        }
      }),
      _vm._v(" "),
      _c("div", { ref: "base", staticClass: "my-chart", style: _vm.style }, [
        _c("div", { ref: "chart" })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }