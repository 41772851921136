<template>
  <div class="gp-rules__actions">
    <button
      class="gp-rules__actions-button"
      type="button"
      @click="addSection"
    >
      <feather-icon name="plus" />
      <l10n value="Add price rules section" />
    </button>

    <button
      class="gp-rules__actions-button"
      type="button"
      @click="exportToExcel"
    >
      <feather-icon name="download" />
      <l10n value="Export rules to Excel" />
    </button>

    <button
      class="gp-rules__actions-button"
      type="button"
      @click="importFromExcel"
    >
      <feather-icon name="upload" />
      <l10n value="Import rules from Excel" />
    </button>
  </div>
</template>

<script>
const utils = require('../../my-utils');

module.exports = {
  props: {
    defaultTypes: {
      type: Array,
      default: () => ['rbp'],
    },
    editingSections: {
      type: Array,
      default: () => ([]),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    addSection() {
      const name = window.prompt(utils.l10n('Please enter rules section name'));

      if (name) {
        const newSection = {
          id: utils.randomId(),
          name,
          rules: [],
          types: _.clone(this.defaultTypes),
        };

        this.$emit('add-section', newSection);
      }
    },

    async exportToExcel() {
      const formatFilter = (filter) => _(filter)
        .filter((part) => !_.isEmpty(part))
        .map((part) => _(part)
          .toPairs()
          .map(([calc, vals]) => `${calc}: ${utils.quote(vals.length === 1 ? vals[0] : vals)}`)
          .join(', '))
        .join('; ');
      const formatParameters = (rule) => ([...rule.text
        .matchAll(/{([^}\.\[]+)[^}]*?}/g)]
        .map((match) => match[1])
        .map((param) => `${param}: ${utils.quote(rule[param])}`)
        .join(', ')
      );
      const XLSX = await import('xlsx');
      const workbook = XLSX.utils.book_new();

      this.editingSections.forEach((section) => {
        const rows = [
          ['Section weight:', section.weight || 1],
          ['Section types:', section.types?.join(', ')],
          [],
          ['Rules'],
          ['Name', 'Parameters', 'Include', 'Exclude', 'Strict', 'Weight', 'Text'],
        ];

        section.rules.forEach((rule) => {
          rows.push([
            rule.type,
            rule.name,
            formatParameters(rule),
            formatFilter(rule.filter),
            formatFilter(rule.filter_not),
            rule.strict || false,
            rule.weight || 1,
            rule.text,
          ]);
        });

        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet, section.name);
      });

      XLSX.writeFile(workbook, `${this.config?.name || 'strategy'}.xlsx`);
    },

    importFromExcel() {},
  },
};
</script>

<style scoped>
.gp-rules__actions {
  margin-top: 10px;
  padding: 0 32px;
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  align-items: start;
}
.gp-rules__actions-button {
  outline: none;
  border: 0;
  background: transparent;
  color: var(--cyan);
}

.gp-rules__actions-button:hover {
  text-decoration: underline;
}
</style>
