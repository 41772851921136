var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-tabs" },
    [
      _c(
        "ul",
        { ref: "tabs" },
        [
          _vm._l(_vm.tabs, function(tab) {
            return _c(
              "li",
              {
                key: tab.id,
                class: { active: tab.id == _vm.activeTabId },
                attrs: { "data-tab": tab.id }
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.activeTabId == tab.id
                          ? (_vm.showTabMenu = tab)
                          : (_vm.activeTabId = tab.id)
                      }
                    }
                  },
                  [
                    _c("l10n", { attrs: { value: tab.name } }),
                    _vm._v(" "),
                    tab.id == _vm.activeTabId
                      ? _c("feather-icon", {
                          ref: "activeTab",
                          refInFor: true,
                          attrs: { name: "chevron-down" }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.addTab()
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "plus" } })],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm.showTabMenu
        ? _c(
            "my-popup",
            {
              attrs: {
                set: (_vm.tab = _vm.showTabMenu),
                placement: "bottom-end",
                anchor:
                  ".gp-tabs > ul > li.active[data-tab=" + _vm.activeTabId + "]"
              },
              on: {
                escape: function($event) {
                  _vm.showTabMenu = null
                },
                clickoutside: function($event) {
                  _vm.showTabMenu = null
                }
              }
            },
            [
              _c("div", { staticClass: "popover show gp-tabs-menu" }, [
                _c("div", { staticClass: "popover-body" }, [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.refreshTab(_vm.showTabMenu)
                              _vm.showTabMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "refresh-cw" } }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Refresh" } })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.tab.path.startsWith("http")
                              ? _vm.tab.path
                              : "/pages/" + _vm.tab.path,
                            target: "_blank"
                          },
                          on: {
                            click: function($event) {
                              _vm.showTabMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { name: "external-link" }
                          }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Open" } })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.removeTab(_vm.tab)
                              _vm.showTabMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "x" } }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Remove" } })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.showSettings = !_vm.showSettings
                              _vm.showTabMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "settings" } }),
                          _vm._v(" "),
                          _c("l10n", { attrs: { value: "Settings" } })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: _vm.portal } },
        [
          _vm._l(_vm.tabs, function(tab) {
            return [
              tab.id == _vm.activeTabId ||
              tab.keep ||
              (tab.lazy && _vm.visitedTabs[tab.id])
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: tab.id == _vm.activeTabId,
                          expression: "tab.id == activeTabId"
                        }
                      ],
                      key: tab.id,
                      staticClass: "gp-tab"
                    },
                    [
                      _vm.showSettings
                        ? _c(
                            "form",
                            {
                              staticClass: "gp-tab-settings",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  _vm.showSettings = false
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  [_c("l10n", { attrs: { value: "Name" } })],
                                  1
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  domProps: { value: tab.name },
                                  on: {
                                    change: function($event) {
                                      return _vm.modifyTab(tab, {
                                        name: $event.target.value
                                      })
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  [_c("l10n", { attrs: { value: "Path" } })],
                                  1
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "form-control",
                                  domProps: { value: tab.path },
                                  on: {
                                    change: function($event) {
                                      return _vm.modifyTab(tab, {
                                        path: $event.target.value
                                      })
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "gp-check",
                                    {
                                      attrs: { checked: tab.lazy },
                                      on: {
                                        change: function($event) {
                                          return _vm.modifyTab(tab, {
                                            lazy: $event
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", {
                                        attrs: {
                                          value: "Keep content once loaded"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        _vm.showSettings = false
                                      }
                                    }
                                  },
                                  [_c("l10n", { attrs: { value: "Done" } })],
                                  1
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      tab.type == "page"
                        ? _c("gp-page", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showSettings,
                                expression: "!showSettings"
                              }
                            ],
                            key: _vm.refreshKeys[tab.id],
                            attrs: {
                              path: tab.path,
                              darkTheme: _vm.darkTheme,
                              config: _vm.config,
                              username: _vm.username,
                              bounds: _vm.storageKey
                                ? _vm.storageKey + "." + tab.id
                                : tab.id,
                              storageKey: _vm.storageKey
                                ? _vm.storageKey.split(".").length < 3
                                  ? _vm.storageKey + "." + tab.id
                                  : _vm.storageKey
                                : null
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }