var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c(
        "div",
        {
          ref: "multiselect",
          staticClass: "pim-tree-multiselect",
          class: { focused: _vm.entry.focused },
          style: { "margin-left": _vm.level * 20 + 26 + "px" },
          on: { change: _vm.handleChange }
        },
        [
          _c(
            "gp-check",
            {
              attrs: { checked: _vm.checked, indeterminate: _vm.indeterminate }
            },
            [
              _vm.selected == 0
                ? [
                    _vm._v(
                      "\n                    select all " +
                        _vm._s(_vm.pluralize(_vm.entry.entry.childrenType)) +
                        "\n                "
                    )
                  ]
                : _vm.selected == 1
                ? [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.l10n(_vm.selected)) +
                        " " +
                        _vm._s(_vm.entry.entry.childrenType) +
                        " selected\n                "
                    )
                  ]
                : [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.l10n(_vm.selected)) +
                        " " +
                        _vm._s(_vm.pluralize(_vm.entry.entry.childrenType)) +
                        " selected\n                "
                    )
                  ]
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }