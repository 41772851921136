var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "editor",
      style: {
        width: _vm.width,
        height: _vm.height,
        minHeight: _vm.computedHeight
      }
    },
    [
      !_vm.editor
        ? _c(
            "small",
            { staticClass: "text-muted", staticStyle: { padding: "20px" } },
            [_vm._v("Initializing...")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }