var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.controlValue,
        expression: "controlValue"
      }
    ],
    staticClass: "form-control",
    attrs: { type: "search", placeholder: _vm.l10n(_vm.placeholder) },
    domProps: { value: _vm.controlValue },
    on: {
      input: function($event) {
        if ($event.target.composing) {
          return
        }
        _vm.controlValue = $event.target.value
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }