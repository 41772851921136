var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-progress" },
    [
      _vm.showAssembly
        ? _c("my-assembly", {
            attrs: { assembly: _vm.assemblyWithQuery },
            on: {
              close: function($event) {
                _vm.showAssembly = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode && _vm.processTime !== null
        ? _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n        " +
                _vm._s(new Number(_vm.processRows).toLocaleString()) +
                " records\n        processed in " +
                _vm._s(new Number(_vm.processTime).toLocaleString()) +
                " ms\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.progress100 > 0 && _vm.progress100 < 100
        ? [
            _c(
              "span",
              {
                staticClass: "vue-progress-path",
                staticStyle: { width: "22px", height: "22px" }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: { width: "22", height: "22", viewBox: "0 0 22 22" }
                  },
                  [
                    _c("g", { staticClass: "container" }, [
                      _c("path", {
                        staticClass: "progress",
                        attrs: {
                          d: _vm.path,
                          "stroke-dasharray": _vm.dasharray,
                          "stroke-dashoffset": _vm.dashoffset
                        }
                      })
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("small", { staticClass: "my-progress-text text-muted" }, [
              _vm._v("\n            " + _vm._s(_vm.progress100) + "\n        ")
            ])
          ]
        : _vm.sorting
        ? [
            _c(
              "span",
              {
                staticClass: "vue-progress-path",
                staticStyle: { width: "22px", height: "22px" }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: { width: "22", height: "22", viewBox: "0 0 22 22" }
                  },
                  [
                    _c("g", { staticClass: "container" }, [
                      _c("path", {
                        staticClass: "progress",
                        attrs: {
                          d: _vm.path,
                          "stroke-dasharray": _vm.dasharray,
                          "stroke-dashoffset": _vm.dashoffset
                        }
                      })
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "small",
              { staticClass: "my-progress-text" },
              [_c("feather-icon", { attrs: { name: "shuffle" } })],
              1
            )
          ]
        : _vm.downloading
        ? _c(
            "span",
            { staticClass: "my-progress-text" },
            [_c("feather-icon", { attrs: { name: "download-cloud" } })],
            1
          )
        : _vm.working
        ? _c(
            "span",
            { staticClass: "my-progress-text" },
            [_c("feather-icon", { attrs: { name: "clock" } })],
            1
          )
        : _vm.editMode && _vm.progress100 === 100 && _vm.assembly
        ? _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.showAssembly = true
                }
              }
            },
            [_c("feather-icon", { attrs: { name: "code" } })],
            1
          )
        : _vm.refreshData && _vm.progress100 === 100
        ? _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.refreshData()
                }
              }
            },
            [_c("feather-icon", { attrs: { name: "refresh-cw" } })],
            1
          )
        : _vm.exportToFile && _vm.progress100 === 100
        ? _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.exportToFile()
                }
              }
            },
            [_c("feather-icon", { attrs: { name: "download" } })],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }