var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-table-search" }, [
    _c("div", [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function($event) {
              _vm.expanded = !_vm.expanded
            }
          }
        },
        [_c("feather-icon", { attrs: { name: "search" } })],
        1
      ),
      _vm._v(" "),
      _vm.expanded
        ? _c("input", {
            ref: "search",
            staticClass: "form-control form-control-sm",
            attrs: { type: "search", placeholder: _vm.l10n(_vm.placeholder) },
            on: {
              search: function($event) {
                _vm.value = $event.target.value
              }
            }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }