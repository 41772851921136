<template>
<div class="gp-runs">
	<!--table class="table table-sm">
        <thead>
            <tr>
                <th colspan="3"></th>
                <th v-for="section in sections" :colspan="section.columns.length">
                    <l10n :value="section.name"/>
                </th>
            <tr>
                <th><l10n value="Run name"/></th>
                <th><l10n value="Started by"/></th>
                <th><l10n value="Started on"/></th>
                <template v-for="section in sections">
                    <th v-for="column in section.columns">
                        <l10n :value="columnName(column)"/>
                    </th>
                </template>
            </tr>
        </thead>
		<tbody>
			<tr v-for="row in optimizations" :key="row[0]">
                <td>
                    <a
                        href="javascript:void(0)"
                        @click="$emit('click', row[0])">
                        {{row[1]}}
                    </a>
                </td>
                <td>{{row[2]}}</td>
                <td>{{formatTime(row[3])}}</td>
                <td v-for="col, i in metrics[row[0]]">
                    {{columns[i].format ? columns[i].format(col) : col}}
                </td>
			</tr>
		</tbody>
	</table-->
    <gp-filter
        v-if="!compact"
        v-model="filter"
        stream="combined"
        :groups="['optimizations', 'search', 'reference-date']"
        :source="{
            dims: ['item', 'target_price_zone as price_zone'],
            links: [{
                linkName: 'optimization',
                sourceName: 'optimization',
                columnPairs: [{
                    srcColumn: 'item',
                    dstColumn: 'item'
                }, {
                    srcColumn: 'price_zone',
                    dstColumn: 'price_zone'
                }]
            }],
        }"
        :vars="vars"
        :filter2="usersFilter"
        expand="optimization"
        :attributes="[{
            name: l10n('Run name'),
            calc: 'optimization.strategy_name',
        }, {
            name: l10n('Started by'),
            calc: 'optimization.create_user'
        }]"
        :popupPortal="null"
        />
    <plain-table
        id="optimizations"
        ref="table"
        stream="combined"
        :freezeDims="true"
        :groups="['optimizations', 'search', 'reference-date']"
        :source="{
            filter0: datesFilter,
            filter1: datesFilter,
            dims: ['item', 'target_price_zone as price_zone'],
            links: [{
                linkName: 'optimization',
                sourceName: 'optimization',
                columnPairs: [{
                    srcColumn: 'item',
                    dstColumn: 'item'
                }, {
                    srcColumn: 'price_zone',
                    dstColumn: 'price_zone'
                }]
            }, {
                linkName: 'optimization_summary',
                sourceName: 'optimization_summary',
                columnPairs: [{
                    srcColumn: 'optimization.optimization_run_id',
                    dstColumn: 'optimization_run_id'
                }]
            }, {
                linkName: 'optimization_goals',
                sourceName: 'optimization_goals',
                columnPairs: [{
                    srcColumn: 'optimization.optimization_run_id',
                    dstColumn: 'optimization_run_id'
                }]
            }],
        }"
        :filter2="filter2"
        :filter3="filter3"
        :dims="dims"
        :vals="vals"
        :cols="cols"
        :vars="vars"
        :initialSort="sort"
        :initialTake="take"
        :clientSort="false"
        :prefetchSize="1"
        expand="optimization"
        @link-clicked="linkClicked"
        @report-updated="report = $event"
        :tableActions="tableActions"
        :isOptimizationsTable="true"
        />
</div>
</template>
<script>
let utils = require("../my-utils")

let formatTime = (x) => {
    return new Date(x).toLocaleString()
}

let formatNumber = (x) => {
    return x ? new Number(x).toLocaleString(this.locale) : "-"
}

let formatPercent = (x) => {
    return x ? new Number(x).toLocaleString(this.locale, {
        style: "percent",
        maximumFractionDigits: 1
    }) : "-"
}

module.exports = {
    mixins: [
        utils.extraFilters,
        utils.configHelpers,
    ],
	props: {
        bounds: { type :String },
        compact: { type: Boolean, default: false },
        locale: { type: String },
        username: { type: String },
        sections: { type: Array },
        filter3: { type: String },
        referenceDate: { type: String },
        pastTimeframe: { type: String },
        futureTimeframe: { type: String },
        dims: {
            type: Array,
            default: () => [{
                name: " ",
                calc: "optimization.optimization_run_id",
                actionable: true,
                actionlink: "javascript:void(0)",
                format: () => utils.l10n("choose"),
                section: "Dimensions",
            }, {
                name: "Run name",
                calc: "optimization.strategy_name",
                section: "Dimensions",
                title: "(x) => x",
            }]
        },
        vals: {
            type: Array,
            default: () => [{
                name: "Permalink",
                calc: "optimization_summary.permalink",
                section: "Optimizations",
                format: (x) => x.split("permalink=")[1],
                actionlink: (row, col) => row[col.i],
            }, {
                name: "Started by",
                calc: "optimization.create_user",
                section: "Optimizations",
            }, {
                name: "Started on",
                calc: "optimization.create_time",
                format: x => new Date(x).toLocaleString(),
                // title: (row, col) => formatTime(row[col.i]),
                section: "Optimizations",
            }, {
                name: "Price changes",
                calc: "optimization_summary.price_changes",
                format: formatNumber,
                section: "Optimizations",
            }, {
                name: "Prices increased",
                calc: "optimization_summary.price_increased",
                format: formatNumber,
                section: "Optimizations",
            }, {
                name: "Prices decreased",
                calc: "optimization_summary.price_decreased",
                format: formatNumber,
                section: "Optimizations",
            }, {
                name: "Average price increase",
                calc: "optimization_summary.price_avg_increase",
                format: formatPercent,
                section: "Optimizations",
            }, {
                name: "Average price decrease",
                calc: "optimization_summary.price_avg_decrease",
                format: formatPercent,
                section: "Optimizations",
            // }, {
            //     name: "Optimize demand weight",
            //     calc: "optimization_goals.demand",
            //     format: this.formatPercent,
            //     section: "Optimizations",
            // }, {
            //     name: "Optimize revenue weight",
            //     calc: "optimization_goals.revenue",
            //     format: this.formatPercent,
            //     section: "Optimizations",
            // }, {
            //     name: "Optimize margin weight",
            //     calc: "optimization_goals.margin",
            //     format: this.formatPercent,
            //     section: "Optimizations",
            }]
        },
        vars: { type: Object },
        sort: { type: Array, default: () => [-5] },
        take: { type: Number, default: 20 },
	},
	data() {
		return {
            filter: [],
			report: null,
            values: {},
		}
	},
    mounted() {
        this.mounted = true
        this.activeQueries = new Set()
    },
    beforeDestroy() {
        this.mounted = false
        for (let queryId in [...this.activeQueries])
            utils.bridge.trigger("cancelReport", queryId)
    },
    watch: {
        async rows() {
            let rows = this.rows
            let table = this.$refs.table
            let tasks = []
            for (let row of rows) {
                let key = row.key
                let values = this.values[row[0]]

                if (values) {
                    row = _.clone(row)
                    row.__cache = {}
                    row.splice(this.offset, row.length, ...values)
                    Vue.set(table.rowOverrides, key, row)
                    continue
                }

                let query = _.cloneDeep(this.query)

                query.id = utils.randomId()
                query.source.dims = _.map(query.source.dims, "calc")
                query.source.vals = _.map(query.source.vals, "calc")
                query.source.cols = _.map(query.source.cols, "calc")

                query.source.filter0 = this.makeFilter(
                    [query.source.filter0, this.$refs.table.extraFilter0])

                query.source.filter1 = this.makeFilter(
                    [query.source.filter1, this.$refs.table.extraFilter1])

                query.source.filter2 = this.makeFilter(
                    [query.source.filter2, this.$refs.table.extraFilter2])

                query.dims = _.map(query.dims, "calc")
                query.vals = _.map(query.vals, "calc")
                query.cols = _.map(query.cols, "calc")

                query.vars = _.assign({}, this.vars, {
                    target_optimization_run_id: utils.quote(row[0])
                })
                tasks.push({row, key, query})
            }
            for (let chunk of _.chunk(tasks, 10)) {
                if (!this.mounted || rows !== this.rows)
                    return

                await Promise.all(chunk.map(({row, key, query}) => {
                    this.activeQueries.add(query.id)
                    return utils.query(query)
                        .then(([values]) => {
                            if (!this.mounted || rows !== this.rows)
                                return
                            row = _.clone(row)
                            row.__cache = {}
                            row.splice(this.offset, row.length, ...values)
                            Vue.set(table.rowOverrides, key, row)
                            this.$set(this.values, row[0], values)
                            this.activeQueries.delete(query.id)
                        })
                        .catch(() => {
                            this.activeQueries.delete(query.id)
                        })
                }))
            }
        }
    },
	methods: {
        l10n(text) {
            return utils.l10n(text)
        },
        linkClicked(e, info) {
            if (info.column.i == 0)
                this.$emit("click", info.row[info.column.i])
        },
        makeFilter(filters) {
            return _(filters)
                .filter()
                .map((filter) => `(${filter})`)
                .join(" && ")
        },
        columnName(column) {
            return column.name || this.metricsByFormula[column.formula]?.name
        },
        resolveTimeframe(metric, column) {
            let timeframe = column.timeframe || metric.timeframe

            if (timeframe == "past")
                timeframe = this.pastTimeframe

            if (timeframe == "future")
                timeframe = this.futureTimeframe

            if (!this.timeframes[timeframe])
                timeframe = "reference_date"

            return timeframe
        },
        makeVals(vals, metric, column, section) {
            let referenceDate = utils.parseDate(this.referenceDate)
            let timeframe = this.resolveTimeframe(metric, column)

            let [startDate, endDate] =
                eval(this.timeframes[timeframe].calc)(referenceDate)

            let resolveSubstitutes = (calc, depth = 0) => {
                if (depth == 10)
                    return calc
                return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                    let formula = this.formulas[symbol]
                    if (formula !== undefined && !this.isAggregationFormula(formula))
                        return `(${resolveSubstitutes(formula, depth + 1)})`
                    else
                        return symbol
                })
            }

            let registerFormula = (symbol) => {
                let formula = this.formulas[symbol]
                if (formula !== undefined) {
                    if (this.isAggregationFormula(formula)) {
                        vals[`${symbol}_${timeframe}`] =
                            this.resolveDateConditions(
                                resolveSubstitutes(formula),
                                startDate,
                                endDate,
                                referenceDate)
                    }
                    else {
                        for (let [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
                            registerFormula(symbol)
                        }
                    }
                }
            }

            let symbols = metric.formula.split(/[\s,]+/g)
            for (let symbol of symbols)
                registerFormula(symbol)
        },
        makeCols(cols, metric, column, section) {
            let calc = undefined
            let symbols = metric.formula.split(/[\s,]+/g)
            let symbol = symbols[0]

            let formula = this.formulas[symbol]

            let timeframe = this.resolveTimeframe(metric, column)

            if (formula !== undefined) {
                if (this.isAggregationFormula(formula))
                    calc = `${symbol}_${timeframe}`
                else {
                    let resolveSubstitutes = (calc, depth = 0) => {
                        if (depth == 10)
                            return calc
                        return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                            let formula = this.formulas[symbol]
                            if (formula !== undefined)
                                if (this.isAggregationFormula(formula))
                                    return `${symbol}_${timeframe}`
                                else
                                    return `(${resolveSubstitutes(formula, depth + 1)})`
                            else
                                return symbol
                        })
                    }
                    calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                        return resolveSubstitutes(symbol)
                    })
                }
            }

            let name = column.name || metric.name

            let format = this.formats[metric.format] || metric.format
            if (_.isString(format)) {
                try {
                    format = eval(format)
                }
                catch (ex) {
                    console.warn(format, ex)
                }
            }
            if (!_.isFunction(format))
                format = x => x

            let style = column.style

            if (calc !== undefined) {
                cols.push(_.assign({
                        name,
                        calc,
                        style,
                        format,
                        metric,
                        section: section.name,
                    },
                    _.omit(metric, ["name", "type", "format"])))
            }
        },
		formatTime(x) {
			return new Date(x).toLocaleString()
		},
        formatNumber(x) {
            return x ? new Number(x).toLocaleString(this.locale) : "-"
        },
        formatPercent(x) {
            return x ? new Number(x).toLocaleString(this.locale, {
                style: "percent",
                maximumFractionDigits: 1
            }) : "-"
        },
        async exportToExcel() {
            let XLSX = await import("xlsx")
            let workbook = XLSX.utils.book_new()
            let table = this.$refs.table
            let rows = []
            rows.push(table.columns.map(column => utils.l10n(column.name)))
            for (let row of table.rows) {
                row = table.rowOverrides[row.key] || row
                rows.push(table.columns.map(column =>
                    column.format(row[column.i])))
            }
            let worksheet = XLSX.utils.aoa_to_sheet(rows)
            XLSX.utils.book_append_sheet(workbook, worksheet, "report")
            XLSX.writeFile(workbook, "optimizations.xlsx")
        },
	},
	computed: {
        tableActions() {
            return [{
                icon: "download",
                text: "Download as Excel",
                call: () => { this.exportToExcel(); return true },
                menu: true,
            }];
        },
        cols() {
            return this.columns.map(column => ({
                calc: "0.0",
                name: column.name,
                style: column.style,
                format: column.format,
                section: column.section,
            }))
        },
        offset() {
            return this.dims.length + this.vals.length
        },
        usersFilter() {
            return `(optimization.create_user == '${this.username}' || !(optimization.create_user in ['ag', 'oleg']))`
        },
        filter2() {
            let filter2 = []
            filter2.push(this.usersFilter)
            for (let condition of this.filter) {
                for (let key of _.keys(condition)) {
                    let value = condition[key]
                    if (key && value) {
                        filter2.push(`(${key}) in ${utils.quote(value)}`)
                    }
                }

            }
            return filter2.join(" && ")
        },
        columns() {
            return _.map(this.query.source.cols)
        },
        rows() {
            return this.report?.rows || []
        },
        datesFilter() {
            let dates = new Set()
            let referenceDate = utils.parseDate(this.referenceDate)
            for (let date of [
                    referenceDate,
                    utils.nextDate(referenceDate),
                    utils.prevDate(referenceDate)])
                dates.add(utils.formatDate(date))
            for (let section of this.sections) {
                for (let column of section.columns) {
                    let metric = this.metricsByFormula[column.formula]
                    if (metric) {
                        let timeframe = metric.timeframe
                        if (!this.timeframes[timeframe])
                            timeframe = "reference_date"
                        let [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate)
                        if (endDate >= startDate) {
                            if (metric.formula) {
                                let formula = this.resolveSubstitutes(metric.formula)
                                if (_.includes(formula, "date_before_start"))
                                    startDate = utils.prevDate(startDate)
                                if (_.includes(formula, "date_after_end"))
                                    endDate = utils.nextDate(endDate)
                            }
                            let date = new Date(startDate)
                            while (date.getTime() <= endDate.getTime()) {
                                dates.add(utils.formatDate(date))
                                date = utils.nextDate(date)
                            }
                        }
                    }
                }
            }
            return `date in [${[...dates].map((date) => `\`${date}\``).join(", ")}]`
        },
        query() {
            let vals = {}
            let cols = []
            let dims = [] // this.primaryDims
            for (let section of this.sections) {
                for (let column of section.columns) {
                    let metric = this.metricsByFormula[column.formula]
                    if (metric) {
                        this.makeVals(vals, metric, column, section)
                        this.makeCols(cols, metric, column, section)
                    }
                }
            }
            return {
                stream: "combined",
                source: {
                    filter0: this.datesFilter,
                    dims,
                    cols,
                    vals: _(vals)
                        .toPairs()
                        .map(([name, calc]) => ({
                            name,
                            calc: `${calc} as ${name}`,
                            show: false}))
                        .sortBy("calc")
                        .value(),
                },
                vals: _.map(cols,
                    ({metric, format, calc}, i) => {
                        let aggregation = "sum"
                        if (metric.format == "percent")
                            aggregation = "avg"

                        if (metric.formula.startsWith("avg"))
                            aggregation = "avg"

                        if (_.startsWith(calc, "avg"))
                            aggregation = "avg"
                        if (_.startsWith(calc, "min"))
                            aggregation = "min"
                        if (_.startsWith(calc, "max"))
                            aggregation = "max"

                        let precision = undefined
                        if (aggregation == "sum")
                            precision = 0

                        let col = `col${i+dims.length+1}`

                        return {
                            metric,
                            format,
                            precision,
                            calc: `${aggregation}(${col} if ${col} != 0)`,
                        }
                    })
            }
        },
	}
}
</script>
<style>
.gp-runs .table {
    font-size: 0.9em;
}
.gp-runs .table td {
    white-space: nowrap;
}
.gp-runs .table tr:last-child th:nth-child(n+5) {
    text-align: right;
}
.gp-runs .table td:nth-child(n+5) {
    text-align: right;
}
.gp-runs .table th:nth-child(n+11) a {
    pointer-events: none;
    color: inherit;
    text-decoration: none!important;
}
.gp-runs .feather-icon-download {
    display: none;
}
.gp-runs .plain-table-slider {
    margin: 0;
}
.gp-runs th.my-column-dim {
    background-color: white;
}
.my-dark-theme .gp-runs th.my-column-dim {
    background-color: #303030;
}
.gp-runs .plain-table-body {
    margin-left: -16px;
    margin-right: -16px;
}
.gp-runs .table {
    padding-left: 16px!important;
    padding-right: 16px!important;
}
</style>