var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plain-table-tile",
      class: _vm.rowClass,
      style: _vm.rowStyle,
      attrs: { tabindex: "0", "data-row": _vm.i }
    },
    [
      _c(
        "a",
        {
          staticStyle: { float: "right" },
          attrs: { href: "javascript:void(0)", tabindex: "-1" },
          on: {
            click: function($event) {
              return _vm.$emit("maximize")
            }
          }
        },
        [_c("feather-icon", { attrs: { name: "maximize" } })],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.sections, function(section, i) {
        return section.visibleColumns.length
          ? [
              _vm._l(section.visibleColumns, function(column, j) {
                return [
                  _vm.editable && _vm.editableColumn == column.i
                    ? _c("plain-table-cell-editable", {
                        key: column.id || i + "-" + j,
                        attrs: {
                          i: i,
                          meta: _vm.meta,
                          row: _vm.row,
                          rows: _vm.rows,
                          column: column,
                          section: { visibleColumns: [] },
                          editing: true
                        }
                      })
                    : _c("plain-table-cell-static", {
                        key: column.id || i + "-" + j,
                        attrs: {
                          i: i,
                          meta: _vm.meta,
                          row: _vm.row,
                          label: column.name,
                          column: column,
                          section: { visibleColumns: [] }
                        }
                      })
                ]
              })
            ]
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }