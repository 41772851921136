<template>
    <div class="pie-chart">
        <my-errors :errors="errors" @retry="requestData"/>

        <my-caption :caption="caption"/>

        <my-progress
            :key="reportId"
            :progress="progress"
            :processRows="processRows"
            :processTime="processTime"
            :meta="meta"
            :exportToFile="exportToFile"
            :assembly="assembly"/>

        <my-filters v-model="filters" :chart="chart"/>

        <my-download :file="file"/>

        <my-tooltip
            ref="tooltip"
            :meta="meta"
            :keepOnly="keepOnly"
            :exclude="exclude"
            :actions="actions"
            />

        <div class="my-chart" :style="style" ref="base">
            <div ref="chart"></div>
        </div>
    </div>
</template>

<script>
module.exports = {
    mixins: [
        require("../data.js"),
        require("./base.js"),
        require("./pie-chart.js"),
    ],
    methods: {
        tuneChart(chart) {

            chart.selectAll(".pie-slice")
                .on("mouseenter", (e, {data}) => {
                    this.$refs.tooltip.show(data, e)
                })
                .on("mousemove", (e, {data}) => {
                    this.$refs.tooltip.move(data, e)
                })
                .on("mouseleave", (e, {data}) => {
                    this.$refs.tooltip.hide(data, e)
                })
                .on("click", (e, {data}) => {
                    this.$refs.tooltip.click(data, e)
                })

            chart.selectAll(".dc-legend-item")
                .on("click", (e,d) => {
                    if (d.others) 
                        for (name of d.others)
                            chart.filter(name)
                    else
                        chart.filter(d.name)
                    chart.redraw()
                    this.$refs.tooltip.click({
                        key:    d.name,
                        value:  d.data,
                        others: d.others,
                    },e)
                })
        }            
    }
}
</script>