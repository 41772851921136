<template>
<div class="gp-version">
	<span>{{clientVersion.slice(0, length)}}</span>
	<a
		class="alert alert-info"
		href="javascript:window.location.reload()"
		v-if="actualVersion && actualVersion != clientVersion">
		<l10n value="New version is available. Please refresh the page to apply changes."/>
	</a>
</div>
</template>
<script>
module.exports = {
	props: {
		length: { type: Number, default: 10 },
	},
	data() {
		return {
			clientVersion: $("script[src*='app']").attr("src").split("/")[2].split(".")[2],
			actualVersion: null,
		}
	},
	mounted() {
		this.timer = setInterval(this.checkActualVersion, 1000 * 60)
	},
	beforeDestroy() {
		clearInterval(this.timer)
	},
	methods: {
		checkActualVersion() {
			$.get(window.location.href)
				.then(page => page.match(/app2\.bundle\.([0-9a-z]+)/)[1])
				.then(verison => this.actualVersion = verison)
		},
	}
}
</script>
<style>
.gp-version:before {
    /*content: "сборка: ";*/
}
.gp-version {
    white-space: nowrap;
    color: white;
    font-size: 0.8em;
}
.gp-version .alert {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translatex(-50%);
    z-index: 100!important;
    border-radius: 0 0 10px 10px;
    padding: 4px 20px;
    font-size: 14px;
    box-shadow: 0 0 10px -4px black;
}
.gp-version {
    white-space: nowrap;
    font-size: 0.8em;
    color: var(--dark);
    /*opacity: 0.7;*/
}
.gp-version .alert {
    /*opacity: 1;*/
    position: fixed;
    top: 0;
    left: 50%;
    transform: translatex(-50%);
    z-index: 100!important;
    border-radius: 0 0 10px 10px;
    padding: 4px 20px;
    font-size: 14px;
    box-shadow: 0 0 10px -4px black;
    color: white;
}
</style>
