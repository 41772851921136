var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c(
        "div",
        {
          staticClass: "pim-hierarchies-entry",
          class: { focused: _vm.entry.focused },
          style: {
            "margin-left": _vm.level * 20 + "px",
            "margin-right": "10px"
          }
        },
        [
          _vm.entry.children != null && _vm.entry.children.length
            ? _c(
                "a",
                {
                  staticClass: "pim-hierarchies-open",
                  attrs: { href: "javascript:void(0)", tabindex: "-1" },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        _vm.entry.opened ? "close" : "open",
                        _vm.entry
                      )
                    }
                  }
                },
                [
                  _c("feather-icon", {
                    attrs: { name: _vm.entry.opened ? "book-open" : "book" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pim-hierarchies-entry-head" },
            [
              _vm.multiselect
                ? _c("gp-check", {
                    attrs: { inline: true },
                    model: {
                      value: _vm.entry.selected,
                      callback: function($$v) {
                        _vm.$set(_vm.entry, "selected", $$v)
                      },
                      expression: "entry.selected"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  ref: "name",
                  staticClass: "pim-hierarchies-entry-name",
                  attrs: { tabindex: "0" },
                  on: {
                    focus: function($event) {
                      return _vm.$emit("focus", _vm.entry)
                    },
                    blur: function($event) {
                      return _vm.$emit("blur", _vm.entry)
                    }
                  }
                },
                [
                  _vm.formattedName
                    ? _vm._l(_vm.formattedName, function(part) {
                        return _c(
                          "span",
                          { class: { matched: part.matched } },
                          [_vm._v(_vm._s(part.text))]
                        )
                      })
                    : [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.entry.name || "[name]") +
                            "\n                    "
                        )
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _vm.entry.desc
                ? _c(
                    "span",
                    { staticClass: "pim-hierarchies-entry-desc" },
                    [
                      _vm._v("\n                    –\n                    "),
                      _vm.formattedDesc
                        ? _vm._l(_vm.formattedDesc, function(part) {
                            return _c(
                              "span",
                              { class: { matched: part.matched } },
                              [_vm._v(_vm._s(part.text))]
                            )
                          })
                        : [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.entry.desc) +
                                "\n                    "
                            )
                          ]
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.entry.error
                ? _c("span", { staticClass: "pim-hierarchies-entry-error" }, [
                    _vm._v(
                      "\n                    – " +
                        _vm._s(_vm.entry.error) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }