<template>
  <button
    class="my-button"
    :style="getButtonStyles"
    type="button"
    @click="onClick"
  >
    <div v-if="isLoading" class="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <slot v-else>
      <l10n value="Submit" />
    </slot>
  </button>
</template>

<script>
module.exports = {
  props: {
    isLoading: false,
    evaluateButton: {
      type: Object,
      required: false,
    },
  },
  computed: {
    getButtonStyles() {
      if (this.evaluateButton?.styles) {
        let styles = '';

        Object.entries(this.evaluateButton.styles).forEach((style) => {
          styles += `${style[0]}: ${style[1]}; `;
        });
        return styles;
      }
      return null;
    },
  },
  methods: {
    onClick() {
      if (!this.isLoading) {
        if (this.evaluateButton) {
          this.$emit('mybutton-click', this.evaluateButton);
        } else {
          this.$emit('mybutton-click');
        }
      }
    },
  },
};
</script>

<style scoped>
  .my-button {
    min-width: 70px;
    margin-right: 10px;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    background-color: #95a5a6;
    transition: all .3s ease-in;
  }

  .my-button:hover {
    background-color: #2C3E50;
  }

  .my-button:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
    outline: none;
  }

  .my-button:disabled {
    background-color: #2C3E50;
  }

  .loader {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 12px;
  }
  .loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 8px;
    background: #fff;
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .loader div:nth-child(1) {
    left: 4px;
    animation-delay: -0.24s;
  }
  .loader div:nth-child(2) {
    left: 14px;
    animation-delay: -0.12s;
  }
  .loader div:nth-child(3) {
    left: 24px;
    animation-delay: 0;
  }
  @keyframes loader {
    0% {
      top: 2px;
      height: 12px;
    }
    50%, 100% {
      top: 6px;
      height: 4px;
    }
  }
</style>
