var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "inline-input", class: { focused: _vm.focused } },
    [
      _c("a", { attrs: { href: "javascript:void(0)" } }, [
        _c("span", [_c("l10n", { attrs: { value: _vm.value } })], 1),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          class: { "no-spin": _vm.noSpin },
          attrs: { type: _vm.type, pattern: _vm.pattern },
          domProps: { value: _vm.value },
          on: {
            input: _vm.handleInput,
            blur: _vm.handleBlur,
            focus: _vm.handleFocus,
            keydown: _vm.handleKeyDown
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }