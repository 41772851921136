<template>
  <div
    class="gp-stored"
    :class="{ compact }"
  >
    <div class="gp-stored-select">
      <gp-select
        ref="gpSelectConfig"
        v-model="savedConfig"
        :placeholder="l10n('Config name')"
        :options="visibleConfigs"
        @change="onChangeSelectedConfig"
      />

      <a
        v-if="!compact"
        href="javascript:void(0)"
        @click="onClickRefreshConfigsList"
      >
        <feather-icon :name="loading ? 'clock' : 'refresh-cw'" />
      </a>

      <a
        v-if="compact"
        href="javascript:void(0)"
        :class="{ disabled: !username || !somethingEdited && savedConfig }"
        @click="onClickSaveSettings"
      >
        <feather-icon name="save" />
      </a>

      <a
        v-if="compact"
        href="javascript:void(0)"
        :class="{ disabled: !savedConfig || savedConfig.username !== username }"
        @click="onClickDeleteSettings"
      >
        <feather-icon name="trash" />
      </a>
    </div>

    <template v-if="!compact">
      <gp-check
        v-if="config"
        :checked="config.shared"
        :disabled="config.username && config.username != username"
        @change="$set(config, 'shared', $event)"
      >
        <l10n value="Share this config" />
      </gp-check>

      <gp-pills
        v-if="config && config.shared && shareGroups.length != 0"
        v-model="config.sharedWith"
        :options="shareGroups"
        placeholder="Shared with"
      />

      <gp-check
        v-model="showSharedConfigs"
        @change="onShowShareConfigStateChange"
      >
        <l10n value="Show shared configs" />
      </gp-check>

      <button
        class="btn btn-sm btn-primary"
        type="button"
        :disabled="!username || !somethingEdited && savedConfig"
        @click="onClickSaveSettings"
      >
        <l10n value="Save settings" />
      </button>

      <button
        class="btn btn-sm btn-secondary"
        type="button"
        :disabled="!somethingEdited"
        @click="onClickRevertSettings"
      >
        <l10n value="Revert settings" />
      </button>

      <button
        class="btn btn-sm btn-danger"
        type="button"
        :disabled="!savedConfig || savedConfig.username !== username"
        @click="onClickDeleteSettings"
      >
        <l10n value="Delete settings" />
      </button>
    </template>
  </div>
</template>

<script>
const utils = require('../my-utils');
const ls = require('../api/localStorage');

module.exports = {
  model: {
    prop: 'config',
    event: 'change',
  },

  props: {
    family: { type: String },
    config: { type: Object },
    compact: { type: Boolean },
    username: { type: String },
    shareGroups: { type: Array, default: () => [] },
    uniqueKey: { type: String, default: 'Initial' },
  },

  data() {
    return {
      l10n: utils.l10n,
      savedConfig: {},
      configsList: [],
      showSharedConfigs: localStorage.showSharedConfigs === 'true',
      loading: false,
      timer: null,
    };
  },

  mounted() {
    delete localStorage[`${this.family}-configs`];

    const { config, configs } = utils.loadLocalConfigs(this.family);

    this.savedConfig = config;
    this.configsList = configs;

    if (this.savedConfig) {
      // ???
      this.$emit('change', _.cloneDeep(this.savedConfig));
    }

    this.loadSavedConfigs();
    this.timer = setInterval(this.loadSavedConfigs, 1000 * 60);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  computed: {
    visibleConfigs() {
      const newEntry = {
        id: '__new',
        name: utils.l10n('Create new entry'),
      };
      const visibleConfigs = this.configsList
        .filter((config) => !config.username
          || config.username === this.username
          || (this.showSharedConfigs && config.shared));

      return [
        newEntry,
        ...visibleConfigs,
      ];
    },

    somethingEdited() {
      return JSON.stringify(this.config) !== JSON.stringify(this.savedConfig);
    },
  },

  methods: {
    onChangeSelectedConfig(config) {
      if (config?.id === '__new') {
        this.savedConfig = {
          id: utils.randomId(),
          name: '',
          user: this.username,
        };
      } else {
        this.saveUniqueDataToLocalStorage(`${this.family}-config-id`, config?.id);
      }

      this.$emit('change', _.cloneDeep(this.savedConfig));
    },

    onShowShareConfigStateChange() {
      localStorage.setItem('showSharedConfigs', this.showSharedConfigs);
    },

    getUniqueTableDataFromLocalStorage(key) {
      return ls.loadFromLocalStorage(this.uniqueKey, key);
    },

    saveUniqueDataToLocalStorage(key, value) {
      return ls.saveToLocalStorage(this.uniqueKey, key, value);
    },

    onClickSaveSettings() {
      if (!this.username) {
        return;
      }

      let name = this.$refs.gpSelectConfig.inputValue;

      if (!name) {
        name = window.prompt(utils.l10n('Plase enter config name'));
      }

      if (!name) {
        return;
      }

      const filter = localStorage[`${this.uniqueKey}_gp-search-filter`] || '[]';
      const classes = localStorage[`${this.uniqueKey}_gp-search-classes`] || '[]';
      const categories = localStorage[`${this.uniqueKey}_gp-search-categories`] || '[]';

      const newConfig = {
        ..._.cloneDeep(this.config),
        id: utils.randomId(),
        username: this.username,
        name,
        topSettings: {
          filter,
          classes,
          categories,
        },
      };

      if (!this.savedConfig || this.savedConfig.name !== name || this.savedConfig.username !== this.username) {
        if (this.savedConfig && this.savedConfig.name === name && this.savedConfig.username !== this.username) {
          const text = utils
            .l10n('This config was created by user {user}. Would you like to give your copy another name?')
            .replace('{user}', this.savedConfig.username);
          const newName = window.prompt(text, name);

          if (newName) {
            newConfig.name = newName;
          }
        }

        newConfig.shared = false;
        this.configsList.push(newConfig);
      } else {
        const idx = this.configsList.indexOf(this.savedConfig);

        newConfig.id = this.savedConfig.id;
        this.configsList.splice(idx, 1, newConfig);
      }

      this.savedConfig = newConfig;

      Promise.resolve($.ajax({
        url: `/storage/${this.family}/${this.savedConfig.id}`,
        method: 'PUT',
        contentType: 'applcation/json',
        data: JSON.stringify(this.savedConfig),
      })).then(this.loadSavedConfigs);

      this.$emit('saved', _.cloneDeep(this.savedConfig));
    },

    async loadSavedConfigs() {
      this.loading = true;

      const { configs } = await utils.loadSavedConfigs(this.family);

      if (!_.isEqual(configs, this.configsList)) {
        this.configsList = configs;

        const savedConfigId = this.getUniqueTableDataFromLocalStorage(`${this.family}-config-id`);

        if (!this.savedConfig && savedConfigId) {
          this.savedConfig = this.configsList.find(({ id }) => id === savedConfigId) || null;
        } else if (this.savedConfig) {
          this.savedConfig = this.configsList.find(({ id }) => id === this.savedConfig.id) || null;
        }
      }

      this.loading = false;
    },

    onClickRevertSettings() {
      this.$emit('change', _.cloneDeep(this.savedConfig), true);
    },

    onClickRefreshConfigsList() {
      this.loadSavedConfigs();
    },

    onClickDeleteSettings() {
      if (this.savedConfig.username !== this.username) {
        return;
      }

      const text = utils
        .l10n('Are you sure you want to delete config "{config}"?')
        .replace('{config}', this.savedConfig.name);

      if (window.confirm(text)) {
        const { id } = this.savedConfig;
        const idx = this.configsList.indexOf(this.savedConfig);

        if (idx !== -1) {
          this.configsList.splice(idx, 1);
        }

        this.savedConfig = null;

        Promise.resolve($.ajax({
          url: `/storage/${this.family}/${id}`,
          method: 'DELETE',
        })).then(this.loadSavedConfigs);
      }
    },
  },
};
</script>

<style>
.gp-stored:not(.compact) {
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: -21px;
  margin: 0 -20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-right: calc(20px - 8px);
  z-index: 5;
  background-color: white;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  box-shadow: 0 10px 10px -10px #00000020;
}
.my-dark-theme .gp-stored:not(.compact) {
  background-color: var(--dark);
}
.gp-stored .gp-select {
  flex-basis: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
}
.gp-stored .gp-check {
  flex-basis: 100%;
  margin-bottom: 10px;
}
.gp-stored button {
  flex-grow: 1;
  flex-basis: 30%;
  margin-right: 8px;
  margin-bottom: 4px;
}
.gp-stored-select {
  display: flex;
  width: 100%;
  margin-right: 20px;
}
.gp-stored-select > a .feather-icon svg {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  display: inline-block;
}
.gp-stored-select > a .feather-icon-clock svg {
  color: var(--green);
  transform: scale(0.9);
}
.gp-stored .gp-check + .gp-check {
  margin-top: -10px;
}
</style>
