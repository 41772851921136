var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-stream" },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "gp-stream-loading" },
            [_c("feather-icon", { attrs: { name: "clock" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dontLoadHuge
        ? _c(
            "div",
            { staticClass: "alert alert-info" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger",
                  staticStyle: { float: "right", margin: "-3px 0" },
                  on: {
                    click: function($event) {
                      _vm.loadHugeConfirmed = true
                    }
                  }
                },
                [
                  _c("l10n", {
                    attrs: {
                      value: "Process {size} records",
                      size: new Number(_vm.streamSize).toLocaleString()
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("l10n", {
                attrs: {
                  value:
                    "Stream has above {limit} records. Are you sure you want to process them?",
                  limit: new Number(_vm.threshold).toLocaleString()
                }
              }),
              _vm._v(" "),
              _c("div", { staticStyle: { clear: "right" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.dontLoadHuge
        ? [
            _c("gp-filter", {
              attrs: {
                stream: _vm.stream,
                "popup-portal": null,
                attributes: _vm.columns
                  .filter(function(ref) {
                    var indexed = ref.indexed

                    return indexed
                  })
                  .map(function(ref) {
                    var calc = ref.gqlName
                    var name = ref.name
                    var type = ref.type

                    return { name: _vm.l10n(name), calc: calc, type: type }
                  })
              },
              model: {
                value: _vm.filter_,
                callback: function($$v) {
                  _vm.filter_ = $$v
                },
                expression: "filter_"
              }
            }),
            _vm._v(" "),
            _vm.pageCount > 1
              ? _c(
                  "div",
                  {
                    staticClass: "plain-table-paginator",
                    attrs: { tabindex: "0" },
                    on: { keydown: _vm.handlePaginatorKeyDown }
                  },
                  [
                    _c(
                      "a",
                      {
                        class: { disabled: _vm.page === 0 },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.setDesiredPage(_vm.page - 10)
                          }
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { name: "chevrons-left" } })
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        class: { disabled: _vm.page === 0 },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.setDesiredPage(_vm.page - 1)
                          }
                        }
                      },
                      [_c("feather-icon", { attrs: { name: "chevron-left" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.promptPage }
                      },
                      [
                        _c("l10n", {
                          attrs: {
                            value: "page {page}",
                            page: new Number(_vm.page + 1).toLocaleString()
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        class: { disabled: _vm.page === _vm.pageCount - 1 },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.setDesiredPage(_vm.page + 1)
                          }
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { name: "chevron-right" } })
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        class: { disabled: _vm.page === _vm.pageCount - 1 },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.setDesiredPage(_vm.page + 10)
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { name: "chevrons-right" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("l10n", {
                      staticClass: "nowrap",
                      attrs: {
                        rows: new Number(_vm.size).toLocaleString(),
                        value: "{rows} rows,"
                      }
                    }),
                    _vm._v(" "),
                    _c("l10n", {
                      staticClass: "nowrap",
                      attrs: {
                        pages: new Number(_vm.pageCount).toLocaleString(),
                        value: "{pages} pages,"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.promptPageSize }
                      },
                      [
                        _c("l10n", {
                          staticClass: "nowrap",
                          attrs: {
                            "page-size": new Number(
                              _vm.pageSize
                            ).toLocaleString(),
                            value: "{page-size} rows per page"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("inline-help", {
                      attrs: {
                        text:
                          "Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward.",
                        html: true
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass:
                  "table table-sm table-striped table-hover table-responsive",
                style: { opacity: _vm.loading ? 0.8 : 1 }
              },
              [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("th", [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            staticClass: "form-check-input",
                            attrs: { id: "row_all", type: "checkbox" },
                            domProps: { checked: _vm.allSelected },
                            on: {
                              click: function($event) {
                                return _vm.toogleSelectedStreamSelection()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", {
                            staticClass: "form-check-label",
                            attrs: { for: "row_all" }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.columns, function(column, i) {
                        return (_vm.showColumns == null ||
                          _vm.showColumns.includes(column.gqlName)) &&
                          (_vm.hideColumns == null ||
                            !_vm.hideColumns.includes(column.gqlName))
                          ? _c("th", { attrs: { "data-type": column.type } }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toogleSort(i)
                                    }
                                  }
                                },
                                [
                                  _c("l10n", { attrs: { value: column.name } }),
                                  _vm._v(" "),
                                  _vm.sort[0] === i + 1
                                    ? _c("feather-icon", {
                                        attrs: { name: "arrow-up" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.sort[0] === -i - 1
                                    ? _c("feather-icon", {
                                        attrs: { name: "arrow-down" }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.records.rows, function(record) {
                    return _c(
                      "tr",
                      [
                        _c("td", [
                          _c("div", { staticClass: "form-check" }, [
                            _c("input", {
                              staticClass: "form-check-input",
                              attrs: {
                                id: "row_" + record[0],
                                type: "checkbox"
                              },
                              domProps: { checked: _vm.selection[record[0]] },
                              on: {
                                click: function($event) {
                                  return _vm.toogleSelectedStreamSelection(
                                    record[0]
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "form-check-label",
                              attrs: { for: "row_" + record[0] }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.columns, function(column, i) {
                          return (_vm.showColumns == null ||
                            _vm.showColumns.includes(column.gqlName)) &&
                            (_vm.hideColumns == null ||
                              !_vm.hideColumns.includes(column.gqlName))
                            ? [
                                _c(
                                  "td",
                                  { attrs: { "data-type": column.type } },
                                  [
                                    _vm.isLink(record[i + 1])
                                      ? [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: record[i + 1],
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v(_vm._s(record[i + 1]))]
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatValue(
                                                record[i + 1],
                                                column
                                              )
                                            )
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("l10n", {
                  attrs: {
                    rows: new Number(_vm.selectedCount).toLocaleString(),
                    value: "{rows} rows selected"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "gp-stream-actions" }, [
        !_vm.dontLoadHuge
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { disabled: !_vm.selectedCount },
                on: { click: _vm.deleteSelectedRows }
              },
              [_c("l10n", { attrs: { value: "Delete selected rows" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-secondary", on: { click: _vm.exportData } },
          [_c("l10n", { attrs: { value: "Export data" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            staticStyle: { position: "relative" }
          },
          [
            _c("l10n", { attrs: { value: "Import data" } }),
            _vm._v(" "),
            _c("input", {
              style: {
                position: "absolute",
                opacity: 0,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%"
              },
              attrs: { type: "file" },
              on: { change: _vm.importData }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-secondary", on: { click: _vm.addRow } },
          [_c("l10n", { attrs: { value: "Manually add a row" } })],
          1
        )
      ]),
      _vm._v(" "),
      _vm.newRow
        ? _c(
            "my-dialog",
            {
              attrs: { title: "New row" },
              on: {
                close: function($event) {
                  _vm.newRow = null
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: { click: _vm.submitNewRow }
                          },
                          [_c("l10n", { attrs: { value: "Submit" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function($event) {
                                _vm.newRow = null
                              }
                            }
                          },
                          [_c("l10n", { attrs: { value: "Cancel" } })],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                286846115
              )
            },
            [
              _c("div", { staticClass: "gp-stream-new-row" }, [
                _c(
                  "table",
                  { ref: "newRow", on: { keydown: _vm.handleKeyDown } },
                  [
                    _c(
                      "tbody",
                      _vm._l(_vm.columns, function(column, i) {
                        return _c("tr", [
                          _c("td", [_vm._v(_vm._s(column.name))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              ["string"].includes(column.type)
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    domProps: {
                                      value: _vm.newRow[column.name]
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.newRow[column.name] =
                                          $event.target.value
                                      }
                                    }
                                  })
                                : ["int8", "int16", "int32", "int64"].includes(
                                    column.type
                                  )
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    attrs: { type: "number" },
                                    domProps: {
                                      value: _vm.newRow[column.name]
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.newRow[column.name] = parseInt(
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : ["float", "double"].includes(column.type)
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    attrs: { type: "number" },
                                    domProps: {
                                      value: _vm.newRow[column.name]
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.newRow[column.name] = parseFloat(
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                : ["date"].includes(column.type)
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    attrs: { type: "date" },
                                    domProps: {
                                      value: _vm.newRow[column.name]
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.newRow[column.name] =
                                          $event.target.value
                                      }
                                    }
                                  })
                                : ["datetime"].includes(column.type)
                                ? _c("input", {
                                    staticClass: "form-control form-control-sm",
                                    attrs: { type: "datetime-local" },
                                    domProps: {
                                      value: _vm.newRow[column.name]
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.newRow[column.name] =
                                          $event.target.value
                                      }
                                    }
                                  })
                                : ["json"].includes(column.type)
                                ? _c("textarea", {
                                    staticClass: "form-control form-control-sm",
                                    domProps: {
                                      value: _vm.newRow[column.name]
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.newRow[column.name] =
                                          $event.target.value
                                      }
                                    }
                                  })
                                : ["bool"].includes(column.type)
                                ? _c("gp-check", {
                                    attrs: { checked: _vm.newRow[column.name] },
                                    on: {
                                      change: function($event) {
                                        _vm.newRow[column.name] = $event
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }