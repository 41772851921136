var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.imageOverLink
    ? _c(
        "my-popup",
        {
          key: "image-over",
          attrs: {
            anchor: _vm.imageOverAnchor,
            portal: _vm.portal,
            "class-name": "pim-tree-image-over",
            placement: "top start"
          }
        },
        [
          _c(
            "div",
            {
              ref: "imageOverPopup",
              staticClass: "popover",
              style: { opacity: _vm.imageOverOpacity }
            },
            [
              _c("div", { staticClass: "popover-body" }, [
                _c("img", { attrs: { src: _vm.imageOverLink } })
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }