var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-menu" }, [
    _c(
      "a",
      {
        attrs: { href: "javascript:void(0)" },
        on: {
          click: function($event) {
            _vm.expanded = !_vm.expanded
          }
        }
      },
      [_c("feather-icon", { attrs: { name: "menu" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "gp-menu-items" },
      _vm._l(_vm.menu, function(item) {
        return _c("li", [
          _c(
            "a",
            { attrs: { href: item.link } },
            [
              _c("feather-icon", { attrs: { name: item.icon } }),
              _vm._v(" "),
              _c("l10n", { attrs: { value: item.name } })
            ],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }