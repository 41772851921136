var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-calendar", {
    ref: "calendar",
    attrs: { "show-weeknumbers": "", attributes: _vm.attributes },
    on: {
      weeknumberclick: _vm.onWeekdayclick,
      weekdayclick: _vm.onWeekdayclick,
      dayclick: _vm.onDayClick
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }