module.exports = _.merge({
    methods: {
        makeChart(base, instant) {
            let n = this.dims.length
            let m = this.vals.length

            let dim = this.ndx.dimension(row => row.slice(n))

            let grp = dim.group()
            // .reduce(
            //     (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[i+n] }; return p },
            //     (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[i+n] }; return p },
            //     () => _.fill(new Array(m), 0))

            let chart = dc.scatterPlot(base)

            chart.dimension(dim)
            chart.group(grp, this.title, (d) => d.value)

            window.ndx = this.ndx
            window.dim = dim
            window.grp = grp
            window.chart = chart

            // _(this.vals)
            //     .take(1)
            //     .forEach((group, i) => {
            //         let name = _.isString(group) ? group : group.name
            //         chart.group(grp, name, (d) => d.value[i])
            //     })

            return chart
        },
    },
}, require("./props").chartProps({
    title:              { type: String },
    brush:              { type: [Function, String], default: "d3.brush()" },
    emptyColor:         { type: String, default: null },
    emptyOpacity:       { type: Number, default: 0 },
    nonemptyOpacity:    { type: Number, default: 0 },
    customSymbol:       { type: [Function, String], default: 'd3.symbol()' },
    emptySize:          { type: Number, default: 0 },
    excludedColor:      { type: Number, default: null },
    excludedOpacity:    { type: Number, default: 1 },
    excludedSize:       { type: Number, default: null },
    existenceAccessor:  { type: [Function, String], default: (d) => d.value },
    highlightedSize:    { type: Number, default: 5 },
    // symbol:             { type: [Function, String], default: d3.symbolCircle },
    symbolSize:         { type: Number, default: 3 },
    useCanvas:          { type: Boolean, default: false },
    keyAccessor:        { type: [Function, String], default: (d) => d.key[0] },
    valueAccessor:      { type: [Function, String], default: (d) => d.key[1] },
}))

