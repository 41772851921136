var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-jstree", {
    staticClass: "gp-treeview",
    attrs: {
      "no-dots": _vm.noDots,
      data: _vm.data,
      multiple: _vm.multiple,
      "show-checkbox": _vm.showCheckbox,
      "allow-transition": false
    },
    on: { "item-click": _vm.onItemClick },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(_) {
          return [
            !_.model.loading
              ? _c("feather-icon", {
                  staticClass: "gp-treeview-icon",
                  attrs: {
                    role: "presentation",
                    name: _.model.icon || "folder",
                    size: "16px"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", {
              staticClass: "gp-treeview-item",
              domProps: { innerHTML: _vm._s(_.model.text) }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }