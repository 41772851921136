<template>
    <div class="pim-tree compact" ref="tree" v-if="mode == 'compact'">
        <ul>
            <!--li>
                <a
                    href="javascript:void(0)"
                    :class="{disabled: history.indexOf(roots) == 0}"
                    @click="$emit('navigate', history[history.indexOf(roots) - 1])"
                    >
                    <feather-icon name="arrow-left"/>
                </a>
            </li>
            <li>
                <a
                    href="javascript:void(0)"
                    :class="{disabled: roots == homeRoots}"
                    @click="$emit('navigate', homeRoots)"
                    >
                    <feather-icon name="home"/>
                </a>
            </li>
            <li>
                <a 
                    href="javascript:void(0)"
                    :class="{disabled: history.indexOf(roots) == history.length - 1}"
                    @click="$emit('navigate', history[history.indexOf(roots) + 1])"
                    >
                    <feather-icon name="arrow-right"/>
                </a>
            </li-->
            <li
                v-for="entry in focusPath"
                class="pim-tree-entry"
                :class="{focused: entry == focus}"
                :key="entry.key || entry.id"
                @click="focus = entry"
                >
                <div class="pim-tree-entry-head">
                    <div class="pim-tree-entry-name">
                        {{entry.name}}
                        <a
                            v-if="entry == focus && entry.parent"
                            class="pim-tree-entry-close"
                            href="javascript:void(0)"
                            @click="focus = entry.parent; $event.stopPropagation()">
                            <feather-icon name="x"/>
                        </a>
                    </div>
                </div>
            </li>
            <li key="next" v-show="focus && focus.children">
                <div class="pim-tree-entry-next">
                    <select
                        ref="select"
                        :placeholder="placeholder"
                        :data-placeholder="placeholder"
                        @change="focus = focus.children.find(child => child.id == $event.target.value)">
                        <option value="" :selected="true" disabled="disabled"/>
                        <template v-if="focus && focus.children">
                            <option
                                v-for="child in focus.children"
                                :value="child.id"
                                :key="child.id">
                                {{child.name}}
                            </option>
                        </template>
                    </select>
                </div>
            </li>
        </ul>
    </div>
    <div v-else class="pim-tree" ref="tree">
        <div class="pim-tree-move">
            <div>
                <span></span>
                <a tabindex="-1"
                    href="javascript:void(0)"
                    @click="moveUp"
                    :class="{disabled: !(focus && canMoveUp(focus))}"
                    title="Shift+ArrowUp">
                    <feather-icon name="arrow-up"/>
                </a>
                <span></span>
            </div>
            <div>
                <a tabindex="-1"
                    href="javascript:void(0)"
                    @click="moveLeft"
                    :class="{disabled: !(focus && canMoveLeft(focus))}"
                    title="Shift+ArrowLeft">
                    <feather-icon name="arrow-left"/>
                </a>
                <a tabindex="-1"
                    href="javascript:void(0)"
                    @click="showMenu = true"
                    :class="{disabled: !focus}">
                    <feather-icon name="menu" ref="menu"/>
                </a>
                <a tabindex="-1"
                    href="javascript:void(0)"
                    @click="moveRight"
                    :class="{disabled: !(focus && canMoveRight(focus))}"
                    title="Shift+ArrowRight">
                    <feather-icon name="arrow-right"/>
                </a>
            </div>
            <div>
                <span></span>
                <a tabindex="-1"
                    href="javascript:void(0)"
                    @click="moveDown"
                    :class="{disabled: !(focus && canMoveDown(focus))}"
                    title="Shift+ArrowDown">
                    <feather-icon name="arrow-down"/>
                </a>
                <span></span>
            </div>
        </div>
        <div class="pim-tree-head">
            <div class="pim-tree-search">
                <my-search v-model="searchString" ref="search"/>
                <!--p>
                    Use arrows to navigate through the hierarchy.<br>
                    Use Shift + arrows to move elements around.
                </p-->
                <pim-filter
                    v-model="filter"
                    :fields="fields"
                    />
                <gp-check v-model="multiselect">
                    Enable multiselect mode
                </gp-check>
            </div>
        </div>
        <div class="pim-tree-entries">
            <table>
                <tbody>
                    <template v-for="entry in visibleEntries">
                        <div class="pim-tree-tiles" v-if="entry.type == 'tiles'">
                            <div
                                class="pim-tree-tile"
                                :class="{focused: tile == focus}"
                                @click="focus = tile"
                                :key="tile.id"
                                v-for="tile in entry.tiles">
                                <span class="pim-tree-tile-sequence" v-if="tile.attrs.sequence">{{tile.attrs.sequence}}</span>
                                <span class="pim-tree-tile-primary" v-if="tile.attrs.is_primary_image">primary</span>
                                <img v-if="tile.image" :src="tile.image">
                                <label>{{tile.name}}</label>
                                <p v-if="tile.desc">{{tile.desc}}</p>
                            </div>
                        </div>
                        <pim-tree-table
                            v-else-if="entry.type == 'table'"
                            :style="{'--level': getEntryLevel(entry.entry, roots) + 1}"
                            :entry="entry.entry"
                            :entries="entry.entries"
                            :fields="fields"
                            @focus="focus = $event"
                            />
                        <pim-tree-chart
                            v-else-if="entry.type == 'chart'"
                            :style="{'--level': getEntryLevel(entry.entry, roots) + 1}"
                            :entry="entry.entry"
                            :entries="entry.entries"
                            :fields="fields"
                            :config="config"
                            @focus="focus = $event"
                            @context="$emit('context', $event)"
                            @open-in-tab="$emit('open-in-tab', $event)"
                            />
                        <pim-workflow
                            v-else-if="
                                entry.type == 'diagram' &&
                                entry.entry.type == 'workflow' &&
                                entry.entry.childrenType == 'function' &&
                                entry.entry.children &&
                                entry.entry.children.length > 0"
                            @selection="$event && $event.length > 0 ? $emit('focus', entry.entry.children.find(child => child.id == $event[0].key)) : null"
                            :style="{'--level': getEntryLevel(entry.entry, roots) + 1}"
                            :workflow="entry.entry"
                            :settings="workflow.settings"
                            :nodeTemplate="workflow.nodeTemplate"
                            :linkTemplate="workflow.linkTemplate"
                            :nodeTemplateMap="workflow.nodeTemplateMap"
                            :linkTemplateMap="workflow.linkTemplateMap"
                            :diagramLayout="workflow.diagramLayout"
                            :paletteLayout="workflow.paletteLayout"
                            :diagramModel="{
                                'class': 'go.GraphLinksModel',
                                linkFromPortIdProperty: 'fromPort',
                                linkToPortIdProperty: 'toPort',
                                nodeDataArray: [{
                                    key: 'Start',
                                    text: 'Start',
                                    category: 'Start',
                                }, {
                                    key: 'End',
                                    text: 'End',
                                    category: 'End',
                                }].concat(entry.entry.children.map(child => ({
                                    key: child.id,
                                    name: child.name,
                                    attrs: child.attrs,
                                    category: child.name.endsWith('Translations') ? 'Automation' : 'Function',
                                }))),
                                linkDataArray: [{
                                    from: 'Start',
                                    to: entry.entry.children[0].id,
                                    fromPort: 'R',
                                    toPort: 'L',
                                }, {
                                    from: entry.entry.children[entry.entry.children.length-1].id,
                                    to: 'End',
                                    fromPort: 'R',
                                    toPort: 'L',
                                }].concat(entry.entry.children.slice(1).map((child, i) => ({
                                    from: entry.entry.children[i].id,
                                    to: child.id,
                                    fromPort: 'R',
                                    toPort: 'L',
                                })))
                            }"
                            :paletteModel="workflow.paletteModel"
                            :autoheight="workflow.autoheight"
                            />
                        <div class="pin-tree-reset-children"
                            :style="{'--level': getEntryLevel(entry.entry, roots) + 1}"
                            v-else-if="entry.type == 'reset-children'"
                            >
                            <a
                                href="javascript:void(0)"
                                @click="loadChildren(entry.entry.children, entry.childrenType)">
                                open all {{pluralize(entry.entry.childrenType)}} to {{pluralize(entry.childrenType)}}
                            </a>
                        </div>
                        <pim-tree-paginator
                            v-else-if="entry.type == 'paginator'"
                            ref="entries"
                            :entry="entry"
                            :classify="classify"
                            :pluralize="pluralize"
                            @focus="focus = $event"
                            />
                        <pim-tree-multiselect
                            v-else-if="entry.type == 'multiselect'"
                            ref="entries"
                            :entry="entry"
                            :classify="classify"
                            :pluralize="pluralize"
                            @focus="focus = $event"
                            />
                        <pim-tree-entry
                            v-else
                            ref="entries"
                            :class="{focused: entry == focus}"
                            :key="entry.key || entry.id"
                            :entry="entry"
                            @focus="focus = $event"
                            @open="openEntry"
                            @close="closeEntry"
                            :pluralize="pluralize"
                            :multiselect="multiselect"
                            :formatColumn="formatColumn"
                            />
                    </template>
                </tbody>
            </table>
        </div>
        <my-popup
            v-if="focus && showMenu"
            portal="popup"
            :draggable="true"
            placement="bottom"
            @clickoutside="showMenu = false"
            :anchor="$refs.menu.$el">
            <div class="popover pim-tree-menu">
                <div class="popover-body">
                    <label>{{capitalize(focus.type)}} {{focus.name}}</label>
                    <ul>
                        <li v-if="focus.parent">
                            <a href="javascript:void(0)"
                                @click="showMenu = false; $emit('context', focus)">
                                <feather-icon name="file-plus"/>
                                Set this {{focus.type}} as a context
                            </a>
                        </li>
                        <li v-if="focus">
                            <a href="javascript:void(0)"
                                @click="showMenu = false; $emit('open-in-tab', focus)">
                                <feather-icon name="external-link"/>
                                Open this {{focus.type}} in new tab
                            </a>
                        </li>
                        <li v-if="focus.children && focus.opened && focus.children.length">
                            <a href="javascript:void(0)">
                                <feather-icon name="eye"/>
                                <feather-icon name="chevron-right"/>
                                Show child {{pluralize(focus.childrenType)}} as ...
                            </a>
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'mode', 'tiles')">
                                        <feather-icon :name="focus.mode == 'tiles' ? 'check' : 'square'"/>
                                        tiles
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'mode', 'table')">
                                        <feather-icon :name="focus.mode == 'table' ? 'check' : 'square'"/>
                                        a table
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $delete(focus, 'mode')">
                                        <feather-icon :name="!focus.mode ? 'check' : 'square'"/>
                                        a tree
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'mode', 'chart')">
                                        <feather-icon :name="focus.mode == 'chart' ? 'check' : 'square'"/>
                                        a chart
                                    </a>
                                </li>
                                <li v-if="focus.type == 'workflow'">
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'mode', 'diagram')">
                                        <feather-icon :name="focus.mode == 'diagram' ? 'check' : 'square'"/>
                                        a diagram
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li v-if="focus.mode == 'chart'">
                            <a href="javascript:void(0)">
                                <feather-icon name="pie-chart"/>
                                <feather-icon name="chevron-right"/>
                                Switch chart type to ...
                            </a>
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'chartType', 'pie-chart')">
                                        <feather-icon :name="focus.chartType == 'pie-chart' || !focus.chartType ? 'check' : 'square'"/>
                                        a pie chart
                                    </a>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'chartType', 'bar-chart')">
                                        <feather-icon :name="focus.chartType == 'bar-chart' ? 'check' : 'square'"/>
                                        a bar chart
                                    </a>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'chartType', 'bubble-chart')">
                                        <feather-icon :name="focus.chartType == 'bubble-chart' ? 'check' : 'square'"/>
                                        a bubble chart
                                    </a>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'chartType', 'scatter-chart')">
                                        <feather-icon :name="focus.chartType == 'scatter-chart' ? 'check' : 'square'"/>
                                        a scatter chart
                                    </a>
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; $set(focus, 'chartType', 'time-series')">
                                        <feather-icon :name="focus.chartType == 'time-series' ? 'check' : 'square'"/>
                                        a time series chart
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <template v-if="traverse(focus.type).length == 1">
                            <li v-for="type in traverse(focus.type)">
                                <a href="javascript:void(0)"
                                    @click="showMenu = false; openEntry(focus, type)">
                                    <feather-icon name="book-open"/>
                                    Open this {{focus.type}} to {{pluralize(type)}}
                                </a>
                            </li>
                        </template>
                        <template v-else-if="traverse(focus.type).length > 1">
                            <li>
                                <a href="javascript:void(0)">
                                    <feather-icon name="book-open"/>
                                    <feather-icon name="chevron-right"/>
                                    Open this {{focus.type}} to ...
                                </a>
                                <ul>
                                    <li v-for="type in traverse(focus.type)">
                                        <a href="javascript:void(0)"
                                            @click="showMenu = false; openEntry(focus, type)">
                                            {{pluralize(type)}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </template>
                        <li v-if="focus.childrenType || classify(focus.type)">
                            <a href="javascript:void(0)"
                                @click="showMenu = false; reloadChildren(focus)">
                                <feather-icon name="refresh-cw"/>
                                Reload child {{pluralize(focus.childrenType || classify(focus.type))}}
                            </a>
                        </li>
                        
                        <li v-if="focus.type == 'category'">
                            <a href="javascript:void(0)"
                                @click="showMenu = false; createSubEntry()">
                                <feather-icon name="file-plus"/>
                                Create {{addArticle(classify(focus.type))}} under
                            </a>
                        </li>
                        <li v-if="focus.type == 'category'">
                            <a href="javascript:void(0)"
                                @click="showMenu = false; createEntryAfter()">
                                <feather-icon name="file-plus"/>
                                Create {{addArticle(focus.type)}} right after
                            </a>
                        </li>
                        <li v-if="focus.type == 'category'">
                            <a href="javascript:void(0)"
                                @click="showMenu = false; removeThisEntry()">
                                <feather-icon name="trash"/>
                                Remove this {{focus.type}}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"
                                @click="showMenu = false; exportAttributes();">
                                <feather-icon name="download"/>
                                Export attributes
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"
                                @click="showMenu = false; importAttributes();">
                                <feather-icon name="upload"/>
                                Import attributes
                            </a>
                        </li>
                        <li v-for="action in actions">
                            <a href="javascript:void(0)"
                                @click="showMenu = false; action.call ? action.call() : null;">
                                <feather-icon :name="action.icon"/>
                                <feather-icon name="chevron-right" v-if="action.children"/>
                                {{action.name}}
                            </a>
                            <ul v-if="action.children">
                                <li v-for="child in action.children">
                                    <a href="javascript:void(0)"
                                        @click="showMenu = false; child.call ? child.call() : null;">
                                        <feather-icon :name="child.icon" v-if="child.icon"/>
                                        {{child.name}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </my-popup>
        <my-popup
            v-if="showColumns"
            :draggable="true"
            placement="bottom"
            @clickoutside="showColumns = false"
            :anchor="$refs.menu.$el">
            <div class="popover pim-tree-columns">
                <div class="popover-body">
                    <pim-columns
                        :autofocus="true"
                        :columns="columns"
                        :fields="fields"
                        @submit="showColumns = false; columns = $event.columns;"
                        @cancel="showColumns = false;"
                        />
                </div>
            </div>
        </my-popup>
        <form style="display: none" ref="uploadForm">
            <input type="file" ref="uploadInput" @change="handleFileUpload">
        </form>
        <pim-export-dialog
            :entry="focus"
            v-if="showExportDialog"
            :fields="fields"
            @close="showExportDialog = false"
            />
    </div>
</template>
<script>
let utils = require("../my-utils")
require("../chosen.jquery.js")

module.exports = {
    mixins: [
        require("./pim-helper.js")
    ],
    props: {
        mode: { type: String, default: "default" },
        config: { type: Object },
        search: { type: Object },
        fields: { type: Array, default: () => [] },
        locales: { type: Array, default: () => [] },
        storefronts: { type: Array, default: () => [] },
        username: { type: String },
        darkTheme: { type: Boolean },
        actions: { type: Array, default: () => [] },
        workflow: { type: Object },
        canMoveUp: {
            type: Function,
            default: entry =>
                entry.parent &&
                entry.parent.children.indexOf(entry) > 0
        },
        canMoveDown: {
            type: Function,
            default: entry =>
                entry.parent &&
                entry.parent.children.indexOf(entry) < entry.parent.children.length - 1
        },
        canMoveLeft: {
            type: Function,
            default: entry =>
                entry.parent &&
                entry.parent.type == entry.type
        },
        canMoveRight: {
            type: Function,
            default: entry =>
                entry.parent &&
                entry.parent.children.indexOf(entry) > 0 &&
                entry.parent.parent
        },
        roots: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            filter: [],
            focus: null,
            fieldsReport: null,
            entriesReport: null,
            searchString: "",
            showMenu: false,
            showColumns: false,
            multiselect: false,
            columns: JSON.parse(localStorage['pim-tree-main-columns'] || '[]'),
            imageOverAnchor: null,
            imageOverOpacity: 1,
            imageOverLink: null,
            showExportDialog: false,
        }
    },
    mounted() {
        window.tree = this
        document.addEventListener("keydown", this.handleKeyDown)
        this.$el.addEventListener("contextmenu", this.handleContextmenu)
        this.loadChildren(this.roots)
        if (this.mode == "compact")
            this.createChosen()
        this.prepareRoots()
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.handleKeyDown)
        this.$el.removeEventListener("contextmenu", this.handleContextmenu)
    },
    methods: {
        prepareRoots() {
            let focus = null
            let loop = entry => {
                if (entry.focused)
                    focus = entry
                else if (entry.children)
                    entry.children.forEach(loop)
            }
            this.roots.forEach(loop)
            this.focus = focus
            this.loadChildren(this.roots)
        },
        nextId() {
            return _(this.getAllEntries()).map(({id}) => parseInt(id)).max() + 1
        },
        getAllEntries() {
            let entries = []
            let loop = (entry) => {
                entries.push(entry)
                for (let child of entry.children || [])
                    if (!child.search || child.search.matched)
                        loop(child)
            }
            _.forEach(this.roots, loop)
            return entries
        },
        createChosen() {
            $(this.$refs.select)
                .chosen({width: "100%"})
                .change(() => {
                    let option = _(this.$refs.select.options)
                        .find(option => option.selected && !option.disabled)
                    if (option) {
                        option.selected = false
                        let focus = this.focus.children.find(
                            child => child.id == option.value)
                        if (focus)
                            this.focus = focus
                    }
                    option = _(this.$refs.select.options)
                        .find(option => option.disabled)
                    option.selected = true
                })
            $(this.$el)
                .find("pim-tree-entry-next .chosen-search-input")
                .attr("placeholder", this.placeholder)
        },
        async openEntry(entry, childrenType) {
            this.$set(entry, "opened", true)
            this.loadChildren(entry, childrenType)
            if (entry.children != null) {
                if (Object.isFrozen(entry.children))
                    this.$set(entry, "children", [...entry.children])
                this.loadChildren(entry.children)
            }
        }, 
        async closeEntry(entry) {
            this.$set(entry, "opened", false)
            let loop = entry => {
                if (entry.opened)
                    this.$set(entry, "opened", false)
                if (entry.selected)
                    this.$delete(entry, "selected")
                for (let child of entry.children || [])
                    loop(child)
            }
            for (let child of entry.children || [])
                loop(child)
        }, 
        async exportAttributes() {
            this.showExportDialog = true
            return
            let XLSX = await import("xlsx")
            let workbook = XLSX.utils.book_new()
            let rows = []
            let header = ["Storefront", "Level", "ID", "Name", "Parent ID", "Parent Name"]
                .concat(this.entriesReport.meta.vals.slice(5).map(({name}) => name))
                .concat(["Deleted"])
            rows.push(header)
            let getLevel = entry => {
                let level = -2
                while (entry) {
                    level += 1
                    entry = entry.parent
                }
                return level
            }
            let entries = []
            let loop = (entry) => {
                entries.push(entry)
                _.forEach(entry.children, loop)
            }
            _.forEach(this.catalog.children, loop)
            for (let entry of entries) {
                if (entry.type == 'category')
                    rows.push([
                        entry.brand,
                        getLevel(entry),
                        entry.id,
                        entry.name,
                        entry.parent?.id,
                        entry.parent?.name
                    ]
                    .concat(entry.attrs.map(({key, val}) => val))
                    .concat([""]))
            }
            let worksheet = XLSX.utils.aoa_to_sheet(rows)
            XLSX.utils.book_append_sheet(workbook, worksheet, "categories")
            XLSX.writeFile(workbook, "categories.xlsx")
        },
        importAttributes() {
            $(this.$refs.uploadInput).trigger("click")
        },
        handleFileUpload(e) {
            let file = e.target.files[0]
            this.$refs.uploadForm.reset()
            this.uploadFile(file);
        },
        async uploadFile(file) {
            var formData = new FormData()
            formData.append("file", file)

            let {rows} = await Promise.resolve($.ajax({
                url: "/import2",
                type: "POST",
                data: formData,
                processData: false,
                contentType: false
            }))

            let columns = {
                id: rows[0].indexOf("ID"),
                name: rows[0].indexOf("Name"),
            }
            for (let field of this.fields)
                columns[field.key] = rows[0].indexOf(field.name)

            let count = 0

            let entries = this.getAllEntries()

            for (let row of rows) {
                let entry = entries.find(entry => entry.id == row[columns.id])
                if (entry) {
                    for (let key of _.keys(columns)) {
                        let value = row[columns[key]]
                        if (value !== undefined) {
                            if (value == null)
                                value = ""
                            if (key == "name" && entry.name != value) {
                                this.$set(entry, "name", value)
                                count += 1
                            }
                            else if (key != "id") {
                                let attr = entry.attrs.find(attr => attr.key == key)
                                if (attr !== undefined && attr.val != value) {
                                    this.$set(attr, "val", value)
                                    count += 1
                                }
                            }
                        }
                    }
                }
            }
            if (count == 0)
                window.alert(`No changes detected.`)
            else    
                window.alert(`Applied ${count} changes.`)
        },
        handleContextmenu(e) {
            // e.preventDefault()
        },
        async handleKeyDown(e) {
            let focus = this.focus
            if (focus &&
                $(document.activeElement).closest(this.$refs.tree).length != 0 &&
                $(document.activeElement).closest(".pim-workflow").length == 0)
            {
                if (e.shiftKey) {
                    if (e.key == "ArrowUp" && this.canMoveUp(focus))
                        this.moveUp(focus)
                    if (e.key == "ArrowDown" && this.canMoveDown(focus))
                        this.moveDown(focus)
                    if (e.key == "ArrowLeft" && this.canMoveLeft(focus)) {
                        this.moveLeft(focus)
                        e.preventDefault()
                    }
                    if (e.key == "ArrowRight" && this.canMoveRight(focus)) {
                        this.moveRight(focus)
                        e.preventDefault()
                    }
                }
                else {
                    if (e.key == "ArrowUp" && this.prevEntry) {
                        this.focusEntry(this.prevEntry)
                        e.preventDefault()
                    }
                    if (e.key == "ArrowDown" && this.nextEntry) {
                        this.focusEntry(this.nextEntry)
                        e.preventDefault()
                    }
                    if (e.key == "ArrowLeft") {
                        this.$set(focus, "opened", false)
                        e.preventDefault()
                    }
                    if (e.key == "ArrowRight") {
                        this.openEntry(focus)
                        e.preventDefault()
                    }
                }
            }
        },
        moveUp() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            focus.parent.children.splice(i, 1)
            focus.parent.children.splice(i-1, 0, focus)
            Vue.nextTick(() => this.focusEntry(focus))
        },
        moveDown() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            focus.parent.children.splice(i, 1)
            focus.parent.children.splice(i+1, 0, focus)
            Vue.nextTick(() => this.focusEntry(focus))
        },
        moveLeft() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            focus.parent.children.splice(i, 1)
            let j = focus.parent.parent.children.indexOf(focus.parent)
            focus.parent.parent.children.splice(j+1, 0, focus)
            focus.parent = focus.parent.parent
            Vue.nextTick(() => this.focusEntry(focus))
        },
        moveRight() {
            let focus = this.focus
            let i = focus.parent.children.indexOf(focus)
            let parent = this.unfreeze(focus.parent.children[i-1])
            focus.parent.children.splice(i, 1)
            if (parent.children == null)
                this.$set(parent, "children", [])
            parent.children.push(focus)
            focus.parent = parent
            this.$set(parent, "opened", true)
            Vue.nextTick(() => this.focusEntry(focus))
        },
        unfreeze(entry) {
            if (Object.isFrozen(entry)) {
                let clone = _.clone(entry)
                if (entry.parent) {
                    let i = entry.parent.children.indexOf(entry)
                    entry.parent.splice(i, 1, clone)
                }
                if (clone.children) {
                    clone.children = clone.children.map(this.unfreeze)
                    for (let child of clone.children)
                        child.parent = clone
                }
            }
            else if (entry.children && Object.isFrozen(entry.children)) {
                this.$set(entry, "children", _.clone(entry.children))
            }
            return entry
        },
        focusEntry(entry) {
            if (this.$refs.entries)
                for (let component of this.$refs.entries)
                    if (component.entry == entry)
                        component.focus()
        },
        ensureVisible(entry) {
            entry.component.$el.scrollIntoView({
                alignToTop: false,
                scrollIntoViewOptions: {
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                }
            })
        },
        createSubEntry() {
            let focus = this.focus
            let type = this.classify(focus.type)
            let name = window.prompt(`Please enter a ${type} name:`)
            if (name) {
                let entry = {
                    id: this.nextId(),
                    name,
                    type,
                    rules: [],
                    parent: focus,
                    children: [],
                }
                focus.children.push(entry)
                this.$set(focus, "opened", true)
                Vue.nextTick(() => this.focusEntry(entry))
            }
        },
        createEntryAfter() {
            let focus = this.focus
            let type = this.classify(focus.parent?.type)
            let name = window.prompt(`Please enter a ${type} name:`)
            if (name) {
                let entry = {
                    id: this.nextId(),
                    name,
                    type,
                    rules: [],
                    parent: focus.parent,
                    children: [],
                }
                let i = focus.parent.children.indexOf(focus)
                focus.parent.children.splice(i+1, 0, entry)
                Vue.nextTick(() => this.focusEntry(entry))
            }
        },
        removeThisEntry() {
            let focus = this.focus
            if (window.confirm(`Are you sure you want to remove ${focus.type} ${focus.name}?`)) {
                let i = focus.parent.children.indexOf(focus)
                focus.parent.children.splice(i, 1)
                if (focus.children.length) {
                    let subType = this.classify(focus.type)
                    if (window.confirm(`Do you want to move its ${pluralize(subType)} to the parent ${focus.parent.type}?`)) {
                        for (let entry of focus.children) {
                            focus.parent.children.push(entry)
                        }
                    }
                }
            }
        },
        formatColumn(key, val) {
            if (val == null)
                return null
            if (_.isBoolean(val))
                return val ? "yes" : "no"
            if (_.isNumber(val))
                return Number(val).toLocaleString()
            let type = this.types[key]
            if (type == "date")
                return moment(new Date(val)).format("MM-DD-YYYY")
            if (type == "datetime")
                return moment(new Date(val)).format("MM-DD-YYYY HH:mm:ss")
            return val
        }
    },
    computed: {
        focusPath() {
            let entry = this.focus || this.roots[0]
            let path = []
            while (entry) {
                path.splice(0, 0, entry)
                entry = entry.parent || entry.shadow?.parent
            }
            return path
        },
        placeholder() {
            if (this.focus) {
                let childrenType = this.classify(this.focus.type)
                if (childrenType)
                    return `Select ${this.addArticle(childrenType)}`
            }
            return ""
        },
        types() {
            return _(this.fields).map(field => [field.key, field.type]).fromPairs().value()
        },
        fuse() {
            return new Fuse(this.entries, this.search)
        },
        // canMoveUp() {
        //     return this.focus &&
        //         this.focus.parent &&
        //         this.focus.parent.children.indexOf(this.focus) > 0
        // },
        // canMoveDown() {
        //     return this.focus &&
        //         this.focus.parent &&
        //         this.focus.parent.children.indexOf(this.focus) < this.focus.parent.children.length - 1
        // },
        // canMoveLeft() {
        //     return this.focus &&
        //         this.focus.parent &&
        //         this.focus.parent.parent &&
        //         this.focus.parent.parent.parent
        // },
        // canMoveRight() {
        //     return this.canMoveUp && this.focus.parent.parent
        // },
        visibleEntries() {
            let visibleEntries = []
            let loop = (entry) => {
                visibleEntries.push(entry)
                if (entry.children && entry.children.length &&
                    (entry.opened || entry.search && entry.search.matched))
                {
                    if (entry.mode == "table") {
                        visibleEntries.push({
                            type: "table",
                            entries: entry.children.filter(child =>
                                !child.search || child.search.matched),
                            entry
                        })
                    }

                    else if (entry.mode == "diagram") {
                        visibleEntries.push({
                            type: "diagram",
                            entry
                        })
                    }

                    else if (entry.mode == "chart") {
                        visibleEntries.push({
                            type: "chart",
                            entries: entry.children.filter(child =>
                                !child.search || child.search.matched),
                            entry
                        })
                    }

                    else {
                        let hasMixedTypes = false
                        let lastLoadedType = null
                        let lastLoadedAt = null
                        for (let child of entry.children) {
                            if (child.children) {
                                if (lastLoadedAt == null) {
                                    lastLoadedAt = child.loadedAt
                                    lastLoadedType = child.childrenType
                                }
                                else {
                                    if (lastLoadedType != null && lastLoadedType != child.childrenType)
                                    {
                                        hasMixedTypes = true
                                    }
                                    if (child.loadedAt > lastLoadedAt) {
                                        lastLoadedAt = child.loadedAt
                                        lastLoadedType = child.childrenType
                                    }
                                }
                            }
                        }

                        if (lastLoadedAt != null && hasMixedTypes)
                            visibleEntries.push({
                                type: "reset-children",
                                entry,
                                childrenType: lastLoadedType,
                            })

                        let cursor = visibleEntries.length

                        let tiles = entry.mode == "tiles" ? [] : null

                        let page = entry.page || 1
                        let take = entry.take || 20
                        let i = 0
                        for (let child of entry.children) {
                            if (!child.search || child.search.matched) {
                                if (i >= (page - 1) * take && i < page * take) {
                                    if (tiles)
                                        tiles.push(child)
                                    else
                                        loop(child)
                                }
                                i += 1
                            }
                        }
                        let size = i
                        if (tiles && size > 0) {
                            visibleEntries.push({
                                type: "tiles",
                                tiles,
                            })
                        }
                        if (size > 1 && this.multiselect) {
                            visibleEntries.splice(cursor, 0, {
                                type: "multiselect",
                                entry,
                            })
                        }
                        if (size > take) {
                            visibleEntries.splice(cursor, 0, {
                                type: "paginator",
                                entry,
                                take,
                                page,
                                size,
                                pages: Math.floor((size + take - 1) / take),
                            })
                        }
                    }
                }
            }
            _.forEach(this.roots, loop)
            return visibleEntries
        },
        prevEntry() {
            let i = this.visibleEntries.indexOf(this.focus)
            if (i == -1)
                return this.visibleEntries[this.visibleEntries.length-1]
            while (i > 0) {
                i -= 1
                let entry = this.visibleEntries[i]
                if (entry.type != "paginator" &&
                    entry.type != "multiselect")
                    return entry
            }
            return null
        },
        nextEntry() {
            let i = this.visibleEntries.indexOf(this.focus)
            if (i == -1)
                return this.visibleEntries[0]
            while (i < this.visibleEntries.length - 1) {
                i += 1
                let entry = this.visibleEntries[i]
                if (entry.type != "paginator" &&
                    entry.type != "multiselect")
                    return entry
            }
            return null
        },
        selection() {
            if (this.multiselect) {
                let selection = []
                let loop = (entry) => {
                    if (entry.selected)
                        selection.push(entry)
                    if (entry.children)
                        for (let child of entry.children)
                            loop(child)
                }
                for (let root of this.roots)
                    loop(root)
                return selection
            }
            else return null
        },
    },
    watch: {
        mode() {
            if (this.mode == "compact")
                Vue.nextTick(() => this.createChosen())
        },
        roots(oldRoots, newRoots) {
            if (oldRoots != newRoots)
                this.prepareRoots()
        },
        multiselect() {
            if (!this.multiselect)
                for (let entry of this.entries)
                    if (entry.selected)
                        this.$delete(entry, "selected")
        },
        placeholder() {
            $(this.$el)
                .find(".pim-tree-entry-next .chosen-search-input")
                .attr("placeholder", this.placeholder)
        },
        selection() {
            this.$emit("selection", this.selection)
        },
        focus: {
            deep: true,
            handler(newFocus, oldFocus) {
                if (this.mode == "compact")
                    $(this.$refs.select).trigger("chosen:updated")
                if (newFocus == oldFocus)
                    return
                this.$emit("focus", newFocus)

                Vue.nextTick(() => {
                    if (oldFocus)
                        this.$delete(oldFocus, "focused")
                    if (newFocus)
                        this.$set(newFocus, "focused", true)
                })
                localStorage["pim-tree-focus"] = newFocus?.id
            }
        },
        columns() {
            localStorage["pim-tree-main-columns"] = JSON.stringify(this.columns)
        },
        searchString() {
            for (let entry of this.entries) {
                if (entry.search)
                    this.$delete(entry, "search")
            }
            if (this.searchString) {
                let matchedEntries = this.fuse.search(this.searchString)

                for (let matchedEntry of matchedEntries) {
                    let matches = matchedEntry.matches
                    for (let match of matches) {
                        if (match.indices.length > 1)
                            match.indices = match.indices.filter(([s,e]) => !(s == 0 && e == match.value.length - 1))
                    }
                    this.$set(matchedEntry.item, "search", {matched: true, matches: matches})
                    let parentEntry = matchedEntry.item.parent
                    while (parentEntry) {
                        if (!parentEntry.search)
                            this.$set(parentEntry, "search", {matched: true})
                        parentEntry = parentEntry.parent
                    }
                }
                for (let entry of this.entries) {
                    if (!entry.search)
                        this.$set(entry, "search", {matched: false})
                }
            }
        }
    },
}
</script>
<style>
.pim-tree .chosen-container {
    /*width: auto!important;*/
}
.pim-tree .chosen-container-single .chosen-single abbr {
    right: 22px;
}
.pim-tree .chosen-container-multi .chosen-choices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2px;
}
.pim-tree .chosen-container-multi .chosen-choices li.search-choice,
.pim-tree .chosen-container-multi .chosen-choices li.search-field {
    float: none;
    margin: 2px;
}
.pim-tree .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    margin-top: -2px;
    margin-bottom: -2px;
}
.pim-tree .chosen-container .chosen-drop {
    width: auto;
    border-top: 1px solid rgb(88,151,251);
    margin-top: -1px;
}
.pim-tree .chosen-container .chosen-results li {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pim-tree-body {
    display: flex;
    flex-direction: row;
    height: 1px;
    flex-grow: 1;
}
.pim-tree-main {
    position: relative;
}
.pim-tree-main .gp-news {
    margin-top: -5px;
    margin-right: -5px;
}
.pim-tree-foot {
    background: white;
    z-index: 1;
    flex-basis: 31px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0px #00000020
}
.my-dark-theme .pim-tree-foot {
    background: #222;
}
.pim-tree-side-live {
    margin-top: 20px;
}
.pim-tree-side {
    box-shadow: 0 0 10px 0px #00000020
}
.pim-tree-side .form-inline .form-group {
    display: flex;
    width: 100%;
}
.pim-tree-side .form-inline label {
    flex-basis: 1px;
    flex-grow: 1;
    display: block;
}
.pim-tree-foot.opened {
    flex-basis: 50%;
}
.pim-tree-foot-tabs {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    line-height: 30px;
    border-top: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
}
.my-dark-theme .pim-tree-foot-tabs {
    border-top: 1px solid var(--light);
    border-bottom: 1px solid var(--light);
}
.pim-tree-foot-tabs {
    background-image: linear-gradient(to bottom, #eee, white 5%, white 60%, #eee);
}
.pim-tree-side-tabs {
    background-image: linear-gradient(to right, #eee, white 5%, white 60%, #eee);
}
.my-dark-theme .pim-tree-foot-tabs {
    background: #333;
}
.my-dark-theme .pim-tree-side-tabs {
    background: #333;
}
.pim-tree-foot-tabs > ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}
.pim-tree-foot-tabs > ul > li {
    white-space: nowrap;
    border-right: 1px solid var(--dark);
}
.pim-tree-foot-tabs > ul > li.active {
    background: white;
    border-top: 1px solid var(--dark);
    margin-top: -1px;
    margin-bottom: -1px;
}
.my-dark-theme .pim-tree-foot-tabs > ul > li.active {
    border-top: 1px solid var(--light);
    border-left: 1px solid var(--light);
    border-right: 1px solid var(--light);
    background: #222;
}
.pim-tree-foot-tabs > ul > li > a {
    padding: 0 15px;
    color: var(--dark)
}
.my-dark-theme .pim-tree-foot-tabs > ul > li > a {
    color: var(--light)
}
.pim-tree-foot-tabs > ul > li.active > a {
    color: #222;
}
.my-dark-theme .pim-tree-foot-tabs > ul > li.active > a {
    color: white;
}
.pim-tree-foot-tabs > ul > li > a:hover {
    text-decoration: none;
}
.pim-tree-foot-tabs svg {
    width: 20px;
    margin-top: -2px;
    margin-right: 6px;
}
.pim-tree-foot {
    position: relative;
}
.pim-tree-foot-body {
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
}
.pim-tree-foot-calendar + .pim-tree-foot-body {
    top: 70px;
}
.pim-tree-foot-body > p {
    padding: 15px;
    color: var(--dark);
    font-size: 0.95em;
}
.my-dark-theme .pim-tree-foot-body > p {
    color: var(--light);
}
.pim-tree-foot-body .plain-table {
    flex-basis: 1px;
    flex-grow: 1;
    padding: 10px 20px;
    overflow-y: auto;
}
.pim-tree-foot-body .my-column-dim {
    display: none;
}
.pim-tree-foot-body .gp-section-columns {
    flex-basis: 300px;
    padding: 10px;
    padding-left: 0;
}
.pim-tree-foot-body .gp-section-columns {
    display: flex;
    flex-direction: column;
}
.pim-tree-foot-body .gp-section-columns > * {
    margin-bottom: 0!important;
}
.pim-tree-foot-body .gp-section-columns-actions {
    border-top: 1px solid var(--dark);
    margin-right: 0;
    padding-top: 10px;
}
.gp-section-columns-actions > *:last-child {
    margin-right: 0;
}
.pim-tree-foot-body .gp-section-columns ul {
    list-style: none;
    border: none;
    margin: 0;
    font-size: 0.9em;
}
.pim-tree-foot-body .gp-section-columns ul input {
    transform: translate(0, -1px);
}
.pim-tree-head p {
    color: var(--dark);
    margin-bottom: 0;
    font-size: 0.95em;
    line-height: 1.4;
}
.my-dark-theme .pim-tree-head p {
    color: var(--light);
}
.pim-tree-head .gp-check {
    color: var(--dark);
    font-size: 0.95em;
}
.my-dark-theme .pim-tree-head .gp-check {
    color: var(--light);;
}
.pim-tree-head .gp-check input {
    transform: translate(0, -1px);
}
.pim-tree-main {
    overflow-y: auto;
    padding: 15px 20px;
    margin-right: 0;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 400px);
}
.matched {
    color:var(--red);
    text-decoration: underline;
}
.pim-tree input[type="search"] {
    margin-bottom: 10px;
}
.pim-tree-move {
    float: right;
    border: 2px solid var(--light);
    border-radius: 50%;
    margin: 10px;
    position: sticky;
    top: 10px;
    background: #ffffffaa;
    z-index: 2;
    display: flex;
    flex-direction: column;
    line-height: 18px;
    padding: 4px;
}
.my-dark-theme .pim-tree-move {
    border: 2px solid var(--dark);
    background: #00000022;
}
.pim-tree-move > * {
    display: flex;
    height: 22px;
}
.pim-tree-move > * > * {
    width: 22px;
}
.pim-tree-move svg {
    width: 18px;
    height: 18px;
    margin: 2px;
    display: block;
}
.pim-tree-entry.focused
> .pim-tree-entry-head
> .pim-tree-entry-name {
    background: var(--light);
    outline: none;
    box-shadow: 0px 0px 0px 2px var(--cyan);
}
.my-dark-theme .pim-tree-entry.focused
> .pim-tree-entry-head
> .pim-tree-entry-name {
    background: var(--dark);
}
.pim-tree a.disabled {
    pointer-events: none;
    color: var(--dark);
    opacity: 0.7;
}
.my-dark-theme .pim-tree a.disabled {
    color: var(--light);
}
.pim-tree-entry-desc {
    color: var(--dark);
}
.my-dark-theme .pim-tree-entry-desc {
    color: var(--light);
}
.pim-tree-entry-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
    padding-right: 2px;
    margin: -1px;
}
.pim-tree-entry-head .gp-check {
    transform: translate(0, 1px);
    margin-left: 6px;
    margin-right: -6px;
}
.pim-tree-entry-name {
    padding: 0 4px;
    cursor: pointer;
    position: relative;
    margin-left: 1px;
}
.pim-tree-open svg {
    width: 18px;
    height: 18px;
    display: inline-block;
}
.pim-tree-entry {
    position: relative;
}
.pim-tree-open {
    position: absolute;
    top: -1px;
    left: -20px;
}
.pim-tree-menu {
    font-size: 1em;
    max-width: 300px;
    white-space: nowrap;
}
.pim-tree-menu label {
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    text-overflow: ellipsis;
}
.pim-tree-menu ul {
    list-style: none;
    margin: 0 -8px;
    padding: 0;
}
.pim-tree-menu li a {
    display: block;
    padding: 2px 10px;
}
.pim-tree-menu li a svg {
    width: 17px;
    height: 17px;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    margin-right: 4px;
}
.pim-tree-search {
    width: 300px;
}
.pim-tree input[type="search"] {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--gray);
    background: transparent;
}
.pim-tree input[type="search"]:focus {
    outline: none;
    box-shadow: none;
    /*color: white;*/
    background: transparent;
    border-bottom-color: var(--dark);
}
.my-dark-theme .pim-tree input[type="search"]:focus {
    border-bottom-color: white;
}
.pim-tree ::-webkit-search-cancel-button {
    position: relative;
    right: -10px;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-size: 18px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(80,80,80)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
}
.pim-tree-head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}
.pim-tree-side {
    flex-basis: 450px;
    display: flex;
    flex-direction: row;
}
.pim-tree-side-main {
    padding: 15px 20px;
}
.pim-tree-side-main > p {
    color: var(--dark);
    font-size: 0.95em;
    line-height: 1.4;
}
.my-dark-theme .pim-tree-side-main > p {
    color: var(--light);
}
.pim-tree-side-tabs {
    width: 32px;
    border-left: 1px solid var(--dark);
    border-right: 1px solid var(--dark);
}
.my-dark-theme .pim-tree-side-tabs {
    border-left: 1px solid var(--light);
    border-right: 1px solid var(--light);
}
.pim-tree-side-tabs > ul {
    background-color: white;
    width: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
.my-dark-theme .pim-tree-side-tabs > ul {
    background-color: var(--dark);
}
.pim-tree-side-tabs > ul > li {
    display: inline-block;
    border-bottom: 1px solid var(--dark);
}
.my-dark-theme .pim-tree-side-tabs > ul > li {
    border-bottom: 1px solid var(--light);
}
.pim-tree-side-tabs > ul > li > a {
    display: block;
    padding-top: 100%;
    vertical-align: top;
}
.pim-tree-side-tabs > ul > li > a > span {
    color: #444;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    transform-origin: 0 0;
    white-space: nowrap;
    transform: rotate(-90deg) translate(-14px, -1px);
}
.pim-tree-side {
    position: relative;
}
.pim-tree-side-main {
    position: absolute;
    top: 0;
    left: 32px;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
}
.pim-tree-side-tabs > ul {
    background: transparent;
}
.pim-tree-side-tabs > ul > li > a {
    /*background: var(--light);*/
    background: transparent;
}
.pim-tree-side-tabs > ul > li > a > span {
    color: var(--dark);
}
.my-dark-theme .pim-tree-side-tabs > ul > li > a > span {
    color: var(--light);
}
.pim-tree-side-tabs > ul > li.active {
    background: white;
}
.my-dark-theme .pim-tree-side-tabs > ul > li.active {
    background: #222;
}
.pim-tree-side-tabs > ul > li.active > a > span {
    color: #222
}
.my-dark-theme .pim-tree-side-tabs > ul > li.active > a > span {
    color: white;
}
.pim-tree-side-main .gp-stored {
    top: -16px;
    margin-top: -16px;
}
.pim-tree-side-main .plain-table-body {
    overflow-x: auto;
    margin: 0 -20px;
    width: calc(100% + 40px);
}
.pim-tree-side-main .plain-table-body > table {
    padding: 0 20px;
}
.pim-filter-popup {
    width: 320px;
    padding: 10px;
}
.pim-filter-popup-notes {
    max-width: initial;
}
.pim-tree-side-main svg {
    width: 18px;
    height: 18px;
}
.pim-tree-rules {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}
.pim-tree-rule-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pim-tree-rule-head:not(:last-child) {
    padding-bottom: 4px;
    border-bottom: 1.5px solid var(--cyan);
}
.pim-tree-rule-body {
    margin-left: 9px;
    padding: 10px 15px;
    padding-bottom: 0;
    border-left: 1.5px solid var(--cyan);
}
.pim-tree-side-main input,
.pim-tree-side-main select,
.pim-tree-side-main textarea {
    color: black;
}
.pim-tree-side-main .form-group > label {
    margin-bottom: 5px;
    color: var(--dark);
}
.my-dark-theme .pim-tree-side-main .form-group > label  {
    color: var(--light);
}
.my-dark-theme ::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
}
.my-dark-theme .pim-tree-side-main input,
.my-dark-theme .pim-tree-side-main select,
.my-dark-theme .pim-tree-side-main textarea {
    background: transparent;
    color: white;
    border-bottom: 1px solid var(--light);
    border-radius: 0;
}
.pim-tree-side-main .form-group > label:first-child:last-child {
    display: block;
    margin-bottom: -10px;
}
.pim-tree-rule-head svg {
    transform: translate(0, -1px);
}
.pim-tree-rule-body .form-group > .gp-check {
    font-size: 0.9em;
    margin-left: 6px;
    border-left: 1.5px solid var(--cyan);
    padding-left: 37px;
}
.pim-tree-rule-body .form-group > .gp-check input {
    margin-top: 4px;
}
.pim-tree-rule-body .pim-filter {
    margin-left: 6px;
    padding: 4px 0;
    padding-left: 15px;
    border-left: 1.5px solid var(--cyan);
}
.pim-tree-side-main .editable:hover {
    text-decoration: underline;
}
.pim-tree-rules-actions {
    margin-top: 15px;
    margin-right: -10px;
    display: flex;
}
.pim-tree-rules-actions > * {
    margin-right: 10px;
}
.pim-hierarchies a > .feather-icon:not(:last-child) svg {
    margin-top: -4px;
}
.plain-table-body img {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: -4px 0;
    vertical-align: top;
    object-fit: contain;
    /*outline: 1px solid red;*/
}
.plain-table-presented-row img {
    max-width: 200px;
    max-height: 200px;
}
.plain-table-presented-row th,
.plain-table-presented-row td {
    border: none!important;
}
.plain-table-presented-row th:after,
.plain-table-presented-row td:after {
    content: none!important;
}
.plain-table-presented-row td.my-column-tagged > span,
.plain-table-presented-row td.my-column-string > span {
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
}
.plain-table-tile-size {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    margin-right: -10px;
    align-items: center;
    margin-left: 1px;
}
.plain-table-tile-size > * {
    margin: 0;
    padding: 0;
    margin-right: 4px;
    line-height: 20px;
    color: var(--cyan);
}
.plain-table-tile-size svg {
    width: 18px;
    height: 18px;
    display: block;
}
.plain-table-tile-size input {
    padding: 2px 4px;
    height: auto;
    width: 60px;
    text-align: right;
}
.plain-table-manage-table ul > li > a svg {
    display: inline-block;
    transform: translate(0, 2px);
}
.plain-table-tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-right: -10px;
    margin-bottom: -10px;
    --tile-padding: 10px;
    font-size: 0.95em;
    transition: opacity 0.1s;
}
.plain-table-tile {
    flex-basis: calc(var(--tile-size) + var(--tile-padding) * 2 + 2px);
    padding: var(--tile-padding) ;
    border: 1px solid var(--gray);
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}
.plain-table-tile img {
    width: calc(var(--tile-size) + var(--tile-padding) * 2 - 2px);
    height: calc(var(--tile-size) + var(--tile-padding) * 2 - 2px);
    margin: 0 calc(1px - var(--tile-padding));
}
.plain-table-tile td:nth-last-child(2) img {
    margin-bottom: calc(0px - var(--tile-padding));
}
.plain-table-tile > a {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    line-height: 18px;
    z-index: 1;
}
.plain-table-tile:hover > a {
    display: block;
}
.plain-table-tile > a svg {
    display: block;
    width: 18px!important;
    height: 18px!important;
}
.plain-table-tile > a + td:not(.my-column-editable) {
    margin-top: -5px;
}
.plain-table-tile th,
.plain-table-tile td {
    padding: 2px 0;
    position: relative;
    line-height: 21px;
    display: block;
    width: var(--tile-size);
}
.plain-table-tile td {
    min-height: 25px;
}
.plain-table-tile td.my-column-editable {
    padding: 2px 5px;
}
.plain-table-tile label {
    margin: 0;
    position: absolute;
    left: 0;
    z-index: -1;
    color: #aaa;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.plain-table-tile td.my-column-editable label {
    padding-left: 5px;
    color: var(--cyan);
    opacity: 0.5;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.plain-table-tile td.my-column-editable.editable input {
    font-size: 1em;
}
.plain-table-tile td.my-column-editable.editable > span:not(:empty) + span {
    margin-top: -21px;
}
.plain-table-tile label + span {
    background: white;
}
.my-dark-theme .plain-table-tile label + span {
    background: #222;
}
.pim-tree-side .form-group {
    margin-bottom: 8px;
}
.pim-tree-side .form-group > label {
    font-size: 0.9em;
    margin-bottom: 3px;
}
.pim-tree-side .edited {
    color: var(--red);
}
.pim-tree-side .mixed svg,
.pim-tree-side .edited svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: top;
    margin-top: -2px;
    margin-left: -2px;
}
.pim-tree-side .edited svg {
    color: var(--red);
}
.pim-tree-side .mixed {
    color: var(--teal);
}
.pim-tree-attributes-actions {
    background: white;
    position: sticky;
    bottom: -15px;
    padding: 10px 0;
}
.my-dark-theme .pim-tree-attributes-actions {
    background: #222;
}
.pim-tree-entries > table {
    max-width: 100%;
}
.pim-tree-entries > table > tbody > tr:first-child > td {
    padding-top: 10px;
}
.pim-tree-entries > table > tbody > tr > td:not(:first-child) {
    padding-left: 6px;
}
.pim-tree-entries > table > tbody > tr > td:not(:first-child) svg {
    position: absolute;
    top: 4px;
    left: -5px;
    width: 16px;
    height: 16px;
}
.pim-tree-entries > table > tbody > tr > td {
    position: relative;
    padding: 0 px;
    white-space: nowrap;
}
.pim-tree-entries > table > tbody > tr > td:not(:first-child) {
    color: var(--gray);
    /*border-left: 1px solid var(--gray);*/
}
.pim-tree-entries > table > tbody > tr > td > span {
    display: block;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    text-overflow: ellipsis;
    font-size: 0.95em;
}
.pim-tree-foot-calendar {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
    padding: 8px 20px;
    vertical-align: top;
    user-select: none;
}
.pim-tree-foot-calendar select {
    display: inline-block;
    width: 80px;
    border: none;
    border-bottom: 1px solid var(--dark);
    border-radius: 0;
    padding: 2px;
    margin: -1px;
    height: 24px;
    font-size: 14px;
    color: #222;
}
.pim-tree-foot-calendar svg {
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin-top: 1px;
}
.pim-tree-foot-calendar .day {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    border: 1px solid var(--gray);
    background: white;
    margin-left: 4px;
}
.my-dark-theme .pim-tree-foot-calendar .day {
    background: #222;
}
.pim-tree-foot-calendar .month {
    margin: 0 4px;
}
.pim-tree-foot-calendar .day[data-day="0"],
.pim-tree-foot-calendar .day[data-day="6"] {
    /*color: white;*/
    background: var(--light);
}
.pim-tree-foot-calendar .day.holiday {
    color: var(--red);
    border-color: var(--red);
    background: #e74c3c22;
}
.pim-tree-foot-calendar .day:focus {
    outline: none;
}
.pim-tree-foot-calendar .day.active {
    border-color: var(--cyan);
    outline: 1px solid var(--cyan);
    transform: scale(1.2);
}
.pim-tree-entry-error {
    color: var(--red);
}
.pim-tree.compact > ul {
    list-style: none;
    padding: 1px;
    margin: 0;
    display: flex;
}
.pim-tree.compact > ul > li {
    margin-right: 10px;
}
.pim-tree.compact .pim-tree-entry-head {
    padding: 2px;
}
.pim-tree.compact .pim-tree-entry.focused > .pim-tree-entry-head > .pim-tree-entry-name {
    padding-left: 8px;
    padding-right: 8px;
}
.pim-tree-entry-close svg {
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin-top: 1.5px;
    margin-right: -6px
}
.pim-tree.compact .chosen-container .chosen-single {
    background: transparent;
    box-shadow: none;
    border: none;
}
.pim-tree-open  {
    user-select: none;
}
</style>