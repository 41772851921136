const utils = require('../my-utils');

module.exports.makeGQRequest = (query, url) => {
  const reqUrl = url || '/graphql';

  try {
    return fetch(reqUrl, {
      method: 'POST',
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .catch(() => {
        const notification = {
          text: 'An error occurred while executing the request',
          type: 'error',
          id: utils.randomId(),
          created: Date.now(),
        };

        return notification;
      });
  } catch (error) {
    console.warn(error);
  }

  return undefined;
};
