const component = require('../../input/endings.vue').default;

module.exports = () => {
  const valueToInput = (x) => x;
  const inputToValue = (x) => x;
  const display = (x) => x.join(', ');

  return {
    component,
    valueToInput,
    inputToValue,
    display,
    suffix: '',
    prefix: '',
  };
};
