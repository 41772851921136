<template>
    <div ref="map" class="my-leaflet"/>
</template>
<script>

/*
maxWidth    Number  300 Max width of the popup, in pixels.
minWidth    Number  50  Min width of the popup, in pixels.
maxHeight   Number  null    If set, creates a scrollable container of the given height inside a popup if its content exceeds it.
autoPan Boolean true    Set it to false if you don't want the map to do panning animation to fit the opened popup.
autoPanPaddingTopLeft   Point   null    The margin between the popup and the top left corner of the map view after autopanning was performed.
autoPanPaddingBottomRight   Point   null    The margin between the popup and the bottom right corner of the map view after autopanning was performed.
autoPanPadding  Point   Point(5, 5) Equivalent of setting both top left and bottom right autopan padding to the same value.
keepInView  Boolean false   Set it to true if you want to prevent users from panning the popup off of the screen while it is open.
closeButton Boolean true    Controls the presence of a close button in the popup.
autoClose   Boolean true    Set it to false if you want to override the default behavior of the popup closing when another popup is opened.
closeOnEscapeKey    Boolean true    Set it to false if you want to override the default behavior of the ESC key for closing of the popup.
closeOnClick    Boolean *   Set it if you want to override the default behavior of the popup closing when user clicks on the map. Defaults to the map's closePopupOnClick option.
className   String  ''  A custom CSS class name to assign to the popup.
*/

module.exports = {
    mixins: [
        require("./data.js"),
    ],
    props: {
        preferCanvas:           { type: Boolean, default: false },
        attributionControl:     { type: Boolean, default: false },
        zoomControl:            { type: Boolean, default: false },
        closePopupOnClick:      { type: Boolean, default: false },
        zoomSnap:               { type: Number, default: 1 },
        zoomDelta:              { type: Number, default: 1 },
        trackResize:            { type: Boolean, default: true },
        boxZoom:                { type: Boolean, default: true },
        doubleClickZoom:        { type: [Boolean, String], default: true },
        dragging:               { type: Boolean, default: true },
        center:                 { type: [Array, Object] },
        zoom:                   { type: Number },
        minZoom:                { type: Number },
        layers:                 { type: Array, default: () => [] },
        maxBounds:              { type: Array },
        zoomAnimation:          { type: Boolean, default: true },
        zoomAnimationThreshold: { type: Number, default: 4 },
        fadeAnimation:          { type: Boolean, default: true },
        markerZoomAnimation:    { type: Boolean, default: true },
        transform3DLimit:       { type: Number, default: Math.pow(2,23) },
        inertia:                { type: Boolean },
        inertiaDeceleration:    { type: Number, default: 3000 },
        inertiaMaxSpeed:        { type: Number, default: Infinity },
        easeLinearity:          { type: Number, default: 0.2 },
        worldCopyJump:          { type: Boolean, default: false },
        maxBoundsViscosity:     { type: Number, default: 0 },
        keyboard:               { type: Boolean, default: true },
        keyboardPanDelta:       { type: Number, default: 80 },
        scrollWheelZoom:        { type: [Boolean, String], default: true },
        wheelDebounceTime:      { type: Number, default: 40 },
        wheelPxPerZoomLevel:    { type: Number, default: 60 },
        tap:                    { type: Boolean, default: true },
        tapTolerance:           { type: Number, default: 15 },
        touchZoom:              { type: [Boolean, String] },
        bounceAtZoomLimits:     { type: Boolean, default: true },
        tiles: {
            type: Object,
            default: () => ({
                url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
                maxZoom: 18,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                // id: 'mapbox/streets-v11',
                // id: "mapbox/outdoors-v11",
                // id: "mapbox/dark-v10",
                id: "mapbox/light-v10",
                tileSize: 512,
                zoomOffset: -1
            })
        },
        markers:       { type: Array, default: () => [] },
        imageOverlays: { type: Array, default: () => [] },
        videoOverlays: { type: Array, default: () => [] },
        geoJSONLayers: { type: Array, default: () => [] },

        transform:     { type: [String, Function] },
    },
    mounted() {
        this.mounted = true
        this.init()
        this.resizeSensor = new ResizeSensor(this.$refs.map, () => {
            this.map?.invalidateSize()
        })

    },
    beforeDestroy() {
        this.term()
        this.resizeSensor.detach()
        this.mounted = false
    },
    watch: {
        options() {
            if (this.map) {
                this.term()
                this.init()
            }
        },
        markers() {
            if (this.map) {
                this.termMarkers()
                this.initMarkers()
            }
        },
        imageOverlays() {
            if (this.map) {
                this.termImageOverlays()
                this.initImageOverlays()
            }
        },
        videoOverlays() {
            if (this.map) {
                this.termVideoOverlays()
                this.initVideoOverlays()
            }
        },
        geoJSONLayers() {
            if (this.map) {
                this.termGeoJSONLayers()
                this.initGeoJSONLayers()
            }
        },
        objects() {
            if (this.map) {
                this.termObjects()
                this.initObjects()
            }
        },
    },
    computed: {
        objects() {
            let objects = []
            if (this.report != null) {
                let rows = this.report.rows
                let transform = this.transform
                if (_.isString(transform)) {
                    try {
                        transform = eval(transform)
                    }
                    catch (ex) {
                        console.warn(transform, ex)
                    }
                }
                if (_.isFunction(transform)) {
                    try {
                        rows = transform.call(this, rows)
                    }
                    catch (ex) {
                        console.warn("cannot transform objects", ex)
                    }
                }
                if (_.isArray(rows))
                    for (let row of rows)
                        if (_.isArray(row))
                            objects.push({
                                id: row[0],
                                type: row[1],
                                options: row[2]
                            })
                        else if (_.isPlainObject(row))
                            objects.push(row)
            }
            return objects
        },
        options() {
            return {
                preferCanvas: this.preferCanvas,
                attributionControl: this.attributionControl,
                zoomControl: this.zoomControl,
                closePopupOnClick: this.closePopupOnClick,
                zoomSnap: this.zoomSnap,
                zoomDelta: this.zoomDelta,
                trackResize: this.trackResize,
                boxZoom: this.boxZoom,
                doubleClickZoom: this.doubleClickZoom,
                dragging: this.dragging,
                center: this.center,
                zoom: this.zoom,
                minZoom: this.minZoom,
                layers: this.layers,
                maxBounds: this.maxBounds,
                zoomAnimation: this.zoomAnimation,
                zoomAnimationThreshold: this.zoomAnimationThreshold,
                fadeAnimation: this.fadeAnimation,
                markerZoomAnimation: this.markerZoomAnimation,
                transform3DLimit: this.transform3DLimit,
                inertia: this.inertia,
                inertiaDeceleration: this.inertiaDeceleration,
                inertiaMaxSpeed: this.inertiaMaxSpeed,
                easeLinearity: this.easeLinearity,
                worldCopyJump: this.worldCopyJump,
                maxBoundsViscosity: this.maxBoundsViscosity,
                keyboard: this.keyboard,
                keyboardPanDelta: this.keyboardPanDelta,
                scrollWheelZoom: this.scrollWheelZoom,
                wheelDebounceTime: this.wheelDebounceTime,
                wheelPxPerZoomLevel: this.wheelPxPerZoomLevel,
                tap: this.tap,
                tapTolerance: this.tapTolerance,
                touchZoom: this.touchZoom,
                bounceAtZoomLimits: this.bounceAtZoomLimits,
            }
        }
    },
    methods: {
        term() {
            this.map?.remove()
            delete this.map
        },
        async init() {
            if (!this.mounted)
                return

            await import("./leaflet.js")

            this.map = L.map(this.$refs.map).setView(this.center, this.zoom)
            let tiles = L.tileLayer(this.tiles.url, _.omit(this.tiles, "url")).addTo(this.map)
            this.initMarkers()
            this.initImageOverlays()
            this.initVideoOverlays()
            this.initGeoJSONLayers()
            this.initObjects()

            window.map = this.map
        },
        newObject(type, options) {
            switch (type) {
                case "Marker":
                    return this.newMarker(options)
                case "ImageOverlay":
                    return this.newImageOverlay(options)
                case "VideoOverlay":
                    return this.newVideoOverlay(options)
                case "GeoJSONLayer":
                    return this.newGeoJSONLayer(options)
                case "SVGOverlay":
                    return this.newSVGOverlay(options)
                default:
                    throw `unknown object type ${type}`
            }
        },
        initObjects() {
            activeObjects = []
            for (let {id, type, options} of this.objects) {
                try {
                    activeObjects.push(this.newObject(type, options))
                }
                catch (ex) {
                    console.warn("cannot create", type, options, ex)
                }
            }
            this.activeObjects = activeObjects
        },
        termObjects() {
            for (let object of this.activeObjects || []) {
                object.remove()
            }
            delete this.activeObjects
        },
        newPopup(object, options) {
            let popup = L.popup(options)
            if (options.point)
                popup.setLatLng(options.point)
            if (options.content)
                popup.setContent(options.content)
            object.bindPopup(popup)
        },
        newTooltip(object, options) {
            object.bindTooltip(options.content, options)
        },
        newMarker(options) {
            options = _.clone(options)
            if (options.icon && options.icon.className)
                options.icon = L.divIcon(options.icon)
            else if (options.icon)
                options.icon = L.icon(options.icon)
            let object = L.marker(options.point, options)
            if (options.popup)
                this.newPopup(object, options.popup)
            if (options.tooltip)
                this.newTooltip(object, options.tooltip)
            object.addTo(this.map)
            return object
        },
        newImageOverlay(options) {
            let object = undefined
            if (options.topLeft)
                object = L.imageOverlay.rotated(
                    options.imageUrl,
                    options.topLeft,
                    options.topRight,
                    options.bottomLeft,
                    options)
            else
                object = L.imageOverlay(
                    options.imageUrl,
                    options.imageBounds,
                    options)
            if (options.popup)
                this.newPopup(object, options.popup)
            if (options.tooltip)
                this.newTooltip(object, options.tooltip)
            object.addTo(this.map)
            return object
        },
        newVideoOverlay(options) {
            let object = L.videoOverlay(
                options.videoUrl,
                options.videoBounds)
            if (options.popup)
                this.newPopup(object, options.popup)
            if (options.tooltip)
                this.newTooltip(object, options.tooltip)
            object.addTo(this.map)
            return object
        },
        newGeoJSONLayer(options) {
            let object = L.geoJSON(options)
            if (options.popup)
                this.newPopup(object, options.popup)
            if (options.tooltip)
                this.newTooltip(object, options.tooltip)
            object.addTo(this.map)
            return object
        },
        newSVGOverlay(options) {
            let object = L.svgOverlay(options.svg, options.bounds, options)
            if (options.popup)
                this.newPopup(object, options.popup)
            if (options.tooltip)
                this.newTooltip(object, options.tooltip)
            object.addTo(this.map)
            return object
        },
        initMarkers() {
            let activeMarkers = []
            for (let options of this.markers) {
                try {
                    activeMarkers.push(this.newMarker(options))
                }
                catch (ex) {
                    console.warn("cannot create marker", options, ex)
                }
            }
            this.activeMarkers = activeMarkers
        },
        termMarkers() {
            for (let marker of this.activeMarkers || []) {
                marker.remove()
            }
            delete this.activeMarkers
        },
        initImageOverlays() {
            let activeImageOverlays = []
            for (let options of this.imageOverlays) {
                try {
                    activeImageOverlays.push(this.newImageOverlay(options))
                }
                catch (ex) {
                    console.warn("cannot create image overlay", options, ex)
                }
            }
            this.activeImageOverlays = activeImageOverlays
        },
        termImageOverlays() {
            for (let object of this.activeImageOverlays || []) {
                object.remove()
            }
            delete this.activeImageOverlays
        },
        initVideoOverlays() {
            let activeVideoOverlays = []
            for (let options of this.videoOverlays) {
                try {
                    activeVideoOverlays.push(this.newVideoOverlay(options))
                }
                catch (ex) {
                    console.warn("cannot create video overlay", options, ex)
                }
            }
            this.activeVideoOverlays = activeVideoOverlays
        },
        termVideoOverlays() {
            for (let object of this.activeVideoOverlays || []) {
                object.remove()
            }
            delete this.activeVideoOverlays
        },
        initGeoJSONLayers() {
            let activeGeoJSONLayers = []
            for (let options of this.geoJSONLayers) {
                try {
                    activeGeoJSONLayers.push(this.newGeoJSONLayer(options))
                }
                catch (ex) {
                    console.warn("cannot create geo json", options, ex)
                }
            }
            this.activeGeoJSONLayers = activeGeoJSONLayers
        },
        termGeoJSONLayers() {
            for (let object of this.activeGeoJSONLayers || []) {
                object.remove()
            }
            delete this.activeGeoJSONLayers
        },
    }

}
</script>