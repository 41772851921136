<template>
<div class="pim-ai-cell">
	{{row[column.i]}}
	{{column.options}}
</div>
</template>
<script>
module.exports = {
	props: {
		row: { type: Array },
		column: { type: Object },
	}
}
</script>