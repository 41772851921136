var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toasts-container" },
    _vm._l(_vm.notifications, function(notification) {
      return notification.isNew
        ? _c("gp-toast", {
            key: notification.id,
            attrs: { notification: notification }
          })
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }