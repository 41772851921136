<template>
    <div class="gp-scope">
        <div class="form-group">
            <input
                class="form-control form-control-sm"
                ref="name"
                v-model="name"
                :placeholder="l10n('Name')">
            <div class="invalid-feedback">
                <l10n value="Please choose a scope name"/>
            </div>
        </div>
        <div class="form-group">
            <input
                class="form-control form-control-sm"
                v-model="description"
                :placeholder="l10n('Description')">
        </div>
        <div class="form-group">
            <chosen
                stream="combined"
                :dims="['class']"
                :multiple="true"
                :placeholder="l10n('Classes')"
                />
        </div>
        <div class="form-group">
            <gp-filter
                stream="combined"
                v-model="filter2"
                :attributes="attributes"
                />
        </div>
        <button
            class="btn btn-sm btn-primary"
            @click="createScope()"
            >
            <l10n value="Open"/>
        </button>
        <button
            class="btn btn-sm btn-secondary"
            @click="createScope()"
            >
            <l10n value="Save changes"/>
        </button>
        <button class="btn btn-sm btn-danger"><l10n value="Delete"/></button>
    </div>
</template>
<script>
let utils = require('../my-utils')

module.exports = {
	props: {
        section: { type: Object  },
        row: 	 { type: Array   },
        meta: 	 { type: Object  },
        totals:  { type: Boolean },
        attributes: { type: Array },
	},
	data() {
		return {
			l10n: utils.l10n,
			name: "",
			description: "",
			filter1: [],
			filter2: [],
		}
	},
    methods: {
        async createScope() {
            if (this.name == "") {
                this.$refs.name.classList.add("is-invalid")
                return
            }
            let {username} = await Promise.resolve($.ajax("/session"))
            let scopeId = utils.randomId()
            let createUser = username
            let createTime = Date.now()
            let records = JSON.stringify([[
                scopeId,
                createUser,
                createTime,
                this.name,
                this.description,
                JSON.stringify(this.filter1),
                JSON.stringify(this.filter2),
                JSON.stringify([])
            ]])
            let query = `
                mutation {
                    appendRecords(
                        stream: ${utils.quote(this.stream)},
                        format: "json",
                        records: ${utils.quote(records)})
                }`
            
            await utils.fetchWithAjaxOpts({
                url: "/graphql",
                method: "POST",
                data: JSON.stringify({query}),
                dataType: "json",
                contentType: "application/json"
            })

        }
    }
}
</script>