var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    class: _vm.className,
    style: { color: _vm.color, width: _vm.size, height: _vm.size },
    domProps: { innerHTML: _vm._s(_vm.html) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }