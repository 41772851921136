var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "my-button",
      style: _vm.getButtonStyles,
      attrs: { type: "button" },
      on: { click: _vm.onClick }
    },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [
            _c("div"),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c("div")
          ])
        : _vm._t("default", function() {
            return [_c("l10n", { attrs: { value: "Submit" } })]
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }