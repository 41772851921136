var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toast",
      class: { hide: !_vm.isOpen },
      attrs: {
        id: "liveToast",
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true"
      }
    },
    [
      _c("div", { staticClass: "toast-header" }, [
        _c("img", { staticClass: "rounded me-2", attrs: { src: "", alt: "" } }),
        _vm._v(" "),
        _c(
          "strong",
          { staticClass: "toast-text", class: _vm.headerTitleClass },
          [_c("l10n", { attrs: { value: _vm.headerTitle } })],
          1
        ),
        _vm._v(" "),
        _c("small", { staticClass: "toast-time" }, [
          _vm._v(_vm._s(_vm.timeString))
        ]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: {
            type: "button",
            "data-bs-dismiss": "toast",
            "aria-label": "Закрыть"
          },
          on: { click: _vm.closeToast }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "toast-body" },
        [_c("l10n", { attrs: { value: _vm.notification.text } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }