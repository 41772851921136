var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fulltext-search" },
    [
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.query,
              expression: "query"
            }
          ],
          staticClass: "form-control",
          attrs: { autofocus: "" },
          domProps: { value: _vm.query },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.query = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.pageCount > 1
        ? _c(
            "div",
            {
              staticClass: "plain-table-paginator form-group",
              attrs: { tabindex: "0" },
              on: { keydown: _vm.handlePaginatorKeyDown }
            },
            [
              _c(
                "a",
                {
                  class: { disabled: _vm.page === 0 },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.desiredPage = _vm.page - 10
                    }
                  }
                },
                [_c("feather-icon", { attrs: { name: "chevrons-left" } })],
                1
              ),
              _c(
                "a",
                {
                  class: { disabled: _vm.page === 0 },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.desiredPage = _vm.page - 1
                    }
                  }
                },
                [_c("feather-icon", { attrs: { name: "chevron-left" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.promptPage }
                },
                [
                  _vm._v(
                    "\n            page " +
                      _vm._s(new Number(_vm.page + 1).toLocaleString())
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: _vm.page === _vm.pageCount - 1 },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.desiredPage = _vm.page + 1
                    }
                  }
                },
                [_c("feather-icon", { attrs: { name: "chevron-right" } })],
                1
              ),
              _c(
                "a",
                {
                  class: { disabled: _vm.page === _vm.pageCount - 1 },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.desiredPage = _vm.page + 10
                    }
                  }
                },
                [_c("feather-icon", { attrs: { name: "chevrons-right" } })],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "nowrap" }, [
                _vm._v(_vm._s(new Number(_vm.size).toLocaleString()) + " rows,")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "nowrap" }, [
                _vm._v(
                  _vm._s(new Number(_vm.pageCount).toLocaleString()) + " pages,"
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "nowrap" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.promptPageSize }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(new Number(_vm.pageSize).toLocaleString()) +
                        " rows"
                    )
                  ]
                ),
                _vm._v(" per page")
              ]),
              _vm._v(" "),
              _c("inline-help", {
                attrs: {
                  text:
                    "Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward.",
                  html: true
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "ol",
          {
            style: { opacity: _vm.pending ? 0.5 : 1 },
            attrs: { start: _vm.start }
          },
          _vm._l(_vm.docs, function(doc) {
            return _c("li", { key: doc.key }, [
              _c("p", [
                _c("a", {
                  attrs: { href: doc.url, target: "_blank" },
                  domProps: { innerHTML: _vm._s(doc.title || "Untitled") }
                })
              ]),
              _c("div", { staticClass: "text-muted" }, [
                _c("small", [
                  _c("div", [
                    doc.publish_time != new Date(0, 0, 0)
                      ? _c("span", [
                          _vm._v(
                            "\n                                    Published " +
                              _vm._s(doc.publish_time.toLocaleDateString()) +
                              ".\n                                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(_vm._s(doc.journal)),
                    doc.doi
                      ? _c("span", [
                          _vm._v(
                            ",\n                                    doi:" +
                              _vm._s(doc.doi)
                          )
                        ])
                      : _vm._e(),
                    doc.pmcid
                      ? _c("span", [
                          _vm._v(
                            ",\n                                    pmcid:" +
                              _vm._s(doc.pmcid)
                          )
                        ])
                      : _vm._e(),
                    doc.pubmed_id
                      ? _c("span", [
                          _vm._v(
                            ",\n                                    pubmed:" +
                              _vm._s(doc.pubmed_id)
                          )
                        ])
                      : _vm._e(),
                    doc.license
                      ? _c("span", [
                          _vm._v(
                            ",\n                                    license:" +
                              _vm._s(doc.license)
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  doc.authors
                    ? _c("div", [_vm._v("Authors: " + _vm._s(doc.authors))])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              doc.abstract
                ? _c("p", { domProps: { innerHTML: _vm._s(doc.abstract) } })
                : _vm._e()
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("data-engine", {
        attrs: {
          id: "engine1",
          stream: _vm.stream,
          dims: _vm.dims1,
          vals: _vm.vals1,
          sort_: _vm.sort1,
          take_: _vm.take1,
          skip_: _vm.skip1,
          filter3: "val1 > 0"
        },
        on: {
          "report-updated": _vm.report1Updated,
          "reportId-updated": _vm.reportId1Updated
        }
      }),
      _vm._v(" "),
      _c("data-engine", {
        attrs: {
          id: "engine2",
          stream: _vm.stream,
          dims: _vm.dims2,
          vals: _vm.vals2,
          filter2: _vm.filter2,
          instant: true,
          group: "nofollow"
        },
        on: {
          "report-updated": _vm.report2Updated,
          "reportId-updated": _vm.reportId2Updated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }