<!-- eslint-disable vue/no-textarea-mustache -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="gp-autocomplete">
    <div class="gp-autocomplete-values">
      <feather-icon v-if="loading" name="clock" />
      <textarea
        ref="values"
        class="form-control form-control-sm"
        @focus="autocomplete.autocomplete('search')"
      >{{value.join("\n")}}</textarea>
    </div>
    <div ref="autocomplete" />
    <div class="gp-autocomplete-popup-notes">
      <l10n value="Use multiple lines to filter by multiple values" />
    </div>
  </div>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  mixins: [
    utils.configHelpers,
    utils.referenceDateHelper,
    utils.extraFilters,
  ],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array, default: () => [] },
    options: { type: Object, default: () => {} },
  },
  data() {
    return {
      autocomplete: null,
    };
  },
  beforeDestroy() {
    if (this.autocomplete) this.autocomplete.autocomplete('destroy');
  },
  mounted() {
    const self = this;
    if (this.autocomplete) this.autocomplete.autocomplete('destroy');

    this.autocomplete = $(this.$refs.values).autocomplete({
      minLength: 0,
      appendTo: this.$refs.autocomplete,
      source(request, response) {
        const searchTerm = _.last(request.term.split('\n')).toLowerCase();
        const options = Object.keys(self.options).map((label) => label);
        const filteredOptions = options.filter((option) => option.toLowerCase().startsWith(searchTerm));

        response(filteredOptions || []);
      },
      focus: () => false,
      select: (_, ui) => {
        const terms = $(this.$refs.values).val().split('\n');
        terms.pop();
        terms.push(ui.item.label);
        const value = terms.join('\n');
        if (value === this.autocompleteMore) {
          this.threshold *= 10;
          _.defer(() => {
            this.autocomplete.autocomplete('search', '');
            const e = $.Event('keydown');
            e.which = 40;
            $(this.$refs.values).trigger('focus').trigger(e);
          });
        } else {
          $(this.$refs.values).val(value);
        }
        this.$emit('input', terms);
        return false;
      },
    });
  },
};
</script>
<style scoped>
.gp-autocompletes-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}
.gp-autocompletes-list > li {
  border-top: 1px solid var(--dark);
  padding: 0;
  margin: 0;
}
.my-dark-theme .gp-autocompletes-list > li {
  border-top: 1px solid var(--light);
}
.gp-autocompletes-list > li label {
  background-color: var(--light);
  padding-top: 0;
  padding: 1px 4px;
  display: block;
}
.my-dark-theme .gp-autocompletes-list > li label {
  background-color: var(--dark);
}
.gp-autocompletes-list > li p {
  line-height: 1.3;
  font-size: 0.9em;
}
.gp-autocomplete-conditions {
  display: inline;
}
.gp-autocomplete-popup {
  padding: 8px 8px;
  border-radius: 4px;
  display: inline-block;
  background-color: rgb(191,223,294);
}
*[data-popper-placement] {
  z-index: 100!important;
}
.gp-autocomplete-popup .gp-select {
  margin-bottom: 6px;
}
.gp-autocomplete-values + div {
  margin-bottom: 6px;
  position: relative;
}
.gp-autocomplete-popup .btn.btn-xs {
  padding: 0 8px;
  font-size: 0.9em;
}
.gp-autocompletes .new svg {
  color: #1489FF80;
  border: none;
  margin-top: -2px;
  margin-bottom: -6px;
  vertical-align: top;
}
.gp-autocompletes .operator {
  opacity: 0.8;
  font-style: italic;
}
.gp-autocomplete-popup-actions {
  display: flex;
  margin-right: -6px;
}
.gp-autocomplete-popup-actions > * {
  flex-grow: 1;
  flex-basis: 1px;
  margin-right: 6px;
}
.ui-autocomplete {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute !important;
  left: inherit !important;
  top: inherit!important;
  max-height: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.ui-autocomplete .ui-menu-item-wrapper {
  border: none!important;
  outline: none!important;
  padding: 0 8px;
  font-size: 1em;
}
.ui-autocomplete .ui-menu-item-wrapper.ui-state-active {
  /*width: 100px!important;*/
  border: none!important;
  outline: none!important;
  background-color: #3498db30!important;
  color: inherit!important;
}
.gp-autocomplete-popup {
  box-shadow: 0 0 10px 10px #00000010;
  border: 1px solid var(--cyan);
}
.my-dark-theme .gp-autocomplete-popup {
  background-color: rgb(35,53,74);
  border: 1px solid black;
}
.gp-autocomplete .feather-icon-trash {
  visibility: hidden
}
.gp-autocomplete .feather-icon-trash svg {
  width: 18px;
  height: 18px;
}
ul.gp-autocompletes-list > li:hover .feather-icon-trash {
  visibility: visible;
}
.gp-autocomplete-popup input[type="number"] {
  max-width: 150px;
}
.gp-autocompletes-actions {
  display: flex;
  margin-right: -10px;
}
.gp-autocompletes-actions > * {
  margin-right: 10px;
  flex-grow: 1;
}
.gp-autocomplete-delete {
  float: right;
  margin-right: 4px;
}
.gp-autocompletes .feather-icon-filter svg {
  width: 16px;
  height: 16px;
  opacity: 0.8;
}
.gp-autocompletes-list > li label {
  padding: 4px 8px;
}
.gp-autocomplete-delete {
  margin-top: 2px;
}
.gp-autocompletes-list li label {
  cursor: move
}
.gp-autocompletes-list li {
  padding-bottom: 4px;
}
.gp-autocompletes-list li p {
  padding: 0 8px;
  padding-bottom: 10px;
  margin: 0;
}
.gp-autocompletes ul {
  margin-left: -20px;
  margin-right: -20px;
}
.gp-autocompletes ul li label {
  padding: 4px 20px;
}
.gp-autocompletes ul li p {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
}
.gp-autocomplete-values {
  position: relative;
}
.gp-autocomplete .feather-icon svg,
.gp-autocomplete-popup .feather-icon svg {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-bottom: -2px;
}
.gp-autocomplete .feather-icon-filter svg {
  margin-right: 4px;
}
.gp-autocomplete-popup .feather-icon-clock {
  position: absolute;
  top: 2px;
  right: 4px;
  color: var(--dark);
}
.gp-autocomplete-popup .feather-icon-clock svg {
  width: 16px;
  height: 16px;
}
.gp-autocomplete-conditions .my-popup .ui-autocomplete li {
  font-size: 13px;
}
.gp-autocomplete-values {
  margin-bottom: 0!important;
}
.gp-autocomplete-popup .btn.btn-xs {
  padding: 2px 8px;
}
.gp-autocomplete .operator {
  font-style: italic;
}
.gp-autocomplete .operator {
  font-style: italic;
}
.gp-autocomplete-values textarea.form-control {
  height: 100px;
}
.gp-autocomplete-popup-notes {
  font-size: 0.9em;
  max-width: 200px;
  white-space: initial;
  opacity: 0.6;
  line-height: 1.3em;
  padding: 0 6px;
}
.gp-autocomplete {
  font-size: 14px;
  width: 270px;
}
.gp-autocomplete textarea,
.ui-autocomplete {
  font-size: 13px;
}
.ui-widget {
  font-family: inherit;
}
.my-dark-theme .gp-autocomplete .feather-icon-plus svg path {
  fill: #ffffff;
}
</style>
