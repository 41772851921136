var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-4" }, [
    _c(
      "h4",
      { staticClass: "mb-4" },
      [_c("l10n", { attrs: { value: "Rules migration tool" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-danger mb-2" },
      [
        _c("l10n", {
          attrs: { value: "1) Transform old configs and save it with new name" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "config-rename" }, [
      _c("span", [_c("l10n", { attrs: { value: "Old config name" } })], 1),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.oldFamilyName,
            expression: "oldFamilyName"
          }
        ],
        attrs: { id: "oldConfigName", type: "text" },
        domProps: { value: _vm.oldFamilyName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.oldFamilyName = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("span", [_c("l10n", { attrs: { value: "New config name" } })], 1),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newFamilyName,
            expression: "newFamilyName"
          }
        ],
        attrs: { id: "newConfigName", type: "text" },
        domProps: { value: _vm.newFamilyName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.newFamilyName = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-primary mt-2 w-100",
        attrs: { type: "button" },
        on: { click: _vm.migrateConfig }
      },
      [_c("l10n", { attrs: { value: "Migrate saved configs" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-danger mb-2 mt-4" },
      [
        _c("l10n", {
          attrs: {
            value:
              "2) Add Elements -> rules:storedFamilyName with value from 'New config name'"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-danger mb-2 mt-4" },
      [
        _c("l10n", {
          attrs: {
            value:
              "3) Replace old config at Elements -> rulesLibrary with new one from textarea below"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("textarea", {
      staticClass: "w-100",
      attrs: { rows: "10" },
      domProps: { value: _vm.newLibraryRules }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }