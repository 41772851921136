var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stats
    ? _c("div", { staticClass: "gp-rules-stats" }, [
        _c(
          "div",
          [
            _c("l10n", {
              attrs: {
                value: "{count} price changes",
                count: new Number(_vm.stats.changes).toLocaleString()
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.stats.increased
          ? _c(
              "div",
              [
                _c("l10n", {
                  attrs: {
                    value: "{count} price increased on {avg} in average",
                    count: new Number(_vm.stats.increased).toLocaleString(),
                    avg: _vm.formatPercent(_vm.stats.avg_increase * 100)
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stats.decreased
          ? _c(
              "div",
              [
                _c("l10n", {
                  attrs: {
                    value: "{count} price decreased on {avg} in average",
                    count: new Number(_vm.stats.decreased).toLocaleString(),
                    avg: _vm.formatPercent(_vm.stats.avg_decrease * -100)
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stats.dropped_impact > 0
          ? _c(
              "div",
              [
                _c("l10n", {
                  attrs: {
                    value:
                      "{count} price changes are dropped due to metric impact limit",
                    count: new Number(_vm.stats.dropped_impact).toLocaleString()
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stats.dropped_limits > 0
          ? _c(
              "div",
              [
                _c("l10n", {
                  attrs: {
                    value:
                      "{count} price changes are dropped due to price change limit",
                    count: new Number(_vm.stats.dropped_limits).toLocaleString()
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stats.dropped_markdown_budget > 0
          ? _c(
              "div",
              [
                _c("l10n", {
                  attrs: {
                    value:
                      "{count} price changes are dropped due to markdown budget limit",
                    count: new Number(
                      _vm.stats.dropped_markdown_budget
                    ).toLocaleString()
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.stats.errors > 0
          ? _c(
              "div",
              [
                _c("l10n", {
                  attrs: {
                    value: "{errors} optimization tasks failed",
                    errors: new Number(_vm.stats.errors).toLocaleString()
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }