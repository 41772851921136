var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-scope" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name"
          }
        ],
        ref: "name",
        staticClass: "form-control form-control-sm",
        attrs: { placeholder: _vm.l10n("Name") },
        domProps: { value: _vm.name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "invalid-feedback" },
        [_c("l10n", { attrs: { value: "Please choose a scope name" } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.description,
            expression: "description"
          }
        ],
        staticClass: "form-control form-control-sm",
        attrs: { placeholder: _vm.l10n("Description") },
        domProps: { value: _vm.description },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.description = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("chosen", {
          attrs: {
            stream: "combined",
            dims: ["class"],
            multiple: true,
            placeholder: _vm.l10n("Classes")
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("gp-filter", {
          attrs: { stream: "combined", attributes: _vm.attributes },
          model: {
            value: _vm.filter2,
            callback: function($$v) {
              _vm.filter2 = $$v
            },
            expression: "filter2"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-primary",
        on: {
          click: function($event) {
            return _vm.createScope()
          }
        }
      },
      [_c("l10n", { attrs: { value: "Open" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-secondary",
        on: {
          click: function($event) {
            return _vm.createScope()
          }
        }
      },
      [_c("l10n", { attrs: { value: "Save changes" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-sm btn-danger" },
      [_c("l10n", { attrs: { value: "Delete" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }