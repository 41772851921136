<template>
    <ul class="gp-pills">
        <li v-for="item, i in items" :class="item.class">
            <span>
                {{item.name}}
                <a
                    href="javascript:void(0)"
                    @click="removeItem(i)">
                    <feather-icon name="x"/>
                </a>
            </span>
        </li>
        <li key="select">
            <gp-select
                v-model="item"
                ref="select"
                :options="options"
                :placeholder="placeholder"
                :recentOptionsKey="recentOptionsKey"
                />
        </li>
    </ul>
</template>
<script>
module.exports = {
    model: {
        prop: "items",
        event: "change",
    },
    props: {
        items: { type: Array, default: () => [] },
        options: { type: Array, default: () => [] },
        placeholder: { type: String },
        recentOptionsKey: { type: String, },
    },
    data() {
        return {
            item: null,
        }
    },
    methods: {
        removeItem(i) {
            let items = _.clone(this.items)
            items.splice(i, 1)
            this.$emit("change", items)
        }
    },
    watch: {
        item(item) {
            if (item) {
                let items = _.clone(this.items)
                items.push(item)
                this.item = null
                this.$emit("change", items)
                this.$refs.select.addToRecent(item)
            }
        }
    },
}
</script>
<style>
ul.gp-pills {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    margin-right: -10px;
    width: calc(100% + 10px);
}
ul.gp-pills > li {
    margin-right: 6px;
    margin-bottom: 6px;
}
ul.gp-pills > li > span {
    display: inline-block;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 4px;
    border-radius: 5px;
}
.my-light-theme ul.gp-pills > li > span {
    background-color: #3498db50;
}
.my-dark-theme ul.gp-pills > li > span {
    background-color: var(--blue);
}
ul.gp-pills > li > span svg {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    color: var(--gray-dark);
}
.my-dark-theme ul.gp-pills > li > span svg {
    color: white;
}
ul.gp-pills > li > input {
    background-color: transparent;
    border: none;
    outline: none;
    line-height: 26px;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid var(--gray);
}
.my-dark-theme ul.gp-pills > li > input {
    color: white;
}
ul.gp-pills > li:last-child {
    flex-grow: 1;
}
.gp-pills ul li {
    white-space: nowrap;
    font-size: 0.9em;
}
ul.gp-pills > li > span {
    font-size: 0.9em;
    padding-right: 0;
}
ul.gp-pills > li > span a {
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
    margin-bottom: -2px;
    padding-right: 4px;
}
.gp-pills .gp-select-remove-from-recent {
    margin-right: -2px;
}
</style>