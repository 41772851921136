var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pim-fields" }, [
    _c("h2", [_vm._v("Fields Setup")]),
    _vm._v(" "),
    _c(
      "table",
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.groups, function(fields, group) {
          return [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c(
                    "td",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toggleGroup(group)
                        }
                      }
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          name: _vm.activeGroup == group ? "book-open" : "book"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      attrs: { colspan: "8" },
                      on: {
                        click: function($event) {
                          return _vm.toggleGroup(group)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(group) +
                          "\n                    "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                group == _vm.activeGroup
                  ? [
                      _vm._l(fields, function(field) {
                        return [
                          _c("tr", { key: field.id }, [
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.toggleField(field)
                                  }
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    name: _vm.activeFields[field.id]
                                      ? "chevron-down"
                                      : "chevron-right"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.toggleField(field)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      field.description || field.field_name
                                    ) +
                                    "\n                                "
                                ),
                                _vm.isEdited(field)
                                  ? _c("span", { staticClass: "edited" }, [
                                      _vm._v(
                                        "\n                                    [edited]\n                                    "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              _vm.$delete(
                                                _vm.override,
                                                field.id
                                              )
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: { name: "x" }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-check", {
                                  attrs: {
                                    checked: _vm.get(field, "is_readonly"),
                                    disabled:
                                      _vm.get(field, "is_derived") ||
                                      _vm.get(field, "is_searchable") ||
                                      _vm.get(field, "is_locked") ||
                                      _vm.get(field, "has_storefronts") ||
                                      _vm.get(field, "has_locales")
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.set(
                                        field,
                                        "is_readonly",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-check", {
                                  attrs: {
                                    checked: _vm.get(field, "is_derived")
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.set(
                                        field,
                                        "is_derived",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-check", {
                                  attrs: {
                                    disabled: !_vm.get(field, "is_derived"),
                                    checked: _vm.get(field, "overridable")
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.set(
                                        field,
                                        "overridable",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-check", {
                                  attrs: {
                                    checked: _vm.get(field, "is_searchable")
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.set(
                                        field,
                                        "is_searchable",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-check", {
                                  attrs: {
                                    checked: _vm.get(field, "is_locked")
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.set(field, "is_locked", $event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-check", {
                                  attrs: {
                                    disabled: _vm.get(field, "has_storefronts"),
                                    checked: _vm.get(field, "has_locales")
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.set(
                                        field,
                                        "has_locales",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("gp-check", {
                                  attrs: {
                                    disabled: _vm.get(field, "has_locales"),
                                    checked: _vm.get(field, "has_storefronts")
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.set(
                                        field,
                                        "has_storefronts",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm.activeFields[field.id]
                            ? _c("tr", { key: field.id + "-active" }, [
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { attrs: { colspan: "8" } },
                                  [
                                    _c("pim-field", {
                                      attrs: {
                                        fields: fields,
                                        locales: _vm.locales,
                                        storefronts: _vm.storefronts,
                                        template: _vm.templatesIndex[field.id],
                                        field: _vm.thaw(field),
                                        darkTheme: _vm.darkTheme
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.override,
                                            field.id,
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ]
                      })
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Field Name")]),
        _vm._v(" "),
        _c("th", [_c("span", [_vm._v("Show")])]),
        _vm._v(" "),
        _c("th", [_c("span", [_vm._v("Derived")])]),
        _vm._v(" "),
        _c("th", [_c("span", [_vm._v("Overridable")])]),
        _vm._v(" "),
        _c("th", [_c("span", [_vm._v("Searchable")])]),
        _vm._v(" "),
        _c("th", [_c("span", [_vm._v("Lockes")])]),
        _vm._v(" "),
        _c("th", [_c("span", [_vm._v("Storefront")])]),
        _vm._v(" "),
        _c("th", [_c("span", [_vm._v("Locale")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }