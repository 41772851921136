var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.file != null && !_vm.dismissed
    ? _c(
        "my-dialog",
        {
          attrs: { title: "File is ready to download" },
          on: {
            close: function($event) {
              _vm.dismissed = true
            }
          }
        },
        [
          _c(
            "p",
            [
              _c("l10n", {
                attrs: {
                  value: "Pease use the link below to start downloading"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.file.link,
                  download: _vm.file.name,
                  target: "_blank"
                }
              },
              [_vm._v(_vm._s(_vm.file.name))]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "small",
              { staticClass: "text-muted" },
              [
                _c("l10n", {
                  attrs: {
                    value: "{size} bytes, {rows} rows, {cols} columns",
                    size: new Number(_vm.file.size).toLocaleString(),
                    rows: new Number(_vm.file.rows).toLocaleString(),
                    cols: new Number(_vm.file.cols).toLocaleString()
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }