var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-login-form" },
    [
      _c("p", [_c("l10n", { attrs: { value: "Authorization Required" } })], 1),
      _vm._v(" "),
      _vm.canLoginWithPIN
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-sm btn-primary",
                attrs: { href: "/miracl?state=" + _vm.returnPath }
              },
              [_c("l10n", { attrs: { value: "Login with PIN" } })],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canLoginWithPIN && _vm.canLoginWithPassword
        ? _c(
            "div",
            { staticClass: "form-group" },
            [_c("l10n", { attrs: { value: "OR" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canLoginWithPassword
        ? [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username"
                  }
                ],
                ref: "username",
                class: {
                  "form-control": true,
                  "is-valid": _vm.passed
                },
                attrs: {
                  required: "",
                  autofocus: "",
                  spellcheck: "false",
                  autocorrect: "off",
                  autocapitalize: "off",
                  placeholder: _vm.l10n("user name"),
                  disabled: _vm.busy
                },
                domProps: { value: _vm.username },
                on: {
                  keydown: _vm.usernameKeyDown,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.username = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                ref: "password",
                class: {
                  "form-control": true,
                  "is-valid": _vm.passed
                },
                attrs: {
                  required: "",
                  placeholder: _vm.l10n("password"),
                  disabled: _vm.busy,
                  type: "password"
                },
                domProps: { value: _vm.password },
                on: {
                  keydown: _vm.passwordKeyDown,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            !_vm.passed
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    attrs: { type: "button", disabled: !_vm.canLogin },
                    on: { click: _vm.login }
                  },
                  [
                    !_vm.busy
                      ? _c("l10n", { attrs: { value: "Submit" } })
                      : _c("l10n", { attrs: { value: "Submitting..." } })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.failed
              ? _c(
                  "div",
                  {
                    staticClass: "invalid-feedback",
                    staticStyle: { display: "block" }
                  },
                  [
                    _c("l10n", {
                      attrs: { value: "Wrong user name or password" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }