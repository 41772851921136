<template>
    <div class="gp-search">
        <template v-if="product == 'price'">
            <gp-data
                id="gp-search-categories"
                :stream="stream"
                :source="source"
                :groups="['categories', 'reference-date']"
                :filter0="filter0"
                :filter1="filter1"
                :filter2="filter2"
                filter3="dim1 != ''"
                :dims="['classif.category', 'class']"
                :initialSort="[1]"
                :instant="false"
                v-model="categoriesReport"
                />
            <div class="gp-search-classes">
                <feather-icon name="folder"/>
                <gp-pills
                  v-model="selectedCategories"
                  :options="selectableCategories"
                  :placeholder="l10n('Categories')"
                  recentOptionsKey="recentCategories"
                />
                <gp-pills
                  v-model="selectedClasses"
                  :options="knownClasses"
                  :placeholder="l10n('Classes')"
                  recentOptionsKey="recentClasses"
                />
            </div>
        </template>
        <gp-filter
            v-model="filter"
            :stream="stream"
            :source="source"
            :groups="['search-filter']"
            :filter0="filterFilters.filter0"
            :filter1="filterFilters.filter1"
            :filter2="filterFilters.filter2"
            :threshold="threshold"
            :attributes="attributes"
            :formulas="formulas"
            :vars="vars"
            />
        <template v-if="product == 'price'">
            <gp-check v-model="showLinkedItems" :disabled="!selectiveFilter">
                <l10n value="Show linked items"/>
                <inline-help text="If set includes related items in line, size, brand and other groups"/>
            </gp-check>
            <gp-check v-model="showInactiveAssortment">
                <l10n value="Show inactive assortment"/>
            </gp-check>
            <gp-check v-model="showClosedStores">
                <l10n value="Show closed stores"/>
            </gp-check>
            <gp-data
                v-if="showLinkedItems && selectiveFilter"
                id="gp-linked-attrs"
                :stream="stream"
                :source="source"
                :groups="['linked-attrs', 'reference-date']"
                :filter0="directFilters.filter0"
                :filter1="directFilters.filter1"
                :filter2="directFilters.filter2"
                :dims="linkedAttrs"
                v-model="linkedAttrsReport"
                />
        </template>
    </div>
</template>
<script>
let utils = require('../my-utils')
module.exports = {
    mixins: [
        utils.extraFilters,
        utils.configHelpers,
        utils.referenceDateHelper
    ],
    props: {
        product:        { type: String, default: "price" },
        stream:         { type: String, default: "default" },
        vars:           { type: Object, default: () => ({}) },
        source:         { type: Object },
        groups:         { type: Array },
        filter0:        { type: String },
        filter1:        { type: String },
        filter2:        { type: String },
        threshold:      { type: Number, default: 100 },
        attributes:     { type: Array,  default: () => [] },
        formulas:       { type: Object, default: () => ({}) },
        linkedAttrs:    { type: Array,  default: () => ["line_group.line_group"] },
        uniqueKey:      { type: String, default: 'Initial' },
        useTopSettingsSaving: { type: Boolean, default: false },
    },
    data() {
        let filter = localStorage[`${this.uniqueKey}_gp-search-filter`] || "[]";
        let classes = localStorage[`${this.uniqueKey}_gp-search-classes`] || "[]";
        let categories = localStorage[`${this.uniqueKey}_gp-search-categories`] || "[]";
        let showInactiveAssortment = localStorage["gp-search-showInactiveAssortment"] !== "false"
        showInactiveAssortment = true
        let showClosedStores = localStorage["gp-search-showClosedStores"] === "true"
        let showLinkedItems = localStorage["gp-search-showLinkedItems"] === "true"
        if (filter) {
            try { filter = JSON.parse(filter) }
            catch (ex) { console.warn(ex); filter = [] }
        }
        if (classes) {
            try { classes = JSON.parse(classes) }
            catch (ex) { console.warn(ex); classes = [] }
        }
        if (categories) {
            try { categories = JSON.parse(categories) }
            catch (ex) { console.warn(ex); categories = [] }
        }
        return {
            l10n: utils.l10n,
            filter,
            categoriesReport: null,
            linkedAttrsReport: null,
            selectedClasses: classes,
            selectedCategories: categories,
            showInactiveAssortment,
            showClosedStores,
            showLinkedItems,
        }
    },
    created() {
      if (this.useTopSettingsSaving) {
        utils.bridge.bind('configChanged', this.onConfigChanged);
      }
    },
    mounted() {
      if (this.useTopSettingsSaving) {
        const currentConfig = JSON.parse(localStorage[`${this.uniqueKey}-currentConfig`] || '[]');

        this.setTopSettings(currentConfig?.topSettings);
      }
    },
    methods: {
      setTopSettings(topSettings) {
        this.filter = JSON.parse(topSettings?.filter || '[]');
        this.selectedCategories = JSON.parse(topSettings?.categories || '[]');
        this.selectedClasses = JSON.parse(topSettings?.classes || '[]');
      },
      onConfigChanged(newConfig) {
        if (newConfig?.topSettings) {
          this.setTopSettings(newConfig?.topSettings);
  
          return;
        }
  
        this.filter = [];
        this.selectedCategories = [];
        this.selectedClasses = [];
      }
    },
    beforeMount() {
        window.gpsearch = this
        utils.bridge.trigger("filtersChanged", this._uid, this.filters)
    },
    beforeDestroy() {
      if (this.useTopSettingsSaving) {
        utils.bridge.unbind('configChanged', this.onConfigChanged);
      }
    },
    computed: {
        selectableCategories() {
            return _.concat(
                this.knownCategories,
                [{
                    name: utils.l10n("ALL CATEGORIES"),
                    class: "everything",
                    classes: [],
                }])
        },
        knownCategories() {
            return _(this.categoriesReport?.rows)
                .groupBy("0")
                .toPairs()
                .map(([name, rows]) => ({name, classes: _.map(rows, "1")}))
                .value()
        },
        knownClasses() {
            return _(this.knownCategories)
                .filter(({name}) =>
                    _.isEmpty(this.selectedCategories) ||
                    _.some(this.selectedCategories, category => _.isEmpty(category.classes)) ||
                    _.some(this.selectedCategories, category => category.name === name))
                .map("classes")
                .flatten()
                .uniq()
                .sortBy()
                .map(name => ({name}))
                .value()
        },
        filters() {
            let filter0 = []
            let filter1 = []
            let filter2 = []
            let filter3 = []

            let push = (filters) => {
                if (filters.filter0)
                    filter0.push(filters.filter0)
                if (filters.filter1)
                    filter1.push(filters.filter1)
                if (filters.filter2)
                    filter2.push(filters.filter2)
                if (filters.filter3)
                    filter3.push(filters.filter3)
            }

            push(this.directFilters)

            if (this.directFilters.filter2)
                push(this.linkedFilters)

            let join = (filter) => _.isEmpty(filter) ? "" : `(${filter.join(" || ")})`

            return {
                stream: this.stream,
                groups: this.groups,
                filter0: join(filter0),
                filter1: join(filter1),
                filter2: join(filter2),
                filter3: join(filter3),
            }
        },
        selectiveFilter() {
            let date = utils.nextDate(
                this.referenceDate ?
                    utils.parseDate(this.referenceDate) :
                    new Date())

            let resolve = calc =>
                this.resolveDateConditions(
                    this.resolveSubstitutes(calc),
                    date,
                    date)

            let hasOrCondition = this.showLinkedItems || (this.filter.length > 1)
            let filter0 = []
            let filter1 = []
            let filter2 = []
            for (let condition of this.filter) {
                let f0 = []
                let f1 = []
                let f2 = []
                for (let key in condition) {
                    let value = condition[key]
                    if (key && value) {
                        if (!hasOrCondition && ['item', 'class', 'store'].includes(key)) {
                            let f = value.length == 1
                                ? `${resolve(key)} == ${utils.quote(value[0])}`
                                : `${resolve(key)} in ${utils.quote(value)}`
                            f0.push(f)
                            f1.push(f)
                        } else {
                            let f = value.length == 1
                                ? `(${resolve(key)}) == ${utils.quote(value[0])}`
                                : `(${resolve(key)}) in ${utils.quote(value)}`
                            f2.push(f)
                        }
                    }
                }
                if (f0.length > 0)
                    filter0.push(f0.join(' && '))
                if (f1.length > 0)
                    filter1.push(f1.join(' && '))
                if (f2.length > 0)
                    filter2.push(f2.join(' && '))
            }
            return filter0 || filter1 || filter2
                ? {
                    filter0: filter0.join(' || '),
                    filter1: filter1.join(' || '),
                    filter2: filter2.join(' || ')
                }
                : undefined
        },
        linkedFilters() {
            let filter0 = []
            let filter1 = []
            let filter2 = []
            let filter3 = []

            if (this.product == "price" &&
                this.showLinkedItems &&
                this.linkedAttrsReport &&
                this.linkedAttrsReport.size > 0)
            {
                for (let i=0; i < this.linkedAttrsReport.columns.length; ++i) {
                    let {calc} = this.linkedAttrsReport.meta.dims[i]
                    let values = new Set()
                    for (let row of this.linkedAttrsReport.rows)
                        if (row[i])
                            values.add(row[i])
                    values = [...values]
                    if (values.length)
                        filter2.push(`(${calc}) in ${utils.quote(values)}`)
                }
            }

            if (this.product == "price" && !this.showInactiveAssortment && filter2.length > 0)
                filter2 = [`assort.item != '' && (${filter2.join(" || ")})`]

            return {
                stream: this.stream,
                groups: this.groups,
                filter0: filter0.join(" || "),
                filter1: filter1.join(" || "),
                filter2: filter2.join(" || "),
                filter3: filter3.join(" || "),
            }
        },
        directFilters() {
            let filter0 = []
            let filter1 = []
            let filter2 = []
            let filter3 = []

            if (this.product == "price") {

                filter2.push("store != '0'")

                if (_.some(this.selectedCategories, category => !_.isEmpty(category.classes))) {
                    let classes = _(this.selectedCategories)
                        .filter(category => !_.isEmpty(category.classes))
                        .map(({classes}) => classes)
                        .flatten()
                        .value()

                    filter0.push(`class in ${utils.quote(classes)}`)
                    filter1.push(`class in ${utils.quote(classes)}`)
                }

                if (_.some(this.selectedCategories, category => _.isEmpty(category.classes))) {
                    // whole business explcitly requested, no hard filters, a bit of cleanup
                    filter2.push("classif.category != ''")
                }
                else {
                    let categories = _(this.selectedCategories)
                        // .filter(category => !category.scope)
                        // .filter(category => !_.isEmpty(category.classes))
                        .map(({name}) => name)
                        .value()

                    let classes = _(this.selectedClasses)
                        .map(({name}) => name)
                        .flatten()
                        .value()

                    if (!_.isEmpty(categories)) {
                        filter2.push(`classif.category in ${utils.quote(categories)}`)
                    }

                    if (!_.isEmpty(classes)) {
                        filter0.push(`class in ${utils.quote(classes)}`)
                        filter1.push(`class in ${utils.quote(classes)}`)
                    }

                    if (_.isEmpty(categories) && _.isEmpty(classes)) {
                        filter0.push(`class in []`)
                        filter1.push(`class in []`)
                    }
                }

                if (!this.showInactiveAssortment)
                    filter2.push("assort.item != ''")

                if (!this.showClosedStores)
                    filter2.push("isnull(!closed_store.closed, true)")
            }

            let selectiveFilter = this.selectiveFilter
            if (selectiveFilter) {
                if (selectiveFilter.filter0)
                    filter0.push(`(${selectiveFilter.filter0})`)
                if (selectiveFilter.filter1)
                    filter1.push(`(${selectiveFilter.filter1})`)
                if (selectiveFilter.filter2)
                    filter2.push(`(${selectiveFilter.filter2})`)
            }

            return {
                stream: this.stream,
                groups: this.groups,
                filter0: filter0.join(" && "),
                filter1: filter1.join(" && "),
                filter2: filter2.join(" && "),
                filter3: filter3.join(" && "),
            }
        },
        filterFilters() {
            let filter0 = []
            let filter1 = []
            let filter2 = []

            if (this.filter0)
                filter0.push(this.filter0)

            if (this.filter1)
                filter1.push(this.filter1)

            if (this.filter2)
                filter2.push(this.filter2)

            if (this.extraFilter0)
                filter0.push(this.extraFilter0)

            if (this.extraFilter1)
                filter1.push(this.extraFilter1)

            if (this.extraFilter2)
                filter2.push(this.extraFilter2)

            
            if (this.product == "price") {
                if (!_.some(this.selectedCategories, category => _.isEmpty(category.classes))) {
                    let categories = this.selectedCategories.map(({name}) => name)
                    filter2.push(`classif.category in ${utils.quote(categories)}`)
                }
            }

            return {
                filter0: filter0.join(" && "),
                filter1: filter1.join(" && "),
                filter2: filter2.join(" && "),
            }
        },
    },
    watch: {
        filter() {
            localStorage[`${this.uniqueKey}_gp-search-filter`] = JSON.stringify(this.filter);

            if (this.useTopSettingsSaving) {
              utils.bridge.trigger('topSettingsChanged');
            }
        },
        knownCategories() {
            this.selectedCategories =
                this.selectedCategories
                    .map(category => this.knownCategories.find(({name}) => category.name == name) || category)
        },
        selectedClasses() {
            localStorage[`${this.uniqueKey}_gp-search-classes`] = JSON.stringify(this.selectedClasses);

            if (this.useTopSettingsSaving) {
              utils.bridge.trigger('topSettingsChanged');
            }
        },
        selectedCategories() {
            localStorage[`${this.uniqueKey}_gp-search-categories`] = JSON.stringify(this.selectedCategories);

            if (this.useTopSettingsSaving) {
              utils.bridge.trigger('topSettingsChanged');
            }
        },
        filters(filters, previosFilters) {
            if (!_.isEqual(filters, previosFilters))
                utils.bridge.trigger("filtersChanged", this._uid, this.filters)
        },
        showInactiveAssortment() {
            localStorage["gp-search-showInactiveAssortment"] = this.showInactiveAssortment
        },
        showClosedStores() {
            localStorage["gp-search-showClosedStores"] = this.showClosedStores
        },
        showLinkedItems() {
            localStorage["gp-search-showLinkedItems"] = this.showLinkedItems
        },
    }
}
</script>
<style>
.gp-search-classes {
    display: block!important;
}
.gp-search {
    display: inline-block;
    margin-right: 40px;
}
.gp-search .gp-pills {
    display: inline;
    margin-right: 10px;
}
.gp-search .gp-pills {
    display: inline;
}
.gp-search .gp-pills > li {
    display: inline-block;
}
.gp-search-classes {
    display: flex;
    margin-bottom: -4px;
}
.gp-search-classes .feather-icon-folder svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.gp-search .gp-pills input {
    width: 100%;
    max-width: 150px;
}
.gp-search .gp-pills {
    margin-bottom: 4px;
}
.gp-search > .form-check {
    margin-left: 3px;
    font-size: 1.1em;
}
.gp-search ul.gp-pills > li:last-child {
    flex-grow: 0;
    flex-basis: 150px;
}
.gp-search .my-inline-help .feather-icon svg {
    width: 20px;
    height: 20px;
}
.gp-search {
    display: inline;
}
.gp-search .form-check {
    float: left;
    clear: left;
}
.gp-search .my-inline-help .feather-icon svg {
    width: 20px;
    height: 20px;
}
</style>