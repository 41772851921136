var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("my-errors", {
        attrs: { errors: _vm.errors },
        on: { retry: _vm.requestData }
      }),
      _vm._v(" "),
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _c("my-progress", {
        key: _vm.reportId,
        attrs: {
          progress: _vm.progress,
          processRows: _vm.processRows,
          processTime: _vm.processTime,
          meta: _vm.meta,
          assembly: _vm.assembly
        }
      }),
      _vm._v(" "),
      _c("my-filters", {
        attrs: { chart: _vm.chart },
        model: {
          value: _vm.filters,
          callback: function($$v) {
            _vm.filters = $$v
          },
          expression: "filters"
        }
      }),
      _vm._v(" "),
      _c("my-tooltip", {
        ref: "tooltip",
        attrs: {
          meta: _vm.meta,
          keepOnly: _vm.keepOnly,
          exclude: _vm.exclude,
          actions: _vm.actions
        }
      }),
      _vm._v(" "),
      _c("div", { ref: "base", staticClass: "my-chart", style: _vm.style }, [
        _c("div", { ref: "chart" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }