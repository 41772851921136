var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-reports" }, [
    _c(
      "table",
      {
        staticClass:
          "table table-sm table-striped table-bordered table-hover table-responsive"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.visibleReports, function(report) {
            return _c(
              "tr",
              {
                class: {
                  "table-info": report.status === "cache",
                  "table-success": report.status === "done",
                  "table-danger":
                    report.cancelled || report.status === "failed",
                  selected: _vm.selectedReport === report
                },
                on: {
                  click: function($event) {
                    _vm.selectedReport =
                      _vm.selectedReport === report ? null : report
                  }
                }
              },
              [
                _c("td", [_vm._v(_vm._s(report.name))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(report.cancelled ? "cancelled" : report.status))
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(report.cores))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(report.username))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      report.progress !== undefined
                        ? new Number(report.progress).toLocaleString(
                            undefined,
                            { style: "percent" }
                          )
                        : ""
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(report.sorting))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(report.reportId))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(report.source))]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.filter0 } }, [
                  _c("span", [_vm._v(_vm._s(report.filter0 || "-"))])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.filter1 } }, [
                  _c("span", [_vm._v(_vm._s(report.filter1 || "-"))])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.filter2 } }, [
                  _c("span", [_vm._v(_vm._s(report.filter2 || "-"))])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.filter3 } }, [
                  _c("span", [_vm._v(_vm._s(report.filter3 || "-"))])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.dims } }, [
                  _c("span", [_vm._v(_vm._s(report.dims || "-"))])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.vals } }, [
                  _c("span", [_vm._v(_vm._s(report.vals || "-"))])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.cols } }, [
                  _c("span", [_vm._v(_vm._s(report.cols || "-"))])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.sort } }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        report.sort && report.sort.length > 0
                          ? report.sort.join(", ")
                          : "-"
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("td", { attrs: { title: report.duration } }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        report.duration !== undefined
                          ? Number(report.duration).toLocaleString()
                          : null
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  report.reportId && !report.cancelled
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.cancelReport(report.reportId)
                            }
                          }
                        },
                        [_c("l10n", { attrs: { value: "cancel" } })],
                        1
                      )
                    : _vm._e()
                ])
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("name")]),
        _vm._v(" "),
        _c("th", [_vm._v("status")]),
        _vm._v(" "),
        _c("th", [_vm._v("cores")]),
        _vm._v(" "),
        _c("th", [_vm._v("user")]),
        _vm._v(" "),
        _c("th", [_vm._v("progress")]),
        _vm._v(" "),
        _c("th", [_vm._v("sorting")]),
        _vm._v(" "),
        _c("th", [_vm._v("id")]),
        _vm._v(" "),
        _c("th", [_vm._v("source")]),
        _vm._v(" "),
        _c("th", [_vm._v("filter0")]),
        _vm._v(" "),
        _c("th", [_vm._v("filter1")]),
        _vm._v(" "),
        _c("th", [_vm._v("filter2")]),
        _vm._v(" "),
        _c("th", [_vm._v("filter3")]),
        _vm._v(" "),
        _c("th", [_vm._v("dims")]),
        _vm._v(" "),
        _c("th", [_vm._v("vals")]),
        _vm._v(" "),
        _c("th", [_vm._v("cols")]),
        _vm._v(" "),
        _c("th", [_vm._v("sort")]),
        _vm._v(" "),
        _c("th", [_vm._v("duration")]),
        _vm._v(" "),
        _c("th", [_vm._v("action")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }