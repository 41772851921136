var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.keep === true ||
    _vm.keep === "true" ||
    _vm.show === true ||
    _vm.show === "true"
    ? _c(
        "div",
        {
          style: {
            display: _vm.show === true || _vm.show === "true" ? null : "none"
          }
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }