var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.rootClass },
    [
      _c("div", { ref: "contentPanel", staticClass: "my-content-panel" }, [
        _c(
          "div",
          { staticClass: "my-container" },
          [
            _c("markdown", {
              staticClass: "my-content",
              attrs: {
                "dark-theme": _vm.darkTheme,
                config: _vm.config,
                username: _vm.username,
                bounds: _vm.bounds,
                data: _vm.dataThrottled,
                text: _vm.textThrottled,
                hash: _vm.hash,
                storage: _vm.storage || {},
                "storage-key": _vm.storageKey
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "my-settings" },
        [
          _vm.pageWarning
            ? _c(
                "span",
                { attrs: { id: "my-page-warning" } },
                [
                  _c("feather-icon", { attrs: { name: "alert-triangle" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.pageWarning))])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("feather-icon", { attrs: { name: "menu" } }),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editMode,
                    expression: "editMode"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { id: "editMode", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.editMode)
                    ? _vm._i(_vm.editMode, null) > -1
                    : _vm.editMode
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.editMode,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.editMode = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.editMode = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.editMode = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "form-check-label", attrs: { for: "editMode" } },
                [_c("l10n", { attrs: { value: "design mode" } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.darkTheme,
                    expression: "darkTheme"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { id: "darkTheme", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.darkTheme)
                    ? _vm._i(_vm.darkTheme, null) > -1
                    : _vm.darkTheme
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.darkTheme,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.darkTheme = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.darkTheme = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.darkTheme = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "darkTheme" }
                },
                [_c("l10n", { attrs: { value: "dark theme" } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.l10nEnable,
                    expression: "l10nEnable"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { id: "l10nEnable", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.l10nEnable)
                    ? _vm._i(_vm.l10nEnable, null) > -1
                    : _vm.l10nEnable
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.l10nEnable,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.l10nEnable = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.l10nEnable = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.l10nEnable = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "l10nEnable" }
                },
                [_c("l10n", { attrs: { value: "translation" } })],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "text-muted",
                staticStyle: { "line-height": "1" }
              },
              [
                _vm.username !== ""
                  ? _c(
                      "small",
                      [
                        _c("l10n", {
                          attrs: {
                            value: "Logged in as: {username}",
                            username: _vm.username
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.username !== ""
              ? _c(
                  "a",
                  {
                    attrs: { href: "javascript::void(0)" },
                    on: { click: _vm.logout }
                  },
                  [_c("l10n", { attrs: { value: "logout" } })],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.editModeActivated
        ? _c("dev", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editMode,
                expression: "editMode"
              }
            ],
            attrs: {
              path: _vm.path,
              hash: _vm.hash,
              text: _vm.text,
              data: _vm.data,
              original: _vm.original,
              "dark-theme": _vm.darkTheme,
              "text-warning": _vm.textWarning,
              "data-warning": _vm.dataWarning,
              "page-warning": _vm.pageWarning,
              "text-throttled": _vm.textThrottled,
              "data-throttled": _vm.dataThrottled
            },
            on: {
              "change:text": _vm.handleTextChange,
              "change:data": _vm.handleDataChange,
              "change:asset": _vm.handleAssetChange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.notifications.length
        ? _c("gp-toast-queue", { attrs: { notifications: _vm.notifications } })
        : _vm._e(),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "modal" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "popup" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "tooltip" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "hover" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }