var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-endings" }, [
    _c(
      "table",
      [
        _c(
          "tr",
          [
            _c(
              "th",
              {
                staticClass: "gp-endings-all",
                class: {
                  active: _vm.isAllActive()
                },
                on: {
                  click: function($event) {
                    return _vm.toogleAll()
                  }
                }
              },
              [_vm._v("\n        *\n      ")]
            ),
            _vm._v(" "),
            _vm._l(_vm.digitsAsStr, function(i) {
              return _c(
                "th",
                {
                  key: i,
                  class: {
                    active: _vm.isColActive(i)
                  },
                  on: {
                    click: function($event) {
                      return _vm.toogleCol(i)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s("" + _vm.prefix + i + "0" + _vm.suffix) +
                      "\n      "
                  )
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm._l(_vm.digitsAsStr, function(j) {
          return _c(
            "tr",
            { key: j },
            [
              _c(
                "th",
                {
                  class: {
                    active: _vm.isRowActive(j)
                  },
                  on: {
                    click: function($event) {
                      return _vm.toogleRow(j)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.prefix + "0" + j + _vm.suffix) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.digitsAsStr, function(i) {
                return _c(
                  "td",
                  {
                    key: i,
                    class: {
                      active: _vm.endings[i + j]
                    },
                    on: {
                      click: function($event) {
                        return _vm.toogleCell(i, j)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s("" + _vm.prefix + i + j + _vm.suffix) +
                        "\n      "
                    )
                  ]
                )
              })
            ],
            2
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }