// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../icons/search-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.plain-table-manage-section ul {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n}\n.gp-section-columns .form-control {\n    height: 46px;\n    padding: 0 8px 0 42px;\n    font-family: Inter;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 120%;\n    border: none;\n    border-bottom: 1px solid #E9E9E9;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n    border-bottom-left-radius: 0px;\n    border-bottom-right-radius: 0px;\n    background: #f7f7f7 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + " ) no-repeat 12px;\n}\n.gp-section-columns .form-control:focus {\n    -webkit-box-shadow: none;\n    box-shadow: none;\n}\n.gp-section-columns ul.gp-section-columns__list {\n    overflow-y: auto;\n    max-height: 300px;\n    padding: 8px 12px;\n}\nul.gp-section-columns__list::-webkit-scrollbar {\n    width: 3px;\n    background-color: #ffffff;\n}\nul.gp-section-columns__list::-webkit-scrollbar-thumb {\n    width: 3px;\n    border-radius: 50%;\n    background-color: #E9E9E9;\n}\n.gp-section-columns__list hr {\n    margin: 0;\n}\n.gp-section-columns__list li {\n    padding: 8px;\n}\n.gp-section-columns-actions {\n    display: flex;\n    justify-content: space-around;\n    padding: 8px 16px 16px;\n}\n.gp-section-columns-actions .button {\n    width: 130px;\n    height: 40px;\n}\n.gp-section-columns .matched {\n    color: var(--red);\n}\n.gp-section-columns ul {\n  list-style: none;\n}\n", ""]);
// Exports
module.exports = exports;
