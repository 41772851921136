<template>
  <iframe
    v-if="notebook"
    class="notebook-iframe"
    :src="src"
  />
</template>

<script>
export default {
  props: {
    notebook: {
      type: String,
      required: true,
    },

    query: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    src() {
      const searchParams = new URLSearchParams(this.query);
      return `/voila/voila/render/${this.notebook}.ipynb?${searchParams}`;
    },
  },
};
</script>

<style scoped>
.notebook-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
