const component = require('../../input/text.vue').default;

module.exports = ({
  initial = '-',
}) => {
  const valueToInput = (x) => x;
  const inputToValue = (x) => {
    if (!x) return initial;
    return x;
  };
  const display = (x) => `${x}`;

  return {
    component,
    valueToInput,
    inputToValue,
    display,
  };
};
