var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-tree-chart" },
    [
      _vm.chartType == "pie-chart"
        ? _c("pie-chart", {
            ref: "chart",
            attrs: {
              stream: "default",
              dims: _vm.dims,
              vals: _vm.vals,
              provider: _vm.provider,
              throttled: false,
              actions: _vm.actions,
              colors: _vm.colors
            }
          })
        : _vm.chartType == "bar-chart"
        ? _c("bar-chart", {
            ref: "chart",
            attrs: {
              stream: "default",
              dims: _vm.dims,
              vals: _vm.vals,
              provider: _vm.provider,
              throttled: false,
              actions: _vm.actions,
              colors: _vm.colors,
              x: "d3.scaleBand()",
              xUnits: "dc.units.ordinal",
              elasticY: true,
              marginTop: _vm.marginTop,
              marginLeft: _vm.marginLeft,
              marginRight: _vm.marginRight,
              marginBottom: _vm.marginBottom,
              height: _vm.height,
              xTickAngle: _vm.xTickAngle
            }
          })
        : _vm.chartType == "bubble-chart"
        ? _c("bubble-chart", {
            ref: "chart",
            attrs: {
              stream: "default",
              dims: _vm.dims,
              vals: _vm.vals,
              provider: _vm.provider,
              throttled: false,
              actions: _vm.actions,
              colors: _vm.colors,
              elasticX: true,
              elasticY: true,
              elasticRadius: true,
              sortBubbleSize: true,
              radiusValueAccessor: function(d) {
                return d.value[2] || 1
              },
              colorAccessor: function(d) {
                return d.value[3] || "blue"
              }
            }
          })
        : _vm.chartType == "scatter-chart"
        ? _c("scatter-chart", {
            ref: "chart",
            attrs: {
              stream: "default",
              dims: _vm.dims,
              vals: _vm.vals,
              provider: _vm.provider,
              throttled: false,
              actions: _vm.actions,
              colors: _vm.colors
            }
          })
        : _vm.chartType == "time-series" && _vm.config
        ? _c("gp-chart", {
            ref: "chart",
            attrs: {
              stream: "combined",
              metrics: _vm.config.metrics,
              attributes: _vm.config.attributes,
              formulas: _vm.config.formulas,
              formats: _vm.config.formats,
              timeframes: _vm.config.timeframes,
              height: 300,
              elasticX: true,
              elasticY: true,
              marginTop: 60,
              marginLeft: 60,
              marginRight: 80,
              marginBottom: 50,
              yAxisPadding: "10%",
              yTicks: 5,
              xTicks: 10,
              rightYTicks: 5,
              xTickAngle: -45,
              x: "d3.scaleTime()",
              xUnits: " d3.timeDay",
              round: "d3.timeDay.round",
              xTickFormat: "utils.multiFormatDate",
              yTickFormat: "d3.format('.2s')",
              rightYTickFormat: "d3.format('$.2s')",
              rightYAxisFormats: ["value"],
              brushOn: false,
              title: "(d) => d.value[0]",
              renderVerticalGridLines: true,
              renderHorizontalGridLines: true,
              clipPadding: 10,
              transitionDuration: 0,
              notBefore: "2021-01-01",
              notAfter: "2022-12-31",
              renderLegend: false,
              colors: "d3.scaleOrdinal(d3.schemeTableau10)",
              filter2: _vm.filter2
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("pim-attributes-ex", {
        ref: "attributes",
        attrs: {
          show: false,
          entries: _vm.entries,
          fields: _vm.attributesFields
        },
        on: {
          change: function($event) {
            _vm.attributes = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }