var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-inline gp-column-precision" }, [
    _c("label", [_c("l10n", { attrs: { value: "Precision:" } })], 1),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control form-control-sm",
      attrs: { type: "number", min: "0", max: "4" },
      domProps: { value: _vm.value },
      on: { change: _vm.handleChange }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }