<template>
  <div class="d-flex flex-column">
    <my-popup
      v-if="selectedDay.date"
      class="popup"
      portal=""
      @clickoutside="hideSettingsPopup"
    >
      <div class="gp-rules-calendar-settings d-flex align-items-center">
        <l10n value="Discount" />
        <select
          ref="select"
          class="select-type ml-1"
          :class="selectClass"
          :value="selectedDay.type"
          @change="onSelectTypeChange"
        >
          <option style="color: var(--cyan)" value="eq">
            <l10n value="equals" />
          </option>
          <option style="color: var(--red)" value="le">
            <l10n value="deep or equal" />
          </option>
        </select>

        <input
          v-if="isInputVisible"
          ref="input"
          type="number"
          class="value-input ml-1"
          :value="selectedDay.value"
          @keyup.enter="onValueInputEnter"
          @keyup.escape="onValueInputEscape"
        >

        <span
          v-else
          class="btn-link ml-1"
          @click="onValueInputShow"
        >{{ selectedDay.value }} %</span>

        <button
          class="trash-btn btn p-0 ml-4"
          type="button"
          @click="onDeleteValueClick"
        >
          <feather-icon
            class="trash-icon"
            name="trash-2"
          />
        </button>
      </div>
    </my-popup>

    <v-calendar
      show-weeknumbers
      :attributes="attributes"
      :value="selectedDates"
      :available-dates="selectedDates"
      @dayclick="onDayClick"
    />

    <button
      class="btn btn-danger btn-xs w-100 mt-2"
      type="button"
      @click="onResetClick"
    >
      <l10n value="Reset force percents" />
    </button>
  </div>
</template>

<script>
const colors = {
  le: 'red',
  eq: 'blue',
};

module.exports = {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    mode: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'md_settings'].includes(value),
    },
  },

  data() {
    return {
      model: _.cloneDeep(this.value),
      selectedDay: {},
      isInputVisible: false,
    };
  },

  computed: {
    selectedDates() {
      return this.model.map((day) => day.date);
    },

    attributes() {
      return _.map(colors, (color, key) => ({
        highlight: { fillMode: 'outline', color },
        dates: this.model.filter((day) => day.type === key).map((day) => day.date),
      }));
    },

    selectClass() {
      return [
        colors[this.selectedDay.type],
      ];
    },

    inputClass() {
      const type = this.isInputVisible ? 'input' : 'span';
      return [
        `gp-rules-calendar-value__${type}`,
      ];
    },
  },

  methods: {
    onDayClick(clicked) {
      this.selectedDay = this.model.find((day) => Number(day.date) === Number(clicked.date)) || {};
      this.$set(this.selectedDay, 'value', this.selectedDay.value ?? 0);
      this.$set(this.selectedDay, 'type', this.selectedDay.type ?? 'le');
    },

    onResetClick() {
      this.model = this.model.map((day) => {
        const newDay = { ...day };
        delete newDay.value;
        delete newDay.type;
        return newDay;
      });
    },

    onSelectTypeChange(evt) {
      this.selectedDay.type = evt.currentTarget.value;
    },

    onValueInputShow() {
      this.isInputVisible = true;
      this.$nextTick(() => {
        this.$refs.input?.focus?.();
        this.$refs.input?.select?.();
      });
    },

    onValueInputEnter(evt) {
      this.selectedDay.value = parseFloat(evt.currentTarget.value);
      this.isInputVisible = false;
    },

    onValueInputEscape() {
      this.isInputVisible = false;
    },

    onDeleteValueClick() {
      this.$delete(this.selectedDay, 'value');
      this.$delete(this.selectedDay, 'type');
      this.hideSettingsPopup();
    },

    hideSettingsPopup() {
      this.selectedDay = {};
    },
  },
};
</script>

<style scoped>
.trash-btn {
  margin-top: -2px;
}

.trash-icon {
  color: var(--red);
}

.trash-icon >>> svg {
  width: 18px;
  height: 18px;
}

.value-input {
  min-width: auto;
  width: 42px;
  margin: 0;
  height: 23px;
}

.select-type {
  cursor: pointer;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  min-width: 1px;
}

.select-type:focus {
  outline: none;
}

.select-type.red {
  color: var(--red);
}

.select-type.blue {
  color: var(--cyan);
}

.gp-rules-calendar-settings {
  box-shadow: 0 0 10px 10px #00000010;
  border: 1px solid var(--cyan);
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(191,223,294);
}

.gp-rules-calendar-settings .btn-link {
  cursor: pointer;
  width: 42px;
}

.popup {
  position: relative;
  top: 30px;
  left: 280px;
}
</style>
