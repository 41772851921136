var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("markdown", {
    ref: "markdown",
    staticClass: "gp-page",
    attrs: {
      base: _vm.base,
      "dark-theme": _vm.darkTheme,
      config: _vm.config,
      editable: _vm.editable,
      username: _vm.username,
      bounds: _vm.bounds,
      path: _vm.path,
      hash: _vm.hash,
      data: _vm.dataThrottled,
      text: _vm.textThrottled,
      storage: _vm.storage,
      "storage-key": _vm.storageKey,
      "auto-height": _vm.autoHeight
    },
    on: { editing: _vm.handleEditing, edited: _vm.handleEdited }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }