var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-report" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "gp-report-loading" },
          [_c("feather-icon", { attrs: { name: "clock" } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.errors
      ? _c(
          "div",
          { staticClass: "alert alert-warning" },
          _vm._l(_vm.errors, function(error) {
            return _c("div", [_vm._v(_vm._s(error.message))])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.pageCount > 1
      ? _c(
          "div",
          {
            staticClass: "plain-table-paginator",
            attrs: { tabindex: "0" },
            on: { keydown: _vm.handlePaginatorKeyDown }
          },
          [
            _c(
              "a",
              {
                class: { disabled: _vm.page === 0 },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.setDesiredPage(_vm.page - 10)
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "chevrons-left" } })],
              1
            ),
            _c(
              "a",
              {
                class: { disabled: _vm.page === 0 },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.setDesiredPage(_vm.page - 1)
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "chevron-left" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.promptPage }
              },
              [
                _c("l10n", {
                  attrs: {
                    value: "page {page}",
                    page: new Number(_vm.page + 1).toLocaleString()
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { disabled: _vm.page === _vm.pageCount - 1 },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.setDesiredPage(_vm.page + 1)
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "chevron-right" } })],
              1
            ),
            _c(
              "a",
              {
                class: { disabled: _vm.page === _vm.pageCount - 1 },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.setDesiredPage(_vm.page + 10)
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "chevrons-right" } })],
              1
            ),
            _vm._v(" "),
            _c("l10n", {
              staticClass: "nowrap",
              attrs: {
                rows: new Number(_vm.size).toLocaleString(),
                value: "{rows} rows,"
              }
            }),
            _vm._v(" "),
            _c("l10n", {
              staticClass: "nowrap",
              attrs: {
                pages: new Number(_vm.pageCount).toLocaleString(),
                value: "{pages} pages,"
              }
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.promptPageSize }
              },
              [
                _c("l10n", {
                  staticClass: "nowrap",
                  attrs: {
                    "page-size": new Number(_vm.pageSize).toLocaleString(),
                    value: "{page-size} rows per page"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("inline-help", {
              attrs: {
                text:
                  "Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward.",
                html: true
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass:
          "table table-sm table-striped table-hover table-responsive",
        style: { opacity: _vm.loading ? 0.8 : 1 }
      },
      [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.columns, function(column, i) {
              return _c(
                "th",
                { attrs: { "data-type": column.type } },
                [
                  _c("l10n", {
                    attrs: { value: column.synonym || column.name }
                  })
                ],
                1
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.records.rows, function(record) {
            return _c(
              "tr",
              [
                _vm._l(_vm.columns, function(column, i) {
                  return [
                    _c(
                      "td",
                      {
                        attrs: {
                          set: (_vm.value = _vm.formatValue(record[i])),
                          "data-type": column.type
                        }
                      },
                      [
                        _vm.value.match(/^https?:/)
                          ? [
                              _c(
                                "a",
                                {
                                  attrs: { href: _vm.value, target: "_blank" }
                                },
                                [_vm._v(_vm._s(_vm.value))]
                              )
                            ]
                          : [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.value) +
                                  "\n                        "
                              )
                            ]
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          }),
          0
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.exportData } },
      [_c("l10n", { attrs: { value: "Export data" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }