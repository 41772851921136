var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-hierarchies-side" },
    [
      _c(
        "div",
        { staticClass: "pim-hierarchies-side-main" },
        [
          _vm.mode != "filters" && _vm.mode != "strategies"
            ? [
                _vm.entries &&
                _vm.entries.length > 1 &&
                _vm.mode == "attributes"
                  ? [_vm._m(0)]
                  : _vm.entry
                  ? [
                      _c("h2", [
                        _c(
                          "a",
                          {
                            staticStyle: { float: "right" },
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.changeName }
                          },
                          [_c("feather-icon", { attrs: { name: "edit" } })],
                          1
                        ),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.entry.name || "[name]") +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c(
                          "a",
                          {
                            staticStyle: { float: "right" },
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.changeDesc }
                          },
                          [_c("feather-icon", { attrs: { name: "edit" } })],
                          1
                        ),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.entry.desc || "[description]") +
                            "\n                "
                        )
                      ])
                    ]
                  : _c("p", [
                      _vm._v(
                        "\n                Please select a category from the hierarchy one the left side.\n            "
                      )
                    ])
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.entries && _vm.entries.length > 0) || _vm.entry
            ? _c("pim-attributes", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mode == "attributes",
                    expression: "mode == 'attributes'"
                  }
                ],
                attrs: {
                  stream: "categories",
                  focus: _vm.entry,
                  entries:
                    _vm.entries && _vm.entries.length
                      ? _vm.entries
                      : [_vm.entry],
                  search: _vm.search,
                  fields: _vm.fields,
                  username: _vm.username
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.entry && _vm.entry.type == "category"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mode == "assignment",
                      expression: "mode == 'assignment'"
                    }
                  ]
                },
                [
                  _c("h3", [_vm._v("Business rules")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "pim-hierarchies-rules" },
                    _vm._l(_vm.entryRules, function(rule) {
                      return _c(
                        "li",
                        {
                          key: rule.id,
                          staticClass: "pim-hierarchies-rule",
                          attrs: { id: rule.id }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pim-hierarchies-rule-head" },
                            [
                              _c(
                                "a",
                                {
                                  staticStyle: { float: "right" },
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.showRuleMenu = rule
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { name: "more-vertical" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$set(
                                        rule,
                                        "opened",
                                        !rule.opened
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      name: rule.opened
                                        ? "chevron-down"
                                        : "chevron-right"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(rule.name || "[name]") +
                                      "\n                        "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          rule.opened
                            ? _c(
                                "div",
                                { staticClass: "pim-hierarchies-rule-body" },
                                [
                                  _vm.$refs.strategies
                                    ? _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", [_vm._v("Strategy")]),
                                          _vm._v(" "),
                                          _c("gp-select", {
                                            attrs: {
                                              options:
                                                _vm.$refs.strategies
                                                  .savedConfigs
                                            },
                                            model: {
                                              value: rule.strategy,
                                              callback: function($$v) {
                                                _vm.$set(rule, "strategy", $$v)
                                              },
                                              expression: "rule.strategy"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        [
                                          _c(
                                            "gp-check",
                                            {
                                              model: {
                                                value: rule.hasItemFilter,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    rule,
                                                    "hasItemFilter",
                                                    $$v
                                                  )
                                                },
                                                expression: "rule.hasItemFilter"
                                              }
                                            },
                                            [_vm._v("Item filter")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      rule.hasItemFilter
                                        ? _c("gp-filter", {
                                            attrs: {
                                              stream: "items",
                                              formulas: _vm.config.formulas,
                                              attributes: _vm.config.attributes
                                            },
                                            model: {
                                              value: rule.itemFilter,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  rule,
                                                  "itemFilter",
                                                  $$v
                                                )
                                              },
                                              expression: "rule.itemFilter"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      rule.hasItemFilter
                                        ? _c(
                                            "gp-check",
                                            {
                                              model: {
                                                value:
                                                  rule.itemFilterSetAsPrimary,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    rule,
                                                    "itemFilterSetAsPrimary",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "rule.itemFilterSetAsPrimary"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Set as primary category\n                            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        [
                                          _c(
                                            "gp-check",
                                            {
                                              model: {
                                                value: rule.hasVariationFilter,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    rule,
                                                    "hasVariationFilter",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "rule.hasVariationFilter"
                                              }
                                            },
                                            [_vm._v("Variation filter")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      rule.hasVariationFilter
                                        ? _c("gp-filter", {
                                            attrs: {
                                              stream: "items",
                                              formulas: _vm.config.formulas,
                                              attributes: _vm.config.attributes
                                            },
                                            model: {
                                              value: rule.variationFilter,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  rule,
                                                  "variationFilter",
                                                  $$v
                                                )
                                              },
                                              expression: "rule.variationFilter"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      rule.hasVariationFilter
                                        ? _c(
                                            "gp-check",
                                            {
                                              model: {
                                                value:
                                                  rule.variationFilterSetAsPrimary,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    rule,
                                                    "variationFilterSetAsPrimary",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "rule.variationFilterSetAsPrimary"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Set as primary category\n                            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.addRule }
                    },
                    [
                      _c("feather-icon", { attrs: { name: "plus" } }),
                      _vm._v(
                        "\n                Add business rule\n            "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pim-hierarchies-rules-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { disabled: !_vm.rulesChanged },
                        on: { click: _vm.submitRules }
                      },
                      [
                        _vm._v(
                          "\n                    Submit changes\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-secondary",
                        attrs: { disabled: !_vm.rulesChanged },
                        on: { click: _vm.discardRules }
                      },
                      [
                        _vm._v(
                          "\n                    Discard changes\n                "
                        )
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mode == "filters",
                  expression: "mode == 'filters'"
                }
              ]
            },
            [
              _c("gp-stored", {
                ref: "filters",
                attrs: {
                  family: "filters",
                  username: _vm.username,
                  config: _vm.filter
                },
                on: {
                  saved: function($event) {
                    _vm.filter = $event
                  },
                  change: function($event) {
                    _vm.filter = $event
                  }
                }
              }),
              _vm._v(" "),
              _vm.filter
                ? [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Conditions")]),
                        _vm._v(" "),
                        _c("gp-filter", {
                          attrs: {
                            stream: "items",
                            formulas: _vm.config.formulas,
                            attributes: _vm.config.attributes
                          },
                          model: {
                            value: _vm.filter.filter,
                            callback: function($$v) {
                              _vm.$set(_vm.filter, "filter", $$v)
                            },
                            expression: "filter.filter"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mode == "strategies",
                  expression: "mode == 'strategies'"
                }
              ]
            },
            [
              _c("gp-stored", {
                ref: "strategies",
                attrs: { family: "strategies", username: _vm.username },
                model: {
                  value: _vm.strategy,
                  callback: function($$v) {
                    _vm.strategy = $$v
                  },
                  expression: "strategy"
                }
              }),
              _vm._v(" "),
              _vm.strategy
                ? [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Date start")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.strategy.dateStart,
                            expression: "strategy.dateStart"
                          }
                        ],
                        staticClass: "form-control form-control-sm",
                        attrs: { type: "datetime-local" },
                        domProps: { value: _vm.strategy.dateStart },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.strategy,
                              "dateStart",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Date end")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.strategy.dateEnd,
                            expression: "strategy.dateEnd"
                          }
                        ],
                        staticClass: "form-control form-control-sm",
                        attrs: { type: "datetime-local" },
                        domProps: { value: _vm.strategy.dateEnd },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.strategy,
                              "dateEnd",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.$refs.filters
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Item filter")]),
                            _vm._v(" "),
                            _c("gp-select", {
                              attrs: {
                                options: _vm.$refs.filters.savedConfigs
                              },
                              model: {
                                value: _vm.strategy.itemFilter,
                                callback: function($$v) {
                                  _vm.$set(_vm.strategy, "itemFilter", $$v)
                                },
                                expression: "strategy.itemFilter"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$refs.filters
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Variation filter")]),
                            _vm._v(" "),
                            _c("gp-select", {
                              attrs: {
                                options: _vm.$refs.filters.savedConfigs
                              },
                              model: {
                                value: _vm.strategy.variationFilter,
                                callback: function($$v) {
                                  _vm.$set(_vm.strategy, "variationFilter", $$v)
                                },
                                expression: "strategy.variationFilter"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.entry &&
          _vm.entry.type == "category" &&
          (_vm.mode == "assignment" ||
            _vm.mode == "filters" ||
            _vm.mode == "strategies")
            ? _c(
                "div",
                { staticClass: "pim-hierarchies-side-live" },
                [
                  _c("h3", [_vm._v("Live preview")]),
                  _vm._v(" "),
                  _vm.mode == "assignment"
                    ? _c("div", { staticClass: "form-inline" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Simulation date")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.simulationDay,
                                expression: "simulationDay"
                              }
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "date" },
                            domProps: { value: _vm.simulationDay },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.simulationDay = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("plain-table", {
                    staticStyle: { "--tile-size": "100px" },
                    attrs: {
                      stream: "items",
                      dims: ["key"],
                      vals: [
                        {
                          calc: "image",
                          type: "html",
                          name: "Item Image",
                          format: function(x) {
                            return x ? "<img src='" + x + "'>" : ""
                          }
                        },
                        {
                          calc: "name",
                          name: "Item Name"
                        }
                      ],
                      initialSort: [1],
                      filter2: _vm.previewFilter
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.showRuleMenu
        ? _c(
            "my-popup",
            {
              attrs: {
                draggable: true,
                placement: "bottom",
                anchor:
                  "#" + _vm.showRuleMenu.id + " .feather-icon-more-vertical"
              },
              on: {
                clickoutside: function($event) {
                  _vm.showRuleMenu = null
                }
              }
            },
            [
              _c("div", { staticClass: "popover pim-hierarchies-menu" }, [
                _c("div", { staticClass: "popover-body" }, [
                  _c("label", [_vm._v(_vm._s(_vm.showRuleMenu.name))]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.previewRuleVariations(_vm.showRuleMenu)
                              _vm.showRuleMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "list" } }),
                          _vm._v("Preview items\n                        ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.previewRuleVariations(_vm.showRuleMenu)
                              _vm.showRuleMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "list" } }),
                          _vm._v("Preview variations\n                        ")
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.runRule(_vm.showRuleMenu)
                              _vm.showRuleMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "play" } }),
                          _vm._v("Run strategy now\n                        ")
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.changeRuleName(_vm.showRuleMenu)
                              _vm.showRuleMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "edit" } }),
                          _vm._v("Rename this rule\n                        ")
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              _vm.removeRule(_vm.showRuleMenu)
                              _vm.showRuleMenu = null
                            }
                          }
                        },
                        [
                          _c("feather-icon", { attrs: { name: "trash" } }),
                          _vm._v("Remove this rule\n                        ")
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.entry ||
        (_vm.entries &&
          _vm.entries.find(function(entry) {
            return entry.type == "category"
          }))) &&
      _vm.fields
        ? _c("gp-data", {
            attrs: {
              stream: "categories",
              filter2:
                "id in [\n            " +
                (_vm.entries || [_vm.entry])
                  .filter(function(entry) {
                    return entry.type == "category"
                  })
                  .map(function(entry) {
                    return "'" + entry.id + "'"
                  })
                  .join(",") +
                "]",
              dims: ["id"],
              vals: _vm.fields.map(function(row) {
                return row.key
              }),
              throttled: false
            },
            model: {
              value: _vm.valuesReport,
              callback: function($$v) {
                _vm.valuesReport = $$v
              },
              expression: "valuesReport"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-info" }, [
      _vm._v("\n                    Multiple categories selected."),
      _c("br"),
      _vm._v(
        "\n                    Bulk editing mode activated.\n                "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }