<template>
  <my-popup
    v-if="isPopupVisible"
    portal="popup"
    @clickoutside="$emit('cancel')"
  >
    <template #anchor>
      <span
        class="gp-rule-knob-value"
        v-html="display"
      />
    </template>

    <div class="gp-rule-popup">
      <slot name="input" />

      <div class="gp-rule-popup-actions">
        <button
          type="button"
          class="btn btn-primary btn-xs"
          @click="$emit('submit')"
        >
          <l10n value="OK" />
        </button>

        <button
          type="button"
          class="btn btn-secondary btn-xs"
          @click="$emit('cancel')"
        >
          <l10n value="Cancel" />
        </button>
      </div>
    </div>
  </my-popup>

  <span
    v-else
    class="gp-rule-knob-value"
    @click="isPopupVisible = true"
    v-html="display"
  />
</template>

<script>
module.exports = {
  props: {
    display: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isPopupVisible: false,
    };
  },
};
</script>

<style scoped>
  .gp-rule-knob-value {
    color: var(--cyan);
    cursor: pointer;
  }
  .gp-rule-knob-value:hover {
    text-decoration: underline;
  }
  .gp-rule-popup {
      padding: 8px 8px;
      border-radius: 4px;
      display: inline-block;
      background-color: rgb(191, 223, 294);
  }
  .gp-rule-popup input,
  .gp-rule-popup select {
      margin-bottom: 6px;
  }
  .gp-rule-popup textarea + div {
      margin-bottom: 6px;
      position: relative;
  }
  .gp-rule-popup .btn.btn-xs {
      padding: 0 8px;
      font-size: 0.9em;
  }
  .gp-rule-popup-actions {
      display: flex;
      margin-right: -6px;
  }
  .gp-rule-popup-actions > * {
      flex-grow: 1;
      flex-basis: 1px;
      margin-right: 6px;
  }
  .gp-rule-popup {
      box-shadow: 0 0 10px 10px #00000010;
      border: 1px solid var(--cyan);
  }
  .my-dark-theme .gp-rule-popup {
      background-color: rgb(35,53,74);
      border: 1px solid black;
  }
  .gp-rule-popup input,
  .gp-rule-popup select {
      min-width: 150px;
  }
  .gp-rule-popup input[type="number"] {
      max-width: 150px;
  }

  .gp-rule-popup,
  .gp-filter-popup {
      padding: 10px;
      display: flex;
      flex-direction: column;
  }
  .gp-filter-values textarea {
      height: 100px;
  }
  .gp-rule-popup > *,
  .gp-filter-popup > * {
      margin-bottom: 10px!important;
  }
  .gp-rule-popup > *:last-child,
  .gp-filter-popup > *:last-child {
      margin-bottom: 0!important;
  }
  .gp-rule-popup .btn.btn-xs {
      padding: 2px 8px;
  }
  </style>
