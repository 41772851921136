<template>
  <div>
    <div class="form-group">
      <label>
        <l10n value="Optimization run name" />
      </label>

      <input
        :value="runName"
        :placeholder="configName"
        class="form-control"
        @input="onRunNameInput"
      >
    </div>

    <div class="form-group">
      <gp-check
        :checked="temporaryRun"
        @change="onTemporaryRunChange"
      >
        <l10n value="Don't save optimization results" />
      </gp-check>
    </div>

    <div
      v-if="availableOptimizationTypes.length"
      class="form-group optimization-types"
    >
      <label>
        <l10n value="Optimization type" />
      </label>

      <div>
        <gp-check
          v-for="optimizationType in availableOptimizationTypes"
          :key="optimizationType.code"
          ref="optimizationTypeCheckbox"
          :checked="checkedOptimizationTypes.includes(optimizationType.code)"
          :type="multipleTypes ? 'checkbox' : 'radio'"
          name="optimization-type"
          @click="onOptimizationTypeClick(optimizationType.code)"
        >
          <l10n :value="optimizationType.name" />
        </gp-check>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {
    configName: {
      type: String,
      default: '',
    },

    multipleTypes: {
      type: Boolean,
      default: false,
    },

    availableOptimizationTypes: {
      type: Array,
      default: () => ([]),
    },

    checkedOptimizationTypes: {
      type: Array,
      default: () => ['rbp'],
    },
  },

  data() {
    return {
      runName: '',
      temporaryRun: false,
    };
  },

  methods: {
    onRunNameInput($event) {
      this.runName = $event?.target?.value || '';
      this.$emit('set-run-name', this.runName);
    },

    onTemporaryRunChange(temporaryRun) {
      this.temporaryRun = temporaryRun;
      this.$emit('set-is-temporary-run', temporaryRun);
    },

    onOptimizationTypeClick(code) {
      let res = [];
      if (this.multipleTypes) {
        if (this.checkedOptimizationTypes.includes(code)) {
          res = this.checkedOptimizationTypes.filter((c) => c !== code);
        } else {
          res = [...this.checkedOptimizationTypes, code];
        }
      } else {
        res = [code];
      }

      this.$emit('set-checked-optimization-types', res);
    },
  },
};
</script>
