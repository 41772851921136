var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gp-news",
      on: {
        mouseenter: function($event) {
          _vm.created = true
        }
      }
    },
    [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function($event) {
              _vm.created = true
              _vm.opened = !_vm.opened
            }
          }
        },
        [_c("feather-icon", { attrs: { name: _vm.opened ? "x" : "gift" } })],
        1
      ),
      _vm._v(" "),
      _vm.created
        ? _c("gp-page", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.opened,
                expression: "opened"
              }
            ],
            ref: "page",
            attrs: {
              path: _vm.path,
              username: _vm.username,
              darkTheme: _vm.darkTheme,
              editable: true
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }