<template>
    <div class="pim-field">
        <div class="pim-field-move">
            <a href="javascript:void(0)"><feather-icon name="arrow-up"/></a>
            <a href="javascript:void(0)"><feather-icon name="arrow-down"/></a>
        </div>
        <div class="form-group">
            <label>Name</label>
            <input
                class="form-control form-control-sm"
                v-model="field.field_name">
        </div>
        <div class="form-group">
            <label>Type</label>
            <select
                class="form-control form-control-sm"
                v-model="field.field_type">
                <option value="alphanumeric">alphanumeric</option>
                <option value="boolean">boolean</option>
                <option value="date">date</option>
                <option value="datetime">datetime</option>
                <option value="decimal">decimal</option>
                <option value="dropdown">dropdown</option>
                <option value="json">json</option>
                <option value="multidropdown">multidropdown</option>
                <option value="numeric">numeric</option>
                <option value="textarea">textarea</option>
            </select>
        </div>
        <div class="form-group">
            <label>Description</label>
            <input
                class="form-control form-control-sm"
                v-model="field.description">
        </div>
        <!--div class="form-group">
            <label>API Name</label>
            <input
                class="form-control form-control-sm"
                v-model="field.api_name">
        </div-->
        <div class="form-group" v-if="field.has_locales">
            <label>Locales</label>
            <gp-check
                v-for="locale in locales"
                :key="locale.id"
                :checked="field.locale_id == locale.id">
                {{locale.name}}
            </gp-check>
        </div>
        <div class="form-group" v-if="field.has_storefronts">
            <label>Storefronts</label>
            <gp-check
                v-for="storefront in storefronts"
                :key="storefront.id"
                :checked="field.storefront_id == storefront.id">
                {{storefront.name}}
            </gp-check>
        </div>
        <div class="form-group" v-if="field.is_derived">
            <label>Computation Type</label>
            <gp-check
                type="radio"
                name="derive_type"
                :checked="computationType == 'inherit'"
                @click="$set(field, 'derive_type', 'inherit')"
                >
                Inherit
            </gp-check>
            <gp-check
                type="radio"
                name="derive_type"
                :checked="computationType == 'aggregate'"
                @click="$set(field, 'derive_type', 'aggregate')"
                :disabled="
                    field.field_type == 'alphanumeric' ||
                    field.field_type == 'textarea' ||
                    field.field_type == 'json' ||
                    field.field_type == 'dropdown' ||
                    field.field_type == 'multidropdown'">
                Aggregate
            </gp-check>
            <gp-check
                type="radio"
                name="derive_type"
                :checked="computationType == 'expression'"
                @click="$set(field, 'derive_type', 'expression')"
                >
                Expression
            </gp-check>
            <gp-check
                type="radio"
                name="derive_type"
                :checked="computationType == 'template'"
                @click="$set(field, 'derive_type', 'template')"
                >
                Template
            </gp-check>
        </div>
        <div
            class="form-group"
            v-if="computationType == 'inherit'">
            <label>Parent field</label>
            <select
                class="form-control form-control-sm"
                v-model="field.inherit_field">
                <option v-for="field in fields" if="field.id" :value="field.id">
                    {{field.description || field.name}}
                </option>
            </select>
        </div>
        <div
            class="form-group"
            v-if="computationType == 'aggregate'">
            <label>Children field</label>
            <select
                class="form-control form-control-sm"
                v-model="field.aggregate_field">
                <option v-for="field in fields" if="field.id" :value="field.id">
                    {{field.description || field.name}}
                </option>
            </select>
        </div>
        <div
            class="form-group"
            v-if="computationType == 'aggregate'">
            <label>Aggregation function</label>
            <select
                class="form-control form-control-sm"
                v-model="field.aggregate_function">
                <template v-if="field.field_type == 'decimal' || field.field_type == 'numeric'">
                    <option value="sum">Sum</option>
                    <option value="min">Minimum</option>
                    <option value="max">Maximum</option>
                    <option value="avg">Average</option>
                    <option value="med">Median</option>
                    <option value="mod">Mode</option>
                </template>
                <template v-if="field.field_type == 'boolean'">
                    <option value="all">All</option>
                    <option value="any">Any</option>
                </template>
                <template v-if="field.field_type == 'date' || field.field_type == 'datetime'">
                    <option value="min">Min</option>
                    <option value="max">Max</option>
                </template>
            </select>
        </div>
        <div
            class="form-group"
            v-if="computationType == 'expression'">
            <label>Expression</label>
            <textarea
                class="form-control form-control-sm"
                v-model="field.derived_expr"
                />
        </div>
        <div
            class="form-group"
            v-if="computationType == 'template'">
            <label>Template</label>
            <ace-editor
                :autoHeight="true"
                mode="ruby"
                :value="template && template.template_file_data || ''"
                :darkTheme="darkTheme"
                />
        </div>
        <!--p>{{field}}</p-->
    </div>
</template>
<script>
module.exports = {
    props: {
        field: { type: Object },
        fields: { rows: Array },
        locales: { type: Array },
        storefronts: { type: Array },
        template: { type: Object },
        darkTheme: { type: Boolean },
    },
    computed: {
        computationType() {
            if (this.field.is_derived) {
                if (this.field.derive_type)
                    return this.field.derive_type
                if (this.field.derived_expr)
                    return "expression"
                if (this.field.template_method)
                    return "template"
            }
        }
    }
}
</script>