var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gp-autocomplete" }, [
    _c(
      "div",
      { staticClass: "gp-autocomplete-values" },
      [
        _vm.loading
          ? _c("feather-icon", { attrs: { name: "clock" } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "textarea",
          {
            ref: "values",
            staticClass: "form-control form-control-sm",
            on: {
              focus: function($event) {
                return _vm.autocomplete.autocomplete("search")
              }
            }
          },
          [_vm._v(_vm._s(_vm.value.join("\n")))]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { ref: "autocomplete" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gp-autocomplete-popup-notes" },
      [
        _c("l10n", {
          attrs: { value: "Use multiple lines to filter by multiple values" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }