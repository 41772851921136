module.exports = _.merge({
    watch: {
        ordering:   function() { this.createChart() },
    },
    methods: {
        makeChart(instant) {
            let n = this.dims.length
            let m = this.vals.length

            let dimension = this.ndx.dimension((row) => row.slice(0, n))

            let group = dimension.group().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))

            let chart = dc.pieChart(this.$refs.chart)
                .dimension(dimension)
                .group(group)

            return chart
        },
    },
}, require("./props").chartProps({
    cx:                     { type: Number },
    cy:                     { type: Number },
    drawPaths:              { type: Boolean, default: false },
    emptyTitle:             { type: String, default: "empty" },
    externalLabels:         { type: Number, default: undefined },
    externalRadiusPadding:  { type: Number, default: 2.5 },
    innerRadius:            { type: Number, default: 0 },
    minAngleForLabel:       { type: Number, default: 0.5 },
    radius:                 { type: Number, default: undefined },
    slicesCap:              { type: Number, default: Infinity },
    ordering:               { type: [Function, String], default: (d) => -d.value[0] },
}))
