<template>
  <div class="d-flex flex-column">
    <v-calendar
      ref="calendar"
      class="calendar"
      show-weeknumbers
      :attributes="attributes"
      :value="selectedDates"
      @weeknumberclick="selectDates"
      @dayclick="onDayClick"
      @update:from-page="updateWeekdayClick"
    />

    <button
      class="btn btn-danger btn-xs w-100 mt-2"
      type="button"
      @click="onResetClick"
    >
      <l10n value="Reset dates" />
    </button>
  </div>
</template>

<script>
module.exports = {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    mode: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'md_settings'].includes(value),
    },
  },

  data() {
    return {
      model: _.cloneDeep(this.value),
    };
  },

  computed: {
    selectedDates() {
      return this.model.map((day) => day.date);
    },

    availableDates() {
      return [];
    },

    attributes() {
      return [{
        highlight: true,
        dates: this.selectedDates,
      }];
    },
  },

  methods: {
    updateWeekdayClick() {
      setTimeout(() => {
        const weekDays = this.$refs.calendar?.$el.querySelectorAll('.vc-weekday:not(:first-child)');
        if (weekDays?.length) {
          weekDays.forEach((x, i) => {
            x.addEventListener('click', (event) => {
              const days = this.$refs.calendar.pages[0].days.filter((day) => day.weekdayPosition === i + 1 && day.inMonth);
              this.selectDates({ i, days, event });
            });
          });
        }
      }, 200);
    },

    selectDates({ days }) {
      const notSelectedDays = days.filter((day) => !this.model.some((modelDay) => Number(day.date) === Number(modelDay.date)));
      if (notSelectedDays.length) {
        notSelectedDays.forEach((day) => this.onDayClick(day));
      } else {
        days.forEach((day) => this.onDayClick(day));
      }
    },

    onDayClick(clicked) {
      const isDaySelected = this.model.find((day) => Number(day.date) === Number(clicked.date));
      if (isDaySelected) {
        this.model = this.model.filter((day) => Number(day.date) !== Number(clicked.date));
      } else {
        this.model = [...this.model, { date: clicked.date }];
      }
    },

    onResetClick() {
      this.model = [];
    },
  },
};
</script>

<style scoped>
.calendar >>> .vc-weekday {
  padding-top: 7px;
  padding-top: 7px;
}

.calendar >>> .vc-weeknumber-content,
.calendar >>> .vc-weekday {
  border-radius: var(--rounded-full);
}

.calendar >>> .vc-weeknumber-content:hover,
.calendar >>> .vc-weekday:not(:first-child):hover {
  background-color: rgba(204,214,224,.3);
  cursor: pointer;
}
</style>
