var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-tree-table" },
    [
      _c("plain-table", {
        ref: "table",
        attrs: {
          stream: "default",
          dims: _vm.dims,
          vals: _vm.vals,
          provider: _vm.provider,
          totals: false,
          tableActions: _vm.tableActions,
          throttled: false,
          initialSort: [_vm.hasImage ? 2 : 1]
        },
        on: {
          "row-selected": _vm.rowSelected,
          "rows-selected": _vm.rowsSelected
        }
      }),
      _vm._v(" "),
      _vm.showColumnSelector
        ? _c(
            "my-popup",
            { attrs: { anchor: _vm.$refs.table.$refs.showTableActions } },
            [
              _c("div", { staticClass: "popover" }, [
                _c(
                  "div",
                  { staticClass: "popover-body" },
                  [
                    _vm.$refs.attributes
                      ? _c("pim-columns", {
                          ref: "columns",
                          attrs: {
                            type: _vm.type,
                            fields: _vm.fields,
                            columns: _vm.columns
                          },
                          on: {
                            submit: function($event) {
                              _vm.showColumnSelector = false
                              _vm.columns = $event
                            },
                            cancel: function($event) {
                              _vm.showColumnSelector = false
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pim-attributes-ex", {
        ref: "attributes",
        attrs: {
          show: false,
          entries: _vm.entries,
          fields: _vm.attributesFields
        },
        on: {
          change: function($event) {
            _vm.attributes = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }