var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gp-portal", { attrs: { to: _vm.portal } }, [
    _c(
      "div",
      { staticClass: "modal fade show", staticStyle: { display: "block" } },
      [
        _c("div", { ref: "dialog", class: _vm.dialogClass }, [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _c(
                  "h5",
                  { staticClass: "modal-title" },
                  [
                    _c("l10n", { attrs: { value: _vm.title } }),
                    _vm._v(" "),
                    _vm._t("title")
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "javascript:void(0)",
                      "aria-label": "Close"
                    },
                    on: { click: _vm.onClose }
                  },
                  [_c("feather-icon", { attrs: { name: "close" } })],
                  1
                ),
                _vm._v(" "),
                _vm._t("header")
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [_vm._t("default")], 2),
            _vm._v(" "),
            _vm.hasFooter
              ? _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }