var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-column-stats" },
    [
      _c(
        "a",
        {
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function($event) {
              _vm.expanded = !_vm.expanded
            }
          }
        },
        [
          _c("feather-icon", {
            attrs: { name: _vm.expanded ? "chevron-down" : "chevron-right" }
          }),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Statistics" } })
        ],
        1
      ),
      _vm._v(" "),
      _vm.expanded
        ? [
            _c("table", [
              _c("tbody", [
                _c("tr", [
                  _c("th", [_c("l10n", { attrs: { value: "Min:" } })], 1),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.minValue)))])
                ]),
                _vm._v(" "),
                _vm.vals.length >= 10
                  ? _c("tr", [
                      _c(
                        "th",
                        [_c("l10n", { attrs: { value: "10% quantile:" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.q10Value)))])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.vals.length >= 10
                  ? _c("tr", [
                      _c(
                        "th",
                        [_c("l10n", { attrs: { value: "25% quantile:" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.q25Value)))])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_c("l10n", { attrs: { value: "Avg:" } })], 1),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.avgValue)))])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_c("l10n", { attrs: { value: "Median:" } })], 1),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.medValue)))])
                ]),
                _vm._v(" "),
                _vm.modValues.length > 0
                  ? _c("tr", [
                      _c("th", [_c("l10n", { attrs: { value: "Modes:" } })], 1),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.modValues.map(_vm.formatValue).join(" "))
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.vals.length >= 10
                  ? _c("tr", [
                      _c(
                        "th",
                        [_c("l10n", { attrs: { value: "75% quantile:" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.q75Value)))])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.vals.length >= 10
                  ? _c("tr", [
                      _c(
                        "th",
                        [_c("l10n", { attrs: { value: "90% quantile:" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.q90Value)))])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_c("l10n", { attrs: { value: "Max:" } })], 1),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.maxValue)))])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_c("l10n", { attrs: { value: "Sum:" } })], 1),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.formatValue(_vm.sumValue)))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                model: {
                  value: _vm.excludeZeros,
                  callback: function($$v) {
                    _vm.excludeZeros = $$v
                  },
                  expression: "excludeZeros"
                }
              },
              [_c("l10n", { attrs: { value: "Exclude zero values" } })],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }