var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gp-data", {
    staticClass: "pim-entries-attrs",
    attrs: {
      stream: _vm.tableConfig.stream,
      source: _vm.tableConfig.source,
      dims: _vm.tableConfig.dims,
      vals: _vm.tableConfig.vals,
      cols: _vm.tableConfig.cols,
      filter1: _vm.tableConfig.filter1,
      filter2: _vm.tableConfig.filter2
    },
    model: {
      value: _vm.report,
      callback: function($$v) {
        _vm.report = $$v
      },
      expression: "report"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }