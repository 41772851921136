var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gp-data", {
    attrs: {
      stream: _vm.stream,
      dims: _vm.dims,
      vals: _vm.vals,
      cols: _vm.cols,
      sort: _vm.sort,
      filter1: _vm.filter1,
      filter2: _vm.filter2
    },
    model: {
      value: _vm.report,
      callback: function($$v) {
        _vm.report = $$v
      },
      expression: "report"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }