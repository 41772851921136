var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "small",
    { staticClass: "text-muted" },
    [
      _c("markdown", {
        attrs: {
          text: _vm.text,
          html: false,
          breaks: false,
          emoji: false,
          "xhtml-out": false,
          "task-lists": false,
          quotes: "\"\"''"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }