<template>
    <span><slot/></span>
</template>
<script>
module.exports = {
    mounted() {
        $(this.$el).tooltip({
            track: true,
            show: false,
            hide: false,
        })
    },
    beforeDestroy() {
        $(this.$el).tooltip("destroy")
    },
}
</script>