var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model.number",
        value: _vm.model,
        expression: "model",
        modifiers: { number: true }
      }
    ],
    ref: "input",
    attrs: { type: "number" },
    domProps: { value: _vm.model },
    on: {
      keyup: [
        function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.$emit("submit")
        },
        function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
          ) {
            return null
          }
          return _vm.$emit("cancel")
        }
      ],
      input: function($event) {
        if ($event.target.composing) {
          return
        }
        _vm.model = _vm._n($event.target.value)
      },
      blur: function($event) {
        return _vm.$forceUpdate()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }