<template>
  <div
    v-if="stats"
    class="gp-rules-stats"
  >
    <div>
      <l10n
        value="{count} price changes"
        :count="new Number(stats.changes).toLocaleString()"
      />
    </div>

    <div v-if="stats.increased">
      <l10n
        value="{count} price increased on {avg} in average"
        :count="new Number(stats.increased).toLocaleString()"
        :avg="formatPercent(stats.avg_increase * 100)"
      />
    </div>

    <div v-if="stats.decreased">
      <l10n
        value="{count} price decreased on {avg} in average"
        :count="new Number(stats.decreased).toLocaleString()"
        :avg="formatPercent(stats.avg_decrease * -100)"
      />
    </div>

    <div v-if="stats.dropped_impact > 0">
      <l10n
        value="{count} price changes are dropped due to metric impact limit"
        :count="new Number(stats.dropped_impact).toLocaleString()"
      />
    </div>

    <div v-if="stats.dropped_limits > 0">
      <l10n
        value="{count} price changes are dropped due to price change limit"
        :count="new Number(stats.dropped_limits).toLocaleString()"
      />
    </div>

    <div v-if="stats.dropped_markdown_budget > 0">
      <l10n
        value="{count} price changes are dropped due to markdown budget limit"
        :count="new Number(stats.dropped_markdown_budget).toLocaleString()"
      />
    </div>

    <div v-if="stats.errors > 0">
      <l10n
        value="{errors} optimization tasks failed"
        :errors="new Number(stats.errors).toLocaleString()"
      />
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {
    stats: {
      type: Object,
      default: null,
    },

    locale: {
      type: String,
      default: 'en-US',
    },
  },

  methods: {
    formatPercent(x) {
      return Number(x / 100).toLocaleString(this.locale, {
        style: 'percent',
        maximumFractionDigits: 1,
      });
    },
  },
};
</script>

<style scoped>
.gp-rules-stats {
  padding: 10px 15px;
  margin: 10px 0;
  margin-bottom: 20px;
  border: 2px solid var(--cyan);
  border-radius: 10px;
  font-size: 0.95em;
}
</style>
