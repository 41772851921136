module.exports = _.merge({
    watch: {
        ordering:   function() { this.createChart() },
    },
    methods: {
        makeChart(base) {
            let chart = dc.compositeChart(base)
            let nestedCharts = []
            _.defer(() => {
                for (slot of this.$slots.default) {
                    if (slot.componentInstance)
                        if (slot.componentInstance.chart)
                            nestedCharts.push(slot.componentInstance.chart)
                }

                chart.compose(nestedCharts)
                _.defer(() => chart.render())
            })
            window.compositeChart = chart
            return chart
        }
    },
}, require("./props").chartProps({
    barPadding:     { type: Number, default: 0.5 },
    outerPadding:   { type: Number, default: 0.8 },
    ordering:       { type: [Function, String], default: (d) => d.key[0] },
    keyAccessor:    { type: [Function, String], default: (d) => d.key.length == 1 ? d.key[0] : d.key.join("\n") },
    alignYAxes:     { type: Boolean, default: false },
    rightY:         { type: [Function, String], default: "d3.scaleLinear().domain([])" },
    shareColors:    { type: Boolean, default: false },
    shareTitle:     { type: Boolean, default: true },
    useRightAxisGridLines: { type: Boolean, default: false },
}))
