var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fixedData != null || _vm.floatData != null
    ? _c("gp-portal", { attrs: { to: "tooltip" } }, [
        _c(
          "div",
          {
            ref: "tooltip",
            class: {
              "my-tooltip": true,
              "my-tooltip-float":
                _vm.fixedData == null && _vm.floatData != null,
              "my-tooltip-fixed": _vm.fixedData != null
            },
            style: _vm.style
          },
          [
            _vm.fixedData != null
              ? _c(
                  "div",
                  { staticClass: "my-tooltip-actions" },
                  [
                    _vm.actions
                      ? _vm._l(_vm.actions, function(action, n) {
                          return _c(
                            "button",
                            {
                              staticClass: "btn btn-sm",
                              class: {
                                "btn-outline-primary": n == 0,
                                "btn-outline-secondary": n != 0
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return action.call(_vm.fixedData)
                                }
                              }
                            },
                            [_c("l10n", { attrs: { value: action.name } })],
                            1
                          )
                        })
                      : [
                          _vm.keepOnly
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-outline-primary",
                                  attrs: { type: "button" },
                                  on: { click: _vm.triggerKeepOnly }
                                },
                                [_c("l10n", { attrs: { value: "Keep only" } })],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.exclude
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-outline-secondary",
                                  attrs: { type: "button" },
                                  on: { click: _vm.triggerExclude }
                                },
                                [_c("l10n", { attrs: { value: "Exclude" } })],
                                1
                              )
                            : _vm._e()
                        ]
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("strong", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _vm.data != null
              ? _c("table", { staticClass: "my-tooltip-values" }, [
                  _c(
                    "tbody",
                    [
                      _vm.accessor
                        ? _vm._l(_vm.accessor(_vm.data), function(entry) {
                            return _c("tr", [
                              _c("td", [
                                entry.color
                                  ? _c("span", {
                                      staticClass: "my-tooltip-marker",
                                      style: { "background-color": entry.color }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(entry.name) + ":")])
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(entry.value))])
                            ])
                          })
                        : _vm.meta != null
                        ? _vm._l(
                            _vm.meta.columns.slice(_vm.data.key.length),
                            function(column, i) {
                              return column.name
                                ? _c("tr", [
                                    _c("td", [
                                      _vm._v(_vm._s(column.name) + ":")
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.format(
                                            _vm.data.others
                                              ? _vm.data.value
                                              : _vm.data.value[i],
                                            column
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            }
                          )
                        : _vm._l(_vm.data.value, function(value) {
                            return _c("tr", [
                              _vm.data.layer
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.data.layer) + ":")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.format(value)))])
                            ])
                          })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }