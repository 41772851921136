var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-scopes" },
    [
      _c("plain-table", {
        attrs: {
          stream: _vm.stream,
          source: _vm.source,
          groups: _vm.groups,
          dims: _vm.dims,
          vals: _vm.vals,
          cols: _vm.cols,
          timeZone: _vm.timeZone,
          expandTagName: _vm.expandTagName,
          expandProps: {
            stream: _vm.stream,
            source: _vm.source,
            groups: _vm.groups,
            attributes: _vm.attributes,
            timeZone: _vm.timeZone
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }