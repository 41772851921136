<template>
    <div class="pim-workflow">
        <template v-for="error in errors">
            <p class="alert alert-warning">{{error}}</p>
        </template>
        <a v-show="!editable" href="javascript:void(0)" @click="editable=true">edit workflow</a>
        <div class="pim-workflow-body">
            <div ref="palette" class="pim-workflow-palette" v-show="editable"/>
            <div ref="diagram" class="pim-workflow-diagram" :style="style"/>
        </div>
        <div class="pim-workflow-actions" v-if="editable">
            <button class="btn btn-sm btn-primary" @click="submit">Submit changes</button>
            <button class="btn btn-sm btn-secondary" @click="discard">Discard changes</button>
        </div>
    </div>
</template>
<script>
module.exports = {
    props: {
        settings: { type: Object, default: () => ({}) },
        nodeTemplate: { type: [Function, String] },
        linkTemplate: { type: [Function, String] },
        nodeTemplateMap: { type: Object },
        linkTemplateMap: { type: Object },
        diagramLayout: { type: [Function, String] },
        paletteLayout: { type: [Function, String] },
        diagramModel: { type: Object },
        paletteModel: { type: Object },
        autoheight: { type: Boolean },
    },
    data() {
        return {
            errors: {},
            editable: false,
            diagram: null,
            palette: null,
            contextMenu: null,
            bounds: null,
            selection: null,
            defaultDiagramLayout: null,
            defaultPaletteLayout: null,
            defaultLinkTemplate: null,
            defaultNodeTemplate: null,
            defaultLinkTemplateMap: null,
            defaultNodeTemplateMap: null,
        }
    },
    watch: {
        editable() {
            if (this.diagram) {
                this.diagram.requestUpdate()
                this.diagram.isReadOnly = !this.editable
            }
        },
        selection() {
            this.$emit("selection", this.selection)
        },
        nodes(nodes, oldNodes) {
            if (!_.isEqual(nodes, oldNodes))
                this.updateModel()
        },
        links(links, oldLinks) {
            if (!_.isEqual(links, oldLinks))
                this.updateModel()
        },
        diagramModel: {
            deep: true,
            handler(model, oldModel) {
                // if (!_.isEqual(model, oldModel))
                    this.updateDiagramModel()
                // else
                    // this.diagram?.requestUpdate()
            }
        },
        paletteModel(model, oldModel) {
            if (!_.isEqual(model, oldModel))
                this.updatePaletteModel()
        },
        nodeTemplate() {
            this.updateNodeTemplate()
        },
        linkTemplate() {
            this.updateLinkTemplate()
        },
        nodeTemplateMap() {
            this.updateNodeTemplateMap()
        },
        linkTemplateMap() {
            this.updateLinkTemplateMap()
        },
        diagramLayout() {
            this.updateDiagramLayout()
        },
        paletteLayout() {
            this.updatePaletteLayout()
        },
        settings(settings, oldSettings) {
            if (!_.isEqual(settings, oldSettings))
                this.createDiagram()
        },
        height() {
            if (this.autoheight)
                this.diagram?.requestUpdate()
        },
    },
    computed: {
        height() {
            return this.bounds ? this.bounds.height : null
        },
        style() {
            return {
                'min-height': this.autoheight && this.height != null ? this.height + "px" : null
            }
        },
    },
    methods: {
        nodeStyle() {
            return [
                new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
                {
                    locationSpot: go.Spot.Center
                }
            ]
        },
        makePort(name, align, spot, output, input) {
            const $ = go.GraphObject.make
            let horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom)
            return $(go.Shape, {
                fill: "transparent",
                strokeWidth: 0,
                width: horizontal ? 40 : 8,
                height: !horizontal ? 40 : 8,
                alignment: align,
                stretch: (horizontal ? go.GraphObject.Horizontal : go.GraphObject.Vertical),
                portId: name,
                fromSpot: spot,
                fromLinkable: output,
                toSpot: spot,
                toLinkable: input,
                cursor: "pointer",
                mouseEnter: (e, port) => { if (!e.diagram.isReadOnly) port.fill = "rgba(255,0,255,0.5)" },
                mouseLeave: (e, port) => { port.fill = "transparent" },
            })
        },
        updateDiagramModel() {
            this.diagram.model = go.Model.fromJson(this.diagramModel || {})
        },
        updatePaletteModel() {
            this.palette.model = go.Model.fromJson(this.paletteModel || {})
        },
        resolveProperty(prop, defval) {
            this.$delete(this.errors, prop)
            let value = this[prop]
            if (_.isString(value))  {
                try {
                    value = eval(value)
                }
                catch (ex) {
                    value = null
                    this.$set(this.errors, prop, ex.message)
                }
            }
            if (_.isFunction(value)) {
                try {
                    value = value(this)
                }
                catch (ex) {
                    value = null
                    this.$set(this.errors, prop, ex.message)
                }
            }
            return value != null ? value : defval
        },
        resolvePropertyMap(prop, defval) {
            this.$delete(this.errors, prop)
            let map = new go.Map()
            let valueMap = this[prop]
            if (_.isPlainObject(valueMap)) {
                for (let key of _.keys(valueMap)) {
                    let value = valueMap[key]
                    if (_.isString(value))  {
                        try {
                            value = eval(value)
                        }
                        catch (ex) {
                            value = null
                            this.$set(this.errors, prop, ex.message)
                        }
                    }
                    if (_.isFunction(value)) {
                        try {
                            value = value(this)
                        }
                        catch (ex) {
                            value = null
                            this.$set(this.errors, prop, ex.message)
                        }
                    }

                    if (value != null)
                        map.add(key, value)
                }
            }
            return this.errors[prop] ? defval : map
        },
        updateDiagramLayout() {
            this.diagram.layout = this.resolveProperty("diagramLayout", this.defaultDiagramLayout)
        },
        updatePaletteLayout() {
            this.palette.layout = this.resolveProperty("paletteLayout", this.defaultPaletteLayout)
        },
        updateNodeTemplate() {
            this.diagram.nodeTemplate = this.resolveProperty("nodeTemplate", this.defaultNodeTemplate)
        },
        updateLinkTemplate() {
            this.diagram.linkTemplate = this.resolveProperty("linkTemplate", this.defaultLinkTemplate)
        },
        updateNodeTemplateMap() {
            this.diagram.nodeTemplateMap = this.resolvePropertyMap("nodeTemplateMap", this.defaultNodeTemplateMap)
            this.palette.nodeTemplateMap = this.diagram.nodeTemplateMap
        },
        updateLinkTemplateMap() {
            this.diagram.linkTemplateMap = this.resolvePropertyMap("linkTemplateMap", this.defaultLinkTemplateMap)
            this.palette.linkTemplateMap = this.diagram.linkTemplateMap
        },
        deleteDiagram() {
            if (this.diagram) {
                if (window.diagram == this.diagram)
                    delete window.diagram
                this.contextMenu = null
                this.selection = null
                this.diagram.div = null
                this.diagram = null
            }
        },
        async createDiagram() {
            this.deleteDiagram()
            let go = await import("../../node_modules/gojs/release/go.js")
            window.go = go

            const $ = go.GraphObject.make

            diagram = new go.Diagram(this.$refs.diagram, this.settings)
            diagram.animationManager.initialAnimationStyle = go.AnimationManager.None
            window.diagram = this.diagram = diagram

            diagram.isReadOnly = !this.editable

            this.defaultDiagramLayout = diagram.layout
            this.defaultLinkTemplate = diagram.linkTemplate
            this.defaultNodeTemplate = diagram.nodeTemplate
            this.defaultLinkTemplateMap = diagram.linkTemplateMap
            this.defaultNodeTemplateMap = diagram.nodeTemplateMap

            this.contextMenu = $(go.HTMLInfo, {
                show: this.showContextMenu,
                hide: this.hideContextMenu
            });

            diagram.contextMenu = this.contextMenu

            diagram.addDiagramListener("InitialLayoutCompleted", this.updateBounds)
            diagram.addDiagramListener("LayoutCompleted", this.updateBounds)
            diagram.addDiagramListener("DocumentBoundsChanged", this.updateBounds)
            diagram.addDiagramListener("ChangedSelection", () => {
                let selection = []
                diagram.selection.iterator.each(x => selection.push(x.data))
                this.selection = selection
            })

            let palette = $(go.Palette, this.$refs.palette, {
                "animationManager.initialAnimationStyle": go.AnimationManager.None,
            })

            this.defaultPaletteLayout = palette.layout

            window.palette = this.palette = palette

            this.updateNodeTemplateMap()
            this.updateLinkTemplateMap()
            this.updateNodeTemplate()
            this.updateLinkTemplate()
            this.updateDiagramLayout()
            this.updatePaletteLayout()
            this.updateDiagramModel()
            this.updatePaletteModel()
        },
        updateBounds() {
            this.bounds = this.diagram.computeBounds()
        },
        showContextMenu(obj, diagram, tool) {
            let viewPoint = diagram.lastInput.viewPoint
        },
        hideContextMenu() {

        },
        submit() {
            this.editable = false
        },
        discard() {
            this.editable = false
            let model = _.cloneDeep(this.diagramModel)
            for (let link of model.linkDataArray || []) {
                delete link.points
                delete link.__gohashid
            }
            for (let node of model.nodeDataArray || []) {
                delete node.loc
                delete node.__gohashid
            }
            this.diagram.model = go.Model.fromJson(model)
            this.diagram.requestUpdate()
        }
    },
    mounted() {
        this.createDiagram()
    },
    beforeDestroy() {
        this.deleteDiagram()
    },
}
</script>
<style>
</style>