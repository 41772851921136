const component = require('../../input/number.vue').default;

module.exports = ({
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  currency = 'USD',
  locale = 'en-US',
  maximumFractionDigits = 2,
} = {}) => {
  const valueToInput = (x) => x;
  const inputToValue = (x) => x;
  const display = (x) => {
    if (x <= min) return '-∞';
    if (x >= max) return '+∞';

    return Number(x).toLocaleString(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits,
    });
  };

  return {
    component,
    valueToInput,
    inputToValue,
    display,
  };
};
