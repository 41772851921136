var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "number-display",
      style: { opacity: _vm.reportId ? 0.5 : 1 }
    },
    [
      _c("my-errors", {
        attrs: { errors: _vm.errors },
        on: { retry: _vm.requestData }
      }),
      _vm._v(" "),
      _c("my-caption", { attrs: { caption: _vm.caption } }),
      _vm._v(" "),
      _c("my-progress", {
        key: _vm.reportId,
        attrs: {
          progress: _vm.progress,
          processRows: _vm.processRows,
          processTime: _vm.processTime,
          meta: _vm.meta,
          exportToFile: _vm.exportToFile,
          assembly: _vm.assembly
        }
      }),
      _vm._v(" "),
      _c("my-filters", {
        attrs: { chart: _vm.chart },
        model: {
          value: _vm.filters,
          callback: function($$v) {
            _vm.filters = $$v
          },
          expression: "filters"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "base", staticClass: "my-chart", style: _vm.computedStyle },
        [_vm._t("default"), _vm._v(" "), _c("div", { ref: "chart" })],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }