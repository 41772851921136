const component = require('../../input/calendar/calendar-md-settings.vue').default;

module.exports = ({
  mode,
}) => {
  const valueToInput = (x) => x
    .map((day) => ({
      ...day,
      date: new Date(day.date),
    }));
  const inputToValue = (x) => x
    .map((day) => ({
      ...day,
      date: Number(day.date),
    }));
  const display = (x) => x
    .filter(({ type }) => type)
    .sort((a, b) => a.date - b.date)
    .map(({ date, type, value }) => `${new Date(date).toLocaleDateString()}: ${type === 'le' ? '>=' : ''}${value}`)
    .join(', ');

  return {
    component,
    valueToInput,
    inputToValue,
    display,
    mode,
  };
};
