var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gp-stored", {
        ref: "timeframes",
        attrs: {
          family: "timeframes",
          config: _vm.customTimeframeConfig,
          compact: false,
          username: _vm.username
        },
        on: { change: _vm.onCustomTimeframeConfigChange }
      }),
      _vm._v(" "),
      _c("div", [
        _c(
          "p",
          [
            _c("l10n", { attrs: { value: "Timeframes group:" } }),
            _vm._v(" "),
            _c("gp-select", {
              ref: "groupSelect",
              attrs: {
                options: _vm.groups,
                placeholder: _vm.$options.utils.l10n("Group name")
              },
              on: {
                change: _vm.onSelectChange,
                "select-input": _vm.onSelectInput
              },
              model: {
                value: _vm.selectedGroup,
                callback: function($$v) {
                  _vm.selectedGroup = $$v
                },
                expression: "selectedGroup"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          [
            _c("l10n", { attrs: { value: "Start timeframe:" } }),
            _vm._v(" "),
            _c("inline-select", {
              attrs: { options: _vm.startTypes },
              on: { input: _vm.updateCalcString },
              model: {
                value: _vm.customTimeframeConfig.startType,
                callback: function($$v) {
                  _vm.$set(_vm.customTimeframeConfig, "startType", $$v)
                },
                expression: "customTimeframeConfig.startType"
              }
            }),
            _vm._v(" "),
            _vm.customTimeframeConfig.startType === "fixDate"
              ? _c("inline-input", {
                  attrs: { type: "date" },
                  on: { input: _vm.updateCalcString },
                  model: {
                    value: _vm.customTimeframeConfig.startValueDate,
                    callback: function($$v) {
                      _vm.$set(_vm.customTimeframeConfig, "startValueDate", $$v)
                    },
                    expression: "customTimeframeConfig.startValueDate"
                  }
                })
              : [
                  _c("inline-input", {
                    attrs: { type: "number", noSpin: true },
                    on: { input: _vm.updateCalcString },
                    model: {
                      value: _vm.customTimeframeConfig.startValue,
                      callback: function($$v) {
                        _vm.$set(_vm.customTimeframeConfig, "startValue", $$v)
                      },
                      expression: "customTimeframeConfig.startValue"
                    }
                  }),
                  _vm._v(" "),
                  _c("inline-select", {
                    attrs: { options: _vm.intervals },
                    on: { input: _vm.updateCalcString },
                    model: {
                      value: _vm.customTimeframeConfig.startInterval,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.customTimeframeConfig,
                          "startInterval",
                          $$v
                        )
                      },
                      expression: "customTimeframeConfig.startInterval"
                    }
                  }),
                  _vm._v(" "),
                  _c("inline-select", {
                    staticClass: "startDirection",
                    attrs: { options: _vm.directions },
                    on: { input: _vm.updateCalcString },
                    model: {
                      value: _vm.customTimeframeConfig.startDirection,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.customTimeframeConfig,
                          "startDirection",
                          $$v
                        )
                      },
                      expression: "customTimeframeConfig.startDirection"
                    }
                  })
                ],
            _vm._v(" "),
            _c("span", [_vm._v(".")]),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "After" } }),
            _vm._v(" "),
            _c("inline-select", {
              attrs: { options: _vm.transforms },
              on: { input: _vm.updateCalcString },
              model: {
                value: _vm.customTimeframeConfig.startTransform,
                callback: function($$v) {
                  _vm.$set(_vm.customTimeframeConfig, "startTransform", $$v)
                },
                expression: "customTimeframeConfig.startTransform"
              }
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "p",
          [
            _c("l10n", { attrs: { value: "End timeframe:" } }),
            _vm._v(" "),
            _c("inline-select", {
              attrs: { options: _vm.endTypes },
              on: { input: _vm.updateCalcString },
              model: {
                value: _vm.customTimeframeConfig.endType,
                callback: function($$v) {
                  _vm.$set(_vm.customTimeframeConfig, "endType", $$v)
                },
                expression: "customTimeframeConfig.endType"
              }
            }),
            _vm._v(" "),
            _vm.customTimeframeConfig.endType === "fixDate"
              ? _c("inline-input", {
                  attrs: { type: "date" },
                  on: { input: _vm.updateCalcString },
                  model: {
                    value: _vm.customTimeframeConfig.endValueDate,
                    callback: function($$v) {
                      _vm.$set(_vm.customTimeframeConfig, "endValueDate", $$v)
                    },
                    expression: "customTimeframeConfig.endValueDate"
                  }
                })
              : [
                  _c("inline-input", {
                    attrs: { type: "number", noSpin: true },
                    on: { input: _vm.updateCalcString },
                    model: {
                      value: _vm.customTimeframeConfig.endValue,
                      callback: function($$v) {
                        _vm.$set(_vm.customTimeframeConfig, "endValue", $$v)
                      },
                      expression: "customTimeframeConfig.endValue"
                    }
                  }),
                  _vm._v(" "),
                  _c("inline-select", {
                    attrs: { options: _vm.intervals },
                    on: { input: _vm.updateCalcString },
                    model: {
                      value: _vm.customTimeframeConfig.endInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.customTimeframeConfig, "endInterval", $$v)
                      },
                      expression: "customTimeframeConfig.endInterval"
                    }
                  }),
                  _vm._v(" "),
                  _vm.customTimeframeConfig.endType === "referenceDate"
                    ? _c("inline-select", {
                        staticClass: "endDirection",
                        attrs: { options: _vm.directions },
                        on: { input: _vm.updateCalcString },
                        model: {
                          value: _vm.customTimeframeConfig.endDirection,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.customTimeframeConfig,
                              "endDirection",
                              $$v
                            )
                          },
                          expression: "customTimeframeConfig.endDirection"
                        }
                      })
                    : _vm._e()
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "timeframe-function" },
          [
            _c("l10n", { attrs: { value: "Timeframe function" } }),
            _vm._v(" "),
            _c("span", [_vm._v(":")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("textarea", [_vm._v(_vm._s(_vm.timeframeFunction))])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }