<template>
<div class="gp-tasks">
    <div v-if="errorMessage" class="alert alert-danger">
        <a href="#" @click.prevent="errorMessage = null" class="float-right">
            <feather-icon name="x"/>
        </a>
        <small style="white-space: pre-line;">
            {{errorMessage}}
        </small>
    </div>
    <a style="float: right;" href="#" @click.prevent="refreshTasks(true)">
        <feather-icon :name="loading ? 'clock' : 'refresh-cw'"/>
    </a>
    <button class="btn btn-secondary btn-sm" @click="startingProcess = true" v-if="!startingProcess">
        <l10n value="Start process"/>
    </button>
    <div v-if="startingProcess" class="gp-process-definitions">
        <template v-if="!processDefinition">
            <label><l10n value="Click on the process to start."/></label>
            <ul>
                <li
                    v-for="processDefinition in processDefinitions"
                    :key="processDefinition.id"
                    v-if="processDefinition.key != 'invoice'">
                    <a href="#" @click.prevent="startProcess(processDefinition)">
                        {{processDefinition.name || processDefinition.key}}
                    </a>
                    –
                    <a href="#" @click.prevent="previewProcess(processDefinition)">
                        <l10n value="preview"/>
                    </a>
                </li>
            </ul>
            <div class="gp-start-process-actions">
                <button class="btn btn-sm btn-secondary" @click="startingProcess = false"><l10n value="Cancel"/></button>
            </div>  
        </template>
        <template v-else>
            <h2>{{processDefinition.name}}</h2>
            <markdown
                class="gp-task-form"
                :darkTheme="darkTheme"
                :config="config"
                :username="username"
                :data="{users, username}"
                :text="processHtml || 'Loading...'"
                ref="processHtml">
            </markdown>
            <!--div class="gp-task-form" v-if="processHtml">
                <div class="form-group">
                    <feather-icon name="bell"/>
                    <label>
                        <l10n value="Due"/>
                    </label>
                    <input
                        class="form-control form-control-sm"
                        type="datetime-local"
                        v-model="processDue"
                        />
                </div>
            </div-->


            <div class="gp-start-process-actions">
                <button class="btn btn-sm btn-secondary" @click="processDefinition = null"><l10n value="Cancel"/></button>
                <button class="btn btn-sm btn-primary" @click="submitProcess(processDefinition)"><l10n value="Start process"/></button>
            </div>
        </template>
    </div>
    <div class="gp-tasks-selection">
        <gp-check v-model="showTasksAssignedToMe"><l10n value="Show tasks assigned to me"/> ({{tasksAssignedToMe.length}})</gp-check>
        <gp-check v-model="showTasksCreatedByMe"><l10n value="Show tasks created by me"/> ({{tasksCreatedByMe.length}})</gp-check>
        <gp-check v-model="showTasksWithMyParticipation"><l10n value="Show tasks with my participation"/> ({{tasksWithMyaticipation.length}})</gp-check>
        <gp-check v-model="showUnassignedTasks"><l10n value="Show unassigned tasks"/> ({{unassignedTasks.length}})</gp-check>
        <gp-check v-model="showOverdueTasksOnly"><l10n value="Show overdue tasks only"/> ({{overdueTasks.length}})</gp-check>
    </div>
    <portal to="gp-tasks-counter">
        <span v-if="tasksAssignedToMe.length">[{{tasksAssignedToMe.length}}]</span>
    </portal>
    <ol>
        <li
            v-for="task in visibleTasks"
            :key="task.id"
            :class="{'gp-task': true, active: activeTaskId == task.id}">
            <div class="gp-task-header" @click="toogleTask(task)" @mouseenter="prefetchTask(task)">
                <span class="gp-task-assignee">
                    <template v-if="task.assignee">
                        {{task.assignee}}
                    </template>
                    <button
                        v-else
                        class="btn btn-xs btn-secondary"
                        @click.stop="claimTask(task)">
                        <l10n value="Claim"/>
                    </button>
                </span>
                    <span class="gp-task-subject">{{task.subject}}</span>
                <span class="gp-task-name">{{task.name}}</span>
                <span
                    class="gp-task-description"
                    v-if="task.description != task.name">
                    {{task.description}}
                </span>

                <span class="gp-task-priority" data-priority="task.priority">
                    <l10n v-if="task.priority < 50" value="Low priority"/>
                    <l10n v-if="task.priority > 50" value="High priority"/>
                </span>
                <span class="gp-task-dates">
                    <l10n
                        v-if="task.due"
                        value="Due {time}"
                        :time="formatTimeRelative(task.due)"/>
                    <l10n
                        v-if="task.followUp"
                        value="Follow-up {time}"
                        :time="formatTimeRelative(task.followUp)"/>
                    <l10n
                        v-if="task.created"
                        value="Created {time}"
                        :time="formatTimeRelative(task.created)"/>
                </span>
            </div>
            <template v-if="activeTaskId == task.id">
                <div class="gp-task-actions" v-if="task.creator == username">
                    <!--div class="form-group">
                        <feather-icon name="calendar"/>
                        <label>
                            <l10n value="Follow-up"/>
                        </label>
                        <input
                            class="form-control form-control-sm"
                            type="datetime-local"
                            :value="task.followUp ? formatTime(task.followUp, 'YYYY-MM-DDTHH:MM') : ''"
                            @change="$set(task, 'followUp', $event.target.value)"
                            />
                        <a
                            href="javascript:void(0)"
                            @click="$set(task, 'followUp', null)">
                            <feather-icon name="x"/>
                        </a>
                    </div-->
                    <div class="form-group">
                        <feather-icon name="bell"/>
                        <label>
                            <l10n value="Due"/>
                        </label>
                        <input
                            class="form-control form-control-sm"
                            type="datetime-local"
                            :value="task.due ? formatTime(task.due, 'YYYY-MM-DDTHH:mm') : ''"
                            @change="setDueDate(task, $event.target.value)"
                            />
                        <a
                            href="javascript:void(0)"
                            @click="setDueDate(task, null)">
                            <feather-icon name="x"/>
                        </a>
                    </div>
                    <div class="form-group">
                        <feather-icon name="user"/>
                        <label>
                            <l10n value="Assignee"/>
                        </label>
                        <select
                            class="form-control form-control-sm"
                            :value="task.assignee"
                            @change="assignTask(task, $event.target.value)"
                            >
                            <option :value="task.assignee" selected>{{task.assignee}}</option>
                            <option :value="username" v-if="username && username != task.assignee">{{username}}</option>
                            <option disabled>––––––––––––</option>
                            <option :value="user.id" v-for="user in users">{{user.id}}</option>
                        </select>
                        <a
                            href="javascript:void(0)"
                            @click="unassignTask(task)">
                            <feather-icon name="x"/>
                        </a>
                    </div>
                </div>
                <ul class="nav nav-tabs">
                    <li
                        class="nav-item"
                        v-for="tab, tabId in tabs"
                        v-if="
                            tabId == 'form' ? task.formKey :
                            tabId == 'description' ? task.description : true">
                        <a
                            :class="{
                                'nav-link': true,
                                active: activeTabId == tabId}"
                            href="javascript:void(0)"
                            @click="activeTabId = tabId">
                            <l10n :value="tab"/>
                        </a>
                    </li>
                </ul>
                <div class="gp-task-description" v-if="activeTabId == 'description' && task.description">
                    <p>{{task.description}}</p>
                </div>
                <div class="gp-task-form" v-if="activeTabId == 'form' && task.formKey">
                    <markdown
                        :darkTheme="darkTheme"
                        :config="config"
                        :username="username"
                        :data="{users, username, task}"
                        :text="getTaskForm(task)"
                        ref="taskHtml"/>
                    <div class="gp-task-form-actions">
                        <!-- <button class="btn btn-sm btn-secondary" @click="activeTaskId = null"><l10n value="Cancel"/></button> -->
                        <button
                            class="btn btn-sm btn-primary"
                            :disabled="task.assignee != username"
                            @click="submitTask(task)"><l10n value="Submit"/></button>
                        <a href="#" @click.prevent="claimTask(task)" v-if="task.assignee != username">
                            <l10n value="Claim the task to submit"/>
                        </a>
                        <div style="flex-grow: 1;"/>
                        <button v-if="task.creator == username" class="btn btn-sm btn-danger" @click="deleteProcessInstance(task)">
                            <l10n value="Delete"/>
                        </button>
                    </div>
                </div>
                <div class="gp-task-history" v-if="activeTabId == 'history'">
                    <table>
                        <tbody v-for="operations in taskHistory[task.id]">
                            <template v-for="operation, i in operations">
                                <tr>
                                    <td
                                        v-if="i == 0"
                                        :rowspan="operations.length * 2">
                                        <span>{{formatTime(operation.timestamp, 'MMM D')}}</span>
                                    </td>
                                    <td>{{formatTime(operation.timestamp, 'LT')}}</td>
                                    <td colspan="2">{{operation.operationType}}</td>
                                </tr>
                                <tr>
                                    <td>{{operation.userId}}</td>
                                    <td>{{variableName(operation.property)}}</td>
                                    <td>
                                        <template v-if="parseFloat(operation.newValue) > 1000000000000">
                                            {{new Date(parseFloat(operation.newValue)).toLocaleString()}}
                                        </template>
                                        <template v-else>{{operation.newValue}}</template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="gp-task-form-actions">
                        <!-- <button class="btn btn-sm btn-secondary" @click="activeTaskId = null"><l10n value="Cancel"/></button> -->
                        <div style="flex-grow: 1;"/>
                        <button v-if="task.creator == username" class="btn btn-sm btn-danger" @click="deleteProcessInstance(task)">
                            <l10n value="Delete"/>
                        </button>
                    </div>
                </div>
                    <!--div
                        class="form-group"
                        :key="variableId"
                        v-for="variable, variableId in taskVariables[task.id]">
                        <feather-icon v-if="variable.type == 'String'" name="edit-3"/>
                        <feather-icon v-else-if="variable.type == 'File'" name="paperclip"/>
                        <feather-icon v-else-if="variable.type == 'Double'" name="hash"/>
                        <feather-icon v-else name="code"/>
                        <label>
                            <l10n :value="variableName(variableId)"/>
                        </label>
                        <input
                            class="form-control form-control-sm"
                            v-if="variable.type == 'String'"
                            :value="variable.value"/>
                        <input
                            class="form-control form-control-sm"
                            v-if="variable.type == 'Double'"
                            type="number"
                            :value="variable.value"/>
                        <a 
                            v-if="variable.type == 'File'"
                            :href="`/engine-rest/task/${task.id}/variables/${variableId}/data`"
                            target="_blank">
                            {{variable.valueInfo.filename}}
                        </a>
                        <gp-check
                            v-if="variable.type == 'Boolean'"
                            :checked="variable.value">
                            &nbsp;
                        </gp-check>
                    </div-->
            </template>
            <!--button
                class="btn btn-xs btn-link gp-task-toogle"
                @click.stop="toogleTask(task)">
                <feather-icon :name="activeTaskId == task.id ? 'chevron-up' : 'chevron-down'"/>
            </button-->
        </li>
    </ol>
    <gp-data
        id="gp-tasks-scopes"
        v-if="product != 'pim'"
        stream="combined"
        :groups="['tasks-scopes', 'search']"
        :filter0="scopesFilter0"
        :filter1="scopesFilter1"
        :vals="scopesVals"
        v-model="scopesReport"
        />
    <my-dialog
        v-if="bpmn"
        :xlarge="true"
        title="Process definitions diagram"
        @close="bpmn = null"
        >
        <div ref="bpmn"/>
    </my-dialog>
</div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    mixins: [
        utils.referenceDateHelper
    ],
    props: {
        config: { type: Object, default: () => ({}) },
        product: { type: String },
        username: { type: String },
        darkTheme: { type: Boolean },
    },
    data() {
        return {
            loading: false,
            bpmn: null,
            tasks: [],
            users: [],
            tabs: {
                form: "Form",
                history: "History",
                description: "Description",
            },
            showTasksAssignedToMe: !(localStorage.showTasksAssignedToMe == "false"),
            showTasksCreatedByMe: !(localStorage.showTasksCreatedByMe == "false"),
            showTasksWithMyParticipation: !(localStorage.showTasksWithMyParticipation == "false"),
            showUnassignedTasks: !(localStorage.showUnassignedTasks == "false"),
            showOverdueTasksOnly: localStorage.showOverdueTasksOnly == "true",
            activeTabId: "form",
            activeTaskId: null,
            forms: {},
            taskHistory: {},
            taskVariables: {},
            processDefinitions: null,
            processDefinition: null,
            startingProcess: false,
            processDue: "",
            processHtml: null,
            skipTasksRefresh: false,
            scopesReport: null,
            errorMessage: null,
            locationHash: window.location.hash,
        }
    },
    mounted() {
        window.tasks = this
        this.refreshTasks()
        this.refreshUsers()
        // this.refreshScopes()
        this.refreshProcessDefinitions()
        this.refreshTasksTimer = setInterval(this.refreshTasks, 10000)
        window.addEventListener("hashchange", this.onLocationHashChange, false)
    },
    beforeDestroy() {
        clearInterval(this.refreshTasksTimer)
        window.removeEventListener("hashchange", this.onLocationHashChange)
    },
    computed: {
        scopes() {
            return _(this.users)
                .map(({id, access}) => [id, access.find(({stream}) => stream == "combined")?.filter1 || "true"])
                .fromPairs()
                .value()
        },
        activeTask() {
            return this.tasks.find(task => task.id == this.activeTaskId)
        },
        taskLink() {
            let params = new URLSearchParams(this.locationHash.replace(/^#/,""))
            return params.get("task")
        },
        usersWithMeta() {
            let scopes = []
            if (this.scopesReport && this.scopesReport.rows && this.scopesReport.rows[0]) {
                scopes = this.scopesReport.meta.vals.
                    filter((val, i) => this.scopesReport.rows[0][i])
                    .map(val => val.calc)
            }
            return this.users.map(user => {
                let meta = {
                    scope: this.scopes[user.id],
                    inScope: scopes.includes(this.scopes[user.id]),
                }
                for (let group of user.groups) {
                    meta[_.camelCase(group)] = true
                }
                return _.assign({}, user, {meta})
            })
        },
        usersInScope() {
            return this.usersWithMeta.filter(user => user.meta.inScope)
        },
        usersNotInScope() {
            return this.usersWithMeta.filter(user => !user.meta.inScope)
        },
        scopesFilter0() {
            return `date == \`${this.referenceDate}\``
        },
        scopesFilter1() {
            return `date == \`${this.referenceDate}\``
        },
        scopesVals() {
            return _(this.scopes).values().uniq().sortBy().value()
        },
        context() {
            return {
                user: this.username,
                users: this.usersWithMeta,
            }
        },
        visibleTasks() {
            return _(this.tasks)
                .filter(task =>
                    this.showTasksAssignedToMe && task.assignee && task.assignee == this.username ||
                    this.showTasksCreatedByMe && task.creator && task.creator == this.username ||
                    this.showTasksWithMyParticipation && task.candidateUsers?.includes(this.username) ||
                    this.showOthersTasks && task.assignee && task.assignee != this.username ||
                    this.showUnassignedTasks && !task.assignee && task.candidateUsers?.includes(this.username))
                .filter(task => !this.showOverdueTasksOnly || task.due && new Date(task.due).getTime() < Date.now())
                .sortBy(task => new Date(task.created).getTime())
                .reverse()
                .value()
        },
        tasksAssignedToMe() {
            return this.tasks.filter(task => task.assignee && task.assignee == this.username)
        },
        tasksCreatedByMe() {
            return this.tasks.filter(task => task.creator && task.creator == this.username)
        },
        tasksWithMyaticipation() {
            return this.tasks.filter(task => task.candidateUsers?.includes(this.username))
        },
        unassignedTasks() {
            return this.tasks.filter(task => !task.assignee && task.candidateUsers?.includes(this.username))
        },
        overdueTasks() {
            return this.tasks
                .filter(task =>
                    this.showTasksAssignedToMe && task.assignee && task.assignee == this.username ||
                    this.showTasksCreatedByMe && task.creator && task.creator == this.username ||
                    this.showUnassignedTasks && task.candidateUsers?.includes(this.username))
                .filter(task => task.due && new Date(task.due).getTime() < Date.now())
        }
    },
    watch: {
        taskLink(newValue) {
            if (newValue)
                this.activeTaskId = Object.keys(this.taskVariables)
                    .find(taskId => this.taskVariables[taskId].uniqueId?.value === newValue)
        },
        processHtml() {
            if (this.processHtml)
                _.defer(this.focusFirstInput)
        },
        startingProcess() {
            if (this.startingProcess) {
                this.processDue = ""
            }
        },
        processDefinition() {
            if (this.processDefinition)
                _.defer(this.focusFirstInput)
        },
        showTasksAssignedToMe() {
            localStorage.showTasksAssignedToMe = this.showTasksAssignedToMe
        },
        showTasksCreatedByMe() {
            localStorage.showTasksCreatedByMe = this.showTasksCreatedByMe
        },
        showTasksWithMyParticipation() {
            localStorage.showTasksWithMyParticipation = this.showTasksWithMyParticipation
        },
        showUnassignedTasks() {
            localStorage.showUnassignedTasks = this.showUnassignedTasks
        },
        showOverdueTasksOnly() {
            localStorage.showOverdueTasksOnly = this.showOverdueTasksOnly
        },
        username() {
            this.refreshTasks().then(() => {
                if (this.taskLink) {
                    let loads = this.tasks.map(task => this.fetchTaskVariables(task))
                    Promise.all(loads).then(() => {
                        this.activeTaskId = Object.keys(this.taskVariables)
                            .find(taskId => this.taskVariables[taskId].uniqueId?.value === this.taskLink)
                    })
                }
            })
            this.refreshUsers()
        },
        async activeTask(task, prevTask) {
            if (task && task.id != prevTask?.id) {
                this.fetchTaskHistory(task)
                Vue.nextTick(this.upateFormValues)
                await this.fetchTaskForm(task)
                await this.fetchTaskVariables(task)
                Vue.nextTick(this.upateFormValues)
            }
        },
        activeTabId() {
            let taskId = this.activeTaskId
            let task = this.tasks.find(task => task.id == taskId)
            if (this.activeTabId == "form")
                Vue.nextTick(this.upateFormValues)
            if (this.activeTabId == "history")
                this.fetchTaskHistory(task)
        }
    },
    methods: {
        onLocationHashChange() {
            this.locationHash = window.location.hash
        },
        focusFirstInput() {
            $(".gp-task-form input").first().focus()
        },
        // async refreshScopes() {
        //     let query = `
        //         query {
        //           dataset {
        //             streams {
        //               combined {
        //                 access {
        //                   username
        //                   filter0
        //                   filter1
        //                   filter2
        //                 }
        //               }
        //             }
        //           }
        //         }`
        //     let res = await (await fetch("/graphql", {
        //         method: "POST",
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({query})
        //     })).json()
        //     this.scopes = _(res.data.dataset.streams.combined.access)
        //         .map(({username, filter0, filter1, filter2}) => [username, filter1]).fromPairs().value()
        // },
        getTaskForm(task) {
            let text = this.forms[task.formKey]
            let vars = _(this.taskVariables[task.id])
                .toPairs()
                .map(([name, { type, value, valueInfo }]) => [name, type === "Json" || (valueInfo && valueInfo.serializationDataFormat == "application/json") ? JSON.parse(value) : value])
                .fromPairs()
                .value()
            let context = _.assign({}, this.context, {task}, {vars})

            return text ? Handlebars.compile(text)(context) : "Loading..."
        },
        async unassignTask(task) {
            try {
                this.skipTasksRefresh = true
                this.$set(task, "assignee", null)
                await fetch(`/engine-rest/task/${task.id}/unclaim`, {
                    method: "POST",
                })
            }
            finally {
                this.skipTasksRefresh = false
            }
        },
        async refreshProcessDefinitions() {
            let query = "latest=true&active=true&startableInTasklist=true&startablePermissionCheck=true"
            let processDefinitions = await (await fetch(`/engine-rest/process-definition?${query}`)).json()
            this.processDefinitions = processDefinitions
        },
        prefetchTask(task) {
            this.fetchTaskForm(task)
            if (!this.taskVariables[task.id])
                this.fetchTaskVariables(task)
        },
        async fetchTaskForm(task) {
            if (task.formKey && !this.forms[task.formKey]) {
                let form = await (await fetch(`/engine-rest/task/${task.id}/form`)).json()
                let html = await this.fetchFormHtml(form)
                this.$set(this.forms, task.formKey, html)
            }
        },
        async fetchTaskHistory(task) {
            let history = await (await fetch(`/engine-rest/history/user-operation?processInstanceId=${task.processInstanceId}&maxResults=50&firstResult=0`)).json()
            history = _.groupBy(history, (operation) =>
                this.formatTime(operation.timestamp, 'll'))
            this.$set(this.taskHistory, task.id, history)
        },
        async fetchTaskVariables(task) {
            let variables = await (await fetch(`/engine-rest/task/${task.id}/form-variables?deserializeValues=false`)).json()
            this.$set(this.taskVariables, task.id, variables)
        },
        async deleteProcessInstance(task) {
            if (confirm(utils.l10n("Are you sure you want to delete task {task}?")
                .replace("{task}", task.name)))
            {
                await fetch(`/engine-rest/process-instance/${task.processInstanceId}`, {
                    method: "DELETE",
                    headers: { "Content-Type": "application/json" },
                })
                this.refreshTasks()
            }
        },
        async submitTask(task) {
            let variables = await this.getVariables(this.$refs.taskHtml[0].$el)
            await fetch(`/engine-rest/task/${task.id}/submit-form`, {
                method: "POST",
                body: JSON.stringify({variables}),
                headers: { "Content-Type": "application/json" },
            })
            this.refreshTasks()
        },
        async fetchFormHtml({key, contextPath}) {
            if (key.indexOf("?") == -1)
                key = `${key}?ts=${_.now()}`
            else
                key = `${key}&ts=${_.now()}`
            let form = undefined
            if (contextPath) {
                let path = key.replace("embedded:app:", `${contextPath}/`)
                form = await (await fetch(path)).text()
            }
            else {
                form = await (await fetch(key)).text()
            }
            return form
        },
        async previewProcess(processDefinition) {
            let bpmn = await (await fetch(`/engine-rest/process-definition/${processDefinition.id}/xml`)).json()
            let BpmnViewer = (await import('bpmn-js')).default
            this.bpmn = bpmn
            this.$nextTick(async () => {
                let viewer = new BpmnViewer({
                    container: this.$refs.bpmn
                });
                window.viewer = viewer
                let result = await viewer.importXML(bpmn.bpmn20Xml)
                let { warnings } = result;
                let viewbox = viewer.get("canvas").viewbox()
                $(this.$refs.bpmn).width(viewbox.inner.width)
                $(this.$refs.bpmn).height(viewbox.inner.height)
                viewer.get("canvas").resized()
                viewer.get("canvas").zoom("fit-viewport")
                $(".modal-dialog").attr("style", `width: ${viewbox.inner.width + 34}px!important`)
            })
        },
        async startProcess(processDefinition) {
            this.processDefinition = processDefinition
            let form = await (await fetch(`/engine-rest/process-definition/${processDefinition.id}/startForm`)).json()
            let query = `userId=${this.username}`
            let html = await this.fetchFormHtml(form)
            html = Handlebars.compile(html)(this.context)
            this.processHtml = html
        },
        async getVariables(form) {
            let hasInvalidVars = false
            let variables = {}
            for (let el of $(form).find("*[cam-variable-name]")) {
                if ($(el).attr("readonly"))
                    continue
                let name = $(el).attr("cam-variable-name")
                let type = $(el).attr("cam-variable-type")
                let value = el.value
                if ($(el).attr("type") == "datetime-local" && value) {
                    value = new Date(value).toISOString()
                }
                let valueInfo = undefined
                if (type == "List<String>") {
                    type = "Object"
                    valueInfo = {
                        objectTypeName: "java.util.ArrayList",
                        serializationDataFormat: "application/json",
                    }
                    value = []
                    for (let check of $(form).find("input[type=\"checkbox\"]"))
                        if (check.checked)
                            value.push($(check).data("value"))
                    value = JSON.stringify(value)
                }
                if (type == "Double")
                    value = parseFloat(value)
                if (type == "File") {
                    if (el.files.length > 0) {
                        let file = el.files[0]
                        let data = (await new Promise((resolve, reject) => {
                            let reader = new FileReader();  
                            reader.onload = () => resolve(reader.result)
                            reader.onerror = reject
                            reader.readAsDataURL(file)
                        })).split(";base64,")[1]
                        value = data
                        valueInfo = {
                            filename: file.name,
                            mimeType: file.type,
                        }
                    }
                }
                if (type == "Boolean")
                    value = el.checked
                if ($(el).attr("required") && value === "") {
                    el.classList.add("is-invalid")
                    el.classList.remove("is-valid")
                    hasInvalidVars = true
                }
                else {
                    el.classList.add("is-valid")
                    el.classList.remove("is-invalid")
                }
                variables[name] = {value, type, valueInfo}
            }
            form.classList.add("was-validated")
            if (hasInvalidVars)
                throw "has invalid vars"
            return variables
        },
        async submitProcess(processDefinition) {
            let variables = await this.getVariables(this.$refs.processHtml.$el)
            variables.creator = {type: "String", value:this.username}
            // let query = `deserializeValues=false&variableNames=${Object.keys(variables).join("%2C")}`
            
            // let des = await (await fetch(`/engine-rest/process-definition/${processDefinition.id}/form-variables?${query}`)).json()
            if (this.processDue)
                variables.due = this.processDue

            let res = await (await fetch(`/engine-rest/process-definition/${processDefinition.id}/submit-form`, {
                method: "POST",
                body: JSON.stringify({variables}),
                headers: { "Content-Type": "application/json" },
            })).json()

            switch (res.type) {
            case "RestException":
                this.errorMessage = res.message
                break
            }
            this.processDefinition = null
            this.startingProcess = false
            this.refreshTasks()
        },
        upateFormValues() {
            let taskId = this.activeTaskId
            let variables = this.taskVariables[taskId] || {}
            for (let el of $(this.$el).find("*[cam-variable-name]")) {
                let name = $(el).attr("cam-variable-name")
                let type = $(el).attr("cam-variable-type")
                if (variables[name] !== undefined) {
                    let value = variables[name].value
                    if (el.tagName == "INPUT") {
                        if (type == "Boolean")
                            el.checked = value
                        else
                            $(el).val(value)
                    }
                    else 
                        $(el).text(value)
                }
            }
            for (let link of $(this.$el).find("a[cam-file-download]")) {
                let variable = $(link).attr("cam-file-download")
                link.target = "_blank"
                link.href = `/engine-rest/task/${taskId}/variables/${variable}/data`
                link.innerText = variables[variable]?.valueInfo?.filename || ""
            }
        },
        async setDueDate(task, due) {
            try {
                this.skipTasksRefresh = true
                this.$set(task, "due", due)
                let id = task.id
                task = await (await fetch(`/engine-rest/task/${id}`)).json()
                task.due = due ? new Date(due).toISOString().replace("Z", "+0000") : null
                await fetch(`/engine-rest/task/${id}`, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(task)
                })
            }
            finally {
                this.skipTasksRefresh = false
            }
        },
        async claimTask(task) {
            await this.assignTask(task, this.username)
        },
        async assignTask(task, userId) {
            try {
                this.skipTasksRefresh = true
                this.$set(task, "assignee", userId)
                await fetch(`/engine-rest/task/${task.id}/assignee`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({userId})
                })
            }
            finally {
                this.skipTasksRefresh = false
            }
        },
        variableName(variableId) {
            return _.capitalize(_.snakeCase(variableId).replace("_", " "))
        },
        toogleTask(task) {
            this.activeTaskId = this.activeTaskId == task.id ? null : task.id
        },
        formatTime(time, format) {
            return moment(time).format(format)
        },
        formatTimeRelative(time) {
            return moment(time).fromNow()
        },
        async refreshTasks(indicator) {
            if (this.skipTasksRefresh)
                return
            if (this.username) {
                try {
                    if (indicator)
                        this.loading = true
                    let res = await (await fetch("/engine-rest/filter/182ac375-31b5-11ec-a6b3-eebb378e6ad7/list", {
                        method: "POST",
                        body: JSON.stringify({}),
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/hal+json"
                        }
                    })).json()

                    if (!this.skipTasksRefresh)
                        this.tasks = res._embedded.task ? res._embedded.task.map(task =>
                            _.assign(task, _(task._embedded.variable)
                                    .map(({name, type, value, valueInfo}) => {
                                        if (type === "Json" || (valueInfo && valueInfo.serializationDataFormat == "application/json"))
                                            value = JSON.parse(value)
                                        return [name, value]
                                    })
                                    .fromPairs()
                                    .value())) : []
                }
                finally {
                    if (indicator)
                        this.loading = false
                }
            }
        },
        async refreshUsers() {
            if (this.username) {
                let query = `
                    query {
                        dataset {
                            users {
                                id
                                name                                
                                groups
                                access {
                                    stream
                                    filter0
                                    filter1
                                    filter2
                                }
                            }
                        }
                    }
                    `
                let res = await (await fetch("/graphql", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({query})
                })).json()
                this.users = res.data.dataset.users
            }
        }
    }
}
</script>
<style>
.gp-task-header {
    cursor: pointer;
}
.gp-task-header .btn {
    font-size: 0.9em;
}
.gp-tasks > ol {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
}
.gp-task {
    position: relative;
    padding: 8px 10px;
}
.gp-task:not(.active):hover {
    background-color: #3498db20;
}
.gp-task.active {
    background:
        linear-gradient(to bottom, #3498db20, transparent 100px),
        linear-gradient(to top, #3498db10, transparent 40px);
}
.gp-task-toogle {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    background: white;
}
.gp-task-subject {
    font-weight: bold;
    display: block;
}
.gp-task-name {
    display: block;
}
.gp-task-assignee {
    float: right;
}
.gp-task > .gp-task-description {
    margin-top: 10px;
}
.gp-task-header .gp-task-description {
    display: block;
    opacity: 0.7;
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gp-task-dates {
    display: block;
    font-size: 0.85em;
}
.gp-task-form {
    font-size: 0.9em;
    margin-top: 20px;
    margin-bottom: 20px;
}
.gp-task-form .form-group .feather-icon svg {
    width: 16px;
}

/*.gp-task-form .form-group,
.gp-task-form .control-group {
    display: flex;
    margin-bottom: 6px;
    padding: 0!important;
    vertical-align: baseline;
}
.gp-task-form .col-md-10 {
    padding: 0;
    flex-basis: 70%;
}
.gp-task-form .form-group label,
.gp-task-form .control-group label {
    padding: 1px 0;
    padding-right: 10px;
    line-height: 1.2;
    flex-basis: 30%;
    max-width: initial;
    align-self: center;
    margin-bottom: 0;
}
.gp-task-form .form-group .form-control,
.gp-task-form .control-group .controls {
    flex-basis: 80%;
}
.gp-task-form .form-group .form-control,
.gp-task-form .control-group .controls input,
.gp-task-form .control-group .controls select {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 6px;
    font-size: 1em;
}
.gp-task-form .form-group .form-control[type="checkbox"] {
    width: initial;
    height: initial;
    display: inline-block;
    padding: initial;
    margin: initial;
    line-height: initial;
    margin-top: 1px;
    margin-right: 4px;
    vertical-align: top;
}
.gp-task-form .control-group .controls p {
    margin: 2px 0;
}

*/
.gp-task-form-actions,
.gp-start-process-actions {
    text-align: right;
    margin-top: 15px;
    display: flex;
    margin-right: -10px;
}
.gp-task-form-actions > *,
.gp-start-process-actions > * {
    margin-right: 10px;
}
.gp-task-form-actions .btn,
.gp-start-process-actions .btn {
    min-width: 80px;
}
.gp-task-form-actions > a,
.gp-start-process-actions > a {
    line-height: 29px;
}
.gp-task-actions {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.85em;
    display: table;
    width: 100%;
}
.gp-task .nav {
    margin-top: 10px;
}
.gp-task .nav-link {
    padding: 4px 20px;
}
.gp-task-actions .form-group {
    display: table-row;
}
.gp-task-actions .form-group > * {
    display: table-cell;
    margin-top: 6px;
    vertical-align: baseline;
}
.gp-task-actions .form-group .feather-icon {
    width: 20px;
}
.gp-task-actions .form-group label {
    padding-right: 10px;
    line-height: 1.2;
    width: calc(30% - 20px);
}
.gp-task-actions .form-group .feather-icon-x {
    margin-left: 4px;
    color: var(--gray);
}
.gp-task-actions .form-group .form-control {
    width: 100%;
}
.gp-task-actions svg {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
}
.gp-task-actions select {
    padding: 0 4.5px;
}
.gp-task-priority {
    display: block;
    clear: right;
    float: right;
    font-size: 0.85em;
}
.gp-tasks .btn-xs {
    line-height: 22px;
    padding: 0 10px;
}
.gp-task-actions .btn-xs {
    line-height: 20px;
    padding: 0;
}
.gp-task:after {
    content: "";
    clear: both;
}
.gp-task {
    /*margin-bottom: 10px;*/
    /*padding-bottom: 10px;*/
    border-bottom: 1px solid var(--gray);
}
.gp-task-history {
    font-size: 0.9em;
    margin-bottom: 10px;
    margin-top: 10px;
}
.gp-task-history td {
    padding: 0 10px;
    vertical-align: top;
}
.gp-task-history tr:nth-child(odd) td {
    padding-top: 4px;
}
.gp-task-history tr:nth-child(odd):not(:first-child) td {
    border-top: 1px solid var(--gray);
}
.gp-task-history tr:nth-child(even) td {
    padding-bottom: 4px;
}
.gp-task-history tr:first-child td:first-child span {
    background-color: var(--light);
    line-height: 70px;
    text-align: center;
    width: 70px;
    border-radius: 50%;
    display: inline-block;
    margin: -4px;
    margin-top: 0px;
}
.gp-tasks-selection {
    margin: 20px 0;
}
</style>