<template>
    <div class="pim-columns">
        <my-search v-model="searchString" :autofocus="autofocus" ref="search"/>
        <ul>
            <li v-for="entry in currentColumns">
                <gp-check
                    :checked="true"
                    :key="entry.item.id"
                    @change="removeColumn(entry.item)"
                    >
                    <span
                        v-for="part in formatSearchItem(entry.item.name, entry.matches)"
                        :class="{matched: part.matched}"
                        >{{part.text}}</span>
                </gp-check>
            </li>
            <li><hr/></li>
            <li v-for="entry in visibleColumnCandidates">
                <gp-check
                    :checked="false"
                    :key="entry.item.name"
                    @change="addColumn(entry.item)"
                    >
                    <span
                        v-for="part in formatSearchItem(entry.item.name, entry.matches)"
                        :class="{matched: part.matched}"
                        >{{part.text}}</span>
                </gp-check>
            </li>
            <li v-if="visibleColumnCandidates.length < columnCandidates.length">
                <a href="javascript:void(0)" @click="showMore = true">
                    <l10n value="Show more..."/>
                </a>
            </li>
        </ul>
        <div class="pim-columns-actions">
            <button
                class="btn btn-sm btn-primary"
                :disabled="!somethingChanged"
                @click="$emit('submit', changes.columns)">
                <l10n value="OK"/>
            </button>
            <button
                class="btn btn-sm btn-secondary"
                @click="$emit('cancel')">
                <l10n value="Cancel"/>
            </button>
        </div>
    </div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    mixins: [
        require("./pim-helper.js")
    ],
    props: {
        type:       { type: String },
        fields:     { type: Array, default: () => [] },
        columns:    { type: Array, default: () => [] },
        threshold:  { type: Number, default: 100 },
        autofocus:  { type: Boolean, default: false },
    },
    data() {
        return {
            searchString: "",
            changes: {},
            showMore: false,
        }
    },
    mounted() {
        if (this.autofocus)
            this.$refs.search.$el.focus()
    },
    watch: {
        searchString() {
            this.showMore = false
        }
    },
    methods: {
        formatSearchItem(item) {
            return utils.formatSearchItem(item)
        },
        editableColumns() {
            if (!this.changes.columns) 
                this.$set(this.changes, "columns", _.clone(this.columns))
            return this.changes.columns
        },
        addColumn(column) {
            this.editableColumns().push(column)
        },
        removeColumn(column) {
            let columns = this.editableColumns()
            let i = _.findIndex(columns, ({id}) => id === column.id)
            if (i !== -1)
                columns.splice(i,1)
        },
        applySearch(columns) {
            return this.searchString ?
                new Fuse(columns, {
                    isCaseSensitive: false,
                    shouldSort: true,
                    includeMatches: true,
                    keys: ["name"]
                }).search(this.searchString) : columns.map((column) => ({item:column}))
        },
    },
    computed: {
        visibleGroups() {
            return _.groupBy(this.visibleColumnCandidates, "group")
        },
        somethingChanged() {
            return !_.isEmpty(this.changes)
        },
        currentColumns() {
            return this.applySearch(this.changes.columns || this.columns)
        },
        columnCandidates() {
            let currentColumns = new Set(_.map(this.currentColumns, "item.id"))

            let columns = []
            let fieldGroups = this.fieldGroups(this.type)

            for (let field of this.fields)
                if (fieldGroups.includes(field.group_name) && !currentColumns.has(field.id))
                    columns.push({
                        id: field.id,
                        name: field.name,
                    })

            return this.applySearch(_.sortBy(columns, "name"))
        },
        visibleColumnCandidates() {
            let columns = this.columnCandidates
            return this.showMore ? columns : columns.slice(0, this.threshold)
        }
    },
}
</script>
<style>
.plain-table-manage-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.pim-columns {
    padding: 4px 0;
}
.pim-columns > * {
    margin-bottom: 10px!important;
}
.pim-columns > *:last-child {
    margin-bottom: 0!important;
}
.pim-columns ul {
    overflow-y: auto;
    max-height: 300px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px;
}
.pim-columns-actions {
    display: flex;
}
.pim-columns-actions > .btn-sm {
    flex-grow: 1;
    flex-basis: 1px;
    margin-right: 8px;
    height: auto;
    line-height: 16px;
}
.pim-columns-actions > .btn:last-child {
    margin-right: 0;
}
.pim-columns .matched {
    color: var(--red);
}
.pim-columns ul {
  list-style: none;
}
</style>
