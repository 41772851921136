<template>
    <gp-portal to="tooltip" v-if="fixedData != null || floatData != null">
        <div
            ref="tooltip"
            :style="style"
            :class="{
                'my-tooltip': true,
                'my-tooltip-float': fixedData == null && floatData != null,
                'my-tooltip-fixed': fixedData != null,
            }">
            <div v-if="fixedData != null" class="my-tooltip-actions">
                <template v-if="actions">
                    <button
                        v-for="action, n in actions"
                        type="button"
                        class="btn btn-sm"
                        :class="{
                            'btn-outline-primary': n == 0,
                            'btn-outline-secondary': n != 0
                        }"
                        @click="action.call(fixedData)"
                        ><l10n :value="action.name"/></button>
                </template>
                <template v-else>
                    <button
                        v-if="keepOnly"
                        type="button"
                        class="btn btn-sm btn-outline-primary"
                        @click="triggerKeepOnly"
                        ><l10n value="Keep only"/></button>
                    <button
                        v-if="exclude"
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        @click="triggerExclude"
                        ><l10n value="Exclude"/></button>
                </template>
            </div>
            <strong>{{title}}</strong>
            <table
                class="my-tooltip-values"
                v-if="data != null">
                <tbody>
                    <template v-if="accessor">
                        <tr v-for="entry in accessor(data)">
                            <td>
                                <span
                                    v-if="entry.color"
                                    class="my-tooltip-marker"
                                    :style="{'background-color':entry.color}"/>
                                <span>{{entry.name}}:</span>
                            </td>
                            <td>{{entry.value}}</td>
                        </tr>
                    </template>
                    <template v-else-if="meta != null">
                        <tr v-for="column, i in meta.columns.slice(data.key.length)" v-if="column.name">
                            <td>{{column.name}}:</td>
                            <td>{{format(data.others ? data.value : data.value[i], column)}}</td>
                        </tr>
                    </template>
                    <tr v-else v-for="value in data.value">
                        <td v-if="data.layer">{{data.layer}}:</td>
                        <td>{{format(value)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </gp-portal>
</template>
<script>
let utils = require("./my-utils")
module.exports = {
    data: function() {
        return {
            style: {},
            fixedData: null,
            floatData: null,
        }
    },
    props: {
        keepOnly:   { type: Function },
        exclude:    { type: Function },
        meta:       { type: Object   },
        chart:      { type: Object   },
        accessor:   { type: Function },
        actions:    { type: Array    },
    },
    mounted() {

    },
    beforeDestroy() {
        if (this.fixedData != null)
            this.leaveFixedMode()
    },
    computed: {
        data: function() {
            return this.fixedData != null ? this.fixedData : this.floatData
        },
        title: function() {
            let format = (x) => {
                if (x > 1e12 && x < 2e12)
                    x = new Date(x)
                if (_.isNumber(x))
                    return new Number(x).toLocaleString()
                if (_.isDate(x)) {
                    if (x.getHours() == 0 &&
                        x.getMinutes() == 0 &&
                        x.getSeconds() == 0)
                        return x.toLocaleDateString(undefined, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            weekday: "long",
                        })
                    else
                        return x.toLocaleString()
                }
                return  x
            }

            if (this.data != null) {
                if (_.isDate(this.data.key))
                    return format(this.data.key)
                if (this.chart && this.chart.xAxis && this.chart.xAxis().tickFormat())
                    return this.chart.xAxis().tickFormat()(this.data.key)
                if (_.isString(this.data.key))
                    return this.data.key
                if (_.isArray(this.data.key))
                    return _.filter(this.data.key).map(format).join(", ")
                return format(this.data.key)
            }
        }
    },
    methods: {
        format(x, column) {
            if (column && column.format) {
                let format = _.isString(column.format) ? eval(column.format) : column.format
                return format(x)
            }
            if (_.isNumber(x))
                return new Number(x).toLocaleString()
            if (_.isDate(x))
                return x.toLocaleDateString()
            return x
        },
        click(data, e) {
            this.fixedData = data
            this.skipNextClick = true
            this.moveToPointer(e || d3.event)
        },
        move(data, e) {
            if (this.fixedData == null)
                this.moveToPointer(e || d3.event)
        },
        show(data, e) {
            this.floatData = data
            if (this.fixedData == null)
                this.moveToPointer(e || d3.event)
        },
        hide(data) {
            this.floatData = null
        },
        reset(data) {
            this.floatData = null
            this.fixedData = null
        },
        moveToPointer(e) {
            let width = window.innerWidth
            let height = window.innerHeight
            let style = {}
            if (e.pageX < width - 200) {
                style.left = `${e.pageX+15}px`
                style.right = ""
            }
            else {
                style.left = ""
                style.right = `${width-e.pageX+15}px`
            }
            if (e.pageY < height - 150) {
                style.top = `${e.pageY+15}px`
                style.bottom = ""
            }
            else {
                style.top = ""
                style.bottom = `${height-e.pageY+15}px`
            }
            this.style = style
        },
        pointerDistance(e) {
            let { pageX, pageY } = e
            let { offsetTop, offsetLeft, offsetWidth, offsetHeight } = this.$refs.tooltip

            return Math.max(
                offsetTop - pageY,
                offsetLeft - pageX,
                pageY - (offsetTop + offsetHeight),
                pageX - (offsetLeft + offsetWidth))
        },
        monitorClick(e) {
            if (this.skipNextClick) {
                this.skipNextClick = false
                return
            }
            let distance = this.pointerDistance(e)

            if (distance > 0)
                this.fixedData = null
        },
        monitorMove(e) {
            let distance = this.pointerDistance(e)

            if (distance > 40) {
                this.fixedData = null
                if (this.floatData != null)
                    this.moveToPointer(e)
            }
        },
        enterFixedMode() {
            document.addEventListener("click", this.monitorClick)
            document.addEventListener("mousemove", this.monitorMove)
        },
        leaveFixedMode() {
            document.removeEventListener("click", this.monitorClick)
            document.removeEventListener("mousemove", this.monitorMove)
        },
        triggerKeepOnly() {
            this.keepOnly(this.data)
            this.fixedData = null
        },
        triggerExclude() {
            this.exclude(this.data)
            this.fixedData = null
        },
    },
    watch: {
        fixedData: function(newFixedData, oldFixedData) {
            if (oldFixedData != null)
                this.leaveFixedMode()
            if (newFixedData != null)
                this.enterFixedMode()
        },
    },
}
</script>
<style>
.my-tooltip {
    z-index: 1000;
    position: fixed;
    background-color: white;
    font-size: 12px;
    outline: 1px solid gray;
    padding: 8px;
    max-width: 300px;
    display: none;
}
.my-dark-theme .my-tooltip {
    background-color: black;
}
.my-tooltip.my-tooltip-float {
    display: block;
    pointer-events: none;
}
.my-tooltip.my-tooltip-fixed {
    margin-top: calc(-29px - 4px);
    display: block;
}
.my-tooltip-actions {
    display: flex;
    flex-direction: row;
    margin-right: -8px;
    margin-bottom: 4px;
}
.my-tooltip-actions > * {
    margin-right: 8px;
}
.my-tooltip-values td {
    padding-right: 4px;
    line-height: 1.3;
}
.my-tooltip-values td:last-child {
    padding-right: 0;
    text-align: right;
}
.my-device-ios input.form-control {
    font-size: 18px;
    line-height: 1.3;
    height: calc(1.3em + 0.75rem + 2px);
}
.ui-tooltip {
    box-shadow: none;
    font-size: 0.9em;
    border: 1px solid var(--dark);
    border-radius: 4px;
}
.my-tooltip td {
    vertical-align: top;
}
.my-tooltip-marker {
    float: left;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 2px;
    vertical-align: top;
}
.my-tooltip-marker + span {
    margin-left: 14px;
    display: block;
}
.my-tooltip-values td:first-child:last-child {
    text-align: left;
}
</style>