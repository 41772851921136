var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPopupVisible
    ? _c(
        "my-popup",
        {
          attrs: {
            portal: "popup",
            placement: "bottom-end",
            anchor:
              "*[data-section='" +
              _vm.selectedSection.id +
              "'] .feather-icon-more-vertical"
          },
          on: { escape: _vm.closePopup, clickoutside: _vm.closePopup }
        },
        [
          _c("div", { staticClass: "popover show plain-table-manage-table" }, [
            _c("div", { staticClass: "popover-body" }, [
              _c("ul", [
                _c("li", [
                  _c(
                    "span",
                    { on: { click: _vm.renameSection } },
                    [
                      _c("feather-icon", { attrs: { name: "edit-3" } }),
                      _vm._v(" "),
                      _c("l10n", { attrs: { value: "Rename section" } })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { on: { click: _vm.cloneSection } },
                    [
                      _c("feather-icon", { attrs: { name: "copy" } }),
                      _vm._v(" "),
                      _c("l10n", { attrs: { value: "Duplicate section" } })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { on: { click: _vm.deleteSection } },
                    [
                      _c("feather-icon", { attrs: { name: "trash" } }),
                      _vm._v(" "),
                      _c("l10n", { attrs: { value: "Delete section" } })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }