<template>
    <div class="my-inline-help" :title="translatedText" @click="promptTranslation">
        <feather-icon name="help-circle"/>
    </div>
</template>
<script>
let utils = require('./my-utils')
module.exports = {
    props: {
        text: { type: String },
        html: { type: Boolean, default: false },
    },
    watch: {
        text: function() { this.setupTooltip() },
        html: function() { this.setupTooltip() },
    },
    data() {
        return { translatedText: utils.l10n(this.text) }
    },
    mounted() {
        utils.bridge.bind("l10n", this.translate)
        this.setupTooltip()
    },
    beforeDestroy() {
        utils.bridge.unbind("l10n", this.translate)
        $(this.$el).tooltip("destroy")
    },
    methods: {
        setupTooltip() {
            $(this.$el).tooltip({
                track: true,
                show: false,
                hide: false,
            })
        },
        translate() {
            this.translatedText = utils.l10n(this.text)
        },
        promptTranslation(e) {
            if (e.altKey)
                utils.prompt_l10n(this.text)
        }
    },
}
</script>
<style>
.my-inline-help {
    display: inline-block;
}
.my-inline-help .feather-icon {
    opacity: 0.5;
    filter: grayscale(100%);
}
.my-inline-help .feather-icon svg {
    padding: 2px;
}
</style>