var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "gp-pills" },
    [
      _vm._l(_vm.items, function(item, i) {
        return _c("li", { class: item.class }, [
          _c("span", [
            _vm._v("\n            " + _vm._s(item.name) + "\n            "),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.removeItem(i)
                  }
                }
              },
              [_c("feather-icon", { attrs: { name: "x" } })],
              1
            )
          ])
        ])
      }),
      _vm._v(" "),
      _c(
        "li",
        { key: "select" },
        [
          _c("gp-select", {
            ref: "select",
            attrs: {
              options: _vm.options,
              placeholder: _vm.placeholder,
              recentOptionsKey: _vm.recentOptionsKey
            },
            model: {
              value: _vm.item,
              callback: function($$v) {
                _vm.item = $$v
              },
              expression: "item"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }