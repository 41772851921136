var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-runs" },
    [
      !_vm.compact
        ? _c("gp-filter", {
            attrs: {
              stream: "combined",
              groups: ["optimizations", "search", "reference-date"],
              source: {
                dims: ["item", "target_price_zone as price_zone"],
                links: [
                  {
                    linkName: "optimization",
                    sourceName: "optimization",
                    columnPairs: [
                      {
                        srcColumn: "item",
                        dstColumn: "item"
                      },
                      {
                        srcColumn: "price_zone",
                        dstColumn: "price_zone"
                      }
                    ]
                  }
                ]
              },
              vars: _vm.vars,
              filter2: _vm.usersFilter,
              expand: "optimization",
              attributes: [
                {
                  name: _vm.l10n("Run name"),
                  calc: "optimization.strategy_name"
                },
                {
                  name: _vm.l10n("Started by"),
                  calc: "optimization.create_user"
                }
              ],
              popupPortal: null
            },
            model: {
              value: _vm.filter,
              callback: function($$v) {
                _vm.filter = $$v
              },
              expression: "filter"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("plain-table", {
        ref: "table",
        attrs: {
          id: "optimizations",
          stream: "combined",
          freezeDims: true,
          groups: ["optimizations", "search", "reference-date"],
          source: {
            filter0: _vm.datesFilter,
            filter1: _vm.datesFilter,
            dims: ["item", "target_price_zone as price_zone"],
            links: [
              {
                linkName: "optimization",
                sourceName: "optimization",
                columnPairs: [
                  {
                    srcColumn: "item",
                    dstColumn: "item"
                  },
                  {
                    srcColumn: "price_zone",
                    dstColumn: "price_zone"
                  }
                ]
              },
              {
                linkName: "optimization_summary",
                sourceName: "optimization_summary",
                columnPairs: [
                  {
                    srcColumn: "optimization.optimization_run_id",
                    dstColumn: "optimization_run_id"
                  }
                ]
              },
              {
                linkName: "optimization_goals",
                sourceName: "optimization_goals",
                columnPairs: [
                  {
                    srcColumn: "optimization.optimization_run_id",
                    dstColumn: "optimization_run_id"
                  }
                ]
              }
            ]
          },
          filter2: _vm.filter2,
          filter3: _vm.filter3,
          dims: _vm.dims,
          vals: _vm.vals,
          cols: _vm.cols,
          vars: _vm.vars,
          initialSort: _vm.sort,
          initialTake: _vm.take,
          clientSort: false,
          prefetchSize: 1,
          expand: "optimization",
          tableActions: _vm.tableActions,
          isOptimizationsTable: true
        },
        on: {
          "link-clicked": _vm.linkClicked,
          "report-updated": function($event) {
            _vm.report = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }