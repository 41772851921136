var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-data" },
    [
      _vm.controls
        ? _c("my-progress", {
            attrs: {
              sorting: _vm.sorting,
              progress: _vm.progress,
              processRows: _vm.processRows,
              processTime: _vm.processTime,
              meta: _vm.meta,
              exportToFile: _vm.exportToFile,
              assembly: _vm.assembly
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("my-download", { attrs: { file: _vm.file } }),
      _vm._v(" "),
      _vm._l(_vm.portals, function(portal) {
        return [
          _c("portal", { attrs: { to: portal.name } }, [
            portal.html
              ? _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.render(portal.html)) }
                })
              : _vm._e(),
            _vm._v(" "),
            portal.text
              ? _c("span", [_vm._v(_vm._s(_vm.render(portal.text)))])
              : _vm._e()
          ])
        ]
      }),
      _vm._v(" "),
      _vm.text ? _c("span", [_vm._v(_vm._s(_vm.render(_vm.text)))]) : _vm._e(),
      _vm._v(" "),
      _vm.html
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.render(_vm.html)) } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }