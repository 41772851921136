<template>
    <div class="gp-scopes">

        <plain-table
            :stream="stream"
            :source="source"
            :groups="groups"
            :dims="dims"
            :vals="vals"
            :cols="cols"
            :timeZone="timeZone"
            :expandTagName="expandTagName"
            :expandProps="{stream, source, groups, attributes, timeZone}"
            />
    </div>
</template>
<script>
let utils = require('../my-utils')

module.exports = {
    props: {
        stream: { type: String },
        source: { type: Object },
        groups: { type: Array },
        dims: { type: Array },
        vals: { type: Array },
        cols: { type: Array },
        attributes: { type: Array },
        timeZone: { type: String },
        expandTagName: { type: String },
    },
}
</script>