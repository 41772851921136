let utils = require("../my-utils")

let render = (x) => {
    if (_.isDate(x)) {
        if (x.getHours() === 0 &&
            x.getMinutes() === 0 &&
            x.getSeconds() === 0)
            return x.toLocaleDateString()
        else
            return x.toLocaleString()
    }
    if (_.isNumber(x)) {
        return new Number(x).toLocaleString()
    }
    return `${x}`
}

module.exports = _.merge({
    props: {
        width:          { type: [Number, String], default: "100%" },
        height:         { type: [Number, String], default: 300 },
        minWidth:       { type: [Number, String] },
        minHeight:      { type: [Number, String] },
        maxWidth:       { type: [Number, String] },
        maxHeight:      { type: [Number, String] },
        paddingTop:     { type: [Number, String] },
        paddingBottom:  { type: [Number, String] },
        renderLegend:   { type: Boolean, default: true },
        transitionDuration: { type: Number, default: 750 },
        actions:        { type: Array },
    },
    watch: {
        data:         function() { (this.$parent.chart || this.chart).redraw() },
        renderLegend: function() { this.createChart(true) },
        colors:       function() { this.createChart(true) },
        dims: {
            deep: true,
            handler(a,b) {
                if (!_.isEqual(a,b))
                    this.createChartThrottled(true)
            }
        },
        vals: {
            deep: true,
            handler(a,b) {
                if (!_.isEqual(a,b))
                    this.createChartThrottled(true)
            }
        },
        cols: {
            deep: true,
            handler(a,b) {
                if (!_.isEqual(a,b))
                    this.createChartThrottled(true)
            }
        },
        isIntersecting() {
            if (this.chart != null) {
                if (this.isIntersecting)
                    this.chart.transitionDuration(this.transitionDuration)
                else
                    this.chart.transitionDuration(0)
            }
        },
        filters: function(filters) {
            let groups = _.groupBy(filters, "type")

            let quote = ({value}) => {
                return utils.quote(value, this.report.columns[0])
            }
            let formula = `(${this.meta.columns[0].calc})`
            let group = this.group
            let stream = this.stream

            let filter1 = ""
            let filter2 = ""
            let filter3 = ""

            if (groups.chart) {
                let values = []
                let ranges = []
                for (filter of groups.chart) {
                    if (_.isObject(filter.value) && filter.value.filterType === "RangedFilter")
                        ranges.push(filter)
                    else
                        values.push(filter)
                }

                if (values.length > 0)
                    filter2 = `(${formula}) in [${values.map(quote).join(", ")}]`
                if (ranges.length > 0)
                    filter2 = ranges.map(({value}) => `(${formula} >= ${quote({value:value[0]})} && ${formula} < ${quote({value:value[1]})})`).join(" || ")
            }

            utils.bridge.trigger("filtersChanged", this._uid, {group, stream, filter1, filter2, filter3})

            filter1 = ""
            filter2 = ""
            filter3 = ""

            if (groups.keepOnly)
                filter2 = `${formula} in [${groups.keepOnly.map(quote).join(", ")}]`

            if (groups.exclude)
                filter2 = `${formula} not in [${groups.exclude.map(quote).join(", ")}]`

            utils.bridge.trigger("filtersChanged", -this._uid, {group, stream, filter1, filter2, filter3})
        }
    },
    data: function() {
        return {
            chart: null,
            filters: [],
            isIntersecting: true,
            isVisible: true,
        }
    },
    computed: {
        style: function() {
            let format = (x) => _.isNumber(x) ? `${x}px` : x
            return {
                opacity:        this.reportId ? 0.5 : 1,
                width:          format(this.width),
                height:         format(this.height),
                minWidth:       format(this.minWidth),
                minHeight:      format(this.minHeight),
                maxWidth:       format(this.maxWidth),
                maxHeight:      format(this.maxHeight),
                marginTop:      "10px",
                paddingTop:     format(this.paddingTop),
                paddingBottom:  format(this.paddingBottom),
            }
        }
    },
    methods: {
        setupTooltips(chart) {
            let svg = chart.svg()
            if (svg) {
                let elements = svg.selectAll("rect.bar,circle.dot,g.pie-slice,circle.bubble,g.row rect")

                elements
                    .on("mouseover", (e, d) => {
                        if (this.$refs.tooltip) {
                            if (d.layer) {
                                let data = _.clone(d.data)
                                data.layer = d.layer
                                this.$refs.tooltip.show(data, e)
                            }
                            else
                                this.$refs.tooltip.show(d.data || d, e)
                        }
                    })
                    .on("mouseleave", (d) => {
                        if (this.$refs.tooltip)
                            this.$refs.tooltip.hide()
                    })
                    // .on("mousemove", (d) => {
                    //     if (this.$refs.tooltip)
                    //         this.$refs.tooltip.move(d.data)
                    // })
                    .on("click", (e, d) => {
                        if (this.$refs.tooltip) {
                            if (d.layer) {
                                let data = _.clone(d.data)
                                data.layer = d.layer
                                this.$refs.tooltip.click(data, e)
                            }
                            else
                                this.$refs.tooltip.click(d.data || d, e)
                        }
                    })

                elements.each(function () {
                    var el = d3.select(this)
                    var title = el.select('title')

                    if (title.empty())
                        return false

                    el.attr('data-title', title.text())
                    title.remove()
                })
            }
        },
        tuneChart: function(chart) {
            this.setupTooltips(chart)
        },
        createChart: function(instant) {
            this.destroyChart();
            if (this.$parent.$options._componentTag === "composite-chart") {
                $(this.$refs.base).parent().hide()
                this.chart = this.makeChart(this.$parent.chart)
            }
            else
                this.chart = this.makeChart(this.$refs.chart)
            dc.chartRegistry.deregister(this.chart)
            this.setupChart(this.chart)
            this.renderChart(instant)

            if (this.chart.childOptions) {
                _.defer(() => this.chart.childOptions(this.chart.childOptions()))
            }

        },
        destroyChart: function() {
            if (this.chart)
                this.chart = null
        },
        setupChart: function(chart) {
            for (let name of _.keys(this.$props)) {
                this.setChartProperty(name, this[name], true)
            }
            for (let name of _.keys(this.$options.computed)) {
                this.setChartProperty(name, this[name], true)
            }
            chart.filterHandler((dimension, filters) => this.filterHandler(dimension, filters))
            let width = this.$refs.base.clientWidth
            let height = this.$refs.base.clientHeight
            if (this.turnChart) {
                [width, height] = [height, width]
            }
            chart.width(width)
            chart.height(height)
            chart.on("renderlet",     () => this.tuneChart(chart))
            chart.on("pretransition", () => this.tuneChart(chart))
            chart.chartGroup(this._uid)
            if (this.isIntersecting)
                chart.transitionDuration(this.transitionDuration)
            else
                chart.transitionDuration(0)
        },
        setChartProperty(name, value, silent) {
            switch (name) {
                case "dims":
                case "vals":
                case "cols":
                case "group":
                case "width":
                case "height":
                case "minWidth":
                case "minHeight":
                    return
                case "legend":
                    if (!this.renderLegend)
                        return
                    break
            }
            if (this.$parent.chart) {
                switch (name) {
                    case "x":
                    case "y":
                    case "xUnits":
                    case "elasticX":
                    case "elasticY":
                        return
                }

            }
            let prop = Vue.component(this.$options._componentTag).options.props[name]
            if (prop && _.isString(value) && _.isArray(prop.type) && _.includes(prop.type, Function)) {
                value = eval(value)
            }
            let loop = (object, path) => {
                let attribute = object[path[0]]
                if (attribute !== undefined) {
                    if (path.length === 1) {
                        let oldValue = attribute.call(object)
                        if (!_.isEqual(oldValue, value)) {
                            attribute.call(object, value)
                        }
                    }
                    else {
                        object = attribute.call(object)
                        loop(object, path.slice(1))
                    }
                }
                else if (object.childOptions) {
                    let key = path[0]
                    if (["curve", "renderDataPoints"].includes(key)) {
                        let options = _.clone(object.childOptions())
                        options[key] = value
                        object.childOptions(options)
                    }
                }
            }
            if (name === "colors") {
                if (value !== undefined)
                    this.chart.colors(value)
            }
            else if (name === "xTickFormat")
                this.chart.xAxis().tickFormat(value)
            else if (name === "yTickFormat")
                this.chart.yAxis().tickFormat(value)
            else if (name === "rightYTickFormat") {
                if (this.chart.rightYAxis)
                    this.chart.rightYAxis().tickFormat(value)
            }
            else if (name === "xTicks")
                this.chart.xAxis().ticks(value)
            else if (name === "yTicks")
                this.chart.yAxis().ticks(value)
            else if (name === "rightYTicks") {
                if (this.chart.rightYAxis)
                    this.chart.rightYAxis().ticks(value)
            }
            else
                loop(this.chart, name.split("."))
            if (!silent)
                this.instantRenderChart()
        },
        renderChart: function(instant) {
            if (this.$parent.chart)
                this.$parent.renderChart(instant)
            else {
                if (instant)
                    this.instantRenderChart()
                else
                    this.chart.render()
            }
        },
        instantRenderChart() {
            if (this.$parent.chart)
                this.$parent.instantRenderChart()
            else {
                let chart = this.chart
                let duration = chart.transitionDuration()
                chart.transitionDuration(0)
                chart.render()
                if (duration != 0) {
                    if (this.transitionRestoreTimeout)
                        clearTimeout(this.transitionRestoreTimeout)
                    this.transitionRestoreTimeout =
                        setTimeout(() => { chart.transitionDuration(duration) }, 1000)
                }
            }
        },
        keepOnly: function(data) {
            let values = data.others || [data.key[0]]
            this.chart.filterAll()
            this.filters = values.map((value) => {
                return { type: "keepOnly", value }
            })
        },
        exclude: function(data) {
            let values = data.others || [data.key[0]]
            let filters = _.clone(this.filters)
            let groups = _.groupBy(filters, "type")

            if (groups.chart)
                for (filter of groups.chart) {
                    let value = filter.value
                    if (_.isArray(value) && value.length == 1)
                        value = value[0]
                    if (_.includes(values, value)) {
                        _.remove(filters, filter)
                        this.chart.filter(value)
                    }
                }

            if (groups.keepOnly) {
                for (filter of groups.keepOnly) {
                    let value = filter.value
                    if (_.isArray(value) && value.length == 1)
                        value = value[0]
                    if (_.includes(values, value)) {
                        _.remove(filters, filter)
                    }
                }
                if (!_.some(filters, ({type}) => type === "keepOnly"))
                    for (value of values)
                        filters.push({type: "exclude", value})
            }
            else
                for (value of values)
                    filters.push({type: "exclude", value})

            this.filters = filters
        },
        filterHandler: function(dimension, filters) {
            this.filters = this.filters
                .filter(({type}) => type !== "chart")
                .concat(filters
                    .filter((value) => value !== undefined)
                    .map((value) => { return { type: "chart", value } }))
            return filters
        },
        initialSetup() {
            this.createChart()
        
            this.resizeSensor = new ResizeSensor(this.$refs.base, () => {
                let width = this.$refs.base.clientWidth
                let height = this.$refs.base.clientHeight
                if (this.turnChart) {
                    [width, height] = [height, width]
                }

                if (this.chart.width() !== width) {
                    this.chart.width(width)
                    this.instantRenderChart()
                }
                if (this.chart.height() !== height) {
                    this.chart.height(height)
                    this.instantRenderChart()
                }
            })
            if (window.IntersectionObserver !== undefined) {
                this.observer = new IntersectionObserver((entries) => {
                    let [{isVisible, isIntersecting}] = entries
                    this.isIntersecting = isIntersecting
                    this.isVisible = isVisible 
                })
                this.observer.observe(this.$refs.base);
            }
        }
    },
    mounted() {
        this.createChartThrottled = _.throttle(this.createChart, 1000)
        if (this.$parent.$options._componentTag === "composite-chart")
            _.defer(() => this.initialSetup())
        else
            this.initialSetup()
    },
    beforeDestroy() {
        this.destroyChart()
        this.resizeSensor.detach()
        if (this.observer !== undefined)
            this.observer.disconnect()
    }
}, require("./props").chartProps({
    turnChart: { type: Boolean },
    legend: {
        type: [Object, String, Function],
        default: () =>
            dc.legend()
                .legendText((group) => {
                    if (group.name === "" || _.isEqual(group.name, [""]))
                        return "[empty]"
                    else return group.name
                })
    },
    // colors:         { type: [Object, String, Function], default: (d) => d3.scaleOrdinal(d3.schemeCategory10) },
    colors:         { type: [Object, String, Function], default: undefined },
    caption:        { type: String },
    label:          { type: [Function, String], default: (d) => d.key.map(render).join('\n')+'\n'+d.value.map(render).join('\n') },
    title:          { type: [Function, String], default: (d) => d.key.map(render).join('\n')+'\n'+d.value.map(render).join('\n') },
    renderLabel:    { type: Boolean, default: false },
    renderTitle:    { type: Boolean, default: false },
    keyAccessor:    { type: [Function, String], default: (d) => d.others ? undefined : d.key[0] },
    valueAccessor:  { type: [Function, String], default: (d) => d.value[0] },
}))
