var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-upload" },
    [
      _c(
        "div",
        {
          ref: "dropZone",
          staticClass: "drop-zone",
          on: {
            dragover: function($event) {
              $event.preventDefault()
              _vm.$refs.dropZone.classList.add("highlight")
            },
            dragenter: function($event) {
              $event.preventDefault()
              _vm.$refs.dropZone.classList.add("highlight")
            },
            dragleave: function($event) {
              $event.preventDefault()
              _vm.$refs.dropZone.classList.remove("highlight")
            },
            drop: function($event) {
              $event.preventDefault()
              _vm.$refs.dropZone.classList.remove("highlight")
              _vm.uploadFiles($event.dataTransfer.files)
            }
          }
        },
        [
          _c("l10n", {
            attrs: {
              value: "Darg & drop new files here or click the link below"
            }
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.triggerUpload }
            },
            [_c("l10n", { attrs: { value: "Upload files" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.statuses, function(status, name) {
              return _c(
                "li",
                [
                  _c("feather-icon", { attrs: { name: status } }),
                  _vm._v(
                    "\n                " + _vm._s(name) + "\n                "
                  ),
                  _vm.paths[name]
                    ? _c(
                        "ul",
                        _vm._l(_vm.paths[name], function(family, stream) {
                          return _c(
                            "li",
                            [
                              _c("l10n", { attrs: { value: stream } }),
                              _vm._v(
                                "\n                        -\n                        "
                              ),
                              _c("l10n", {
                                attrs: {
                                  value:
                                    {
                                      loadedPaths: "loaded",
                                      skippedPaths: "skipped",
                                      pendingPaths: "pending",
                                      excludedPaths: "excluded"
                                    }[family] || family
                                }
                              }),
                              _vm._v(" "),
                              family === "loadedPaths"
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.browseStream(stream, [
                                            { __file__: [name] }
                                          ])
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", { attrs: { value: "browse" } })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              family === "loadedPaths"
                                ? _c("gp-loaded-path-info", {
                                    attrs: { stream: stream, paths: [name] }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("form", { ref: "form", staticStyle: { display: "none" } }, [
            _c("input", {
              ref: "input",
              attrs: { type: "file", multiple: "" },
              on: {
                change: function($event) {
                  _vm.uploadFiles($event.target.files)
                  _vm.$refs.form.reset()
                }
              }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.browsingStream
        ? _c(
            "my-dialog",
            {
              attrs: {
                large: true,
                scrollable: true,
                title: _vm.browsingStream
              },
              on: {
                close: function($event) {
                  _vm.browsingStream = null
                  _vm.browsingFilter = null
                }
              }
            },
            [
              _c("gp-stream", {
                attrs: {
                  stream: _vm.browsingStream,
                  filter: _vm.browsingFilter
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }