<template>
<div class="gp-data">

    <my-progress
        v-if="controls"
        :sorting="sorting"
        :progress="progress"
        :processRows="processRows"
        :processTime="processTime"
        :meta="meta"
        :exportToFile="exportToFile"
        :assembly="assembly"/>

    <my-download :file="file"/>

    <template v-for="portal in portals">
        <portal :to="portal.name">
            <span v-if="portal.html" v-html="render(portal.html)"/>
            <span v-if="portal.text">{{render(portal.text)}}</span>
        </portal>
    </template>

    <span v-if="text">{{render(text)}}</span>
    <div v-if="html" v-html="render(html)"></div>

</div>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
    mixins: [
        require("../data.js"),
    ],
    model: {
        prop: "report",
        event: "change",
    },
    props: {
        controls: { type: Boolean, default: false },
        refresh: { type: Number },
        portals: { type: Array, default: () => [] },
        monitor: { type: Array, default: () => [] },
        text: { type: [Function, String] },
        html: { type: [Function, String] },
        json: { type: [Function, String] },
        parentExtraFilters: { type: Object },
    },
    data() {
        return {
            timer: null,
            rows: [],
        }
    },
    mounted() {
        if (this.refresh)
            this.timer = setInterval(() => {
                if (!this.reportId)
                    this.requestData({forced:true})
            }, this.refresh)
        utils.bridge.bind("streamModified", this.handleStreamModified)
    },
    beforeDestroy() {
        utils.bridge.unbind("streamModified", this.handleStreamModified)
        utils.bridge.trigger("unsetInclude", this._uid)
        clearInterval(this.timer)
    },
    methods: {
        handleStreamModified(stream) {
            if (this.monitor.length) {
                if (this.monitor.includes(stream)) {
                    this.requestDataThrottled({forced:true})
                }
            }
        },
        render(code) {
            try {
                if (_.isFunction(code))
                    return code(this.report, this)
                if (_.isString(code))
                    return eval(code)(this.report, this)
            }
            catch (ex) {
                console.warn(code)
                console.warn(ex)
            }
        },
    },
    watch: {
        async report(report, oldReport) {
            if (_.isEqual(report, oldReport))
                return
            this.$emit("change", report)
            this.$emit("report", report)
            if (this.json) {
                try {
                    let json = this.json
                    if (_.isString(json))
                        json = eval(json)
                    if (_.isFunction(json)) {
                        // utils.bridge.trigger("setInclude", this._uid, {})
                        let version = utils.randomId()
                        this.version = version
                        json = await json(this.report, this)
                        if (this.version != version)
                            return
                    }
                    if (_.isPlainObject(json))
                        utils.bridge.trigger("setInclude", this._uid, json)
                }
                catch (ex) {
                    console.warn(this.json)
                    console.warn(ex)
                }
            }
        },
        errors(errors) {
            this.$emit("errors", errors)
        },
        reportId(reportId) {
            this.$emit("reportId", reportId)
        }
    }
}
</script>