<template>
  <div class="gp-rules-configuration">
    <div class="form-check">
      <input
        id="show-rules-configYaml"
        class="form-check-input"
        type="checkbox"
        :checked="showConfig"
        @click="toogleConfig"
      >
      <label
        class="form-check-label"
        for="show-rules-configYaml"
      >
        <l10n value="Show configuration" />
      </label>
    </div>

    <ace-editor
      v-if="showConfig"
      v-model="configYaml"
      class="ace-editor"
      mode="yaml"
      :auto-height="true"
    />

    <button
      v-if="showConfig"
      type="button"
      class="btn btn-sm btn-secondary"
      @click="applyConfig"
    >
      <l10n value="Submit changes" />
    </button>
  </div>
</template>

<script>
module.exports = {
  props: {
    filter: {
      type: Array,
      default: () => ([]),
    },

    sections: {
      type: Array,
      default: () => ([]),
    },

    schedule: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showConfig: false,
      configYaml: null,
    };
  },

  methods: {
    toogleConfig() {
      this.showConfig = !this.showConfig;
      if (this.showConfig) {
        const config = _.omitBy({
          filter: this.filter,
          sections: this.sections,
          schedule: this.schedule,
        }, _.isUndefined);
        this.configYaml = jsyaml.safeDump(config);
      }
    },

    applyConfig() {
      try {
        const newConfig = jsyaml.safeLoad(this.configYaml);

        this.$emit('apply-config', newConfig);
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>

<style scoped>
.ace-editor {
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px)!important;
  margin-bottom: 15px;
}
</style>
