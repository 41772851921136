<template>
  <input
    ref="input"
    v-model.number="model"
    type="number"
    @keyup.enter="$emit('submit')"
    @keyup.escape="$emit('cancel')"
  >
</template>

<script>
module.exports = {
  props: {
    value: {
      type: [Number, String],
    },
  },

  data() {
    return {
      model: _.cloneDeep(this.value),
    };
  },

  mounted() {
    this.$refs.input?.focus?.();
    this.$refs.input?.select?.();
  },
};
</script>
