// basic escaping as interpolation is done within properly quoted attributes or as text nodes
module.exports.escape = _.escape

module.exports.renderClasses = (classes) => {
    if (_.isString(classes))
        return classes
    if (typeof classes === "object") {
        let selectedClasses = []
        for (let name of Object.keys(classes))
            if (classes[name])
                selectedClasses.push(name)
        return selectedClasses.join(" ")
    }
    return ""
}

module.exports.renderStyle = (style) => {
    if (_.isString(style))
        return style
    if (_.isPlainObject(style)) {
        let interpolatedStyles = []
        for (let name of Object.keys(style)) {
            let value = style[name]
            if (value != null) {
                name = name.replace(/([A-Z])/g, (match) => `-${match[0].toLowerCase()}`)
                interpolatedStyles.push(`${name}:${value}`)
            }
        }
        return interpolatedStyles.join(";")
    }
    return ""
}

