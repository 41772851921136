var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-dialog",
    {
      attrs: { title: "Export settings", large: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "pim-export-dialog" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("File name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.fileName,
                expression: "fileName"
              }
            ],
            staticClass: "form-control form-control-sm",
            domProps: { value: _vm.fileName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.fileName = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "gp-check",
              {
                model: {
                  value: _vm.includeParentChild,
                  callback: function($$v) {
                    _vm.includeParentChild = $$v
                  },
                  expression: "includeParentChild"
                }
              },
              [
                _vm._v(
                  "\n                Include parent-child relationships\n            "
                )
              ]
            ),
            _vm._v(" "),
            _vm.entry.childrenType
              ? _c(
                  "gp-check",
                  {
                    model: {
                      value: _vm.exportChildren,
                      callback: function($$v) {
                        _vm.exportChildren = $$v
                      },
                      expression: "exportChildren"
                    }
                  },
                  [
                    _vm._v(
                      "\n                Export child " +
                        _vm._s(_vm.pluralize(_vm.entry.childrenType)) +
                        "\n            "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                model: {
                  value: _vm.exportEditable,
                  callback: function($$v) {
                    _vm.exportEditable = $$v
                  },
                  expression: "exportEditable"
                }
              },
              [
                _vm._v(
                  "\n                Export all editable attributes\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "gp-check",
              {
                model: {
                  value: _vm.exportMandatry,
                  callback: function($$v) {
                    _vm.exportMandatry = $$v
                  },
                  expression: "exportMandatry"
                }
              },
              [
                _vm._v(
                  "\n                Export all mandatory attributes\n            "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group pim-export-dialog-columns" }, [
          _c(
            "div",
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm.capitalize(_vm.pluralize(_vm.entry.type))) +
                    " attributes to include"
                )
              ]),
              _vm._v(" "),
              _c("pim-columns", {
                attrs: { type: _vm.entry.type, fields: _vm.fields }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.entry.childrenType && _vm.exportChildren
            ? _c(
                "div",
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.capitalize(_vm.pluralize(_vm.entry.childrenType))
                      ) + " attributes to include"
                    )
                  ]),
                  _vm._v(" "),
                  _c("pim-columns", {
                    attrs: { type: _vm.entry.childrenType, fields: _vm.fields }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Export")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Cancel")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }