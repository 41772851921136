var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-dialog",
    { attrs: { title: "Assembly" }, on: { close: _vm.onClose } },
    [
      _c(
        "ul",
        { staticClass: "nav nav-tabs my-assembly-tabs" },
        _vm._l(_vm.assembly, function(code, name) {
          return _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                class: { "nav-link": true, active: name === _vm.assemblyTab },
                staticStyle: { "line-height": "1" },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    _vm.assemblyTab = name
                  }
                }
              },
              [_vm._v("\n                " + _vm._s(name) + "\n            ")]
            ),
            _vm._v(" "),
            name === "Query"
              ? _c(
                  "a",
                  {
                    attrs: { href: "/graphiql", target: "_blank" },
                    on: { click: _vm.openQueryInGraphiQL }
                  },
                  [_c("feather-icon", { attrs: { name: "external-link" } })],
                  1
                )
              : _vm._e()
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("ace-editor", {
        staticClass: "my-assembly-code",
        attrs: {
          value: _vm.assembly[_vm.assemblyTab] || "",
          "read-only": true,
          mode: _vm.mode,
          height: "400px"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }