<template>
  <div
    id="liveToast"
    class="toast"
    :class="{ hide: !isOpen }"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-header">
      <img src="" class="rounded me-2" alt="">
      <strong
        class="toast-text"
        :class="headerTitleClass"
      >
        <l10n :value="headerTitle" />
      </strong>
      <small class="toast-time">{{ timeString }}</small>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Закрыть"
        @click="closeToast"
      />
    </div>
    <div class="toast-body">
      <l10n :value="notification.text" />
    </div>
  </div>
</template>

<script>
module.exports = {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpen: true,
      timeString: this.getRelativeTimeString(this.notification.created),
      toastTimeout: null,
      timeStringTimeout: null,
    };
  },

  computed: {
    headerTitle() {
      switch (this.notification.type) {
        case 'warn':
          return 'Warning';
        case 'error':
          return 'Error';
        case 'success':
          return 'Success';
        default:
          return 'Info';
      }
    },

    headerTitleClass() {
      switch (this.notification.type) {
        case 'warn':
          return 'warning';
        case 'error':
          return 'error';
        case 'success':
          return 'success';
        default:
          return 'info';
      }
    },
  },

  mounted() {
    this.toastTimeout = setTimeout(() => {
      this.isOpen = false;
      this.$notifications = this.$notifications.map((notification) => {
        if (notification.id === this.notification.id) {
          notification.isNew = false;
        }
        return notification;
      });
      localStorage.setItem('notifications', JSON.stringify(this.$notifications));
    }, 5000);

    this.timeStringInterval = setInterval(() => {
      this.timeString = this.getRelativeTimeString(this.notification.created);
    }, 10000);
  },

  beforeDestroy() {
    clearTimeout(this.toastTimeout);
    clearInterval(this.timeStringInterval);
  },

  methods: {
    closeToast() {
      this.isOpen = false;
    },

    getRelativeTimeString(date, lang = navigator.language) {
      const timeMs = typeof date === 'number' ? date : date.getTime();
      const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);
      const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];
      const units = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];
      const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds));
      const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;
      const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });
      return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
    },
  },
};
</script>

<style scoped>
  .toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 1;
    border-radius: 0.25rem;
  }
  .toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #95a5a6;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .toast-text {
    margin-right: auto;
  }
  .toast-time {
    margin-right: 5px;
  }
  .toast-body {
    padding: 0.75rem;
  }
  .btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: var(--bs-btn-close-opacity);
  }
  .btn-close:hover {
    opacity: 0.75;
  }
  .btn-close:focus  {
    opacity: 1;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  .info {
    color: var(--info);
  }

  .success {
    color: var(--success);
  }

  .warning {
    color: var(--warning);
  }

  .error {
    color: var(--danger);
  }
</style>
