<template>
  <div class="toasts-container">
    <gp-toast
      v-for="notification in notifications"
      v-if="notification.isNew"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
const GpToast = require('./gp-toast.vue').default;

module.exports = {
  components: {
    'gp-toast': GpToast,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
  .toasts-container {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1rem;
    z-index: 1100;
  }
</style>
