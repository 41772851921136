var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.permalink
    ? _c(
        "my-popup",
        {
          attrs: { draggable: true, placement: "bottom", anchor: _vm.anchor },
          on: {
            clickoutside: function($event) {
              _vm.permalink = null
            }
          }
        },
        [
          _c("div", { staticClass: "popover pim-permalink" }, [
            _c("div", { staticClass: "popover-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "a",
                  {
                    staticClass: "float-right",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.permalink = null
                      }
                    }
                  },
                  [_c("feather-icon", { attrs: { name: "x" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  [
                    _c("l10n", {
                      attrs: {
                        value:
                          "Please use the link below to share current state"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: { readonly: "" },
                    domProps: { value: _vm.permalink },
                    on: {
                      click: function($event) {
                        return $event.target.select()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary",
                        on: { click: _vm.copyPermalink }
                      },
                      [
                        _c("feather-icon", { attrs: { name: "clipboard" } }),
                        _vm._v(" "),
                        _c("l10n", {
                          attrs: {
                            value: _vm.permalinkCopied ? "Copied" : "Copy"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }