var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-rules-schedule" },
    [
      _vm.isConfigSchedulesVisible
        ? [
            _c("gp-data", {
              attrs: {
                id: "gp-rules-categories",
                stream: _vm.stream,
                source: _vm.source,
                groups: ["gp-rules-categories", "reference-date"],
                filter0: _vm.filter0,
                filter1: _vm.filter1,
                filter2: _vm.filter2,
                filter3: "dim1 != ''",
                dims: ["classif.category"],
                "initial-sort": [1],
                instant: false
              },
              model: {
                value: _vm.categoriesReport,
                callback: function($$v) {
                  _vm.categoriesReport = $$v
                },
                expression: "categoriesReport"
              }
            }),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "gp-runs-schedule" },
              _vm._l(_vm.configSchedule, function(
                scheduledRun,
                scheduledRunId
              ) {
                return _c(
                  "li",
                  {
                    key: scheduledRunId,
                    class: {
                      expanded: _vm.expandedScheduledRuns[scheduledRunId]
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "gp-rules-schedule__btn",
                        on: {
                          click: function($event) {
                            return _vm.toggleExpandedSchedule(scheduledRunId)
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            name: _vm.expandedScheduledRuns[scheduledRunId]
                              ? "chevron-down"
                              : "chevron-right"
                          }
                        }),
                        _vm._v(" "),
                        _c("l10n", {
                          attrs: {
                            value:
                              "Run optimization on {days} at {time} for {categories}.",
                            days: _vm.scheduleDaysText(
                              scheduledRun.days,
                              scheduledRun.dows
                            ),
                            time: _vm.scheduleTimeText(scheduledRun.time),
                            categories: _vm.scheduleCategoriesText(
                              scheduledRun.categories
                            )
                          }
                        }),
                        _vm._v(" "),
                        scheduledRun.combineCategories
                          ? _c("l10n", {
                              attrs: {
                                value: "Optimize all categories at once."
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        scheduledRun.autoAccept
                          ? _c("l10n", {
                              attrs: {
                                value:
                                  "Automatically accept recommendations if price change is within {left} to {right} range.",
                                left: _vm.autoAcceptLeftText(
                                  scheduledRun.autoAcceptBoundsLeft
                                ),
                                right: _vm.autoAcceptRightText(
                                  scheduledRun.autoAcceptBoundsRight
                                )
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        scheduledRun.autoAcceptExcludeStrictViolations
                          ? _c("l10n", {
                              attrs: {
                                value:
                                  "Do not accept if strict rules are violated"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.expandedScheduledRuns[scheduledRunId]
                      ? [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              [_c("l10n", { attrs: { value: "Run at:" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: scheduledRun.time,
                                  expression: "scheduledRun.time"
                                }
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "time" },
                              domProps: { value: scheduledRun.time },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    scheduledRun,
                                    "time",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                [_c("l10n", { attrs: { value: "Run on:" } })],
                                1
                              ),
                              _vm._v(" "),
                              !scheduledRun.days.length
                                ? _c(
                                    "div",
                                    { staticClass: "btn-group-toggle" },
                                    _vm._l(
                                      [
                                        "Mon",
                                        "Tue",
                                        "Wed",
                                        "Thu",
                                        "Fri",
                                        "Sat",
                                        "Sun"
                                      ],
                                      function(dow) {
                                        return _c(
                                          "label",
                                          {
                                            key: dow,
                                            class: {
                                              "btn btn-sm btn-secondary": true,
                                              active:
                                                scheduledRun.dows.indexOf(
                                                  dow
                                                ) !== -1
                                            }
                                          },
                                          [
                                            _c("input", {
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked:
                                                  scheduledRun.dows.indexOf(
                                                    dow
                                                  ) !== -1
                                              },
                                              on: {
                                                change: function($event) {
                                                  $event.target.checked
                                                    ? scheduledRun.dows.push(
                                                        dow
                                                      )
                                                    : scheduledRun.dows.splice(
                                                        scheduledRun.dows.indexOf(
                                                          dow
                                                        ),
                                                        1
                                                      )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("l10n", {
                                              attrs: { value: dow }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !scheduledRun.dows.length
                                ? _c("v-calendar", {
                                    attrs: {
                                      locale: _vm.locale,
                                      "is-dark": _vm.darkTheme,
                                      "is-expanded": "",
                                      "trim-weeks": "",
                                      "title-position": "left",
                                      attributes: _vm.calendarAttributes(
                                        scheduledRun.days
                                      )
                                    },
                                    on: {
                                      dayclick: function($event) {
                                        scheduledRun.days.indexOf($event.id) >=
                                        0
                                          ? scheduledRun.days.splice(
                                              scheduledRun.days.indexOf(
                                                $event.id
                                              ),
                                              1
                                            )
                                          : scheduledRun.days.push($event.id)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                [_c("l10n", { attrs: { value: "Run for:" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c("gp-pills", {
                                attrs: {
                                  options: _vm.knownCategories,
                                  placeholder: _vm.pillsPlaceholder,
                                  "recent-options-key": "recentCategories"
                                },
                                model: {
                                  value: scheduledRun.categories,
                                  callback: function($$v) {
                                    _vm.$set(scheduledRun, "categories", $$v)
                                  },
                                  expression: "scheduledRun.categories"
                                }
                              }),
                              _vm._v(" "),
                              _c("gp-filter", {
                                attrs: {
                                  stream: _vm.stream,
                                  source: _vm.source,
                                  attributes: _vm.attributes,
                                  formulas: _vm.formulas,
                                  groups: ["gp-rules-filter", "reference-date"],
                                  vars: _vm.vars
                                },
                                model: {
                                  value: scheduledRun.filter,
                                  callback: function($$v) {
                                    _vm.$set(scheduledRun, "filter", $$v)
                                  },
                                  expression: "scheduledRun.filter"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "gp-check",
                                {
                                  model: {
                                    value: scheduledRun.combineCategories,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scheduledRun,
                                        "combineCategories",
                                        $$v
                                      )
                                    },
                                    expression: "scheduledRun.combineCategories"
                                  }
                                },
                                [
                                  _c("l10n", {
                                    attrs: {
                                      value: "Optimize all categories at once"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "gp-check",
                                {
                                  model: {
                                    value: scheduledRun.autoAccept,
                                    callback: function($$v) {
                                      _vm.$set(scheduledRun, "autoAccept", $$v)
                                    },
                                    expression: "scheduledRun.autoAccept"
                                  }
                                },
                                [
                                  _c("l10n", {
                                    attrs: {
                                      value:
                                        "Automatically accept recommendations"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              scheduledRun.autoAccept
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "gp-rules-auto-accept-bounds"
                                    },
                                    [
                                      _c("l10n", {
                                        attrs: {
                                          value: "if price change is within"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              scheduledRun.autoAcceptBoundsLeft,
                                            expression:
                                              "scheduledRun.autoAcceptBoundsLeft"
                                          }
                                        ],
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { type: "number", step: "0.1" },
                                        domProps: {
                                          value:
                                            scheduledRun.autoAcceptBoundsLeft
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              scheduledRun,
                                              "autoAcceptBoundsLeft",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("%")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              scheduledRun.autoAcceptBoundsRight,
                                            expression:
                                              "scheduledRun.autoAcceptBoundsRight"
                                          }
                                        ],
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { type: "number", step: "0.1" },
                                        domProps: {
                                          value:
                                            scheduledRun.autoAcceptBoundsRight
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              scheduledRun,
                                              "autoAcceptBoundsRight",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("%")])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteScheduledRun(
                                      scheduledRunId
                                    )
                                  }
                                }
                              },
                              [
                                _c("l10n", {
                                  attrs: {
                                    value:
                                      "Delete this scheduled optimization run"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                )
              }),
              0
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "span",
          {
            staticClass: "gp-rules-schedule__btn",
            on: { click: _vm.addScheduledRun }
          },
          [
            _c("feather-icon", { attrs: { name: "calendar" } }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Schedule optimization run" } })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }