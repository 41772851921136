var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { ref: "base", staticClass: "my-popper" }, [
    _c(
      "span",
      {
        key: "reference",
        ref: "reference",
        staticClass: "my-popper-reference"
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        key: "element",
        ref: "element",
        class: "my-popper-element-" + _vm.strategy
      },
      [_vm._t("popper")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }