var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-rules-configuration" },
    [
      _c("div", { staticClass: "form-check" }, [
        _c("input", {
          staticClass: "form-check-input",
          attrs: { id: "show-rules-configYaml", type: "checkbox" },
          domProps: { checked: _vm.showConfig },
          on: { click: _vm.toogleConfig }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "show-rules-configYaml" }
          },
          [_c("l10n", { attrs: { value: "Show configuration" } })],
          1
        )
      ]),
      _vm._v(" "),
      _vm.showConfig
        ? _c("ace-editor", {
            staticClass: "ace-editor",
            attrs: { mode: "yaml", "auto-height": true },
            model: {
              value: _vm.configYaml,
              callback: function($$v) {
                _vm.configYaml = $$v
              },
              expression: "configYaml"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfig
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: { type: "button" },
              on: { click: _vm.applyConfig }
            },
            [_c("l10n", { attrs: { value: "Submit changes" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }