<template>
    <div class="fulltext-search">
        <my-caption :caption="caption"/>

        <div class="form-group">
            <input class="form-control" v-model="query" autofocus/>
        </div>

        <div
            v-if="pageCount > 1"
            tabindex="0"
            @keydown="handlePaginatorKeyDown"
            class="plain-table-paginator form-group">

            <a
                href="javascript:void(0)"
                :class="{disabled: page === 0}"
                @click="desiredPage = page - 10">
                <feather-icon name="chevrons-left"/></a><a
                href="javascript:void(0)"
                :class="{disabled: page === 0}"
                @click="desiredPage = page - 1">
                <feather-icon name="chevron-left"/></a>

            <a href="javascript:void(0)" @click="promptPage">
                page {{new Number(page+1).toLocaleString()}}</a>

            <a
                href="javascript:void(0)"
                :class="{disabled: page === pageCount-1}"
                @click="desiredPage = page + 1">
                <feather-icon name="chevron-right"/></a><a
                href="javascript:void(0)"
                :class="{disabled: page === pageCount-1}"
                @click="desiredPage = page + 10">
                <feather-icon name="chevrons-right"/></a>

            <span class="nowrap">{{new Number(size).toLocaleString()}} rows,</span>
            <span class="nowrap">{{new Number(pageCount).toLocaleString()}} pages,</span>

            <span class="nowrap"><a href="javascript:void(0)" @click="promptPageSize">
                {{new Number(pageSize).toLocaleString()}}&nbsp;rows</a>&nbsp;per&nbsp;page</span>

            <inline-help
                text="Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward."
                :html="true"/>

        </div>

        <div class="form-group">
            <ol :style="{opacity: pending ? 0.5 : 1}" :start="start">
                <li v-for="doc in docs" :key="doc.key">
                    <p>
                        <a :href="doc.url" v-html="doc.title || 'Untitled'" target="_blank"/>
                        <div class="text-muted">
                            <small>
                                <div>
                                    <span v-if="doc.publish_time != new Date(0,0,0)">
                                        Published {{doc.publish_time.toLocaleDateString()}}.
                                    </span>{{doc.journal}}<span v-if="doc.doi">,
                                        doi:{{doc.doi}}</span><span v-if="doc.pmcid">,
                                        pmcid:{{doc.pmcid}}</span><span v-if="doc.pubmed_id">,
                                        pubmed:{{doc.pubmed_id}}</span><span v-if="doc.license">,
                                        license:{{doc.license}}</span>
                                </div>
                                <div v-if="doc.authors">Authors: {{doc.authors}}</div>
                            </small>
                        </div>
                    </p>
                    <p v-html="doc.abstract" v-if="doc.abstract"/>
                </li>
            </ol>
        </div>

        <data-engine
            id="engine1"
            :stream="stream"
            :dims="dims1"
            :vals="vals1"
            :sort_="sort1"
            :take_="take1"
            :skip_="skip1"
            filter3="val1 > 0"
            @report-updated="report1Updated"
            @reportId-updated="reportId1Updated"
            />

        <data-engine
            id="engine2"
            :stream="stream"
            :dims="dims2"
            :vals="vals2"
            :filter2="filter2"
            :instant="true"
            group="nofollow"
            @report-updated="report2Updated"
            @reportId-updated="reportId2Updated"
            />
    </div>
</template>
<script>
let utils = require("./my-utils")
module.exports = {
    data: function() {
        return {
            query: "",
            meta1: null,
            meta2: null,
            report1: null,
            report2: null,
            pageSize: 10,
            desiredPage: 0,
            reportId1: null,
            reportId2: null,
            pending: false,
        }
    },
    props: {
        stream: { type: String, default: "default" },
        pkey: { type: String, default: "pkey" },
        searchFields: { type: Array, default: () => [] },
        previewFields: { type: Array, default: () => [] },
        caption: { type: String },
    },
    components: {
        "data-engine": {
            mixins: [
                require("./data.js"),
            ],
            watch: {
                report() {
                    this.$emit("report-updated", this.report, this.meta)
                },
                reportId() {
                    this.$emit("reportId-updated", this.reportId)   
                },
            },
            render: function(h) {
                return h("div")
            },
            props: {
                sort_: { type: Array, default: null },
                take_: { type: Number, default: null },
                skip_: { type: Number, default: null },
            },
            computed: {
                sort() { return this.sort_ },
                take() { return this.take_ },
                skip() { return this.skip_ },
            }
        }
    },
    computed: {
        dims1() { return [this.pkey] },
        vals1() { return [{
            calc: `search(${utils.quote_string(this.query)}, ${this.searchFields.join(",")})`,
            name: this.query
        }] },
        sort1() { return [-2] },
        dims2() { return [this.pkey] },
        vals2() {
            if (this.meta1) {
                let query = this.meta1.columns[1].name
                return this.searchFields.map((field) =>
                    `isempty(snippet(${utils.quote_string(query)}, ${field}, 4000), htmlescape(${field}))`).concat(this.previewFields)
            }
            else return this.searchFields.map(() => "''")
        },
        filter2() { return `${this.pkey} in [${this.keys.map(utils.quote_string).join(",")}]` },
        keys() { return this.report1 ? this.report1.rows.map(([key]) => key) : [] },
        scores() { return this.report1 ? _.fromPairs(this.report1.rows) : {} },
        docs() {
            return this.report2 ?
                _(this.report2.rows)
                    .map((row) => {
                        let key = row[0]
                        let doc = {key}
                        _.forEach(this.searchFields.concat(this.previewFields), (field, i) =>doc[field] = row[i+1])
                        doc.score = this.scores[key]
                        return doc
                    })
                    .orderBy(["score"], ["desc"])
                    .value() : []
        },
        size() {
            return this.report1 ? this.report1.size : 0
        },
        pageCount() {
            return Math.floor((this.size + this.pageSize - 1) / this.pageSize)
        },
        page() {
            return Math.max(0, Math.min(this.pageCount - 1, this.desiredPage))
        },
        take1() {
            return this.computeFetchWindow().take
        },
        skip1() {
            return this.computeFetchWindow().skip
        },
        start() {
            return this.meta1 ? this.meta1.args.skip + 1 : 1
        }
    },
    methods: {
        updatePending(instant) {
            if (instant) {
                this.pending = this.reportId1 || this.reportId2;
            }
            else {
                clearTimeout(this.updatePendingTimeout)
                this.updatePendingTimeout = setTimeout(() => this.updatePending(true), 10);
            }
        },
        report1Updated(report, meta) {
            this.report1 = report
            this.meta1 = meta
        },
        report2Updated(report, meta) {
            this.report2 = report
            this.meta2 = meta
        },
        reportId1Updated(reportId) {
            this.reportId1 = reportId
            this.updatePending()
        },
        reportId2Updated(reportId) {
            this.reportId2 = reportId
            this.updatePending()
        },
        computeFetchWindow() {
            let skip = this.page * this.pageSize
            let take = this.pageSize
            return {skip, take}
        },
        promptPage() {
            let page = prompt("Go to page:", this.page+1)
            if (page !== null) {
                page = parseInt(page)
                if (!_.isNaN(page))
                    this.desiredPage = page-1
            }
        },
        promptPageSize() {
            let pageSize = prompt("Rows per page:", this.pageSize)
            if (pageSize !== null) {
                pageSize = parseInt(pageSize)
                if (!_.isNaN(pageSize))
                    this.pageSize = pageSize
            }
        },
        handlePaginatorKeyDown: function(e) {
            switch (e.key) {
                case "ArrowLeft":
                    e.preventDefault()
                    e.stopPropagation()
                    this.desiredPage = this.page - (e.shiftKey ? 10 : 1)
                    break
                case "ArrowRight":
                    e.preventDefault()
                    e.stopPropagation();
                    this.desiredPage = this.page + (e.shiftKey ? 10 : 1)
                    break
            }
        },
    }
}
</script>
<style>
    
</style>