var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode == "compact"
    ? _c("div", { ref: "tree", staticClass: "pim-tree compact" }, [
        _c(
          "ul",
          [
            _vm._l(_vm.focusPath, function(entry) {
              return _c(
                "li",
                {
                  key: entry.key || entry.id,
                  staticClass: "pim-tree-entry",
                  class: { focused: entry == _vm.focus },
                  on: {
                    click: function($event) {
                      _vm.focus = entry
                    }
                  }
                },
                [
                  _c("div", { staticClass: "pim-tree-entry-head" }, [
                    _c("div", { staticClass: "pim-tree-entry-name" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(entry.name) +
                          "\n                    "
                      ),
                      entry == _vm.focus && entry.parent
                        ? _c(
                            "a",
                            {
                              staticClass: "pim-tree-entry-close",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  _vm.focus = entry.parent
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_c("feather-icon", { attrs: { name: "x" } })],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.focus && _vm.focus.children,
                    expression: "focus && focus.children"
                  }
                ],
                key: "next"
              },
              [
                _c("div", { staticClass: "pim-tree-entry-next" }, [
                  _c(
                    "select",
                    {
                      ref: "select",
                      attrs: {
                        placeholder: _vm.placeholder,
                        "data-placeholder": _vm.placeholder
                      },
                      on: {
                        change: function($event) {
                          _vm.focus = _vm.focus.children.find(function(child) {
                            return child.id == $event.target.value
                          })
                        }
                      }
                    },
                    [
                      _c("option", {
                        attrs: { value: "", disabled: "disabled" },
                        domProps: { selected: true }
                      }),
                      _vm._v(" "),
                      _vm.focus && _vm.focus.children
                        ? _vm._l(_vm.focus.children, function(child) {
                            return _c(
                              "option",
                              { key: child.id, domProps: { value: child.id } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(child.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ]
            )
          ],
          2
        )
      ])
    : _c(
        "div",
        { ref: "tree", staticClass: "pim-tree" },
        [
          _c("div", { staticClass: "pim-tree-move" }, [
            _c("div", [
              _c("span"),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: !(_vm.focus && _vm.canMoveUp(_vm.focus)) },
                  attrs: {
                    tabindex: "-1",
                    href: "javascript:void(0)",
                    title: "Shift+ArrowUp"
                  },
                  on: { click: _vm.moveUp }
                },
                [_c("feather-icon", { attrs: { name: "arrow-up" } })],
                1
              ),
              _vm._v(" "),
              _c("span")
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  class: {
                    disabled: !(_vm.focus && _vm.canMoveLeft(_vm.focus))
                  },
                  attrs: {
                    tabindex: "-1",
                    href: "javascript:void(0)",
                    title: "Shift+ArrowLeft"
                  },
                  on: { click: _vm.moveLeft }
                },
                [_c("feather-icon", { attrs: { name: "arrow-left" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: !_vm.focus },
                  attrs: { tabindex: "-1", href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.showMenu = true
                    }
                  }
                },
                [_c("feather-icon", { ref: "menu", attrs: { name: "menu" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    disabled: !(_vm.focus && _vm.canMoveRight(_vm.focus))
                  },
                  attrs: {
                    tabindex: "-1",
                    href: "javascript:void(0)",
                    title: "Shift+ArrowRight"
                  },
                  on: { click: _vm.moveRight }
                },
                [_c("feather-icon", { attrs: { name: "arrow-right" } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span"),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: {
                    disabled: !(_vm.focus && _vm.canMoveDown(_vm.focus))
                  },
                  attrs: {
                    tabindex: "-1",
                    href: "javascript:void(0)",
                    title: "Shift+ArrowDown"
                  },
                  on: { click: _vm.moveDown }
                },
                [_c("feather-icon", { attrs: { name: "arrow-down" } })],
                1
              ),
              _vm._v(" "),
              _c("span")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pim-tree-head" }, [
            _c(
              "div",
              { staticClass: "pim-tree-search" },
              [
                _c("my-search", {
                  ref: "search",
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                }),
                _vm._v(" "),
                _c("pim-filter", {
                  attrs: { fields: _vm.fields },
                  model: {
                    value: _vm.filter,
                    callback: function($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter"
                  }
                }),
                _vm._v(" "),
                _c(
                  "gp-check",
                  {
                    model: {
                      value: _vm.multiselect,
                      callback: function($$v) {
                        _vm.multiselect = $$v
                      },
                      expression: "multiselect"
                    }
                  },
                  [
                    _vm._v(
                      "\n                Enable multiselect mode\n            "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pim-tree-entries" }, [
            _c("table", [
              _c(
                "tbody",
                [
                  _vm._l(_vm.visibleEntries, function(entry) {
                    return [
                      entry.type == "tiles"
                        ? _c(
                            "div",
                            { staticClass: "pim-tree-tiles" },
                            _vm._l(entry.tiles, function(tile) {
                              return _c(
                                "div",
                                {
                                  key: tile.id,
                                  staticClass: "pim-tree-tile",
                                  class: { focused: tile == _vm.focus },
                                  on: {
                                    click: function($event) {
                                      _vm.focus = tile
                                    }
                                  }
                                },
                                [
                                  tile.attrs.sequence
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "pim-tree-tile-sequence"
                                        },
                                        [_vm._v(_vm._s(tile.attrs.sequence))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  tile.attrs.is_primary_image
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "pim-tree-tile-primary"
                                        },
                                        [_vm._v("primary")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  tile.image
                                    ? _c("img", { attrs: { src: tile.image } })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("label", [_vm._v(_vm._s(tile.name))]),
                                  _vm._v(" "),
                                  tile.desc
                                    ? _c("p", [_vm._v(_vm._s(tile.desc))])
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        : entry.type == "table"
                        ? _c("pim-tree-table", {
                            style: {
                              "--level":
                                _vm.getEntryLevel(entry.entry, _vm.roots) + 1
                            },
                            attrs: {
                              entry: entry.entry,
                              entries: entry.entries,
                              fields: _vm.fields
                            },
                            on: {
                              focus: function($event) {
                                _vm.focus = $event
                              }
                            }
                          })
                        : entry.type == "chart"
                        ? _c("pim-tree-chart", {
                            style: {
                              "--level":
                                _vm.getEntryLevel(entry.entry, _vm.roots) + 1
                            },
                            attrs: {
                              entry: entry.entry,
                              entries: entry.entries,
                              fields: _vm.fields,
                              config: _vm.config
                            },
                            on: {
                              focus: function($event) {
                                _vm.focus = $event
                              },
                              context: function($event) {
                                return _vm.$emit("context", $event)
                              },
                              "open-in-tab": function($event) {
                                return _vm.$emit("open-in-tab", $event)
                              }
                            }
                          })
                        : entry.type == "diagram" &&
                          entry.entry.type == "workflow" &&
                          entry.entry.childrenType == "function" &&
                          entry.entry.children &&
                          entry.entry.children.length > 0
                        ? _c("pim-workflow", {
                            style: {
                              "--level":
                                _vm.getEntryLevel(entry.entry, _vm.roots) + 1
                            },
                            attrs: {
                              workflow: entry.entry,
                              settings: _vm.workflow.settings,
                              nodeTemplate: _vm.workflow.nodeTemplate,
                              linkTemplate: _vm.workflow.linkTemplate,
                              nodeTemplateMap: _vm.workflow.nodeTemplateMap,
                              linkTemplateMap: _vm.workflow.linkTemplateMap,
                              diagramLayout: _vm.workflow.diagramLayout,
                              paletteLayout: _vm.workflow.paletteLayout,
                              diagramModel: {
                                class: "go.GraphLinksModel",
                                linkFromPortIdProperty: "fromPort",
                                linkToPortIdProperty: "toPort",
                                nodeDataArray: [
                                  {
                                    key: "Start",
                                    text: "Start",
                                    category: "Start"
                                  },
                                  {
                                    key: "End",
                                    text: "End",
                                    category: "End"
                                  }
                                ].concat(
                                  entry.entry.children.map(function(child) {
                                    return {
                                      key: child.id,
                                      name: child.name,
                                      attrs: child.attrs,
                                      category: child.name.endsWith(
                                        "Translations"
                                      )
                                        ? "Automation"
                                        : "Function"
                                    }
                                  })
                                ),
                                linkDataArray: [
                                  {
                                    from: "Start",
                                    to: entry.entry.children[0].id,
                                    fromPort: "R",
                                    toPort: "L"
                                  },
                                  {
                                    from:
                                      entry.entry.children[
                                        entry.entry.children.length - 1
                                      ].id,
                                    to: "End",
                                    fromPort: "R",
                                    toPort: "L"
                                  }
                                ].concat(
                                  entry.entry.children
                                    .slice(1)
                                    .map(function(child, i) {
                                      return {
                                        from: entry.entry.children[i].id,
                                        to: child.id,
                                        fromPort: "R",
                                        toPort: "L"
                                      }
                                    })
                                )
                              },
                              paletteModel: _vm.workflow.paletteModel,
                              autoheight: _vm.workflow.autoheight
                            },
                            on: {
                              selection: function($event) {
                                $event && $event.length > 0
                                  ? _vm.$emit(
                                      "focus",
                                      entry.entry.children.find(function(
                                        child
                                      ) {
                                        return child.id == $event[0].key
                                      })
                                    )
                                  : null
                              }
                            }
                          })
                        : entry.type == "reset-children"
                        ? _c(
                            "div",
                            {
                              staticClass: "pin-tree-reset-children",
                              style: {
                                "--level":
                                  _vm.getEntryLevel(entry.entry, _vm.roots) + 1
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loadChildren(
                                        entry.entry.children,
                                        entry.childrenType
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            open all " +
                                      _vm._s(
                                        _vm.pluralize(entry.entry.childrenType)
                                      ) +
                                      " to " +
                                      _vm._s(
                                        _vm.pluralize(entry.childrenType)
                                      ) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ]
                          )
                        : entry.type == "paginator"
                        ? _c("pim-tree-paginator", {
                            ref: "entries",
                            refInFor: true,
                            attrs: {
                              entry: entry,
                              classify: _vm.classify,
                              pluralize: _vm.pluralize
                            },
                            on: {
                              focus: function($event) {
                                _vm.focus = $event
                              }
                            }
                          })
                        : entry.type == "multiselect"
                        ? _c("pim-tree-multiselect", {
                            ref: "entries",
                            refInFor: true,
                            attrs: {
                              entry: entry,
                              classify: _vm.classify,
                              pluralize: _vm.pluralize
                            },
                            on: {
                              focus: function($event) {
                                _vm.focus = $event
                              }
                            }
                          })
                        : _c("pim-tree-entry", {
                            key: entry.key || entry.id,
                            ref: "entries",
                            refInFor: true,
                            class: { focused: entry == _vm.focus },
                            attrs: {
                              entry: entry,
                              pluralize: _vm.pluralize,
                              multiselect: _vm.multiselect,
                              formatColumn: _vm.formatColumn
                            },
                            on: {
                              focus: function($event) {
                                _vm.focus = $event
                              },
                              open: _vm.openEntry,
                              close: _vm.closeEntry
                            }
                          })
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _vm.focus && _vm.showMenu
            ? _c(
                "my-popup",
                {
                  attrs: {
                    portal: "popup",
                    draggable: true,
                    placement: "bottom",
                    anchor: _vm.$refs.menu.$el
                  },
                  on: {
                    clickoutside: function($event) {
                      _vm.showMenu = false
                    }
                  }
                },
                [
                  _c("div", { staticClass: "popover pim-tree-menu" }, [
                    _c("div", { staticClass: "popover-body" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.capitalize(_vm.focus.type)) +
                            " " +
                            _vm._s(_vm.focus.name)
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        [
                          _vm.focus.parent
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        _vm.showMenu = false
                                        _vm.$emit("context", _vm.focus)
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "file-plus" }
                                    }),
                                    _vm._v(
                                      "\n                            Set this " +
                                        _vm._s(_vm.focus.type) +
                                        " as a context\n                        "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.focus
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        _vm.showMenu = false
                                        _vm.$emit("open-in-tab", _vm.focus)
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "external-link" }
                                    }),
                                    _vm._v(
                                      "\n                            Open this " +
                                        _vm._s(_vm.focus.type) +
                                        " in new tab\n                        "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.focus.children &&
                          _vm.focus.opened &&
                          _vm.focus.children.length
                            ? _c("li", [
                                _c(
                                  "a",
                                  { attrs: { href: "javascript:void(0)" } },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "eye" }
                                    }),
                                    _vm._v(" "),
                                    _c("feather-icon", {
                                      attrs: { name: "chevron-right" }
                                    }),
                                    _vm._v(
                                      "\n                            Show child " +
                                        _vm._s(
                                          _vm.pluralize(_vm.focus.childrenType)
                                        ) +
                                        " as ...\n                        "
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("ul", [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(_vm.focus, "mode", "tiles")
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.mode == "tiles"
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    tiles\n                                "
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(_vm.focus, "mode", "table")
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.mode == "table"
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a table\n                                "
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$delete(_vm.focus, "mode")
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name: !_vm.focus.mode
                                              ? "check"
                                              : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a tree\n                                "
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(_vm.focus, "mode", "chart")
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.mode == "chart"
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a chart\n                                "
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.focus.type == "workflow"
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showMenu = false
                                                _vm.$set(
                                                  _vm.focus,
                                                  "mode",
                                                  "diagram"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                name:
                                                  _vm.focus.mode == "diagram"
                                                    ? "check"
                                                    : "square"
                                              }
                                            }),
                                            _vm._v(
                                              "\n                                    a diagram\n                                "
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.focus.mode == "chart"
                            ? _c("li", [
                                _c(
                                  "a",
                                  { attrs: { href: "javascript:void(0)" } },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "pie-chart" }
                                    }),
                                    _vm._v(" "),
                                    _c("feather-icon", {
                                      attrs: { name: "chevron-right" }
                                    }),
                                    _vm._v(
                                      "\n                            Switch chart type to ...\n                        "
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("ul", [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(
                                              _vm.focus,
                                              "chartType",
                                              "pie-chart"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.chartType ==
                                                "pie-chart" ||
                                              !_vm.focus.chartType
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a pie chart\n                                "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(
                                              _vm.focus,
                                              "chartType",
                                              "bar-chart"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.chartType == "bar-chart"
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a bar chart\n                                "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(
                                              _vm.focus,
                                              "chartType",
                                              "bubble-chart"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.chartType ==
                                              "bubble-chart"
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a bubble chart\n                                "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(
                                              _vm.focus,
                                              "chartType",
                                              "scatter-chart"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.chartType ==
                                              "scatter-chart"
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a scatter chart\n                                "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.showMenu = false
                                            _vm.$set(
                                              _vm.focus,
                                              "chartType",
                                              "time-series"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            name:
                                              _vm.focus.chartType ==
                                              "time-series"
                                                ? "check"
                                                : "square"
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    a time series chart\n                                "
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.traverse(_vm.focus.type).length == 1
                            ? _vm._l(_vm.traverse(_vm.focus.type), function(
                                type
                              ) {
                                return _c("li", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          _vm.showMenu = false
                                          _vm.openEntry(_vm.focus, type)
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "book-open" }
                                      }),
                                      _vm._v(
                                        "\n                                Open this " +
                                          _vm._s(_vm.focus.type) +
                                          " to " +
                                          _vm._s(_vm.pluralize(type)) +
                                          "\n                            "
                                      )
                                    ],
                                    1
                                  )
                                ])
                              })
                            : _vm.traverse(_vm.focus.type).length > 1
                            ? [
                                _c("li", [
                                  _c(
                                    "a",
                                    { attrs: { href: "javascript:void(0)" } },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "book-open" }
                                      }),
                                      _vm._v(" "),
                                      _c("feather-icon", {
                                        attrs: { name: "chevron-right" }
                                      }),
                                      _vm._v(
                                        "\n                                Open this " +
                                          _vm._s(_vm.focus.type) +
                                          " to ...\n                            "
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.traverse(_vm.focus.type),
                                      function(type) {
                                        return _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.showMenu = false
                                                  _vm.openEntry(_vm.focus, type)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.pluralize(type)) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.focus.childrenType || _vm.classify(_vm.focus.type)
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        _vm.showMenu = false
                                        _vm.reloadChildren(_vm.focus)
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "refresh-cw" }
                                    }),
                                    _vm._v(
                                      "\n                            Reload child " +
                                        _vm._s(
                                          _vm.pluralize(
                                            _vm.focus.childrenType ||
                                              _vm.classify(_vm.focus.type)
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.focus.type == "category"
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        _vm.showMenu = false
                                        _vm.createSubEntry()
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "file-plus" }
                                    }),
                                    _vm._v(
                                      "\n                            Create " +
                                        _vm._s(
                                          _vm.addArticle(
                                            _vm.classify(_vm.focus.type)
                                          )
                                        ) +
                                        " under\n                        "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.focus.type == "category"
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        _vm.showMenu = false
                                        _vm.createEntryAfter()
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "file-plus" }
                                    }),
                                    _vm._v(
                                      "\n                            Create " +
                                        _vm._s(_vm.addArticle(_vm.focus.type)) +
                                        " right after\n                        "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.focus.type == "category"
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        _vm.showMenu = false
                                        _vm.removeThisEntry()
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "trash" }
                                    }),
                                    _vm._v(
                                      "\n                            Remove this " +
                                        _vm._s(_vm.focus.type) +
                                        "\n                        "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    _vm.showMenu = false
                                    _vm.exportAttributes()
                                  }
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { name: "download" }
                                }),
                                _vm._v(
                                  "\n                            Export attributes\n                        "
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    _vm.showMenu = false
                                    _vm.importAttributes()
                                  }
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { name: "upload" }
                                }),
                                _vm._v(
                                  "\n                            Import attributes\n                        "
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.actions, function(action) {
                            return _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.showMenu = false
                                      action.call ? action.call() : null
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { name: action.icon }
                                  }),
                                  _vm._v(" "),
                                  action.children
                                    ? _c("feather-icon", {
                                        attrs: { name: "chevron-right" }
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(action.name) +
                                      "\n                        "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              action.children
                                ? _c(
                                    "ul",
                                    _vm._l(action.children, function(child) {
                                      return _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showMenu = false
                                                child.call ? child.call() : null
                                              }
                                            }
                                          },
                                          [
                                            child.icon
                                              ? _c("feather-icon", {
                                                  attrs: { name: child.icon }
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(child.name) +
                                                "\n                                "
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumns
            ? _c(
                "my-popup",
                {
                  attrs: {
                    draggable: true,
                    placement: "bottom",
                    anchor: _vm.$refs.menu.$el
                  },
                  on: {
                    clickoutside: function($event) {
                      _vm.showColumns = false
                    }
                  }
                },
                [
                  _c("div", { staticClass: "popover pim-tree-columns" }, [
                    _c(
                      "div",
                      { staticClass: "popover-body" },
                      [
                        _c("pim-columns", {
                          attrs: {
                            autofocus: true,
                            columns: _vm.columns,
                            fields: _vm.fields
                          },
                          on: {
                            submit: function($event) {
                              _vm.showColumns = false
                              _vm.columns = $event.columns
                            },
                            cancel: function($event) {
                              _vm.showColumns = false
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("form", { ref: "uploadForm", staticStyle: { display: "none" } }, [
            _c("input", {
              ref: "uploadInput",
              attrs: { type: "file" },
              on: { change: _vm.handleFileUpload }
            })
          ]),
          _vm._v(" "),
          _vm.showExportDialog
            ? _c("pim-export-dialog", {
                attrs: { entry: _vm.focus, fields: _vm.fields },
                on: {
                  close: function($event) {
                    _vm.showExportDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }