module.exports = _.merge({
    methods: {
        makeChart(base, instant) {
            let dropNotShown = xs => xs.filter(x => x.show !== false)
            let n = dropNotShown(this.dims).length
            let m = dropNotShown(this.vals).length + dropNotShown(this.cols).length

            let dim = this.ndx.dimension((row) => row.slice(0,n))

            let grp = dim.group().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))

            let chart = dc.bubbleChart(base)

            chart.dimension(dim)
            chart.group(grp)
            // chart.title((d) => `${d.key[0]}\n${d.key[1]}\n${d.value[0]}`)
            // chart.on("renderlet",     () => this.tuneChart(chart))
            // chart.on("pretransition", () => this.tuneChart(chart))

            return chart
        },
    },
}, require("./props").chartProps({
    elasticRadius:  { type: Boolean, default: false },
    sortBubbleSize: { type: Boolean, default: false },
}))

