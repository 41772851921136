var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim" },
    [
      _c(
        "div",
        {
          key: "main",
          staticClass: "pim-main",
          class: { "has-side": _vm.sideShow, "has-foot": _vm.footShow }
        },
        [
          _c("gp-news", { attrs: { id: "news" } }),
          _vm._v(" "),
          _c(
            "gp-portal",
            { attrs: { to: "pim-functions" } },
            [
              _c("gp-select", {
                attrs: {
                  value: _vm.function_,
                  options: _vm.functions,
                  placeholder: "Function"
                },
                on: {
                  change: function($event) {
                    _vm.function_ = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { key: "head", staticClass: "pim-main-head" },
            [
              _c("portal-target", { attrs: { name: "pim-functions" } }),
              _vm._v(" "),
              _c("pim-tree", {
                ref: "headTree",
                attrs: {
                  mode: "compact",
                  id: "headTree",
                  config: _vm.config,
                  search: _vm.search,
                  fields: _vm.fields,
                  locales: _vm.locales,
                  statuses: _vm.statuses,
                  storefronts: _vm.storefronts,
                  workflow: _vm.workflow,
                  roots: _vm.headRoots
                },
                on: {
                  focus: function($event) {
                    _vm.headFocus = $event
                  },
                  selection: function($event) {
                    _vm.headSelection = $event
                  },
                  navigate: function($event) {
                    _vm.headRoots = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { key: "body", staticClass: "pim-main-body" },
            [
              _c("pim-tree", {
                ref: "bodyTree",
                attrs: {
                  id: "bodyTree",
                  config: _vm.config,
                  search: _vm.search,
                  fields: _vm.fields,
                  locales: _vm.locales,
                  statuses: _vm.statuses,
                  storefronts: _vm.storefronts,
                  workflow: _vm.workflow,
                  roots: _vm.bodyRoots,
                  actions: [
                    {
                      icon: "link",
                      name: "Create a permalink",
                      call: function() {
                        return _vm.createPermalink(true)
                      }
                    },
                    {
                      icon: "user",
                      name: "Switch function to ...",
                      children: _vm.functions.map(function(f) {
                        return {
                          icon:
                            _vm.function_ && f.id == _vm.function_.id
                              ? "check"
                              : "square",
                          name: f.name,
                          call: function() {
                            return (_vm.function_ = f)
                          }
                        }
                      })
                    }
                  ]
                },
                on: {
                  focus: function($event) {
                    _vm.bodyFocus = $event
                  },
                  selection: function($event) {
                    _vm.bodySelection = $event
                  },
                  context: _vm.setAsContext,
                  "open-in-tab": _vm.openInTab
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { key: "tabs", staticClass: "pim-main-tabs" },
            [
              _c(
                "pim-tabs",
                {
                  attrs: {
                    tabs: {
                      items: "Items",
                      variations: "Variations",
                      simulation: "Simulation"
                    }
                  },
                  on: {
                    click: function($event) {
                      _vm.footShow = !_vm.footShow
                    }
                  },
                  model: {
                    value: _vm.footMode,
                    callback: function($$v) {
                      _vm.footMode = $$v
                    },
                    expression: "footMode"
                  }
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      name: _vm.footShow ? "chevrons-down" : "chevrons-up"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.footShow
            ? _c("pim-hierarchies-foot", {
                key: "foot",
                attrs: {
                  mode: _vm.footMode,
                  show: _vm.footShow,
                  entry: _vm.bodyFocus,
                  config: _vm.config,
                  username: _vm.username,
                  search: _vm.search,
                  fields: _vm.fields
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pim-side-tabs" },
        [
          _c(
            "pim-tabs",
            {
              attrs: { mode: "vertical", tabs: _vm.sideTabs },
              on: {
                click: function($event) {
                  _vm.sideShow = !_vm.sideShow
                }
              },
              model: {
                value: _vm.sideMode,
                callback: function($$v) {
                  _vm.sideMode = $$v
                },
                expression: "sideMode"
              }
            },
            [
              _c("feather-icon", {
                attrs: {
                  name: _vm.sideShow ? "chevrons-right" : "chevrons-left"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.sideShow
        ? _c(
            "div",
            { key: "side", staticClass: "pim-side" },
            [
              _vm.sideMode == "fields"
                ? _c("pim-fields", {
                    attrs: {
                      locales: _vm.locales,
                      statuses: _vm.statuses,
                      storefronts: _vm.storefronts,
                      fields: _vm.fields_,
                      templates: _vm.templates,
                      darkTheme: _vm.darkTheme
                    },
                    on: {
                      change: function($event) {
                        _vm.fields = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sideMode == "attributes" &&
              _vm.entries.length &&
              _vm.entries[0].type != "company" &&
              _vm.entries[0].type != "storefront"
                ? _c("pim-attributes-ex", {
                    attrs: {
                      locales: _vm.locales,
                      statuses: _vm.statuses,
                      storefronts: _vm.storefronts,
                      functions: _vm.functions,
                      function_: _vm.function_,
                      focus: _vm.focus,
                      entries: _vm.entries,
                      fields: _vm.fields,
                      username: _vm.username,
                      darkTheme: _vm.darkTheme,
                      pluralize: function(type) {
                        return (
                          {
                            company: "companies",
                            storefront: "storefronts",
                            category: "categories",
                            item: "items",
                            variation: "variations",
                            sku: "skus",
                            upc: "upcs"
                          }[type] || type
                        )
                      },
                      romanceCopyField: _vm.romanceCopyField,
                      romanceCopyPrompt: _vm.romanceCopyPrompt
                    },
                    on: {
                      focus: function($event) {
                        _vm.$refs.bodyTree.focus = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sideMode == "filters" ||
              _vm.sideMode == "strategies" ||
              _vm.sideMode == "assignment"
                ? _c("pim-hierarchies-side", {
                    attrs: {
                      mode: _vm.sideMode,
                      entry: _vm.entry,
                      username: _vm.username,
                      search: _vm.search,
                      fields: _vm.fields
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sideMode == "tasks"
                ? _c(
                    "div",
                    { staticClass: "pim-tasks" },
                    [
                      _c("h2", [_vm._v("Tasks")]),
                      _vm._v(" "),
                      _c("gp-tasks", {
                        attrs: {
                          product: "pim",
                          username: _vm.username,
                          darkTheme: _vm.darkTheme
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sideMode == "workflow"
                ? _c("pim-workflow", {
                    attrs: {
                      settings: _vm.workflow.settings,
                      nodeTemplate: _vm.workflow.nodeTemplate,
                      linkTemplate: _vm.workflow.linkTemplate,
                      nodeTemplateMap: _vm.workflow.nodeTemplateMap,
                      linkTemplateMap: _vm.workflow.linkTemplateMap,
                      diagramLayout: _vm.workflow.diagramLayout,
                      paletteLayout: _vm.workflow.paletteLayout,
                      diagramModel: _vm.workflow.diagramModel,
                      paletteModel: _vm.workflow.paletteModel,
                      autoheight: _vm.workflow.autoheight
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sideMode == "function" &&
              _vm.bodyFocus &&
              _vm.bodyFocus.type == "function"
                ? _c("pim-function", {
                    key: _vm.bodyFocus.id,
                    attrs: {
                      entry: _vm.bodyFocus,
                      users: _vm.users,
                      fields: _vm.fields,
                      storefronts: _vm.storefronts
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: {
          stream: "derived_templates",
          dims: ["sku_master_field_id"],
          vals: [
            "template_file_name",
            "template_file_data",
            "template_content_type",
            "metadata_file_name",
            "metadata_content_type"
          ],
          filter2: "is_active_version"
        },
        on: {
          change: function($event) {
            _vm.templates = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: {
          stream: "fields",
          vals: [
            "field_name",
            "api_group_name",
            "api_name",
            "brand_id",
            "derived_expr",
            "derived_type",
            "description",
            "field_type",
            "group_name",
            "has_locales",
            "has_storefronts",
            "is_attribute",
            "is_derived",
            "is_locked",
            "is_readonly",
            "is_reverse",
            "is_searchable",
            "is_template_driven",
            "level",
            "locale_id",
            "lookup_id",
            "overridable",
            "parent_id",
            "sequence_order",
            "template_method"
          ]
        },
        on: {
          change: function($event) {
            _vm.fields_ = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: { stream: "locales" },
        on: {
          change: function($event) {
            _vm.locales = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: { stream: "brands" },
        on: {
          change: function($event) {
            _vm.storefronts = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: {
          stream: "departments",
          vals: [
            "department_name as name",
            "department_code",
            "department_name"
          ]
        },
        on: {
          change: function($event) {
            _vm.functions = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: {
          stream: "pim_workflow_statuses",
          vals: ["name", "status", "department.id as department_id"]
        },
        on: {
          change: function($event) {
            _vm.statuses = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: {
          stream: "department_field_relations",
          vals: [
            "department_id",
            "sku_master_field_id",
            "mandatory",
            "viewable",
            "editable",
            "mass_update"
          ]
        },
        on: {
          change: function($event) {
            _vm.relations = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-stream", {
        attrs: {
          stream: "users",
          vals: [
            "first_name",
            "last_name",
            "job_title",
            "department_id",
            "state",
            "active",
            "is_active"
          ],
          filter2: "state == 'active'"
        },
        on: {
          change: function($event) {
            _vm.users = $event
          }
        }
      }),
      _vm._v(" "),
      _c("pim-permalink", {
        ref: "permalink",
        attrs: {
          anchor: _vm.$refs.bodyTree ? _vm.$refs.bodyTree.$refs.menu.$el : null
        }
      }),
      _vm._v(" "),
      _c("pim-image-over")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }